import withStyles from '@material-ui/core/styles/withStyles';
import SwitchMui from '@material-ui/core/Switch';
import FormControlLabelMui from '@material-ui/core/FormControlLabel';

const SwitchStyle = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },

  switchBase: {
    padding: 2,
    color: theme.palette.darkGrey[50],

    '&$checked': {
      transform: 'translateX(24px)',
      color: (props) => (props.disabled ? theme.palette.darkGrey[50] : theme.palette.secondary.main),

      '& + $track': {
        backgroundColor: theme.palette.darkGrey[2],
        opacity: 1,
        border: `0.5px solid ${theme.palette.darkGrey[50]}`,
      },
    },

    '&$focusVisible $thumb': {
      color: theme.palette.darkGrey[50],
      border: `0.5px solid ${theme.palette.darkGrey[50]}`,
    },
  },

  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
  },

  track: {
    borderRadius: 26 / 2,
    border: `0.5px solid ${theme.palette.darkGrey[15]}`,
    backgroundColor: theme.palette.darkGrey[2],
    opacity: 1,
  },

  checked: {},
  focusVisible: {},
  disabled: {},
}))(({ classes, ...props }) => {
  return (
    <SwitchMui
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      disableRipple
      focusVisibleClassName={classes.focusVisible}
      {...props}
    />
  );
});

const FormControlLabel = withStyles((theme) => ({
  root: {
    margin: 0,
  },
  label: {
    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },
    marginLeft: theme.spacing(2),
  },
  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabelMui);

function Switch({ defaultChecked, ...others }) {
  return <FormControlLabel control={<SwitchStyle defaultChecked={defaultChecked} />} {...others} />;
}

Switch.propTypes = {
  ...Switch.propTypes,
};

export default Switch;
