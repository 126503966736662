import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(340),
    padding: `${theme.typography.pxToRem(17)} ${theme.typography.pxToRem(20)}`,
  },
  typography: {
    padding: theme.spacing(2),
  },

  container: {
    display: 'flex',
  },
}));

export default useStyles;
