import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  createInventory,
  getInventories,
  deleteInventory,
  getInventoryDetails,
  updateInventory,
  getInventoriesScrollPagination,
} from './actions';
import {
  selectCreateInventoryState,
  selectGetInventories,
  selectGetInventoriesState,
  selectDeleteInventoryState,
  selectGetInventoryDetails,
  selectGetInventoryDetailsState,
  selectUpdateInventoryState,
  selectSortingState,
  selectInventoriesPaginationInfo,
  selectGetInventoriesPaginationScrollState,
} from './selectors';

export function withInventoriesAdminHoc(Component) {
  function withInventoriesAdmin(props) {
    return <Component {...props} />;
  }

  withInventoriesAdmin.displayName = `withInventoriesAdmin(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInventoriesAdmin;
}

const mapStateToProps = createStructuredSelector({
  createInventoryState: selectCreateInventoryState,
  inventories: selectGetInventories,
  loadInventoriesState: selectGetInventoriesState,
  deleteInventoryState: selectDeleteInventoryState,
  inventoryDetails: selectGetInventoryDetails,
  inventoryDetailsState: selectGetInventoryDetailsState,
  updateInventoryState: selectUpdateInventoryState,
  sortingState: selectSortingState,
  inventoriesPaginationInfo: selectInventoriesPaginationInfo,
  loadInventoriesPaginationScrollState: selectGetInventoriesPaginationScrollState,
});

export function mapDispatchToProps(dispatch) {
  return {
    resetLoadInventories: () => dispatch(getInventories.reset()),
    resetCreateInventory: () => dispatch(createInventory.reset()),
    resetDeleteInventory: () => dispatch(deleteInventory.reset()),
    resetUpdateInventory: () => dispatch(updateInventory.reset()),
    loadInventories: (filters = {}) => dispatch(getInventories(filters)),
    sortingInventories: (sorting = {}) => dispatch(getInventories.sorting(sorting)),
    resetLoadInventoryDetails: () => dispatch(getInventoryDetails.reset()),
    createInventory: (inventory = {}) => dispatch(createInventory(inventory)),
    deleteInventory: (inventoryUuid) => dispatch(deleteInventory(inventoryUuid)),
    loadInventoryDetails: (inventoryUuid) => dispatch(getInventoryDetails(inventoryUuid)),
    updateInventory: ({ inventoryUuid, inventory }) => dispatch(updateInventory({ inventoryUuid, inventory })),
    loadInventoriesScrollPagination: (filters = {}) => dispatch(getInventoriesScrollPagination(filters)),
    resetInventoriesScrollPagination: () => dispatch(getInventoriesScrollPagination.reset()),
  };
}

export const withInventoriesAdminState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInventoriesAdminState, withInventoriesAdminHoc);
