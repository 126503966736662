import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectPayInDomain = (state) => state.settingsPayIn || initialState;

const selectMovementReasons = createSelector(selectPayInDomain, ({ getMovementReasons }) => getMovementReasons.reasons);

const selectMovementReasonsPagination = createSelector(
  selectPayInDomain,
  ({ getMovementReasons }) => getMovementReasons.pagination,
);

const selectMovementReasonsState = createSelector(selectPayInDomain, ({ getMovementReasons }) => {
  const { error, fetched, fetching } = getMovementReasons;
  return { error, fetched, fetching };
});

const selectMovementReasonDetail = createSelector(
  selectPayInDomain,
  ({ getMovementReasonDetail }) => getMovementReasonDetail.reason,
);

const selectMovementReasonDetailState = createSelector(selectPayInDomain, ({ getMovementReasonDetail }) => {
  const { error, fetched, fetching } = getMovementReasonDetail;
  return { error, fetched, fetching };
});

const selectCreateMovementReasonState = createSelector(
  selectPayInDomain,
  ({ createMovementReason }) => createMovementReason,
);

const selectUpdateMovementReasonState = createSelector(
  selectPayInDomain,
  ({ editMovementReason }) => editMovementReason,
);

const selectDeleteMovementReasonState = createSelector(
  selectPayInDomain,
  ({ deleteMovementReason }) => deleteMovementReason,
);

export {
  selectPayInDomain,
  selectMovementReasons,
  selectMovementReasonsPagination,
  selectMovementReasonsState,
  selectMovementReasonDetail,
  selectMovementReasonDetailState,
  selectCreateMovementReasonState,
  selectUpdateMovementReasonState,
  selectDeleteMovementReasonState,
};
