import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonNavigationBack from '@design-system/ButtonNavigationBack';

import useViewPanel from 'hooks/useViewPanel';

function ViewPanelLayout({
  actionComponent: actionComponentProp,
  children,
  disabledGoBack,
  labelAction,
  labelGoBack,
  onAction,
  onGoBack,
  ...others
}) {
  const theme = useTheme();
  const { open, setOpen } = useViewPanel();

  function handleGoBack() {
    if (onGoBack) {
      return onGoBack();
    }

    return setOpen(false);
  }

  if (open) {
    return (
      <>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <Toolbar />
        </Hidden>
        <Box
          alignItems="center"
          component="header"
          display="flex"
          height="100%"
          justifyContent="space-between"
          maxHeight={theme.typography.pxToRem(120)}
          minHeight={theme.typography.pxToRem(120)}
          p={`0 ${theme.typography.pxToRem(32)}`}
        >
          <ButtonNavigationBack disabled={disabledGoBack} onClick={handleGoBack}>
            {labelGoBack}
          </ButtonNavigationBack>

          {actionComponentProp && <>{actionComponentProp}</>}

          {onAction && <ButtonPrimary onClick={onAction}>{labelAction}</ButtonPrimary>}
        </Box>
        <Box component="main" pb={4} pl={4} pr={4} role="main" {...others}>
          {children}
        </Box>
      </>
    );
  }

  return null;
}

ViewPanelLayout.propTypes = {
  disabledGoBack: PropTypes.bool,
  children: PropTypes.node,
  labelGoBack: PropTypes.string,
  onGoBack: PropTypes.func,
  labelAction: PropTypes.string,
  onAction: PropTypes.func,
  actionComponent: PropTypes.element,
};

export default ViewPanelLayout;
