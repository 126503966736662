import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useListsOptions } from 'providers/root/ListsOptionsProvider';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Accordion from '@experimental-components/Accordion';
import ButtonPlus from '@experimental-components/ButtonPlus';

import DialogAddProducts from 'components/DialogAddProducts';

import useSortingListsOptions from 'hooks/useSortingListsOptions';

import useStyles from './styles';

function AddProducts({ productList, onChangeProductList }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const [expandedAccordion, setExpandedAccordion] = useState(true);
  const [openModalProducts, setOpenModalProducts] = useState(false);

  const {
    getListProductsOptions,
    getListProductsOptionsReset,
    productsOptionsPaginationValues,
    productsOptionsPaginationState,
    getListProductsOptionsPagination,
  } = useListsOptions();
  const { productsOptionsFilterValues, setProductsOptionsFilterByText } = useSortingListsOptions();

  function handleOnOpenModalProducts() {
    setOpenModalProducts(true);
    getListProductsOptions({ ...productsOptionsFilterValues });
  }

  function handleOnCloseModalProducts() {
    setOpenModalProducts(false);
    getListProductsOptionsReset();
  }

  function handleOnActionModalProducts(options) {
    onChangeProductList(options);
    setOpenModalProducts(false);
    getListProductsOptionsReset();
  }

  const handlePaginationProductsOptions = useCallback(() => {
    const currentPage = productsOptionsPaginationValues.page + 1;

    if (!productsOptionsPaginationState.fetching) {
      getListProductsOptionsPagination({ page: currentPage, ...productsOptionsFilterValues });
    }
  }, [
    getListProductsOptionsPagination,
    productsOptionsPaginationValues.page,
    productsOptionsPaginationState.fetching,
    productsOptionsFilterValues,
  ]);

  function handleOnSearchProductsOptions(value) {
    setProductsOptionsFilterByText({ ...productsOptionsFilterValues, filterByText: value });
  }

  return (
    <Accordion
      expanded={expandedAccordion}
      onClick={() => setExpandedAccordion((state) => !state)}
      title={t('common:addProducts.title')}
    >
      <Box pt={6} px={4}>
        <Box color="darkGrey.main" fontSize="0.875rem" mb={4}>
          {t('menuMaker:modifierGroupForm.addProducts.description')}
        </Box>
        {Boolean(productList?.length) && (
          <Box className={classes.containerProducts}>
            <List className={classes.dividerOption}>
              {productList?.map((product) => (
                <ListItem key={`option-assigned-${product?.uuid}`}>
                  <Box className={classes.itemProduct}>{product?.name}</Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box mt={4}>
          <ButtonPlus onClick={handleOnOpenModalProducts}>{t('common:addProducts.addProduct')}</ButtonPlus>
          <DialogAddProducts
            description={t('common:addProducts.descriptionModalModifiersGroups')}
            onAction={handleOnActionModalProducts}
            onClose={handleOnCloseModalProducts}
            onPagination={handlePaginationProductsOptions}
            onSearch={handleOnSearchProductsOptions}
            open={openModalProducts}
            optionsSelected={productList}
            title={t('common:addProducts.title')}
          />
        </Box>
      </Box>
    </Accordion>
  );
}

AddProducts.propTypes = {
  productList: PropTypes.array,
  onChangeProductList: PropTypes.func,
};

export default AddProducts;
