import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import arrayMove from 'utils/array';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconHelp from '@experimental-components/IconsComponents/Help';
import IconButton from '@design-system/IconButton';

import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';

import Button from '@material-ui/core/Button';

import useUserStore from 'hooks/useUserStore';
import Popover from '@material-ui/core/Popover';

import OptionsTable from './OptionsTable';
import OptionSelect from './OptionSelect';
import ModifierGroupSelect from './ModifierGroupSelect';
import useStyles from './styles';

function ModifierOptions({
  currentModifierGroup,
  options,
  selectedOptions,
  onChange,
  handleAddOption,
  modifierGroupsList,
  loadModifierGroups,
  handleEditOptionPrice,
  handleEditOptionMenuOverridePrice,
  handleEditConditionalOptionPrice,
  handleEditConditionalOptionMenuOverridePrice,
  handleExpandOption,
  // Conditional Group
  conditionalGroup,
  updateOptionsContext,
  // Final Group
  handleRemoveFinalGroup,
  t,
}) {
  const classes = useStyles();
  const { storeId: storeUuid } = useUserStore();
  const [showModifierGroupSelect, setShowModifierGroupSelect] = useState(false);
  const [anchorElPop, setAnchorElPop] = useState(null);
  const handleRemoveOption = (value) => {
    onChange(selectedOptions.filter((option) => option !== value));
  };
  useEffect(() => {
    setShowModifierGroupSelect(Boolean(conditionalGroup));
  }, [conditionalGroup]);
  const handleShowModifierGroupSelect = () => setShowModifierGroupSelect(!showModifierGroupSelect);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(selectedOptions, oldIndex, newIndex));
  };
  useEffect(() => {
    loadModifierGroups({ storeUuid, allModifiers: true });
  }, [loadModifierGroups, storeUuid]);

  function handlePopOverClose() {
    setAnchorElPop(null);
  }

  function handlePopOverOpen(event) {
    setAnchorElPop(event.currentTarget);
  }

  // This ensures we don't show options which are already selected.
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <OptionSelect
            handleAddOption={handleAddOption}
            onChange={onChange}
            options={options}
            selectedOptions={selectedOptions}
          />
          {showModifierGroupSelect && (
            <ModifierGroupSelect
              conditionalGroup={conditionalGroup}
              currentModifierGroup={currentModifierGroup}
              modifierGroups={modifierGroupsList}
              updateOptionsContext={updateOptionsContext}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          <Button
            color="default"
            disabled={selectedOptions.length === 0}
            onClick={handleShowModifierGroupSelect}
            size="large"
            style={{ marginTop: 5 }}
            variant="text"
          >
            {t('menuMaker:modifierGroupForm.labels.setConditionalPrices')}
          </Button>
          <IconButton onClick={handlePopOverOpen}>
            <IconHelp />
          </IconButton>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid item xs={7}>
          <OptionsTable
            conditionalGroup={conditionalGroup}
            handleEditConditionalOptionMenuOverridePrice={handleEditConditionalOptionMenuOverridePrice}
            handleEditConditionalOptionPrice={handleEditConditionalOptionPrice}
            handleEditOptionMenuOverridePrice={handleEditOptionMenuOverridePrice}
            handleEditOptionPrice={handleEditOptionPrice}
            handleExpandOption={handleExpandOption}
            handleRemoveFinalGroup={handleRemoveFinalGroup}
            handleRemoveOption={handleRemoveOption}
            onSortEnd={onSortEnd}
            selectedOptions={selectedOptions}
          />
        </Grid>
      </Box>

      <Popover
        anchorEl={anchorElPop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.helperPopOver}
        onClose={handlePopOverClose}
        open={Boolean(anchorElPop)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.message}>
          {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line1')}
          <br />
          <br />
          {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line2')}
          <br />
          {t('menuMaker:modifierGroupForm.labels.conditionalsInfo.line3')}
        </div>
      </Popover>
    </>
  );
}

ModifierOptions.propTypes = {
  currentModifierGroup: PropTypes.object,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  handleAddOption: PropTypes.func,
  // Modifier Groups
  modifierGroupsList: PropTypes.array,
  loadModifierGroups: PropTypes.func,
  // Conditional Group
  conditionalGroup: PropTypes.object,
  updateOptionsContext: PropTypes.func,
  // Prices Edition
  handleEditOptionPrice: PropTypes.func,
  handleEditOptionMenuOverridePrice: PropTypes.func,
  handleEditConditionalOptionPrice: PropTypes.func,
  handleEditConditionalOptionMenuOverridePrice: PropTypes.func,
  handleExpandOption: PropTypes.func,
  // Final Group
  handleRemoveFinalGroup: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withModifierGroups, withTranslation('menuMaker'), memo)(ModifierOptions);
