import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { authTokenRequest } from 'utils/api/baas/auth';
import { setAuthToken, setCookieUser, setUserStoresStoreKey, setUserCurrentStore } from 'utils/auth';

import { logEventRequest, setUserPropertyRequest } from 'providers/root/AmplitudeProvider/helpers';

import { initSignIn } from './actions';

export function* handleInitSignInRequest(action) {
  const { user, password } = action.payload;

  try {
    yield put(initSignIn.request());

    const { session, profile, stores } = yield call(authTokenRequest, {
      user,
      password,
    });

    if (!stores?.length) {
      yield put(
        push({
          pathname: `/without-store`,
        }),
      );
    }

    setAuthToken({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    });

    setUserCurrentStore(stores[0]);
    setUserStoresStoreKey(stores);

    setCookieUser(profile);

    logEventRequest('@event.$login.login');
    setUserPropertyRequest({ email: user });

    yield put(
      initSignIn.success({
        profile,
        stores,
      }),
    );
  } catch (err) {
    logEventRequest('@event.$login.loginFail');
    setUserPropertyRequest({ email: user });
    yield put(initSignIn.failure(err));
  }
}

export default function* signInProviderSaga() {
  yield takeLatest(initSignIn.TRIGGER, handleInitSignInRequest);
}
