import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';

import MenuOutline from '@experimental-components/IconsComponents/MenuOutline';
import CloseOutline from '@experimental-components/IconsComponents/CloseOutline';
import ChevronDoubleLeft from '@experimental-components/IconsComponents/ChevronDoubleLeft';
import IconButton from '@design-system/IconButton';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useEnvironment from 'hooks/useEnvironment';

import { ReactComponent as LogoConnect } from 'images/svg/brand/logo-connect.svg';
import { ReactComponent as IconParrotConnectSmall } from 'images/svg/brand/parrot_connect_small.svg';
import { ReactComponent as IconParrotConnectSmallV2 } from 'images/svg/brand/parrot_connect_small_v2.svg';
import { useHistory } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: (props) => (props?.open ? drawerWidth : `62px`),
      flexShrink: 0,
    },
  },

  appBar: {
    background: (props) => (props.isTestEnv ? theme.palette.testEnv.main : '#000'),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : '#000'),
  },

  drawerPaperMdMobile: {
    width: (props) => (props?.open ? drawerWidth : `62px`),
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : '#000'),
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerMdMobile: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: (props) => (props?.open ? 'row' : 'column'),
    minHeight: theme.typography.pxToRem(120),
    maxHeight: (props) => (props?.open ? theme.typography.pxToRem(120) : '100%'),
    padding: (props) => (props?.open ? '1.5rem 1.125rem' : '2rem 1rem'),
  },
  contentMenuMdMobile: {
    height: '100%',
    maxHeight: '100%',
    display: (props) => (props?.open ? 'flex' : 'none'),
    flexDirection: 'column',
    left: (props) => (props?.open ? 'auto' : `100%`),
    position: (props) => (props?.open ? 'initial' : `absolute`),
    transition: theme.transitions.create(['left', 'display'], {
      easing: theme.transitions.easing.sharp,
      duration: 500,
    }),
  },

  drawerPaperMobile: {
    width: '100%',
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : '#000'),
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  toolbarMobile: {
    justifyContent: 'space-between',
  },

  logSmall: {
    width: 35,
    height: 35,
  },
}));

function SidebarLayoutSidebar({ children, window }) {
  const { isTestEnv } = useEnvironment();
  const history = useHistory();
  const { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer } = useSidebarLayout();

  const classes = useStyles({ isTestEnv, open: openMdDrawer });
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleDrawerMdToggle = () => {
    setOpenMdDrawer((state) => !state);
  };

  function handleLogoClick() {
    history.push('/');
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  function renderHeaderMdMenu() {
    if (!openMdDrawer) {
      return (
        <>
          <IconButton aria-label="open drawer" color="inherit" edge="start" onClick={handleDrawerMdToggle}>
            <MenuOutline color="#fff" size={24} />
          </IconButton>
          <IconParrotConnectSmallV2 />
        </>
      );
    }

    return (
      <>
        <LogoConnect height={30} width={180} />
        <IconButton aria-label="open drawer" color="inherit" edge="start" onClick={handleDrawerMdToggle}>
          <ChevronDoubleLeft color="#fff" size={16} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <Hidden lgUp smUp>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolbarMobile}>
            <IconButton
              aria-label="open drawer"
              className={classes.menuButton}
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuOutline color="#fff" size={24} />
            </IconButton>

            <Box>
              <IconParrotConnectSmall className={classes.logSmall} />
            </Box>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav aria-label="mailbox folders" className={classes.drawer}>
        <Hidden implementation="css" smUp>
          <Drawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            container={container}
            ModalProps={{
              keepMounted: true,
            }}
            onClose={handleDrawerToggle}
            open={openDrawer}
            variant="temporary"
          >
            <Box
              height="100%"
              maxHeight={theme.typography.pxToRem(100)}
              minHeight={theme.typography.pxToRem(100)}
              p={2}
            >
              {openDrawer && (
                <ButtonBase disableRipple onClick={handleDrawerToggle}>
                  <CloseOutline color="#fff" />
                </ButtonBase>
              )}
            </Box>
            {children}
          </Drawer>
        </Hidden>

        <Hidden implementation="css" lgUp xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaperMdMobile,
            }}
            open
            variant="permanent"
          >
            <Box className={classes.headerMdMobile}>{renderHeaderMdMenu()}</Box>
            <Box className={classes.contentMenuMdMobile}>{children}</Box>
          </Drawer>
        </Hidden>

        <Hidden implementation="css" mdDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            open
            variant="permanent"
          >
            <Box
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
              maxHeight={theme.typography.pxToRem(120)}
              minHeight={theme.typography.pxToRem(120)}
              onClick={handleLogoClick}
              sx={{ cursor: 'pointer' }}
            >
              <LogoConnect />
            </Box>

            {children}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

SidebarLayoutSidebar.propTypes = {
  children: PropTypes.node,
  window: PropTypes.func,
};

export default SidebarLayoutSidebar;
