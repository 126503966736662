import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'center',
    component: 'header',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    maxHeight: theme.typography.pxToRem(120),
    minHeight: theme.typography.pxToRem(120),
    padding: `0 ${theme.typography.pxToRem(32)}`,
  },
  namespaceHeader: {
    background: theme.palette.darkGrey[2],
    display: 'flex',
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(32)}`,
  },
  labelButton: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
  endIconRotate: {
    transform: 'rotate(180deg)',
  },
  itemPermissionNamespace: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    width: '100%',
    '&:hover': {
      background: theme.palette.darkGrey[5],
    },
  },
}));

export default useStyles;
