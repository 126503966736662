import { useTranslation } from 'react-i18next';

import { ViewPanelLayout } from 'Layout';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import ButtonPrimary from '@design-system/ButtonPrimary';

function HelperSkeleton() {
  const { t } = useTranslation('menuMaker');

  return (
    <ViewPanelLayout
      actionComponent={<ButtonPrimary>{t('menuMaker:modifierGroupForm.saveButtonLabel')}</ButtonPrimary>}
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={() => {}}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Box>
            <Grid item xs={6}>
              <Box mb={2}>
                <Skeleton height={40} variant="text" width={180} />
              </Box>
              <Box mb={2}>
                <Skeleton height={50} variant="rect" />
              </Box>
            </Grid>

            <Box mt={6}>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Box mb={2}>
                    <Skeleton height={40} variant="text" width={150} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton height={50} variant="rect" />
                  </Box>
                  <Box mb={2}>
                    <Skeleton height={200} variant="rect" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={2}>
            <Skeleton height={60} variant="rect" />
          </Box>
        </Grid>
      </Grid>
    </ViewPanelLayout>
  );
}

export default HelperSkeleton;
