import PropTypes from 'prop-types';

import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@design-system/IconButton';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import useStyles from './styles';

function Accordion({ actionComponent: actionComponentProp, children, expanded, title, onClick }) {
  const classes = useStyles();
  return (
    <Box width="100%">
      <Box className={classes.accordionHeader}>
        {actionComponentProp && <>{actionComponentProp}</>}
        <Box className={classes.accordionAreaAction} onClick={onClick}>
          <Box className={classes.labelSection} component="span">
            {title}
          </Box>
          <IconButton>
            <ChevronDown className={clsx({ [classes.endIconRotate]: expanded })} color="#000" size={15} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  actionComponent: PropTypes.element,
};

export default Accordion;
