import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function ConektaProvider({ children }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
    script.id = 'conektaSDK';
    script.async = true;
    if (!document.getElementById('conektaSDK')) document.body.appendChild(script);
  }, []);

  return React.Children.only(children);
}

ConektaProvider.propTypes = {
  children: PropTypes.element,
};

export default ConektaProvider;
