/* eslint-disable @typescript-eslint/no-explicit-any */
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionDetailsMui, { AccordionDetailsProps } from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
  },
}));

interface Props extends AccordionDetailsProps {
  children: React.ReactNode;
  css?: any;
  customClasses?: any;
}

function AccordionDetails({ children, css = {}, customClasses = {}, ...others }: Props) {
  const classes = useStyles();

  return (
    <AccordionDetailsMui classes={{ root: classes.root, content: classes.content, ...customClasses }} {...others}>
      <Box pl={5} pr={5} pt={6} sx={{ ...css }}>
        {children}
      </Box>
    </AccordionDetailsMui>
  );
}

export default AccordionDetails;
