/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider selectors
 *
 */
import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInventoriesSuppliesProviderDomain = (state) => state.inventoriesSuppliesProvider || initialState;

const selectInventoriesSuppliesList = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ supplies }) => supplies.suplies,
);

const selectInventoriesSuppliesState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ supplies: { error, fetching, fetched } }) => ({
    error,
    fetching,
    fetched,
  }),
);

const selectSuppliesPaginationState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ suppliesPagination }) => suppliesPagination,
);

const selectSuppliesPaginationInfo = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ supplies }) => supplies.pagination,
);

const selectSupply = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ supplyDetails }) => supplyDetails.supply,
);

const selectSupplyState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ supplyDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateSupplyState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ createSupply: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateSupplyState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ updateSupply: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDeleteSupply = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ deleteSupply: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectAssignStorageAreasState = createSelector(
  selectInventoriesSuppliesProviderDomain,
  ({ assignStorageAreas: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

export {
  selectInventoriesSuppliesProviderDomain,
  selectInventoriesSuppliesList,
  selectInventoriesSuppliesState,
  selectSuppliesPaginationState,
  selectSuppliesPaginationInfo,
  selectSupply,
  selectSupplyState,
  selectCreateSupplyState,
  selectUpdateSupplyState,
  selectDeleteSupply,
  selectAssignStorageAreasState,
};
