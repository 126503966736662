import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_STOCK_CATEGORIES,
  GET_STOCK_CATEGORIES_PAGINATION,
  GET_STOCK_CATEGORY_DETAILS,
  CREATE_STOCK_CATEGORY,
  UPDATE_STOCK_CATEGORY,
  DELETE_STOCK_CATEGORY,
} from './constants';

export const getStockCategories = extendRoutine(createRoutine(GET_STOCK_CATEGORIES), [
  'RESET',
  'RESET_SORTING',
  'SORTING',
]);
export const getStockCategoriesPagination = extendRoutine(createRoutine(GET_STOCK_CATEGORIES_PAGINATION), 'RESET');
export const getStockCategoryDetails = extendRoutine(createRoutine(GET_STOCK_CATEGORY_DETAILS), 'RESET');
export const createStockCategory = extendRoutine(createRoutine(CREATE_STOCK_CATEGORY), 'RESET');
export const updateStockCategory = extendRoutine(createRoutine(UPDATE_STOCK_CATEGORY), 'RESET');
export const deleteStockCategory = extendRoutine(createRoutine(DELETE_STOCK_CATEGORY), 'RESET');
