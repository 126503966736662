import queryString from 'query-string';

import request from 'utils/request';

import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMenuMakerMenusRequest(
  { storeUuid: storeUuidArg, brand, name, page = 1, pageSize = 20, version = 1 },
  options = {},
) {
  const storeUuid = storeUuidArg ?? getUserCurrentStore()?.uuid;

  if (version === 1) {
    const query = queryString.stringify({ store_uuid: storeUuid });
    return request(`${API_BASE_URL}/v1/menu-maker/menus/?${query}`, { method: 'GET', ...options });
  }

  if (version === 2) {
    const query = queryString.stringify({ store_uuid: storeUuid, brand, name, page, page_size: pageSize });
    return request(`${API_BASE_URL}/v1/menu-maker/menus/search/?${query}`, { method: 'GET', ...options });
  }
}

export function createMenuRequest(
  { availability, name, sortingPosition, store, operationTimes, providers, brand, categories },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/`, {
    method: 'POST',
    body: JSON.stringify({
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      brand,
      categories,
    }),
    ...options,
  });
}

export function editMenuRequest(
  { menuUuid, availability, name, sortingPosition, store, operationTimes, providers, categories },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      categories,
    }),
    ...options,
  });
}

export function duplicateMenuRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: menuUuid,
      objectType: 'MENU',
    }),
    ...options,
  });
}

export function publishMenuRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/publish/?${query}`, {
    method: 'PUT',
    ...options,
  });
}

export function deleteMenuRequest(menuUuid, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getMenuDetailsRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function getCopyMenuConfigRequest({ menuUuid, storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/copy/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function copyMenuBrandsRequest({ menuUuid, storeUuid, brands, copyMode }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/copy/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      brands,
      copyMode,
    }),
    ...options,
  });
}

export function getProductsForStoreRequest(
  {
    filterByText,
    filterByCategories,
    filterByTypeSoldProduct,
    filterByBrands,
    withoutBrands,
    withoutCategories,
    page = 1,
  },
  options = {},
) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
    page_size: 20,
    page,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/search/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name: filterByText,
      category: filterByCategories,
      sold_alone: filterByTypeSoldProduct,
      brand: filterByBrands,
      without_brands: withoutBrands,
      without_categories: withoutCategories,
    }),
    ...options,
  });
}

export function getCategoriesForStoreRequest(options = {}) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/categories/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function productsAvailabilityForStoreRequest({ storeUuid, productUuid, availability }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/availability/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      availability,
    }),
    ...options,
  });
}

export function duplicateProductRequest({ storeUuid, productUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: productUuid,
      objectType: 'PRODUCT',
    }),
    ...options,
  });
}

export function deleteProductRequest({ storeUuid, productUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getProductRequest({ storeUuid, productUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function updateProductRequest({ storeUuid, product }, options = {}) {
  const productUuid = product.uuid;
  const formData = createFormDataForProductSave(product);

  const headers = { ...options.headers };
  delete headers['Content-Type'];

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: formData,
    ...options,
    headers,
  });
}

export function createProductRequest({ storeUuid, product }, options = {}) {
  const formData = createFormDataForProductSave(product, storeUuid);
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  return request(`${API_BASE_URL}/v1/menu-maker/products/`, {
    method: 'POST',
    body: formData,
    ...options,
    headers,
  });
}

function createFormDataForProductSave(product, storeUuid) {
  const formData = new FormData();
  Object.keys(product).forEach((key) => {
    let value = product[key];
    if (value === null) {
      return;
    }

    if (typeof value === 'object' && key !== 'image') {
      value = JSON.stringify(value);
    }

    formData.append(key, value);
  });

  if (storeUuid) {
    formData.append('store', storeUuid);
  }

  return formData;
}

export function updateProductImageRequest({ storeUuid, productUuid, image }, options = {}) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  const formData = new FormData();
  formData.append('image', image);

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/update-image/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: formData,
    ...options,
    headers,
  });
}

export function publishMenusRequest({ storeUuid, brandUuid, provider }, options = {}) {
  const bodyRequest = {
    store: storeUuid,
    brand: brandUuid,
    provider,
  };

  return request(`${API_BASE_URL}/v1/menu-maker/menus/publish/`, {
    method: 'POST',
    body: JSON.stringify(bodyRequest),
    ...options,
  });
}
