/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getOnlineOrdering,
  getOnlineOrderingPagination,
  getBrandSettings,
  updateBrandSettings,
  updateCanOrdering,
  getBrandContactInformation,
  updateContactInformation,
  getOperationsSettings,
  updateOperationsSettings,
  getDeliveryZones,
  updateDeliveryZones,
  validateDirectionUrl,
  getResumesMenus,
  saveOnboarding,
  createDeliveryZones,
  deleteDeliveryZones,
  publishBrand,
} from './actions';

import {
  selectOnlineOrderingList,
  selectOnlineOrderingState,
  selectOnlineOrderingPaginationState,
  selectOnlineOrderingPaginationInfo,
  selectEnableOrderingState,
  selectBrandSettings,
  selectBrandSettingsState,
  selectUpdateBrandSettingsState,
  selectBrandContactInformation,
  selectBrandContactInformationState,
  selectUpdateContactInformationState,
  selectOperationsSettings,
  selectOperationsSettingsState,
  selectUpdateOperationsSettingsState,
  selectDeliveryZones,
  selectDeliveryZonesState,
  selectUpdateDeliveryZonesState,
  selectValidateDirectionUrlState,
  selectGetResumesMenus,
  selectGetResumesMenusState,
  selectSaveOnboardingState,
  selectCreateDeliveryZonesState,
  selectDeleteDeliveryZonesState,
  selectPublishBrandState,
  selectSaveOnboardingBrand,
} from './selectors';

export function withOnlineOrderingHoc(Component) {
  function withOnlineOrdering(props) {
    return <Component {...props} />;
  }

  withOnlineOrdering.displayName = `withOnlineOrdering(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withOnlineOrdering;
}

const mapStateToProps = createStructuredSelector({
  onlineOrderingList: selectOnlineOrderingList,
  onlineOrderingState: selectOnlineOrderingState,
  onlineOrderingPaginationState: selectOnlineOrderingPaginationState,
  onlineOrderingPaginationInfo: selectOnlineOrderingPaginationInfo,
  enableOrderingState: selectEnableOrderingState,
  bransSettings: selectBrandSettings,
  brandSettingsState: selectBrandSettingsState,
  updateBrandSettingsState: selectUpdateBrandSettingsState,
  brandContactInformation: selectBrandContactInformation,
  brandContactInformationState: selectBrandContactInformationState,
  updateContactInformationState: selectUpdateContactInformationState,
  operationsSettings: selectOperationsSettings,
  operationsSettingsState: selectOperationsSettingsState,
  updateOperationsSettingsState: selectUpdateOperationsSettingsState,
  deliveryZones: selectDeliveryZones,
  deliveryZonesState: selectDeliveryZonesState,
  updateDeliveryZonesState: selectUpdateDeliveryZonesState,
  validateDirectionUrlState: selectValidateDirectionUrlState,
  resumesMenus: selectGetResumesMenus,
  loadResumesMenusState: selectGetResumesMenusState,
  saveOnboardingState: selectSaveOnboardingState,
  createDeliveryZonesState: selectCreateDeliveryZonesState,
  deleteDeliveryZonesState: selectDeleteDeliveryZonesState,
  publishBrandState: selectPublishBrandState,
  saveOnboardingBrand: selectSaveOnboardingBrand,
});

function mapDispatchToProps(dispatch) {
  return {
    loadOnlineOrdering: () => dispatch(getOnlineOrdering()),
    resetOnlineOrdering: () => dispatch(getOnlineOrdering.reset()),
    loadOnlineOrderingPagination: (filters = {}) => dispatch(getOnlineOrderingPagination(filters)),
    updateCanOrdering: (values) => dispatch(updateCanOrdering(values)),
    resetUpdateCanOrdering: () => dispatch(updateCanOrdering.reset()),
    loadBrandSettings: (uuidBrand) => dispatch(getBrandSettings(uuidBrand)),
    resetBrandSettings: () => dispatch(getBrandSettings.reset()),
    updateBrandSettings: (values) => dispatch(updateBrandSettings(values)),
    resetUpdateBrandSettings: () => dispatch(updateBrandSettings.reset()),
    loadBrandContactInformation: (uuidBrand) => dispatch(getBrandContactInformation(uuidBrand)),
    resetBrandContactInformation: () => dispatch(getBrandContactInformation.reset()),
    updateContactInformation: (values) => dispatch(updateContactInformation(values)),
    resetUpdateContactInformation: () => dispatch(updateContactInformation.reset()),
    loadOperationsSettings: (uuidBrand) => dispatch(getOperationsSettings(uuidBrand)),
    resetOperationsSettings: () => dispatch(getOperationsSettings.reset()),
    updateOperationsSettings: (values) => dispatch(updateOperationsSettings(values)),
    resetUpdateOperationsSettings: () => dispatch(updateOperationsSettings.reset()),
    loadDeliveryZones: (uuidBrand) => dispatch(getDeliveryZones(uuidBrand)),
    resetDeliveryZones: () => dispatch(getDeliveryZones.reset()),
    updateDeliveryZones: (values) => dispatch(updateDeliveryZones(values)),
    resetUpdateDeliveryZones: () => dispatch(updateDeliveryZones.reset()),
    validateDirectionUrl: (slugUrl) => dispatch(validateDirectionUrl(slugUrl)),
    resetValidateDirectionUrl: () => dispatch(validateDirectionUrl.reset()),
    loadResumesMenus: (menus) => dispatch(getResumesMenus(menus)),
    resetLoadResumesMenus: () => dispatch(getResumesMenus.reset()),
    saveOnboarding: (config) => dispatch(saveOnboarding(config)),
    resetSaveOnboarding: () => dispatch(saveOnboarding.reset()),
    createDeliveryZone: (values) => dispatch(createDeliveryZones(values)),
    resetCreateDeliveryZone: () => dispatch(createDeliveryZones.reset()),
    deleteDeliveryZone: (values) => dispatch(deleteDeliveryZones(values)),
    resetDeleteDeliveryZone: () => dispatch(deleteDeliveryZones.reset()),
    publishBrand: (config) => dispatch(publishBrand(config)),
    resetPublishBrand: () => dispatch(publishBrand.reset()),
  };
}

export const withOnlineOrderingState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withOnlineOrderingState, withOnlineOrderingHoc);
