/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider actions
 *
 */
import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  GET_SUPPLIES,
  GET_SUPPLIES_PAGINATION,
  GET_SUPPLY_DETAILS,
  CREATE_SUPPLY,
  UPDATE_SUPPLY,
  DELETE_SUPPLY,
  ASSIGN_STORAGE_AREAS,
} from './constants';

export const getSupplies = extendRoutine(createRoutine(GET_SUPPLIES), 'RESET');
export const getSuppliesPagination = extendRoutine(createRoutine(GET_SUPPLIES_PAGINATION), 'RESET');
export const getSupplyDetails = extendRoutine(createRoutine(GET_SUPPLY_DETAILS), 'RESET');
export const createSupply = extendRoutine(createRoutine(CREATE_SUPPLY), 'RESET');
export const updateSupply = extendRoutine(createRoutine(UPDATE_SUPPLY), 'RESET');
export const deleteSupply = extendRoutine(createRoutine(DELETE_SUPPLY), 'RESET');
export const assignStorageAreas = extendRoutine(createRoutine(ASSIGN_STORAGE_AREAS), 'RESET');
