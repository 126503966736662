import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { updateModifierGroupRequest } from 'utils/api/baas/menuMaker/modifierGroups';

import { editModifierGroup } from '../actions';

export function* handleEditModifierGroupRequest(action) {
  const { storeUuid, modifierGroup } = action.payload;
  try {
    yield put(editModifierGroup.request());

    // Update
    const { data } = yield call(baasRequest, updateModifierGroupRequest, {
      storeUuid,
      modifierGroup,
    });

    yield put(editModifierGroup.success(data));
  } catch (err) {
    yield put(editModifierGroup.failure({ error: err }));
  }
}
