import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { changeMenuProductPriceRequest } from 'utils/api/baas/menuMaker/products';

import { updateProductPrice } from '../actions';

export function* handleSetNewProductPrice(action) {
  const { menuUuid, productUuid, price, availability } = action.payload;

  try {
    yield put(updateProductPrice.request());

    const { data } = yield call(baasRequest, changeMenuProductPriceRequest, {
      menuUuid,
      productUuid,
      price,
      availability,
    });

    yield put(updateProductPrice.success(data));
  } catch (err) {
    yield put(updateProductPrice.failure(err));
  }
}
