import { createSelector } from 'reselect';

import { filterInventoriesByText } from 'utils/inventories/supplier/sorting';

import { initialState } from './reducer';

const selectStorageAreasProviderDomain = (state) => state.storageAreasProvider || initialState;

const selectStorageAreas = createSelector(selectStorageAreasProviderDomain, ({ storageAreas, sorting }) => {
  const currentList = [...storageAreas.storageAreas];
  let list = [...currentList];
  const { filterByText } = sorting;
  if (filterByText && filterByText.length >= 3) {
    list = filterInventoriesByText(currentList, sorting);
  }
  return list;
});

const selectStorageAreasState = createSelector(
  selectStorageAreasProviderDomain,
  ({ storageAreas: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectStorageArea = createSelector(
  selectStorageAreasProviderDomain,
  (subState) => subState.storageAreaDetails.storageArea,
);

const selectStorageAreaState = createSelector(
  selectStorageAreasProviderDomain,
  ({ storageAreaDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateStorageAreaState = createSelector(
  selectStorageAreasProviderDomain,
  ({ createStorageArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateStorageAreaState = createSelector(
  selectStorageAreasProviderDomain,
  ({ updateStorageArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDeleteStorageArea = createSelector(
  selectStorageAreasProviderDomain,
  ({ deleteStorageArea: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectStorageAreasPaginationState = createSelector(
  selectStorageAreasProviderDomain,
  ({ storageAreasPagination }) => storageAreasPagination,
);

const selectStorageAreasPaginationInfo = createSelector(
  selectStorageAreasProviderDomain,
  ({ storageAreas }) => storageAreas.pagination,
);

const selectStorageAreaSortingState = createSelector(selectStorageAreasProviderDomain, ({ sorting }) => sorting);

export {
  selectStorageAreasProviderDomain,
  selectStorageAreas,
  selectStorageAreasState,
  selectStorageAreasPaginationState,
  selectStorageArea,
  selectStorageAreaState,
  selectCreateStorageAreaState,
  selectUpdateStorageAreaState,
  selectDeleteStorageArea,
  selectStorageAreasPaginationInfo,
  selectStorageAreaSortingState,
};
