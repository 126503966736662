import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getInvoicesUninvoicedOrdersRequest(
  { startDate, endDate, invoicingConfig, brands, page, paymentMethod, orderBy },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    invoicing_config: invoicingConfig,
    brand: brands,
    page_size: 1000,
    order_by: orderBy,
    page,
    payment_type: paymentMethod,
  });

  return request(`${API_BASE_URL}/v1/invoicing/uninvoiced-orders/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function generateInvoicingRequest(
  { startDate, endDate, invoicingConfig, brands, includedOrders, periodicity, month, year },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
    brand: brands,
  });

  return request(`${API_BASE_URL}/v2/invoicing/uninvoiced-orders/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      invoicingConfig,
      includedOrders,
      periodicity,
      month,
      year,
    }),
    ...options,
  });
}
