import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import ContentLayoutLocalContext from './ContentLayoutLocalContext';

import Container from './Container';
import Header from './Header';
import TabsNavigation from './TabsNavigation';
import TabLink from './TabLink';

function ContentLayout({ children, ...other }) {
  return (
    <ContentLayoutLocalContext.Provider value={{ noPadding: false }}>
      <Box display="flex" flexDirection="column" width="100%" {...other}>
        {children}
      </Box>
    </ContentLayoutLocalContext.Provider>
  );
}

ContentLayout.Header = Header;
ContentLayout.TabsNavigation = TabsNavigation;
ContentLayout.TabLink = TabLink;
ContentLayout.Container = Container;

ContentLayout.propTypes = {
  children: PropTypes.node,
};

export default ContentLayout;
