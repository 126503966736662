import camelCase from 'lodash/camelCase';

const LOGIN = 'login';
const LOGIN_FAIL = 'login_fail';
const LOGOUT = 'logout';
const STORE_CHANGED = 'store_changed';
const VIEW_SALES_REPORT = 'view_sales_report';
const VIEW_SALES_REPORT_ITEM = 'view_sales_report_item';
const VIEW_DRAWERS_REPORT = 'view_drawers_report';
const VIEW_DRAWERS_REPORT_ITEM = 'view_drawers_report_detail';
const EXPORT_REPORT = 'export_report';

const SALES = 'sales';
const DRAWER = 'drawers';

export const config = {
  '@event': {
    $login: {
      [camelCase([LOGIN])]: LOGIN,
      [camelCase([LOGOUT])]: LOGOUT,
      [camelCase([LOGIN_FAIL])]: LOGIN_FAIL,
    },

    $reports: {
      [camelCase([EXPORT_REPORT])]: EXPORT_REPORT,
      [camelCase([VIEW_SALES_REPORT])]: VIEW_SALES_REPORT,
      [camelCase([VIEW_DRAWERS_REPORT])]: VIEW_DRAWERS_REPORT,
      [camelCase([VIEW_SALES_REPORT_ITEM])]: VIEW_SALES_REPORT_ITEM,
      [camelCase([VIEW_DRAWERS_REPORT_ITEM])]: VIEW_DRAWERS_REPORT_ITEM,
    },

    $store: {
      [camelCase([STORE_CHANGED])]: STORE_CHANGED,
    },
  },

  '@@type': {
    [camelCase([SALES])]: SALES,
    [camelCase([DRAWER])]: DRAWER,
  },
};
