import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getMenuDetailsRequest, editMenuRequest } from 'utils/api/baas/menuMaker';

import { getMenuDetails, editMenu } from './actions';

export function* handleGetMenuDetailsRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(getMenuDetails.request());

    const { data } = yield call(baasRequest, getMenuDetailsRequest, {
      menuUuid,
      storeUuid,
    });

    yield put(getMenuDetails.success(data));
  } catch (err) {
    yield put(getMenuDetails.failure(err));
  } finally {
    yield put(getMenuDetails.fulfill());
  }
}

export function* handleEditMenuRequest(action) {
  const {
    menuUuid,
    availability,
    name,
    sortingPosition,
    store,
    operationTimes,
    providers,
    categories,
  } = action.payload;

  try {
    yield put(editMenu.request());

    const { data } = yield call(baasRequest, editMenuRequest, {
      menuUuid,
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      categories,
    });

    yield put(editMenu.success(data));
  } catch (err) {
    yield put(editMenu.failure(err));
  }
}

export default function* menuDetailsSaga() {
  yield takeLatest(getMenuDetails.TRIGGER, handleGetMenuDetailsRequest);
  yield takeLatest(editMenu.TRIGGER, handleEditMenuRequest);
}
