/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  linkMore: {
    cursor: 'pointer',
    lineHeight: '1',
  },
});

function ExpandableList({ list = [] }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showLink, setShowLink] = useState(list.length > 4);
  const [listItems, setListItems] = useState(list.slice(0, 4));

  const handleOpenList = () => {
    setShowLink(false);
    setListItems(list);
  };

  return (
    <>
      {listItems.map((item) => item)}

      {showLink && (
        <Link
          className={classes.linkMore}
          color="secondary"
          onClick={handleOpenList}
          underline="hover"
          variant="button"
        >
          {t('common:buttons.seeMore')}
        </Link>
      )}
    </>
  );
}

ExpandableList.propTypes = {
  list: PropTypes.array,
};

export default ExpandableList;
