import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const QuestionMark = forwardRef(function QuestionMark(props, ref) {
  const { color = '#fff', size = 13, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 18 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M9 .9c4.5 0 8.1 3.6 8.1 8.1s-3.6 8.1-8.1 8.1S.9 13.5.9 9 4.5.9 9 .9zM9 0C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9z"
        fill={color}
      />
      <path
        clipRule="evenodd"
        d="M7.8 5.4c.4-.3 1-.4 1.5-.3.5.1 1 .3 1.3.7.3.4.5.9.5 1.4 0 .6-.5 1.2-1.1 1.6-.3.3-.6.4-.8.5-.2.1-.3.1-.3.1h-.1l.1.4-.1-.3c-.2 0-.3.2-.3.4v1.7c0 .2.2.4.4.4s.4-.2.4-.4v-1.4s.1 0 .1-.1c.3-.1.6-.3 1-.5.7-.5 1.6-1.2 1.6-2.3h-.4.4c0-.7-.3-1.4-.7-2-.5-.5-1.1-.9-1.8-1-.8-.2-1.5 0-2.1.3-.6.4-1.1 1-1.3 1.6-.1.3 0 .5.2.6.2.1.5 0 .6-.3.1-.4.5-.9.9-1.1zM9 12.9c-.2 0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

QuestionMark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default QuestionMark;
