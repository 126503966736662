import produce from 'immer';

import { pageSizeTypes } from 'utils/pagination';

import {
  getSubscriptionHome,
  deletePaymentMethod,
  savePaymentMethod,
  getSubscriptionPayments,
  getSubscriptionPaymentsByPagination,
  getSubscriptionInvoiceData,
  updateSubscriptionInvoiceData,
} from './actions';

const baseState = {
  error: null,
  fetching: false,
  fetched: false,
};

export const initialState = {
  getSubscriptionHome: {
    ...baseState,
    subscription: {},
  },

  deletePaymentMethod: {
    ...baseState,
  },

  savePaymentMethod: {
    ...baseState,
    paymentMethod: {},
  },

  getSubscriptionPayments: {
    ...baseState,
    payments: [],
    pagination: {
      page: 1,
      page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
      count: 0,
      next: null,
      previous: null,
    },
  },

  loadPaginationStatus: {
    ...baseState,
    lastUpdatedRows: {},
  },

  getSubscriptionInvoiceData: {
    ...baseState,
    invoiceData: {},
  },

  updateSubscriptionInvoiceData: {
    ...baseState,
    invoiceData: {},
  },
};

const subscriptionProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getSubscriptionHome.REQUEST:
        draft.getSubscriptionHome = {
          ...initialState.getSubscriptionHome,
          fetching: true,
        };
        break;

      case getSubscriptionHome.SUCCESS:
        draft.getSubscriptionHome.fetching = false;
        draft.getSubscriptionHome.fetched = true;
        draft.getSubscriptionHome.subscription = payload.data;
        break;

      case getSubscriptionHome.FAILURE:
        draft.getSubscriptionHome.error = payload;
        draft.getSubscriptionHome.fetching = false;
        draft.getSubscriptionHome.fetched = false;
        break;

      case getSubscriptionHome.RESET:
        draft.getSubscriptionHome = {
          ...initialState.getSubscriptionHome,
        };
        break;

      case deletePaymentMethod.REQUEST:
        draft.deletePaymentMethod = {
          ...initialState.deletePaymentMethod,
          fetching: true,
        };
        break;

      case deletePaymentMethod.SUCCESS:
        draft.deletePaymentMethod.fetching = false;
        draft.deletePaymentMethod.fetched = true;
        draft.getSubscriptionHome.subscription.paymentMethod = null;
        break;

      case deletePaymentMethod.FAILURE:
        draft.deletePaymentMethod.error = payload;
        draft.deletePaymentMethod.fetching = false;
        draft.deletePaymentMethod.fetched = false;
        break;

      case deletePaymentMethod.RESET:
        draft.deletePaymentMethod = {
          ...initialState.deletePaymentMethod,
        };
        break;

      case savePaymentMethod.REQUEST:
        draft.savePaymentMethod = {
          ...initialState.savePaymentMethod,
          fetching: true,
        };
        break;

      case savePaymentMethod.SUCCESS:
        draft.savePaymentMethod.fetching = false;
        draft.savePaymentMethod.fetched = true;
        draft.savePaymentMethod.paymentMethod = payload.data;
        draft.getSubscriptionHome.subscription.paymentMethod = payload.data;
        break;

      case savePaymentMethod.FAILURE:
        draft.savePaymentMethod.error = payload;
        draft.savePaymentMethod.fetching = false;
        draft.savePaymentMethod.fetched = false;
        break;

      case savePaymentMethod.RESET:
        draft.savePaymentMethod = {
          ...initialState.savePaymentMethod,
        };
        break;

      // get payment history
      case getSubscriptionPayments.REQUEST:
        draft.getSubscriptionPayments = { ...draft.getSubscriptionPayments, fetching: true };
        break;

      case getSubscriptionPayments.SUCCESS:
        draft.getSubscriptionPayments.fetching = false;
        draft.getSubscriptionPayments.fetched = true;
        draft.getSubscriptionPayments.payments = payload.payments;
        draft.getSubscriptionPayments.pagination = {
          ...draft.getSubscriptionPayments.pagination,
          ...payload.pagination,
        };
        break;

      case getSubscriptionPayments.FAILURE:
        draft.getSubscriptionPayments.error = payload;
        draft.getSubscriptionPayments.fetching = false;
        draft.getSubscriptionPayments.fetched = false;
        break;

      case getSubscriptionPayments.RESET:
        draft.getSubscriptionPayments = { ...initialState.getSubscriptionPayments };
        break;

      // pagination
      case getSubscriptionPaymentsByPagination.REQUEST: {
        const currerntSubscriptionPaymentsPaginationState = draft.getSubscriptionPayments;
        draft.getSubscriptionPayments.pagination = {
          ...currerntSubscriptionPaymentsPaginationState?.pagination,
          page: payload.page,
        };
        draft.loadPaginationStatus = { ...initialState.loadPaginationStatus, fetching: true };

        break;
      }

      case getSubscriptionPaymentsByPagination.SUCCESS: {
        const currerntSubscriptionPaymentsState = draft.getSubscriptionPayments;
        let newsRows = [...currerntSubscriptionPaymentsState?.payments];
        newsRows = [...newsRows, ...payload?.payments];

        draft.getSubscriptionPayments = {
          ...currerntSubscriptionPaymentsState,
          payments: [...newsRows],
          pagination: { ...currerntSubscriptionPaymentsState?.pagination, ...payload.pagination },
        };

        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          fetching: false,
          fetched: true,
          lastUpdatedRows: [...payload?.payments],
        };
        break;
      }

      case getSubscriptionPaymentsByPagination.FAILURE:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
          error: payload,
          fetching: false,
        };
        break;

      case getSubscriptionPaymentsByPagination.RESET:
        draft.loadPaginationStatus = {
          ...initialState.loadPaginationStatus,
        };
        break;

      // get invoice data
      case getSubscriptionInvoiceData.REQUEST:
        draft.getSubscriptionInvoiceData = {
          ...initialState.getSubscriptionInvoiceData,
          fetching: true,
        };
        break;

      case getSubscriptionInvoiceData.SUCCESS:
        draft.getSubscriptionInvoiceData.fetching = false;
        draft.getSubscriptionInvoiceData.fetched = true;
        draft.getSubscriptionInvoiceData.invoiceData = payload.data;
        break;

      case getSubscriptionInvoiceData.FAILURE:
        draft.getSubscriptionInvoiceData.error = payload;
        draft.getSubscriptionInvoiceData.fetching = false;
        draft.getSubscriptionInvoiceData.fetched = false;
        break;

      case getSubscriptionInvoiceData.RESET:
        draft.getSubscriptionInvoiceData = {
          ...initialState.getSubscriptionInvoiceData,
        };
        break;

      // update invoice data
      case updateSubscriptionInvoiceData.REQUEST:
        draft.updateSubscriptionInvoiceData = {
          ...initialState.updateSubscriptionInvoiceData,
          fetching: true,
        };
        break;

      case updateSubscriptionInvoiceData.SUCCESS:
        draft.updateSubscriptionInvoiceData.fetching = false;
        draft.updateSubscriptionInvoiceData.fetched = true;
        draft.updateSubscriptionInvoiceData.invoiceData = payload.data;
        draft.getSubscriptionHome.subscription.invoiceData = payload.data;
        break;

      case updateSubscriptionInvoiceData.FAILURE:
        draft.updateSubscriptionInvoiceData.error = payload;
        draft.updateSubscriptionInvoiceData.fetching = false;
        draft.updateSubscriptionInvoiceData.fetched = false;
        break;

      case updateSubscriptionInvoiceData.RESET:
        draft.updateSubscriptionInvoiceData = {
          ...initialState.updateSubscriptionInvoiceData,
        };
        break;

      default:
        return draft;
    }
  });

export default subscriptionProviderReducer;
