import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    height: '100%',
    width: 400,
  },
  input: {
    marginBottom: theme.typography.pxToRem(25),
    width: '100%',
  },
  inputPrice: {
    marginBottom: 0,
    width: '100%',
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#2e2e2e',
    marginBottom: theme.typography.pxToRem(5),
  },
  formContent: {
    padding: theme.typography.pxToRem(20),
  },
  fullList: {
    width: 'auto',
  },
  saveContainer: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    background: '#efefef',
  },
  saveButton: {
    borderRadius: '0',
    padding: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  saveButtonProgress: {
    margin: theme.typography.pxToRem(15),
  },
}));

export default useStyles;
