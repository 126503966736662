import { RestRequest, ResponseFunction, RestContext } from 'msw';

import transactions from '../../../__mocks__/reports/transactions-pay/transactions.json';
import movements from '../../../__mocks__/reports/transactions-pay/movements.json';
import transfers from '../../../__mocks__/reports/transactions-pay/transfers.json';

export function getTranctions(req: RestRequest, res: ResponseFunction, ctx: RestContext) {
  const size = req.url.searchParams.get('size');

  if (Number(size) === 0) {
    return res(ctx.status(204));
  }

  return res(ctx.status(200), ctx.json(transactions));
}

export function getMovements(req: RestRequest, res: ResponseFunction, ctx: RestContext) {
  const size = req.url.searchParams.get('size');

  if (Number(size) === 0) {
    return res(ctx.status(204));
  }

  return res(ctx.status(200), ctx.json(movements));
}

export function getTransfers(req: RestRequest, res: ResponseFunction, ctx: RestContext) {
  const size = req.url.searchParams.get('size');

  if (Number(size) === 0) {
    return res(ctx.status(204));
  }

  return res(ctx.status(200), ctx.json(transfers));
}
