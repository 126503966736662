import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TableCellMui from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import useIconParrot from 'hooks/useIconParrot';

const TableCell = withStyles((theme) => ({
  root: {
    padding: 32,
    fontWeight: 500,
    fontSize: '1rem',
    color: theme.palette.darkGrey.main,
    borderBottom: 0,
  },
}))(TableCellMui);

function TableHeadCell({ align = 'center', active = false, disabled, isSorting, direction, children, ...others }) {
  const { IconArrowDownTable } = useIconParrot({ icon: 'arrowDownTable' });

  return (
    <TableCell align={align} disabled={disabled} {...others}>
      {isSorting && (
        <TableSortLabel active={active} direction={direction} disabled={disabled} IconComponent={IconArrowDownTable}>
          {children}
        </TableSortLabel>
      )}

      {!isSorting && children}
    </TableCell>
  );
}

TableHeadCell.propTypes = {
  ...TableCellMui.propTypes,
  ...TableSortLabel.propTypes,
};

export default TableHeadCell;
