import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import sortBy from 'lodash/sortBy';
import differenceBy from 'lodash/differenceBy';
import { useDebounce } from '@open-react-hooks/use-debounce';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import IconButton from '@design-system/IconButton';

import IconSearchOutline from '@experimental-components/IconsComponents/SearchOutline';
import IconClose from '@experimental-components/IconsComponents/Close';

import PageError from 'components/PageError';
import DisplayMessage from 'components/DisplayMessageV2';

import HelperSkeleton from './HelperSkeleton';

import { sortByToLower } from './utils';
import useStyles from './styles';

function TransferList({
  isLoadingOptions,
  isLoadingOptionsByFilter,
  isSearchFilter,
  isError,
  placeholderLabel,
  labelOptionsAvailable,
  labelOptionsSelected,
  onSearch,
  onChange,
  options,
  optionsSelected,
  scrollPaginationRef,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { debounce } = useDebounce({ delay: 300 });
  const [rightOption, setRightOptions] = useState(optionsSelected ?? []);

  const handleOnSearch = (e) => {
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  const handlePasteRight = (option) => {
    const rightOptionTmp = [...rightOption];
    rightOptionTmp.push(option);
    setRightOptions(rightOptionTmp);
    if (onChange) onChange(rightOptionTmp);
  };

  const handlePasteLeft = (option) => {
    const rightOptionTmp = [...rightOption].filter((item) => item?.value !== option?.value);
    setRightOptions(rightOptionTmp);
    if (onChange) onChange(rightOptionTmp);
  };

  function handleRenderLeftOptions() {
    const leftOptionsTmp = sortBy(differenceBy(options, rightOption, 'value'), [sortByToLower]);

    if (isError) {
      return (
        <Box className={classes.boxMessages}>
          <PageError
            id="productsListDataError"
            maxWidth={300}
            message={t('common:errors.loaded.errorConnection')}
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (isSearchFilter && !isLoadingOptionsByFilter && options?.length === 0) {
      return (
        <Box className={classes.boxMessages}>
          <DisplayMessage
            height="90%"
            id="productsListEmptyData"
            maxWidth={300}
            message={t('common:messages.noResultsFoundSearchRetry')}
            sizeIcon={100}
            sizeMessage={16}
            sizeTitle={16}
            title={t('common:messages.titleNoResultsFoundSearch')}
          />
        </Box>
      );
    }

    if (isLoadingOptionsByFilter) {
      return (
        <List>
          <HelperSkeleton isList />
        </List>
      );
    }

    return (
      <>
        <List>
          {leftOptionsTmp?.map((option) => (
            <ListItem key={option?.value} button onClick={() => handlePasteRight(option)}>
              <Box className={classes.itemText}>{option?.name}</Box>
            </ListItem>
          ))}
          {isLoadingOptions && <HelperSkeleton isList />}
        </List>
        <Box ref={scrollPaginationRef} mb="0.5rem" name="actionListScroll" />
      </>
    );
  }

  useEffect(() => {
    return () => {
      setRightOptions([]);
    };
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.inputSearch}>
        <InputAdornmentFiled
          fullWidth
          hideLabel
          hideLabelHelperTex
          id="header-form-search-box"
          onChange={debounce(handleOnSearch)}
          placeholder={placeholderLabel}
          startAdornment={
            <InputAdornment position="start">
              <IconSearchOutline />
            </InputAdornment>
          }
        />
      </Box>
      <Box className={classes.contentLists}>
        <Box className={classes.fullHeight}>
          {labelOptionsAvailable && <Box className={classes.labelList}>{labelOptionsAvailable}</Box>}
          <Box className={classes.boxList}>{handleRenderLeftOptions()}</Box>
        </Box>
        <Box className={classes.fullHeight}>
          {labelOptionsSelected && <Box className={classes.labelList}>{labelOptionsSelected}</Box>}
          <Box className={classes.boxList}>
            {rightOption && (
              <List>
                {rightOption?.map((option) => (
                  <ListItem key={option?.value}>
                    <Box className={classes.itemText}>{option?.name}</Box>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handlePasteLeft(option)}>
                        <IconClose size={11} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

TransferList.propTypes = {
  isLoadingOptions: PropTypes.bool,
  isLoadingOptionsByFilter: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  isError: PropTypes.bool,
  placeholderLabel: PropTypes.string,
  labelOptionsAvailable: PropTypes.string,
  labelOptionsSelected: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionsSelected: PropTypes.array,
  scrollPaginationRef: PropTypes.object,
};

export default TransferList;
