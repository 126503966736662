import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Select from '@design-system/Select';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import useStyles from './styles';
import { menuProps } from './utils';

function TypeSoldProducts({ onSelectTypeSoldProduct }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');

  function handleRenderNameValue(value) {
    let renderSelected = '';

    switch (value) {
      case 1:
        renderSelected = t('menuMaker:headerForm.filterProducts.allArticles');
        break;
      case 2:
        renderSelected = t('menuMaker:headerForm.filterProducts.soldAlone');
        break;
      case 3:
        renderSelected = t('menuMaker:headerForm.filterProducts.notSoldAlone');
        break;
      default:
        renderSelected = t('menuMaker:headerForm.filterProducts.allArticles');
        break;
    }

    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  return (
    <Select
      defaultValue={1}
      displayEmpty
      fullWidth
      hideLabelHelperText
      label={t('menuMaker:headerForm.typeSoldProducts')}
      MenuProps={{ ...menuProps }}
      name="typeSoldProducts"
      onChange={(e) => onSelectTypeSoldProduct(e.target.value)}
      renderValue={(selected) => handleRenderNameValue(selected)}
    >
      <MenuItem key="all" value={1}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.allArticles')} />
      </MenuItem>
      <MenuItem key="soldAlone" value={2}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.soldAlone')} />
      </MenuItem>
      <MenuItem key="notSoldAlone" value={3}>
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.notSoldAlone')} />
      </MenuItem>
    </Select>
  );
}
TypeSoldProducts.propTypes = {
  onSelectTypeSoldProduct: PropTypes.func,
};

export default TypeSoldProducts;
