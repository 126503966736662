import { Children } from 'react';
import PropTypes from 'prop-types';

import InventoriesAdminProvider from 'providers/inventories/InventoriesAdminProvider';
import InventoriesSupplies from 'providers/inventories/InventoriesSuppliesProvider';
import StockCategoriesProvider from 'providers/inventories/StockCategoriesProvider';
import StorageAreasProvider from 'providers/inventories/StorageAreasProvider';

import ComposeProviders from 'components/ComposeProviders';

function InventoriesRootsProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <InventoriesAdminProvider />,
        <InventoriesSupplies />,
        <StockCategoriesProvider />,
        <StorageAreasProvider />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

InventoriesRootsProvider.propTypes = {
  children: PropTypes.element,
};

export default InventoriesRootsProvider;
