import {
  withStyles,
  InputBase,
  InputBaseProps,
  InputLabel,
  FormControl,
  FormHelperText,
  PropertyProps,
} from '@material-ui/core';

const InputRoot = withStyles((theme) => {
  const placeholder = {
    color: (props: PropertyProps) => (props.disabled ? theme.palette.darkGrey[15] : theme.palette.darkGrey[50]),
    opacity: 1,
  };

  const getBorder = (props: PropertyProps) => {
    if (props?.disabled) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
  };

  const getBorderColor = (props: PropertyProps) => {
    if (props?.disabled) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.darkGrey[50]}`;
  };

  const getBackgroundColor = (props: PropertyProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[2];
    }

    if (props?.error) {
      return theme.palette.error.light;
    }

    return theme.palette.common.white;
  };

  return {
    root: {
      'label + &': {
        marginTop: theme.spacing(4),
      },
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      backgroundColor: (props) => getBackgroundColor(props),
      border: (props) => getBorderColor(props),
      fontSize: 16,
      fontWeight: 400,
      height: '50px',
      boxSizing: 'border-box',
      padding: '1.25rem 1rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,

      '&:hover': {
        border: (props) => getBorder(props),
      },

      '&:focus': {
        border: (props) => getBorder(props),
        borderRadius: theme.shape.borderRadius,
      },
    },

    disabled: {
      color: theme.palette.darkGrey[15],
    },

    error: {
      color: theme.palette.error.main,
    },
  };
})(InputBase);

const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
    fontSize: '0.875rem',
  },
}))(FormHelperText);

interface Props extends InputBaseProps {
  label?: string;
  helperText?: string;
  errorMsg?: string;
  hideLabel?: boolean;
  hideLabelHelperText?: boolean;
}

function TextField({
  id,
  error,
  fullWidth,
  label,
  helperText,
  errorMsg,
  disabled,
  required,
  hideLabel,
  hideLabelHelperText,
  ...props
}: Props) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      {!hideLabel && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}

      <InputRoot {...props} />
      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
}

export default TextField;
