import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';

import ProductForm from 'components/Products/ProductForm';
import { actions } from 'utils/menuMaker';
import { convertToFieldFormat } from 'utils/settingsTaxes/transformer';

function AddProductForm({ categoriesMenusList = [], kitchenAreas, multiTaxes = [], ...other }) {
  const taxesField = convertToFieldFormat(multiTaxes);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      kitchenAreasFields: [],
      exemptField: false,
      ...taxesField,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <ProductForm
        action={actions.ADD}
        categoriesMenusList={categoriesMenusList}
        defaultTaxes={taxesField}
        kitchenAreas={kitchenAreas}
        multiTaxes={multiTaxes}
        {...other}
      />
    </FormProvider>
  );
}

AddProductForm.propTypes = {
  kitchenAreas: PropTypes.array,
  categoriesMenusList: PropTypes.array,
  multiTaxes: PropTypes.array,
};

export default AddProductForm;
