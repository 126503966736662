import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ChevronDoubleLeft = forwardRef(function ChevronRight(props, ref) {
  const { color = '#fff', size = 13, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path d="M7.9 2L1.5 8L7.9 14" stroke={color} strokeWidth="1.5" />
      <path d="M14.8 2L8.4 8L14.8 14" stroke={color} strokeWidth="1.5" />
    </svg>
  );
});

ChevronDoubleLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ChevronDoubleLeft;
