import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useEnvironment from 'hooks/useEnvironment';

function ParrotPayConektaProvider({ children }) {
  const { conektaPublicKey } = useEnvironment();

  useEffect(() => {
    window?.Conekta?.setPublicKey(conektaPublicKey);
  }, [conektaPublicKey]);

  return React.Children.only(children);
}

ParrotPayConektaProvider.propTypes = {
  children: PropTypes.element,
};

export default ParrotPayConektaProvider;
