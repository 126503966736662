import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  description: {
    color: theme.palette.darkGrey.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1rem',
  },
  dialogContent: {
    height: theme.typography.pxToRem(500),
  },
  actionsButton: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(242),
  },
}));

export default useStyles;
