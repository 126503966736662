import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  labelIcon: {
    marginLeft: theme.typography.pxToRem(5),
  },
}));

export default useStyles;
