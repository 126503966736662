import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getReportsResumeALL } from './actions';

import * as selectors from './selectors';

export function withReportsResumeHoc(Component) {
  function withReportsResume(props) {
    return <Component {...props} />;
  }

  withReportsResume.displayName = `withReportsResume(${getDisplayName(Component)})`;
  return withReportsResume;
}

const mapStateToProps = createStructuredSelector({
  resumeSummaries: selectors.selectReportsResumeSummaries,
  resumeSummariesState: selectors.selectReportsResumeSummariesState,
  resumeSalesChannels: selectors.selectReportsResumeSalesChannels,
  resumeSalesChannelsState: selectors.selectReportsResumeSalesChannelsState,
  resumeTopProducts: selectors.selectReportsResumeTopProducts,
  resumeTopProductsState: selectors.selectReportsResumeTopProductsState,
  resumeSalesBrands: selectors.selectReportsResumeSalesBrands,
  resumeSalesBrandsState: selectors.selectReportsResumeSalesBrandsState,
  resumeSalesCategories: selectors.selectReportsResumeSalesCategories,
  resumeSalesCategoriesState: selectors.selectReportsResumeSalesCategoriesState,
  resumeSalesPeriod: selectors.selectReportsResumeSalesPeriod,
  resumeSalesPeriodState: selectors.selectReportsResumeSalesPeriodState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadReportsResumeAll: (filters = {}) => dispatch(getReportsResumeALL(filters)),
    resetReportsResumeAll: () => dispatch(getReportsResumeALL.reset()),
  };
}

export const withReportsResumeState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withReportsResumeState, withReportsResumeHoc);
