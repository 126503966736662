import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getProductRequest } from 'utils/api/baas/menuMaker';

import { getProduct } from '../actions';

export function* handleGetProductRequest(action) {
  const { storeUuid, productUuid } = action.payload;
  try {
    yield put(getProduct.request());

    const { data } = yield call(baasRequest, getProductRequest, {
      storeUuid,
      productUuid,
    });

    yield put(getProduct.success(data));
  } catch (err) {
    yield put(getProduct.failure({ error: err, productUuid }));
  }
}
