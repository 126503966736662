import { useRef } from 'react';

import { useSelector } from 'react-redux';

import { selectLicense, selectLicenseState, selectChangeStoreLoading } from 'providers/root/AuthProvider/selectors';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

function useLicenseState() {
  const licenseStateRef = useRef({});
  const licenseStateStatusRef = useRef({});
  const changeStoreLoadingRef = useRef({});

  licenseStateRef.current = useSelector((state) => selectLicense(state));
  licenseStateStatusRef.current = useSelector((state) => selectLicenseState(state));
  changeStoreLoadingRef.current = useSelector((state) => selectChangeStoreLoading(state));

  return {
    licenseStatus: licenseStateRef.current?.status,
    licenseState: licenseStateStatusRef.current,
    licenseValid: licenseStateRef.current?.status === subscriptionStatusTypes.AVAILABLE,
    licenseInvalid: licenseStateRef.current?.status === subscriptionStatusTypes.UNAVAILABLE,
    paymentInvalid: licenseStateRef.current?.status === subscriptionStatusTypes.PAYMENT_REQUIRED,
    serverDateValidate: licenseStateRef.current?.serverDate,
    IsChangeStoreLoading: changeStoreLoadingRef.current,
  };
}
export default useLicenseState;
