import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { ContentLayout } from 'Layout';

import Box from '@material-ui/core/Box';

import WorkforceTabsNavigation from 'components/WorkforceTabsNavigation';
import PageError from 'components/PageError';
import HeaderForm from 'components/HeaderForm';
import RolesTable from 'components/RolesTable';

function RolesList({
  deleteRole,
  deleteRoleDidFetch,
  deleteRoleIsFetching,
  duplicateRole,
  filteredRoleList,
  onAddButtonClick,
  onSearchBoxChange,
  rolesError,
  rolesIsFetching,
  loadRoles,
}) {
  const { t } = useTranslation('workforce');
  return (
    <ContentLayout>
      <ContentLayout.Header>
        <WorkforceTabsNavigation />
      </ContentLayout.Header>
      <ContentLayout.Container>
        <Box p={4}>
          <HeaderForm
            addButtonLabel={t('workforce:buttons.newRole')}
            onAddButtonClick={onAddButtonClick}
            onSearchBoxChange={onSearchBoxChange}
            searchLabel={t('workforce:headerForm.searchRoles')}
          />
        </Box>

        {rolesError && (
          <PageError
            id="modifierGroupsError"
            labelAction={t('common:buttons.retry')}
            message={t('common:errors.loaded.role')}
            onAction={loadRoles}
          />
        )}

        {!rolesError && (
          <RolesTable
            deleteRole={deleteRole}
            deleteRoleDidFetch={deleteRoleDidFetch}
            deleteRoleIsFetching={deleteRoleIsFetching}
            duplicateRole={duplicateRole}
            rolesIsFetching={rolesIsFetching}
            rolesList={filteredRoleList}
          />
        )}
      </ContentLayout.Container>
    </ContentLayout>
  );
}

RolesList.propTypes = {
  deleteRole: PropTypes.func,
  deleteRoleDidFetch: PropTypes.bool,
  deleteRoleIsFetching: PropTypes.bool,
  duplicateRole: PropTypes.func,
  filteredRoleList: PropTypes.array,
  onAddButtonClick: PropTypes.func,
  onSearchBoxChange: PropTypes.func,
  rolesError: PropTypes.bool,
  rolesIsFetching: PropTypes.bool,
  loadRoles: PropTypes.func,
};

export default RolesList;
