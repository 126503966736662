import React, { memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { useListsOptions } from 'providers/root/ListsOptionsProvider';

import InputAdornment from '@material-ui/core/InputAdornment';
import { compose } from 'redux';
import ButtonAdd from '@design-system/ButtonAdd';

import LiveSearch from '@experimental-components/LiveSearch';

import useSortingListsOptions from 'hooks/useSortingListsOptions';

import { sortingPropertyTypes } from 'utils/products';

function OptionSelect({ options, selectedOptions, onChange, handleAddOption, t }) {
  const addOptionButtonRef = useRef(null);

  const {
    productsAutocompletePaginationValues,
    productsAutocompletePaginationState,
    getListProductsAutocompletePagination,
  } = useListsOptions();
  const {
    getProductsAutocompleteFilter: {
      productsAutocompleteFilterValues,
      productsAutocompleteFilterState,
      setProductsAutocompleteFilterByText,
    },
  } = useSortingListsOptions();

  const getOptionLabel = (item) => item?.product?.name;

  function getFinalOptions() {
    const selectOptionByUuid = [...selectedOptions].map((option) => option?.product?.uuid);
    const optionsTmp = [...options]?.filter((option) => !selectOptionByUuid.includes(option?.product?.uuid));

    return optionsTmp;
  }

  const handlePaginationProductsOptions = useCallback(() => {
    const currentPage = productsAutocompletePaginationValues.page + 1;

    if (!productsAutocompletePaginationState.fetching) {
      getListProductsAutocompletePagination({ page: currentPage, ...productsAutocompleteFilterValues });
    }
  }, [
    getListProductsAutocompletePagination,
    productsAutocompletePaginationState.fetching,
    productsAutocompletePaginationValues.page,
    productsAutocompleteFilterValues,
  ]);

  function handleInputChange(value) {
    if (!productsAutocompleteFilterState.fetching)
      setProductsAutocompleteFilterByText({ filterByText: value, orderBy: sortingPropertyTypes.NAME });
  }

  return (
    <LiveSearch
      endAdornment={
        <InputAdornment position="end">
          <ButtonAdd ref={addOptionButtonRef} onClick={handleAddOption}>
            {t('common:buttons.add')}
          </ButtonAdd>
        </InputAdornment>
      }
      getOptionKey={(item) => item?.product?.uuid}
      getOptionLabel={getOptionLabel}
      hasNextPage={Boolean(productsAutocompletePaginationValues?.next)}
      id="filled-adornment-autocomplete-modifiers"
      isFilterLoading={productsAutocompleteFilterState.fetching}
      isNextPageLoading={productsAutocompletePaginationState.fetching}
      noOptionsText={t('menuMaker:modifierGroupForm.labels.emptyModifierGroups')}
      onChange={(newValue) => {
        onChange(selectedOptions.concat(newValue));
      }}
      onChangeInput={handleInputChange}
      onLoadNextPage={handlePaginationProductsOptions}
      options={getFinalOptions()}
      placeholder={t('menuMaker:modifierGroupForm.labels.addCustomizableArticle')}
    />
  );
}

OptionSelect.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  handleAddOption: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'), memo)(OptionSelect);
