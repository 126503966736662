import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconDeleteOutline from '@experimental-components/IconsComponents/DeleteOutline';

import Menu from '@experimental-components/Menu';
import IconButton from '@design-system/IconButton';

import useIconParrot from 'hooks/useIconParrot';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
    minWidth: 168,
  },
}));

function MenuActions({ open, onDelete, onDuplicate, onEdit, onClose, t, ...others }) {
  const classes = useStyles();

  const { IconDuplicateOutline } = useIconParrot({
    icons: ['duplicateOutline'],
  });

  return (
    <Menu classes={{ paper: classes.paper }} keepMounted onClose={onClose} open={open} {...others}>
      {onEdit && (
        <Menu.Item onClick={onEdit}>
          <IconButton startIcon={<EditOutlinedIcon />}>{t('common:actions.edit')}</IconButton>
        </Menu.Item>
      )}

      {onDuplicate && (
        <Menu.Item onClick={onDuplicate}>
          <IconButton startIcon={<IconDuplicateOutline />}>{t('common:actions.duplicate')}</IconButton>
        </Menu.Item>
      )}

      {onDelete && (
        <Menu.Item data-testid="deleteButton" onClick={onDelete}>
          <IconButton startIcon={<IconDeleteOutline />}>
            <Box color="error.main" component="span">
              {t('common:actions.delete')}
            </Box>
          </IconButton>
        </Menu.Item>
      )}
    </Menu>
  );
}

MenuActions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onEdit: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(MenuActions);
