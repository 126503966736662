import React from 'react';
import PropTypes from 'prop-types';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/dayjs';

import ViewPanelProvider from 'providers/root/ViewPanelProvider';
import ContentLayoutProvider from 'providers/root/ContentLayoutProvider';
import SidebarLayoutProvider from 'providers/root/SidebarLayoutProvider';
import BrandsProvider from 'providers/root/BrandsProvider';
import BrandsProviderV2 from 'providers/root/BrandsProviderV2';
import AddressProvider from 'providers/root/AddressProvider';
import SubscriptionProvider from 'providers/root/SubscriptionProvider';
import ListsOptionsProvider from 'providers/root/ListsOptionsProvider';
import ParrotGoogleMapProvider from '@experimental-components/GoogleMapsLocation/GoogleMapProvider';

import ComposeProviders from 'components/ComposeProviders';

import ReportsRootProvider from './AppProviders/ReportsRootProvider';
import MenuMakerRootProvider from './AppProviders/MenuMakerRootProvider';
import WorkforceRootProvider from './AppProviders/WorkforceRootProvider';
import InvoicingRootProvider from './AppProviders/InvoicingRootProvider';
import SettingsRootProvider from './AppProviders/SettingsRootProvider';
import OnlineOrderingRootProvider from './AppProviders/OnlineOrderingRootProvider';
import InventoriesRootsProvider from './AppProviders/InventoriesRootsProvider';

function AppProviders({ children }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ComposeProviders
        providers={[
          <ContentLayoutProvider />,
          <ViewPanelProvider />,
          <SidebarLayoutProvider />,
          <ParrotGoogleMapProvider
            config={{
              googleMapURLApi: 'https://maps.googleapis.com/maps/api/js',
              apiKey: 'AIzaSyBmuLEB-EAmaQ2LeHXQCV7IKChAYWF77Z8',
              libraries: ['geometry', 'drawing', 'places'],
            }}
          />,
          <BrandsProvider />,
          <BrandsProviderV2 />,
          <AddressProvider />,
          <SubscriptionProvider />,
          <ListsOptionsProvider />,
          <ReportsRootProvider />,
          <MenuMakerRootProvider />,
          <WorkforceRootProvider />,
          <InvoicingRootProvider />,
          <SettingsRootProvider />,
          <InventoriesRootsProvider />,
          <OnlineOrderingRootProvider />,
        ]}
      >
        {React.Children.only(children)}
      </ComposeProviders>
    </MuiPickersUtilsProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProviders;
