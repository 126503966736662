import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

function HelperSkeleton({ isList }) {
  const classes = useStyles();

  if (isList) {
    return [1, 2, 3].map((option) => (
      <ListItem key={`skeletonOption-${option}`}>
        <Skeleton height={16} variant="rect" width="100%" />
      </ListItem>
    ));
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.inputSearch}>
        <Skeleton height={50} variant="rect" width="100%" />
      </Box>
      <Box className={classes.contentLists}>
        <Box className={classes.fullHeight}>
          <Box className={classes.labelList}>
            <Skeleton height={16} variant="text" width={150} />
          </Box>
          <Skeleton className={classes.boxList} variant="rect"></Skeleton>
        </Box>
        <Box className={classes.fullHeight}>
          <Box className={classes.labelList}>
            <Skeleton height={16} variant="text" width={150} />
          </Box>
          <Skeleton className={classes.boxList} variant="rect"></Skeleton>
        </Box>
      </Box>
    </Box>
  );
}

HelperSkeleton.propTypes = {
  isList: PropTypes.bool,
};

export default HelperSkeleton;
