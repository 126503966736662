import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getCategories, getPaginationCategory, createCategory, deleteCategory, duplicateCategory } from './actions';

import {
  selectCategoriesStore,
  selectCategoriesMenusStore,
  selectCategoriesFetching,
  selectCategoriesError,
  selectCategoriesFetched,
  selectCategoriesPagination,
  selectCreateCategory,
  selectCreateCategoryFetching,
  selectCreateCategoryFetched,
  selectCreateCategoryError,
  selectDeleteCategoryFetching,
  selectDeleteCategoryFetched,
  selectDeleteCategoryError,
  selectDuplicateCategory,
  selectDuplicateCategoryFetching,
  selectDuplicateCategoryFetched,
  selectDuplicateCategoryError,
  selectPaginationCategoryState,
} from './selectors';

export function withCategoriesHoc(Component) {
  function withCategories(props) {
    return <Component {...props} />;
  }

  withCategories.displayName = `withCategories(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withCategories;
}

const mapStateToProps = createStructuredSelector({
  categoriesList: selectCategoriesStore,
  categoriesMenusList: selectCategoriesMenusStore,
  categoriesFetching: selectCategoriesFetching,
  categoriesError: selectCategoriesError,
  categoriesFetched: selectCategoriesFetched,
  categoriesPagination: selectCategoriesPagination,
  createCategoryObject: selectCreateCategory,
  createCategoryFetching: selectCreateCategoryFetching,
  createCategoryFetched: selectCreateCategoryFetched,
  createCategoryError: selectCreateCategoryError,
  deleteCategoryFetching: selectDeleteCategoryFetching,
  deleteCategoryFetched: selectDeleteCategoryFetched,
  deleteCategoryError: selectDeleteCategoryError,
  duplicateCategoryObject: selectDuplicateCategory,
  duplicateCategoryFetching: selectDuplicateCategoryFetching,
  duplicateCategoryFetched: selectDuplicateCategoryFetched,
  duplicateCategoryError: selectDuplicateCategoryError,
  paginationCategoryState: selectPaginationCategoryState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadCategories: (values) => dispatch(getCategories(values)),
    createNewCategory: (category) => dispatch(createCategory(category)),
    deleteCategory: ({ storeUuid, categoryUuid }) => dispatch(deleteCategory({ storeUuid, categoryUuid })),
    duplicateCategory: ({ storeUuid, categoryUuid }) => dispatch(duplicateCategory({ storeUuid, categoryUuid })),
    paginateCategory: (values) => dispatch(getPaginationCategory(values)),
    resetCategories: () => dispatch(getCategories.reset()),
    resetCreateCategory: () => dispatch(createCategory.reset()),
    resetDeleteCategory: () => dispatch(deleteCategory.reset()),
    resetDuplicateCategory: () => dispatch(duplicateCategory.reset()),
  };
}

export const withCategoriesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withCategoriesState, withCategoriesHoc);
