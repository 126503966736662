import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';

import ProductForm from 'components/Products/ProductForm';

import { getKitchenAreasFromCategories } from 'utils/kitchenAreas/makeKitchenAreasFields';
import { convertToFieldFormat, mergeAssignTaxesToField } from 'utils/settingsTaxes/transformer';

function WrapperProductForm({ availableCategories, kitchenAreas, multiTaxes = [], product, ...others }) {
  const kitchenAreasFields = product?.kitchenAreasAssignation.map((ka) => ka.uuid);
  let kitchenAreaCanPrint = true;

  const taxesField = convertToFieldFormat(multiTaxes);
  const newTaxesField = mergeAssignTaxesToField(taxesField, product.taxes, multiTaxes);

  let newKitchenAreas = [];
  if (product?.categories.length > 0 && kitchenAreasFields.length === 0) {
    newKitchenAreas = getKitchenAreasFromCategories(availableCategories, product.categories);
  } else if (kitchenAreasFields.length > 0) {
    kitchenAreaCanPrint = product?.kitchenAreasAssignation.every((ka) => ka.canPrint);
  }
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      productCatalogueFiled: product?.taxProductKey,
      taxServingUnitFiled: product?.taxServingUnit,
      kitchenAreasFields: kitchenAreasFields.length > 0 ? kitchenAreasFields : newKitchenAreas,
      kitchenAreasFieldsCanPrint: !kitchenAreaCanPrint,
      canSelectOtherAreaField: product?.kitchenAreasAssignation.length > 0,
      exemptField: product?.isExempt,
      ...newTaxesField,
    },
  });
  return (
    <FormProvider {...formMethods}>
      <ProductForm
        availableCategories={availableCategories}
        defaultTaxes={newTaxesField}
        inheritedAreas={newKitchenAreas}
        kitchenAreas={kitchenAreas}
        multiTaxes={multiTaxes}
        product={product}
        {...others}
      />
    </FormProvider>
  );
}

WrapperProductForm.propTypes = {
  availableCategories: PropTypes.array,
  kitchenAreas: PropTypes.array,
  product: PropTypes.object,
  multiTaxes: PropTypes.array,
};

export default WrapperProductForm;
