import PropTypes from 'prop-types';

import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonNavigationBack from '@design-system/ButtonNavigationBack';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const drawerWidth = 260;
const appBarHeight = 120;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    height: '100%',
    maxHeight: appBarHeight,
    minHeight: appBarHeight,
    marginTop: 0,

    [theme.breakpoints.between('sm', 'md')]: {
      width: (props) => `calc(100% - ${props?.open ? drawerWidth : 62}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: `${theme.mixins.toolbar.minHeight}px !important`,
      width: '100%',
    },
  },

  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  main: {
    flexGrow: 1,
    marginTop: appBarHeight,

    [theme.breakpoints.down('xs')]: {
      marginTop: drawerWidth + theme.mixins.toolbar.minHeight - 140,
    },
    '& main': {
      marginTop: 0,
    },
  },
}));

const Title = ({ children, ...props }) => (
  <Typography
    component="h1"
    style={{ fontWeight: 500, marginBlockEnd: '2rem', color: 'black' }}
    variant="h5"
    {...props}
  >
    {children}
  </Typography>
);

const TopBar = ({ children }) => {
  const { openMdDrawer } = useSidebarLayout();
  const classes = useStyles({ open: openMdDrawer });
  return (
    <AppBar className={clsx(classes.appBar)} elevation={0} position="fixed">
      <Toolbar className={clsx(classes.toolbar)}>{children}</Toolbar>
    </AppBar>
  );
};

function ViewPanelLayout({
  actionComponent: actionComponentProp,
  children,
  disabledGoBack,
  labelAction,
  labelGoBack,
  onAction,
  onGoBack,
  title,
  componentHeader,
  defaultGoBack,
  goBackPath,
  t,
  ...others
}) {
  const { openMdDrawer } = useSidebarLayout();
  const theme = useTheme();
  const classes = useStyles({ open: openMdDrawer });
  const history = useHistory();

  if (componentHeader) {
    return (
      <>
        <Box mb={6}>{componentHeader}</Box>

        <Box component="main" pb={4} pl={4} pr={4} role="main" {...others}>
          {children}
        </Box>
      </>
    );
  }
  function handleGoBack() {
    if (goBackPath) {
      return history.replace(goBackPath);
    }
    return history.goBack();
  }
  return (
    <Box
      className={classes.main}
      component="main"
      pb={theme.typography.pxToRem(32)}
      pl={theme.typography.pxToRem(32)}
      pr={theme.typography.pxToRem(32)}
      role="main"
      {...others}
    >
      {/* TODO: Refactor those conditions */}
      {!title && (
        <TopBar>
          {onGoBack && (
            <ButtonNavigationBack data-testid="btnGoBack" disabled={disabledGoBack} onClick={onGoBack}>
              {labelGoBack}
            </ButtonNavigationBack>
          )}
          {defaultGoBack && (
            <ButtonNavigationBack disabled={disabledGoBack} onClick={handleGoBack}>
              {t('common:buttons.toReturn')}
            </ButtonNavigationBack>
          )}

          {actionComponentProp && <>{actionComponentProp}</>}
          {onAction && <ButtonPrimary onClick={onAction}>{labelAction}</ButtonPrimary>}
        </TopBar>
      )}
      {title && !actionComponentProp && !defaultGoBack && (
        <TopBar>
          <Title>{title}</Title>
        </TopBar>
      )}
      {title && actionComponentProp && (
        <TopBar>
          <Title>{title}</Title>
          {actionComponentProp}
          {onAction && <ButtonPrimary onClick={onAction}>{labelAction}</ButtonPrimary>}
        </TopBar>
      )}
      {title && (defaultGoBack || goBackPath) && (
        <>
          <TopBar>
            <ButtonNavigationBack data-testid="btnGoBack" onClick={handleGoBack}>
              {t('common:buttons.toReturn')}
            </ButtonNavigationBack>
          </TopBar>
          <Title>{title}</Title>
        </>
      )}
      {children}
    </Box>
  );
}

ViewPanelLayout.propTypes = {
  disabledGoBack: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  labelGoBack: PropTypes.string,
  onGoBack: PropTypes.func,
  labelAction: PropTypes.string,
  onAction: PropTypes.func,
  actionComponent: PropTypes.element,
  componentHeader: PropTypes.element,
};

export default withTranslation()(ViewPanelLayout);
