const PROPERTY_NAME = 'name';

const FILTER_PROPERTIES = [PROPERTY_NAME];

export const sortingPropertyTypes = {
  [PROPERTY_NAME]: PROPERTY_NAME,
};

export function filterInventoriesByText(inventories, sorting) {
  return inventories?.filter(
    (inventory) =>
      FILTER_PROPERTIES.filter((property) =>
        inventory[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}
