import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const DuplicateOutline = forwardRef(function DuplicateOutline(props, ref) {
  const { color = '#000000', size = 18, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 18"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M0.444444 0C0.198985 0 0 0.191878 0 0.428571V12.8571C0 13.0938 0.198985 13.2857 0.444444 13.2857C0.689904 13.2857 0.888889 13.0938 0.888889 12.8571V0.857143H11.5556C11.801 0.857143 12 0.665265 12 0.428571C12 0.191878 11.801 0 11.5556 0H0.444444ZM3.99999 3.42857C3.75453 3.42857 3.55554 3.62045 3.55554 3.85714V17.5714C3.55554 17.8081 3.75453 18 3.99999 18H15.5555C15.801 18 16 17.8081 16 17.5714V3.85714C16 3.62045 15.801 3.42857 15.5555 3.42857H3.99999ZM4.44443 17.1429V4.28572H15.1111V17.1429H4.44443Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

DuplicateOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withBackGround: PropTypes.bool,
};

export default DuplicateOutline;
