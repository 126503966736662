import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';

import useNotification from '@design-system/Notification/useNotification';

import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withModifierGroupDetails } from 'providers/menuMaker/ModifierGroupDetails';
import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';
import { useListsOptions } from 'providers/root/ListsOptionsProvider';

import ContentLayout from 'components/ContentLayout';
import PageError from 'components/PageError';
import ModifierGroupForm from 'components/ModifierGroupForm';

import useUserStore from 'hooks/useUserStore';
import useViewPanel from 'hooks/useViewPanel';
import useSortingListsOptions from 'hooks/useSortingListsOptions';

import { actions } from 'utils/menuMaker';

import HelperSkeleton from './HelperSkeleton';

export function ModifierGroup({
  // Modifier Group
  loadModifierGroup,
  modifierGroupObject,
  modifierGroupFetching,
  modifierGroupFetched,
  modifierGroupError,
  resetModifierGroupState,
  // edit modifier group
  editModifierGroup,
  editModifierGroupError,
  editModifierGroupFetching,
  editModifierGroupFetched,
  resetEditModifierGroup,
  // Modifier Group duplicate
  createModifierGroup,
  createModifierGroupError,
  createModifierGroupFetching,
  createModifierGroupFetched,
  resetCreateModifierGroup,
  match,
  t,
}) {
  const history = useHistory();
  const { storeId: storeUuid } = useUserStore();
  const { setOpen: setOpenViewPanel } = useViewPanel();
  const { getListProductsAutocomplete, productsAutocomplete, productsAutocompleteState } = useListsOptions();
  const {
    getProductsAutocompleteFilter: { productsAutocompleteFilterValues },
  } = useSortingListsOptions();

  const { closeAllSnackbar, setNotification } = useNotification();

  const { modifierGroupUuid, actionType } = match.params;

  const isFinishedLoading = modifierGroupFetched && productsAutocompleteState.fetched;

  const isDuplicateAction = actionType === 'duplicate';
  const modifierGroupForForm = JSON.parse(JSON.stringify(modifierGroupObject));

  if (isDuplicateAction) {
    modifierGroupForForm.name = `${modifierGroupForForm.name} - COPIA`;
  }
  useEffect(() => {
    setOpenViewPanel(true);
  }, [setOpenViewPanel]);

  useEffect(() => {
    loadModifierGroup(storeUuid, modifierGroupUuid);
  }, [loadModifierGroup, modifierGroupUuid, storeUuid]);

  useEffect(() => {
    getListProductsAutocomplete({ ...productsAutocompleteFilterValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEditModifierGroup(modifierGroup) {
    editModifierGroup(storeUuid, modifierGroup);
  }

  function handleCreateModifierGroup(modifierGroup) {
    modifierGroup?.options.forEach((option, index) => {
      delete modifierGroup.options[index].uuid;
      delete modifierGroup.options[index].createdAt;
      delete modifierGroup.options[index].updatedAt;
    });
    createModifierGroup(storeUuid, modifierGroup);
  }

  useEffect(() => {
    if (createModifierGroupFetched || editModifierGroupFetched) {
      resetModifierGroupState();
      setOpenViewPanel(false);
      history.push({
        pathname: '/menus/modifier-groups',
      });
    }
  }, [createModifierGroupFetched, editModifierGroupFetched, resetModifierGroupState, setOpenViewPanel, history]);

  useEffect(() => {
    if (editModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupForm.messages.saveError'), { variant: 'error' });
    }
  }, [editModifierGroupError, setNotification, t]);

  useEffect(() => {
    return () => {
      resetModifierGroupState();
      resetEditModifierGroup();
      closeAllSnackbar();
    };
  }, [resetModifierGroupState, resetCreateModifierGroup, resetEditModifierGroup, closeAllSnackbar]);

  return (
    <ContentLayout>
      {modifierGroupError && (
        <PageError
          id="productError"
          labelAction={t('common:buttons.retry')}
          message={t('menuMaker:errors.loadedError')}
          onAction={loadModifierGroup(storeUuid, modifierGroupUuid)}
        />
      )}

      {!isFinishedLoading && modifierGroupFetching && <HelperSkeleton />}

      <Route exact path={`/menus/modifier-groups/details/${modifierGroupUuid}/edit`}>
        {!modifierGroupError && isFinishedLoading && (
          <ModifierGroupForm
            action={actions.EDIT}
            error={Boolean(editModifierGroupError)}
            loading={editModifierGroupFetching}
            modifierGroup={modifierGroupForForm}
            products={productsAutocomplete}
            saveModifierGroup={handleEditModifierGroup}
          />
        )}
      </Route>

      <Route exact path={`/menus/modifier-groups/details/${modifierGroupUuid}/duplicate`}>
        {!modifierGroupError && isFinishedLoading && (
          <ModifierGroupForm
            action={actions.DUPLICATE}
            error={Boolean(createModifierGroupError)}
            loading={createModifierGroupFetching}
            modifierGroup={modifierGroupForForm}
            products={productsAutocomplete}
            saveModifierGroup={handleCreateModifierGroup}
          />
        )}
      </Route>
    </ContentLayout>
  );
}

ModifierGroup.propTypes = {
  // Modifier Group
  loadModifierGroup: PropTypes.func,
  modifierGroupObject: PropTypes.object,
  modifierGroupFetching: PropTypes.bool,
  modifierGroupFetched: PropTypes.bool,
  modifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetModifierGroupState: PropTypes.func,
  // edit modifier group
  editModifierGroup: PropTypes.func,
  editModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  editModifierGroupFetching: PropTypes.bool,
  editModifierGroupFetched: PropTypes.bool,
  resetEditModifierGroup: PropTypes.func,
  // Modifier Group duplicate
  createModifierGroup: PropTypes.func,
  createModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createModifierGroupFetching: PropTypes.bool,
  createModifierGroupFetched: PropTypes.bool,
  resetCreateModifierGroup: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.func,
};

export default compose(
  memo,
  withRequiredLicense(),
  withModifierGroupDetails,
  withModifierGroups,
  withTranslation('menuMaker'),
)(ModifierGroup);
