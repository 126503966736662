import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getInventoriesRequest({ page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
    page,
  });

  return request(`${API_BASE_URL}/v1/suppliers/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getInventoryDetailsRequest(inventoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/${inventoryUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createInventoryRequest(inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}

export function updateInventoryRequest(inventoryUuid, inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/${inventoryUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}

export function deleteInventoriesRequest(inventoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/${inventoryUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}
