import { Children } from 'react';
import PropTypes from 'prop-types';

import Menus from 'providers/menuMaker/Menus';
import MenuDetails from 'providers/menuMaker/MenuDetails';
import MenuProducts from 'providers/menuMaker/MenuProducts';
import MenuPreview from 'providers/menuMaker/MenuPreview';
import Categories from 'providers/menuMaker/Categories';
import CategoryDetails from 'providers/menuMaker/CategoryDetails';
import Products from 'providers/menuMaker/Products';
import ProductDetails from 'providers/menuMaker/ProductDetails';
import ModifierGroups from 'providers/menuMaker/ModifierGroups';
import ModifierGroupDetails from 'providers/menuMaker/ModifierGroupDetails';

import ComposeProviders from 'components/ComposeProviders';

function MenuMakerRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <Menus />,
        <MenuDetails />,
        <MenuProducts />,
        <MenuPreview />,
        <Categories />,
        <CategoryDetails />,
        <Products />,
        <ProductDetails />,
        <ModifierGroups />,
        <ModifierGroupDetails />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

MenuMakerRootProvider.propTypes = {
  children: PropTypes.element,
};

export default MenuMakerRootProvider;
