import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import Header from './Header';
import TabLink from './TabLink';
import Container from './Container';
import TabsNavigation from './TabsNavigation';

function ContentLayout({ children }) {
  return (
    <>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <Toolbar />
      </Hidden>
      {children}
    </>
  );
}

ContentLayout.Header = Header;
ContentLayout.TabsNavigation = TabsNavigation;
ContentLayout.TabLink = TabLink;
ContentLayout.Container = Container;

ContentLayout.propTypes = {
  children: PropTypes.node,
};

export default ContentLayout;
