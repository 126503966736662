/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { SortableHandle } from 'react-sortable-hoc';

import { makeStyles } from '@material-ui/core/styles';
import TableCellMui from '@material-ui/core/TableCell';

import IconButton from '@design-system/IconButton';
import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleVertical';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2.4rem 2rem',
    borderBottom: 0,
    borderTop: ({ isBorder }) => (isBorder ? `1px solid ${theme.palette.darkGrey[5]}` : 0),
    color: ({ isColorError }) => (isColorError ? theme.palette.error.main : theme.palette.common.black),
  },
}));

const DragHandle = SortableHandle(({ icon }) => (
  <IconButton aria-label="drag" size="small">
    {icon ?? <DragHandleVertical />}
  </IconButton>
));

function TableCell({ draggableIcon, isBorder = true, isColorError, isDraggable, children, ...others }) {
  const classes = useStyles({ isBorder, isColorError });

  return (
    <TableCellMui classes={{ root: classes.root }} {...others}>
      {isDraggable && <DragHandle icon={draggableIcon} />}
      {children}
    </TableCellMui>
  );
}

TableCell.propTypes = {
  ...TableCellMui.propTypes,
  isBorder: PropTypes.bool,
};

export default TableCell;
