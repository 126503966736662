import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  createStorageAreaRequest,
  getStorageAreasRequest,
  getStorageAreaDetailsRequest,
  updateStorageAreaRequest,
  deleteStorageAreaRequest,
} from 'utils/api/baas/inventories/storageAreas/storageAreas';

import {
  getStorageAreas,
  getStorageAreasPagination,
  getStorageAreaDetails,
  createStorageArea,
  updateStorageArea,
  deleteStorageArea,
} from './actions';

export function* handleGetStorageAreasRequest(action) {
  const filters = action.payload;

  try {
    yield put(getStorageAreas.request());

    const { data, pagination } = yield call(baasRequest, getStorageAreasRequest, filters);

    yield put(getStorageAreas.success({ data, pagination }));
  } catch (err) {
    yield put(getStorageAreas.failure(err));
  }
}

export function* handleGetStorageAreasPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getStorageAreasPagination.request());

    const { data, pagination } = yield call(baasRequest, getStorageAreasRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getStorageAreasPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getStorageAreasPagination.failure(err));
  }
}

export function* handleGetStorageAreaDetailsRequest(action) {
  const storageAreaUuid = action.payload;

  try {
    yield put(getStorageAreaDetails.request());

    const { data } = yield call(baasRequest, getStorageAreaDetailsRequest, storageAreaUuid);

    yield put(getStorageAreaDetails.success({ data }));
  } catch (err) {
    yield put(getStorageAreaDetails.failure(err));
  }
}

export function* handleCreateStorageAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(createStorageArea.request());

    const { data } = yield call(baasRequest, createStorageAreaRequest, fields);

    yield put(createStorageArea.success({ data }));
  } catch (err) {
    yield put(createStorageArea.failure(err));
  }
}

export function* handleUpdateStorageAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(updateStorageArea.request());
    const { data } = yield call(baasRequest, updateStorageAreaRequest, fields);

    yield put(updateStorageArea.success(data));
  } catch (err) {
    yield put(updateStorageArea.failure(err));
  }
}

export function* handleDeleteStorageAreaRequest(action) {
  const storageAreaUuid = action.payload;
  try {
    yield put(deleteStorageArea.request());

    yield call(baasRequest, deleteStorageAreaRequest, storageAreaUuid);

    yield put(deleteStorageArea.success());
  } catch (err) {
    yield put(deleteStorageArea.failure(err));
  } finally {
    yield put(deleteStorageArea.fulfill(storageAreaUuid));
  }
}

export default function* reportsOrdersProviderRootSga() {
  yield takeLatest(getStorageAreas.TRIGGER, handleGetStorageAreasRequest);
  yield takeLatest(getStorageAreaDetails.TRIGGER, handleGetStorageAreaDetailsRequest);
  yield takeLatest(getStorageAreasPagination.TRIGGER, handleGetStorageAreasPaginationRequest);
  yield takeLatest(createStorageArea.TRIGGER, handleCreateStorageAreaRequest);
  yield takeLatest(updateStorageArea.TRIGGER, handleUpdateStorageAreaRequest);
  yield takeLatest(deleteStorageArea.TRIGGER, handleDeleteStorageAreaRequest);
}
