import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { SortableElement, SortableHandle, SortableContainer } from 'react-sortable-hoc';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import TableHead from '@material-ui/core/TableHead';
import { Collapse, Menu, MenuItem, OutlinedInput } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import PriceFormatOnlyNumbersField from 'components/PriceFormatField/PriceFormatOnlyNumbersField';

import useStyles from './styles';

const DraggableHandle = SortableHandle(({ children }) => children);

const OptionsTableRow = ({
  index,
  conditionalGroup,
  handleRemoveOption,
  handleEditOptionPrice,
  handleEditOptionMenuOverridePrice,
  handleEditConditionalOptionPrice,
  handleEditConditionalOptionMenuOverridePrice,
  handleExpandOption,
  // Final Group
  handleRemoveFinalGroup,
  t,
  ...props
}) => {
  const classes = useStyles();
  const SortableItem = SortableElement(({ value: option }) => {
    /*
    * It expects an item like this:
    * {
        uuid: '5e19ba14-ba92-4401-bb8c-d6ffb1da6f13',
        product: {
          uuid: '81135193-b69e-4a01-bc2a-063453c36615',
          name: 'A nice Product 1',
          description: 'A nice Product 1 Description',
          image: '...',
          price: '100.00',
          availability: 'UNAVAILABLE',
          prepTime: 0,
          serving: '0.00',
          servingUnit: 'PIECES',
        },
        conditionals: [],
        finalGroup: null,
        name: '...',
        description: '...',
        servingUnit: null,
        additionalPrice: '10.00',
        availability: 'AVAILABLE',
      }
    * */
    const optionName = option?.name || option?.product?.name;
    const optionPrice = option?.additionalPrice || option?.product?.price || 0;
    const menuOverrides = option.menuOverrides || [];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
    // Prices for regular Rows
    function renderOptionPrices() {
      if (conditionalGroup) {
        return (
          <>
            {option.conditionals.map((conditionalOption, conditionalIndex) => (
              <TableCell key={`${option.uuid}:${conditionalOption.conditionalOption}`} align="right" width={100}>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    className={classes.priceTexField}
                    defaultValue={conditionalOption.additionalPrice}
                    inputComponent={PriceFormatOnlyNumbersField}
                    onBlur={handleEditConditionalOptionPrice(index, conditionalIndex)}
                    size="small"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    variant="outlined"
                  />
                </FormControl>
              </TableCell>
            ))}
          </>
        );
      }
      return (
        <TableCell align="right" width={125}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              className={classes.priceTexField}
              defaultValue={optionPrice}
              inputComponent={PriceFormatOnlyNumbersField}
              onBlur={handleEditOptionPrice(option, index)}
              size="small"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              variant="outlined"
            />
          </FormControl>
        </TableCell>
      );
    }
    // Prices for menu Rows
    function renderOptionMenuOverridePrices(parentOption, menuOverride, menuOverrideIndex) {
      if (conditionalGroup) {
        const conditionalMenuOverrides = [];
        parentOption.conditionals.forEach((conditionalOption) => {
          const matchConditionalMenuOverride = conditionalOption.menuOverrides.filter((conditionalMenuOverride) => {
            return conditionalMenuOverride.menuUuid === menuOverride.menuUuid;
          })[0];
          matchConditionalMenuOverride.conditionalOptionUuid = conditionalOption.conditionalOption;
          conditionalMenuOverrides.push(matchConditionalMenuOverride);
        });
        return (
          <>
            {conditionalMenuOverrides.map((conditionalMenuOverride, conditionalIndex) => (
              <TableCell
                key={`${parentOption.uuid}:${conditionalMenuOverride.menuUuid}:${conditionalMenuOverride.conditionalOptionUuid}`}
                align="right"
                width={100}
              >
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    className={classes.priceTexField}
                    defaultValue={conditionalMenuOverride.optionPrice || conditionalMenuOverride.productPrice || 0}
                    inputComponent={PriceFormatOnlyNumbersField}
                    onBlur={handleEditConditionalOptionMenuOverridePrice(index, conditionalIndex, menuOverrideIndex)}
                    size="small"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    variant="outlined"
                  />
                </FormControl>
              </TableCell>
            ))}
          </>
        );
      }
      return (
        <TableCell align="right" width={96}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              className={classes.priceTexField}
              defaultValue={menuOverride.optionPrice || menuOverride.productPrice || 0}
              inputComponent={PriceFormatOnlyNumbersField}
              onBlur={handleEditOptionMenuOverridePrice(option, index, menuOverride, menuOverrideIndex)}
              size="small"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              variant="outlined"
            />
          </FormControl>
        </TableCell>
      );
    }

    return (
      <>
        <TableRow className={classes.row}>
          <DraggableHandle>
            <TableCell width={10}>
              <IconButton aria-label="drag" size="small">
                <DragHandleIcon />
              </IconButton>
            </TableCell>
          </DraggableHandle>
          <TableCell>
            {optionName} &nbsp;&nbsp;&nbsp;
            {(menuOverrides.length > 0 || Boolean(option.finalGroup)) && (
              <IconButton aria-label="expand row" onClick={handleExpandOption(option, index)} size="small">
                {option.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          {renderOptionPrices()}
          <TableCell align="right" width={50}>
            <IconButton onClick={handleOpenMenu} size="medium">
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted onClose={handleCloseMenu} open={openMenu}>
              <MenuItem className={classes.actionItem} onClick={() => handleRemoveOption(option)}>
                {t('common:actions.delete')}
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={10} style={{ padding: 0 }}>
            <Collapse in={option.expanded} timeout="auto" unmountOnExit>
              {/* Table for Menu Overrides */}
              <Table className={classes.tableCollapse} size="medium">
                <TableBody>
                  {option.menuOverrides.map((menuOverride, menuOverrideIndex) => (
                    <TableRow key={menuOverride.menuUuid}>
                      <TableCell width={45} />
                      <TableCell component="th" scope="row">
                        {menuOverride.menu}
                      </TableCell>
                      {renderOptionMenuOverridePrices(option, menuOverride, menuOverrideIndex)}
                      <TableCell width={72} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* Table for Final Groups */}
              {option.finalGroup && (
                <Table className={classes.tableCollapse} size="medium">
                  <TableHead className={classes.finalGroupHead}>
                    <TableRow>
                      <TableCell width={45} />
                      <TableCell className="name" colSpan={3}>
                        <b>{option.finalGroup.name}</b>
                      </TableCell>
                      <TableCell className="close-button" width={50}>
                        <IconButton aria-label="expand row" onClick={() => handleRemoveFinalGroup(index)} size="medium">
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {option.finalGroup.options.map((finalGroupOption) => (
                      <TableRow key={finalGroupOption.uuid}>
                        <TableCell width={45} />
                        <TableCell component="th" scope="row">
                          {finalGroupOption.name || finalGroupOption.product?.name}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <b>$ {finalGroupOption.additionalPrice || finalGroupOption.product?.price}</b>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  });

  return <SortableItem index={index} {...props} />;
};

OptionsTableRow.propTypes = {
  conditionalGroup: PropTypes.object,
  handleRemoveOption: PropTypes.func,
  handleEditOptionPrice: PropTypes.func,
  handleEditOptionMenuOverridePrice: PropTypes.func,
  handleEditConditionalOptionPrice: PropTypes.func,
  handleEditConditionalOptionMenuOverridePrice: PropTypes.func,
  handleExpandOption: PropTypes.func,
  index: PropTypes.number,
  // Final Group
  handleRemoveFinalGroup: PropTypes.func,
  t: PropTypes.func,
};

const OptionsTable = ({
  conditionalGroup,
  selectedOptions,
  handleRemoveOption,
  onSortEnd,
  handleEditOptionPrice,
  handleEditOptionMenuOverridePrice,
  handleEditConditionalOptionPrice,
  handleEditConditionalOptionMenuOverridePrice,
  handleExpandOption,
  // Final Group
  handleRemoveFinalGroup,
  t,
}) => {
  const classes = useStyles();
  const priceHeaders = conditionalGroup
    ? conditionalGroup.options.map((option) => option?.name || option?.product?.name)
    : [`${t('menuMaker:modifierGroupForm.labels.price')}`];
  const getOptionKey = (option) => {
    return option.uuid || option.product?.uuid || option.name || option.product?.name;
  };
  const OptionsTableContent = SortableContainer(({ items: options }) => {
    return (
      <TableContainer>
        <Table aria-label="table" className={classes.table}>
          <TableBody>
            {options.map((option, index) => (
              <OptionsTableRow
                key={getOptionKey(option)}
                conditionalGroup={conditionalGroup}
                distance={1}
                handleEditConditionalOptionMenuOverridePrice={handleEditConditionalOptionMenuOverridePrice}
                handleEditConditionalOptionPrice={handleEditConditionalOptionPrice}
                handleEditOptionMenuOverridePrice={handleEditOptionMenuOverridePrice}
                handleEditOptionPrice={handleEditOptionPrice}
                handleExpandOption={handleExpandOption}
                handleRemoveFinalGroup={handleRemoveFinalGroup}
                handleRemoveOption={handleRemoveOption}
                index={index}
                t={t}
                value={option}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  });

  return (
    <Paper>
      {/* HEADER */}
      <TableContainer>
        <Table aria-label="table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={50} />
              <TableCell />
              {priceHeaders.map((value, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index} align="center" width={100}>
                  {value}
                </TableCell>
              ))}
              <TableCell width={72} />
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {/* CONTENT */}
      <OptionsTableContent items={selectedOptions} onSortEnd={onSortEnd} useDragHandle />
    </Paper>
  );
};

OptionsTable.propTypes = {
  conditionalGroup: PropTypes.object,
  selectedOptions: PropTypes.array,
  handleRemoveOption: PropTypes.func,
  onSortEnd: PropTypes.func,
  handleEditOptionPrice: PropTypes.func,
  handleEditOptionMenuOverridePrice: PropTypes.func,
  handleEditConditionalOptionPrice: PropTypes.func,
  handleEditConditionalOptionMenuOverridePrice: PropTypes.func,
  handleExpandOption: PropTypes.func,
  // Final Group
  handleRemoveFinalGroup: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'))(OptionsTable);
