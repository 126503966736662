import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import baasRequest from 'utils/baasRequest';
import {
  getMenuMakerMenusRequest,
  createMenuRequest,
  deleteMenuRequest,
  publishMenuRequest,
  duplicateMenuRequest,
  getCopyMenuConfigRequest,
  copyMenuBrandsRequest,
  publishMenusRequest,
} from 'utils/api/baas/menuMaker';

import {
  getMenus,
  getPaginationMenu,
  createMenu,
  publishMenu,
  duplicateMenu,
  deleteMenu,
  copyMenuConfig,
  copyMenuBrands,
  publishMenus,
} from './actions';

export function* handleGetMenuMakerMenusRequest({ payload = {} }) {
  try {
    yield put(getMenus.request());

    const { data, pagination } = yield call(baasRequest, getMenuMakerMenusRequest, payload);

    yield put(getMenus.success({ data, pagination }));
  } catch (err) {
    yield put(getMenus.failure(err));
  }
}

export function* getPaginationMenuSaga({ payload }) {
  try {
    yield put(getPaginationMenu.request());

    const { data, pagination } = yield call(baasRequest, getMenuMakerMenusRequest, payload);

    yield put(getPaginationMenu.success({ data, pagination, page: payload?.page }));
  } catch (err) {
    yield put(getPaginationMenu.failure(err));
  }
}

export function* handleCreateMenuRequest(action) {
  const { availability, name, sortingPosition, store, operationTimes, providers, brand, categories } = action.payload;

  try {
    yield put(createMenu.request());

    const { data } = yield call(baasRequest, createMenuRequest, {
      availability,
      name,
      sortingPosition,
      store,
      operationTimes,
      providers,
      brand,
      categories,
    });

    yield put(createMenu.success(data));
  } catch (err) {
    yield put(createMenu.failure(err));
  }
}

export function* handleDeleteMenuRequest(action) {
  const menuUuid = action.payload;

  try {
    yield put(deleteMenu.request());

    yield call(baasRequest, deleteMenuRequest, menuUuid);

    yield put(deleteMenu.success());
  } catch (err) {
    yield put(deleteMenu.failure(err));
  } finally {
    yield put(deleteMenu.fulfill(menuUuid));
  }
}

export function* handlePublishMenuRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(publishMenu.request());

    yield call(baasRequest, publishMenuRequest, { menuUuid, storeUuid });

    yield put(publishMenu.success(menuUuid));
  } catch (err) {
    yield put(publishMenu.failure(err));
  }
}

export function* handleDuplicateMenuRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(duplicateMenu.request());

    const { data } = yield call(baasRequest, duplicateMenuRequest, { menuUuid, storeUuid });

    yield put(duplicateMenu.success(data?.newUuid));
    yield put(
      push({
        pathname: `/menus/details/${data?.newUuid}/duplicate/edit`,
      }),
    );
  } catch (err) {
    yield put(duplicateMenu.failure(err));
  }
}

export function* handleCopyMenuConfigRequest(action) {
  const { menuUuid, storeUuid } = action.payload;

  try {
    yield put(copyMenuConfig.request());

    const { data } = yield call(baasRequest, getCopyMenuConfigRequest, { menuUuid, storeUuid });

    yield put(copyMenuConfig.success(data));
  } catch (err) {
    yield put(copyMenuConfig.failure(err));
  }
}

export function* handleCopyMenuBrandsRequest(action) {
  const { menuUuid, storeUuid, brands, copyMode } = action.payload;

  try {
    yield put(copyMenuBrands.request());

    const { data } = yield call(baasRequest, copyMenuBrandsRequest, { menuUuid, storeUuid, brands, copyMode });

    yield put(copyMenuBrands.success(data));
  } catch (err) {
    yield put(copyMenuBrands.failure(err));
  }
}

export function* handlePublishMenusRequest(action) {
  const { storeUuid, brandUuid, provider } = action.payload;

  try {
    yield put(publishMenus.request());

    yield call(baasRequest, publishMenusRequest, { storeUuid, brandUuid, provider });

    yield put(publishMenus.success());
  } catch (err) {
    yield put(publishMenus.failure(err));
  }
}

export default function* menusSaga() {
  yield takeLatest(getMenus.TRIGGER, handleGetMenuMakerMenusRequest);
  yield takeLatest(getPaginationMenu.TRIGGER, getPaginationMenuSaga);
  yield takeLatest(createMenu.TRIGGER, handleCreateMenuRequest);
  yield takeLatest(publishMenu.TRIGGER, handlePublishMenuRequest);
  yield takeLatest(duplicateMenu.TRIGGER, handleDuplicateMenuRequest);
  yield takeLatest(deleteMenu.TRIGGER, handleDeleteMenuRequest);
  yield takeLatest(copyMenuConfig.TRIGGER, handleCopyMenuConfigRequest);
  yield takeLatest(copyMenuBrands.TRIGGER, handleCopyMenuBrandsRequest);
  yield takeLatest(publishMenus.TRIGGER, handlePublishMenusRequest);
}
