const ACTION_TYPE_ADD = 'ADD';
const ACTION_TYPE_REMOVE = 'REMOVE';
const ACTION_TYPE_NOTHING = 'NOTHING';

export const actionsTypes = {
  ACTION_TYPE_ADD,
  ACTION_TYPE_REMOVE,
  ACTION_TYPE_NOTHING,
};

export function getEnabledKitchenAreas(kitchenAreas) {
  let kitchenAreasEnable = [];

  if (kitchenAreas?.length) {
    kitchenAreasEnable = kitchenAreas.filter((brand) => brand.isEnabled);
  }
  return kitchenAreasEnable;
}

export function getKitchenAreaDefault(kitchenAreas) {
  if (kitchenAreas?.length > 0) return kitchenAreas.find((kitchenA) => kitchenA?.isDefault);
  return null;
}

export function makeCategoriesBrandMap({ brands = [], kitchenAreasAssigned = [] }) {
  const brandsFilters = kitchenAreasAssigned.map((kitchen) => {
    const brandDefault = brands.find((br) => br?.uuid === kitchen?.brand);

    if (brandDefault) {
      kitchen = { ...brandDefault, kitchenAreas: kitchen.areas, canPrint: !kitchen.canPrint };
    } else {
      kitchen = {};
    }
    return kitchen;
  });

  return brandsFilters;
}

export function makeKitchenAreasMap({ brands = [], kitchenAreas = [], defaultKitchenAreas = [], type = 'new' } = {}) {
  if (brands?.length) {
    return brands.map((brand) => {
      brand.kitchenAreas = type === 'new' ? [getKitchenAreaDefault(kitchenAreas)?.uuid] : defaultKitchenAreas;
      return brand;
    });
  }

  return [];
}

export function makeOutputKitchenAreas(kitchenAreas) {
  if (kitchenAreas?.length) {
    return kitchenAreas.map((kitchenA) => {
      const brand = {
        brand: Object.keys(kitchenA)[0],
        areas: kitchenA[Object.keys(kitchenA)[0]],
      };

      return brand;
    });
  }

  return [];
}

export function makeOutputProductsKitchenAreas(kitchenAreasFields, kitchenAreas, canPrint) {
  if (kitchenAreasFields?.length) {
    return kitchenAreasFields.map((kitchenA) => {
      const allData = kitchenAreas.find((ka) => ka.uuid === kitchenA);
      allData.canPrint = canPrint;
      return allData;
    });
  }

  return [];
}

export function getDefaultKitchen(kitchenAreas) {
  return kitchenAreas.find((kitchen) => kitchen.isDefault);
}

export function getCategoriesChanges(currentCategories = [], newCategories = []) {
  const oCategory = {
    actionType: '',
    kitchenAreasAssignation: [],
  };
  const currentLength = currentCategories.length;
  const newLength = newCategories.length;
  if (currentLength === newLength) oCategory.actionType = ACTION_TYPE_NOTHING;
  else if (currentLength < newLength) oCategory.actionType = ACTION_TYPE_ADD;
  else if (currentLength > newLength) oCategory.actionType = ACTION_TYPE_REMOVE;

  let kitchenAsignation = [];
  if (oCategory.actionType === ACTION_TYPE_ADD) {
    const category = newCategories.filter((cat) => !currentCategories.includes(cat));
    category.forEach((cat) => {
      if (cat?.kitchenAreasAssignation.length > 0) {
        kitchenAsignation = [...kitchenAsignation, ...cat.kitchenAreasAssignation];
      }
    });
  } else if (oCategory.actionType === ACTION_TYPE_REMOVE) {
    const category = currentCategories.filter((cat) => !newCategories.includes(cat));
    category.forEach((cat) => {
      if (cat?.kitchenAreasAssignation.length > 0) {
        kitchenAsignation = [...kitchenAsignation, ...cat.kitchenAreasAssignation];
      }
    });
    oCategory.kitchenAreasAssignation = category.kitchenAreasAssignation || [];
  }
  kitchenAsignation.map((ka) => ka.uuid).filter((kitchen, i, list) => list.indexOf(kitchen) === i);
  oCategory.kitchenAreasAssignation = kitchenAsignation;
  return oCategory;
}

export function getMergeKitchenAreasAdd(kitchenAreasFields, kitchenAreasAssignation = []) {
  const kitchenAreasToChange = [...kitchenAreasFields];

  if (kitchenAreasAssignation?.length > 0) {
    kitchenAreasAssignation.forEach((kitchenArea) => {
      if (!kitchenAreasToChange.includes(kitchenArea.uuid)) {
        kitchenAreasToChange.push(kitchenArea.uuid);
      }
    });
  }
  return kitchenAreasToChange;
}

export function getMergeKitchenAreasRemove(kitchenAreasFields, currentCategories, kitchenAreasAssignation = []) {
  let kitchenAreasFromCategories = [];
  const kitchenAreasToChange = [...kitchenAreasFields];
  currentCategories.forEach((category) => {
    kitchenAreasFromCategories = [
      ...kitchenAreasFromCategories,
      ...category.kitchenAreasAssignation.map((kitchenArea) => kitchenArea.uuid),
    ];
  });

  kitchenAreasAssignation.forEach((ka) => {
    const alreadyExist = kitchenAreasFromCategories.filter((kaFromCat) => kaFromCat === ka.uuid);
    if (alreadyExist.length === 1) {
      const index = kitchenAreasToChange.findIndex((kaToChange) => kaToChange === ka.uuid);
      kitchenAreasToChange.splice(index, 1);
    }
  });
  return kitchenAreasToChange;
}

export function getNewKitchenAreasFromObject(kitchenAreasToConfirmFields = {}) {
  const keys = Object.keys(kitchenAreasToConfirmFields);
  return keys
    .filter((kaField) => kitchenAreasToConfirmFields[kaField] === true)
    .map((kaField) => kaField.replace('Field', ''));
}

export function getKitchenAreasDetail(selectedKitchenAreas = [], kitchenAreas) {
  if (selectedKitchenAreas.length === 0) return [];

  return selectedKitchenAreas.map((kaUuid) => kitchenAreas.find((ka) => ka.uuid === kaUuid)).filter((ka) => ka?.name);
}

export function getKitchenAreasFromCategories(categoriesList, categories = []) {
  if (categories.length === 0) return [];
  const kitchenAreasAssigned = [];
  const categoriesUuid = categories.map((cat) => cat?.category.uuid);
  categoriesUuid.forEach((cat) => {
    const foundCategory = categoriesList.find((category) => category.uuid === cat);
    if (foundCategory?.kitchenAreasAssignation.length > 0) {
      const onlyEnableKitchenAreas = foundCategory.kitchenAreasAssignation.filter((area) => area?.isEnabled);
      kitchenAreasAssigned.push(...kitchenAreasAssigned, ...onlyEnableKitchenAreas);
    }
  });

  return kitchenAreasAssigned.map((ka) => ka.uuid).filter((kitchen, i, list) => list.indexOf(kitchen) === i);
}
