import { datadogRum } from '@datadog/browser-rum';

import { version } from '../package.json';

export const DD_RUM = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENTE_TOKEN,
    site: 'datadoghq.com',
    service: 'parrot-connect-web',
    env: process.env.NODE_ENV,
    version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};
