import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_REPORTS_RESUME_ALL,
  GET_REPORTS_RESUME_SUMMARIES,
  GET_REPORTS_RESUME_SALES_CHANNELS,
  GET_REPORTS_RESUME_TOP_PRODUCTS,
  GET_REPORTS_RESUME_SALES_BRANDS,
  GET_REPORTS_RESUME_SALES_CATEGORIES,
  GET_REPORTS_RESUME_SALES_PERIOD,
} from './constants';

export const getReportsResumeALL = extendRoutine(createRoutine(GET_REPORTS_RESUME_ALL), 'RESET');
export const getReportsResumeSummaries = extendRoutine(createRoutine(GET_REPORTS_RESUME_SUMMARIES), 'RESET');
export const getReportsResumeSalesChannels = extendRoutine(createRoutine(GET_REPORTS_RESUME_SALES_CHANNELS), 'RESET');
export const getReportsResumeTopProducts = extendRoutine(createRoutine(GET_REPORTS_RESUME_TOP_PRODUCTS), 'RESET');
export const getReportsResumeSalesBrands = extendRoutine(createRoutine(GET_REPORTS_RESUME_SALES_BRANDS), 'RESET');
export const getReportsResumeSalesCategories = extendRoutine(
  createRoutine(GET_REPORTS_RESUME_SALES_CATEGORIES),
  'RESET',
);
export const getReportsResumeSalesPeriod = extendRoutine(createRoutine(GET_REPORTS_RESUME_SALES_PERIOD), 'RESET');
