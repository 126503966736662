import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getStorageAreas,
  getStorageAreasPagination,
  getStorageAreaDetails,
  createStorageArea,
  updateStorageArea,
  deleteStorageArea,
} from './actions';
import {
  selectStorageAreas,
  selectStorageAreasPaginationState,
  selectStorageAreasPaginationInfo,
  selectStorageAreasState,
  selectStorageArea,
  selectStorageAreaState,
  selectCreateStorageAreaState,
  selectUpdateStorageAreaState,
  selectDeleteStorageArea,
  selectStorageAreaSortingState,
} from './selectors';

export function withStorageAreasHoc(Component) {
  function withStorageAreas(props) {
    return <Component {...props} />;
  }

  withStorageAreas.displayName = `withStorageAreas(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withStorageAreas;
}

const mapStateToProps = createStructuredSelector({
  storageAreas: selectStorageAreas,
  storageAreasState: selectStorageAreasState,
  storageAreasPaginationState: selectStorageAreasPaginationState,
  storageAreasPaginationInfo: selectStorageAreasPaginationInfo,
  storageArea: selectStorageArea,
  storageAreaState: selectStorageAreaState,
  createStorageAreaState: selectCreateStorageAreaState,
  updateStorageAreaState: selectUpdateStorageAreaState,
  deleteStorageAreaState: selectDeleteStorageArea,
  sortingState: selectStorageAreaSortingState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadStorageAreas: (filters = {}) => dispatch(getStorageAreas(filters)),
    loadStorageAreasPagination: (filters = {}) => dispatch(getStorageAreasPagination(filters)),
    resetStorageAreas: () => dispatch(getStorageAreas.reset()),
    loadStorageAreaDetails: (storageAreaUuid) => dispatch(getStorageAreaDetails(storageAreaUuid)),
    resetGetStorageAreaDetails: () => dispatch(getStorageAreaDetails.reset()),
    createStorageArea: (fields = {}) => dispatch(createStorageArea(fields)),
    resetCreateStorageArea: () => dispatch(createStorageArea.reset()),
    updateStorageArea: (fields = {}) => dispatch(updateStorageArea(fields)),
    resetUpdateStorageArea: () => dispatch(updateStorageArea.reset()),
    deleteStorageArea: (storageAreaUuid) => dispatch(deleteStorageArea(storageAreaUuid)),
    resetDeleteStorageArea: () => dispatch(deleteStorageArea.reset()),
    sortingStorageAreas: (sorting = {}) => dispatch(getStorageAreas.sorting(sorting)),
    resetSorting: () => dispatch(getStorageAreas.resetSorting()),
  };
}

export const withStorageAreasState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withStorageAreasState, withStorageAreasHoc);
