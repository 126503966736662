import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.typography.pxToRem(210),
  },

  titleLabel: {
    marginTop: '0 !important',
  },

  fileError: {
    color: '#DF130E',
  },

  wrapperPhoto: {
    position: 'relative',
  },

  photoContainer: {
    cursor: 'pointer',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: theme.typography.pxToRem(4),
    },
  },

  removeImage: {
    position: 'absolute',
    bottom: '100%',
    left: '100%',
  },

  placeholderIcon: {
    width: '100%',
    height: '100%',
  },

  changeButton: {
    width: '100%',
  },
}));

export default useStyles;
