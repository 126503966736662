import React from 'react';
import Popover from '@material-ui/core/Popover';

import PropTypes from 'prop-types';

import useStyles from './styles';

const InfoToolTip = ({ children, message }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopover, setOpenPopover] = React.useState(false);
  const handleOnMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };
  const handleOnMouseLeave = () => {
    setOpenPopover(false);
  };
  return (
    <>
      <div className={classes.container} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        {children}
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openPopover}
        style={{ pointerEvents: 'none' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.message}>{message}</div>
      </Popover>
    </>
  );
};

InfoToolTip.propTypes = {
  children: PropTypes.object,
  message: PropTypes.object,
};

export default InfoToolTip;
