/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider hoc
 *
 */
import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getSupplies,
  getSuppliesPagination,
  getSupplyDetails,
  createSupply,
  updateSupply,
  deleteSupply,
  assignStorageAreas,
} from './actions';

import {
  selectInventoriesSuppliesList,
  selectInventoriesSuppliesState,
  selectSuppliesPaginationState,
  selectSuppliesPaginationInfo,
  selectSupply,
  selectSupplyState,
  selectCreateSupplyState,
  selectUpdateSupplyState,
  selectDeleteSupply,
  selectAssignStorageAreasState,
} from './selectors';

export function withInventoriesSuppliesHoc(Component) {
  function withInventoriesSupplies(props) {
    return <Component {...props} />;
  }

  withInventoriesSupplies.displayName = `withInventoriesSupplies(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInventoriesSupplies;
}

const mapStateToProps = createStructuredSelector({
  inventoriesSuppliesList: selectInventoriesSuppliesList,
  inventoriesSuppliesState: selectInventoriesSuppliesState,
  inventoriesSuppliesPaginationState: selectSuppliesPaginationState,
  inventoriesSuppliesPaginationInfo: selectSuppliesPaginationInfo,
  inventorySupply: selectSupply,
  inventorySupplyState: selectSupplyState,
  createInventorySupplyState: selectCreateSupplyState,
  updateInventorySupplyState: selectUpdateSupplyState,
  deleteInventorySupplyState: selectDeleteSupply,
  assignStorageAreasState: selectAssignStorageAreasState,
});

function mapDispatchToProps(dispatch) {
  return {
    loadInventoriesSupplies: (filters = {}) => dispatch(getSupplies(filters)),
    loadInventoriesSuppliesPagination: (filters = {}) => dispatch(getSuppliesPagination(filters)),
    resetInventoriesSupplies: () => dispatch(getSupplies.reset()),
    loadInventorySupplyDetails: (supplyUuid) => dispatch(getSupplyDetails(supplyUuid)),
    resetInventorySupplyDetails: () => dispatch(getSupplyDetails.reset()),
    createInventorySupply: (fields = {}) => dispatch(createSupply(fields)),
    resetCreateInventorySupply: () => dispatch(createSupply.reset()),
    updateInventorySupply: (inventoryUuid, fields = {}) => dispatch(updateSupply({ inventoryUuid, fields })),
    resetUpdateInventorySupply: () => dispatch(updateSupply.reset()),
    deleteInventorySupply: (supplyUuid) => dispatch(deleteSupply(supplyUuid)),
    resetDeleteInventorySupply: () => dispatch(deleteSupply.reset()),
    assignStorageAreasToSupplies: (items, storageAreas) => dispatch(assignStorageAreas({ items, storageAreas })),
    resetAssignStorageAreasToSupplies: () => dispatch(assignStorageAreas.reset()),
  };
}

export const withInventoriesSuppliesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInventoriesSuppliesState, withInventoriesSuppliesHoc);
