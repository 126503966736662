import PropTypes from 'prop-types';

import ButtonAlert from '@design-system/ButtonAlert';
import ButtonOutlined from '@design-system/ButtonOutlined';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ButtonPrimary from '@design-system/ButtonPrimary';

const useStyles = makeStyles((theme) => ({
  paperWidthXl: {
    maxWidth: theme.typography.pxToRem(802),
  },
  buttonsSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(10),
    '& button': {
      width: '100%',
      maxWidth: theme.typography.pxToRem(240),
    },
    '& > :first-child': {
      marginRight: theme.spacing(2),
    },
    '& > :last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(10),
    marginTop: 0,
    textAlign: 'center',
  },
  message: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    paddingBottom: theme.spacing(2),
    color: theme.palette.darkGrey.main,
  },
}));

function ConfirmDialog({
  children,
  disabled = false,
  id,
  labelAccept,
  labelCancel,
  loading,
  message,
  onAccept,
  onCancel,
  open,
  title,
  type = '',
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paperWidthXl: classes.paperWidthXl }}
      fullWidth
      id={id}
      maxWidth="xl"
      onClose={onCancel}
      open={open}
    >
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.title} component="h6">
          {title}
        </Box>
        <Box>
          <Box align="center" className={classes.message} component="p">
            {message}
          </Box>
          {children && <Box>{children}</Box>}
          <Box className={classes.buttonsSection}>
            <ButtonOutlined onClick={onCancel}>{labelCancel}</ButtonOutlined>
            {type === 'warning' ? (
              <ButtonAlert disabled={disabled} loading={loading} onClick={onAccept}>
                {labelAccept}
              </ButtonAlert>
            ) : (
              <ButtonPrimary disabled={disabled} loading={loading} onClick={onAccept}>
                {labelAccept}
              </ButtonPrimary>
            )}
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  labelCancel: PropTypes.string,
  onAccept: PropTypes.func,
  labelAccept: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default ConfirmDialog;
