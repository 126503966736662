import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import Layout from 'Layout';

import { withKitchenAreas } from 'providers/settings/KitchenAreas';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withCategories } from 'providers/menuMaker/Categories';
import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';
import { withProducts } from 'providers/menuMaker/Products';
import { withMultiTaxes } from 'providers/SettingsMultiTaxesProvider';

import useNotification from '@design-system/Notification/useNotification';
import PageError from 'components/PageError';
import ProductFormSkeleton from 'components/ProductFormSkeleton';

import useUserStore from 'hooks/useUserStore';
import { getEnabledKitchenAreas } from 'utils/kitchenAreas/makeKitchenAreasFields';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { actions } from 'utils/menuMaker';

import productSaga from './sagas/index';
import reducer from './reducer';
import WrapperProductForm from './WrapperProductForm';

import { getProduct, editProduct, updateProductPrice as actionUpdateProductPrice } from './actions';

import {
  selectProduct,
  selectProductFetching,
  selectProductFetched,
  selectProductError,
  selectProductEditFetching,
  selectProductEditFetched,
  selectProductEditError,
  selectUpdateProductPriceFetching,
  selectUpdateProductPriceError,
} from './selectors';

export function Product({
  match,
  loadProduct,
  productDetailsObject,
  productDetailsFetching,
  productDetailsFetched,
  productDetailsError,
  resetProductDetails,
  loadCategories,
  categoriesList,
  categoriesFetched,
  loadModifierGroups,
  modifierGroupsList,
  modifierGroupsFetched,
  handleEditProduct,
  productEditFetching,
  productEditFetched,
  productEditError,
  resetEditProduct,
  createProduct,
  createProductFetching,
  createProductFetched,
  createProductError,
  resetCreateProduct,
  loadKitchenAreas,
  kitchenAreas,
  kitchenAreasState,
  categoriesFetching,
  modifierGroupsFetching,
  updateProductPrice,
  updateProductPriceFetching,
  updateProductPriceError,
  resetUpdateProductPrice,
  loadMultiTaxes,
  multiTaxesWithNoOption,
  multiTaxesState,
  t,
}) {
  useInjectSaga({ key: 'product', saga: productSaga });
  useInjectReducer({ key: 'product', reducer });

  const history = useHistory();
  const { storeId } = useUserStore();
  const { productUuid, actionType } = match.params;
  const { setNotification } = useNotification();

  const isDuplicateAction = actionType === 'duplicate';
  const productForForm = { ...productDetailsObject };
  if (isDuplicateAction) {
    productForForm.uuid = null;
    productForForm.name = `${productForForm.name} - COPIA`;
  }

  useEffect(() => {
    loadCategories({ storeUuid: storeId, allCategories: true });
    loadModifierGroups({ storeUuid: storeId, allModifiers: true });
    loadProduct(storeId, productUuid);
    loadKitchenAreas();
    loadMultiTaxes();
  }, [loadCategories, loadKitchenAreas, loadModifierGroups, loadMultiTaxes, loadProduct, productUuid, storeId]);

  useEffect(() => {
    if (createProductFetched) {
      history.push(`/menus/products`);
    }
  }, [createProductFetched, history]);

  useEffect(() => {
    if (productEditFetched) {
      history.push(`/menus/products`);
    }
  }, [history, productEditFetched]);

  useEffect(() => {
    if (createProductFetched) {
      setNotification(t('menuMaker:productForm.messages.saveSuccess'), { variant: 'success' });
    }

    if (createProductError) {
      setNotification(t('menuMaker:productForm.messages.saveError'), { variant: 'error' });
    }

    if (productEditError) {
      setNotification(t('menuMaker:productForm.messages.editError'), { variant: 'error' });
    }

    if (updateProductPriceError) {
      setNotification(t('menuMaker:productForm.messages.editError'), { variant: 'error' });
    }
  }, [createProductFetched, createProductError, productEditError, setNotification, t, updateProductPriceError]);

  useEffect(() => {
    if (productEditFetched) {
      setNotification(t('menuMaker:productForm.messages.editSuccess'), { variant: 'success' });
    }
  }, [productEditFetched, setNotification, t]);

  useEffect(() => {
    return () => {
      resetProductDetails();
      resetEditProduct();
      resetCreateProduct();
      resetUpdateProductPrice();
    };
  }, [resetProductDetails, resetEditProduct, resetCreateProduct, resetUpdateProductPrice]);

  return (
    <Layout>
      {productDetailsError && (
        <PageError
          id="productError"
          labelAction={t('common:buttons.retry')}
          message={t('menuMaker:errors.loadedError')}
          onAction={loadProduct(storeId, productUuid)}
        />
      )}

      <Route exact path={`/menus/products/details/${productUuid}/edit`}>
        {(kitchenAreasState.fetching ||
          multiTaxesState.fetching ||
          categoriesFetching ||
          modifierGroupsFetching ||
          productDetailsFetching) && <ProductFormSkeleton pathGoBack="/menus/products" />}

        {kitchenAreasState.fetched &&
          multiTaxesState.fetched &&
          modifierGroupsFetched &&
          categoriesFetched &&
          productDetailsFetched && (
            <WrapperProductForm
              action={actions.EDIT}
              availableCategories={categoriesList}
              error={productEditError}
              kitchenAreas={getEnabledKitchenAreas(kitchenAreas)}
              loading={productEditFetching}
              modifierGroupsList={modifierGroupsList}
              multiTaxes={multiTaxesWithNoOption}
              multiTaxesState={multiTaxesState}
              onUpdateProductPrice={updateProductPrice}
              product={productForForm}
              saveProduct={handleEditProduct}
              updateProductPriceError={updateProductPriceError}
              updateProductPriceFetching={updateProductPriceFetching}
            />
          )}
      </Route>

      <Route exact path={`/menus/products/details/${productUuid}/duplicate`}>
        {(kitchenAreasState.fetching ||
          multiTaxesState.fetching ||
          categoriesFetching ||
          modifierGroupsFetching ||
          productDetailsFetching) && <ProductFormSkeleton pathGoBack="/menus/products" />}

        {kitchenAreasState.fetched &&
          multiTaxesState.fetched &&
          modifierGroupsFetched &&
          categoriesFetched &&
          productDetailsFetched && (
            <WrapperProductForm
              action={actions.DUPLICATE}
              availableCategories={categoriesList}
              error={createProductError}
              kitchenAreas={getEnabledKitchenAreas(kitchenAreas)}
              loading={createProductFetching}
              modifierGroupsList={modifierGroupsList}
              multiTaxes={multiTaxesWithNoOption}
              multiTaxesState={multiTaxesState}
              product={productForForm}
              saveProduct={createProduct}
            />
          )}
      </Route>
    </Layout>
  );
}

Product.propTypes = {
  match: PropTypes.object,
  loadProduct: PropTypes.func,
  productDetailsObject: PropTypes.object,
  productDetailsFetching: PropTypes.bool,
  productDetailsFetched: PropTypes.bool,
  productDetailsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetProductDetails: PropTypes.func,
  loadCategories: PropTypes.func,
  categoriesList: PropTypes.array,
  categoriesFetched: PropTypes.bool,
  loadModifierGroups: PropTypes.func,
  modifierGroupsList: PropTypes.array,
  modifierGroupsFetched: PropTypes.bool,
  handleEditProduct: PropTypes.func,
  productEditFetching: PropTypes.bool,
  productEditFetched: PropTypes.bool,
  productEditError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetEditProduct: PropTypes.func,
  createProduct: PropTypes.func,
  createProductFetching: PropTypes.bool,
  createProductFetched: PropTypes.bool,
  createProductError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetCreateProduct: PropTypes.func,
  loadKitchenAreas: PropTypes.func,
  kitchenAreas: PropTypes.array,
  kitchenAreasState: PropTypes.object,
  categoriesFetching: PropTypes.bool,
  modifierGroupsFetching: PropTypes.bool,
  updateProductPrice: PropTypes.func,
  updateProductPriceFetching: PropTypes.bool,
  updateProductPriceError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  resetUpdateProductPrice: PropTypes.func,
  loadMultiTaxes: PropTypes.func,
  multiTaxesWithNoOption: PropTypes.array,
  multiTaxesState: PropTypes.object,
  t: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  productDetailsObject: selectProduct,
  productDetailsFetching: selectProductFetching,
  productDetailsFetched: selectProductFetched,
  productDetailsError: selectProductError,
  productEditFetching: selectProductEditFetching,
  productEditFetched: selectProductEditFetched,
  productEditError: selectProductEditError,
  updateProductPriceFetching: selectUpdateProductPriceFetching,
  updateProductPriceError: selectUpdateProductPriceError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadProduct: (storeUuid, productUuid) => dispatch(getProduct({ storeUuid, productUuid })),
    handleEditProduct: ({ storeUuid, product }) => dispatch(editProduct({ storeUuid, product })),
    resetProductDetails: () => dispatch(getProduct.reset()),
    resetEditProduct: () => dispatch(editProduct.reset()),
    updateProductPrice: (values) => dispatch(actionUpdateProductPrice(values)),
    resetUpdateProductPrice: () => dispatch(actionUpdateProductPrice.reset()),
  };
}

export const productState = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  memo,
  withRequiredLicense(),
  productState,
  withMultiTaxes,
  withCategories,
  withModifierGroups,
  withKitchenAreas,
  withProducts,
  withTranslation('menuMaker'),
)(Product);
