import React, { Suspense } from 'react';

import * as Sentry from '@sentry/react';

import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import FontFaceObserver from 'fontfaceobserver';

import { RecoilRoot } from 'recoil';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/900-italic.css';
import '@fontsource/courier-prime';
import 'keen-slider/keen-slider.min.css';

import '@experimental-components/MaterialPickersCalendar/calendar.css';
import '@experimental-components/MaterialPickersDaterange/dateRangePicker.css';
import '@experimental-components/MaterialPickersDaterangeV2/dateRangePickerV2.css';

import AmplitudeProvider from 'providers/root/AmplitudeProvider';
import NotificationProvider from '@design-system/providers/NotificationProvider';
import LocaleProvider from 'providers/root/LocaleProvider';
import SignInProvider from 'providers/root/SignInProvider';
import AuthProvider from 'providers/root/AuthProvider';
import ConektaProvider from 'providers/root/ConektaProvider';
import ParrotPayConektaProvider from 'providers/root/ParrotPayConektaProvider';

import history from 'utils/history';
import theme from 'utils/theme';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { version } from '../package.json';

import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import configureStore from './configureStore';
import { DD_RUM } from './ddRUM';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Observe loading of Roboto (to remove 'Roboto', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Roboto', {});

// When Roboto is loaded, add a font-family using Roboto to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');
DD_RUM();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RecoilRoot>
        <LocaleProvider i18n={i18n}>
          <Suspense
            fallback={
              <Backdrop open>
                <CircularProgress color="secondary" />
              </Backdrop>
            }
          >
            <ConnectedRouter history={history}>
              <HelmetProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <AmplitudeProvider>
                    <NotificationProvider>
                      <AuthProvider>
                        <SignInProvider>
                          <ConektaProvider>
                            <ParrotPayConektaProvider>
                              <App />
                            </ParrotPayConektaProvider>
                          </ConektaProvider>
                        </SignInProvider>
                      </AuthProvider>
                    </NotificationProvider>
                  </AmplitudeProvider>
                </ThemeProvider>
              </HelmetProvider>
            </ConnectedRouter>
          </Suspense>
        </LocaleProvider>
      </RecoilRoot>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  MOUNT_NODE,
);

if (module.hot) {
  module.hot.accept(['./i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const environment = process.env.NODE_ENV;

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    release: `parrot-frontend@${version}`,
    environment,
    tracesSampleRate: 0,
  });
}

reportWebVitals();

if (process.env.REACT_APP_ENABLE_MOCK_SERVER === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}
