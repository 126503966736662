import uniqBy from 'lodash/uniqBy';

export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const STATUS_AVAILABLE = 'AVAILABLE';
const STATUS_UNAVAILABLE = 'UNAVAILABLE';

export const statusType = {
  STATUS_AVAILABLE,
  STATUS_UNAVAILABLE,
};

export function getFilteredProductsByText(products, sorting) {
  const productSearchValue = {};
  products?.forEach((product) => {
    const searchValues = new Set();
    // Add product name
    searchValues.add(product.name.toLowerCase());

    // add modifier names
    product.modifierGroups.forEach((modifierGroupContainer) => {
      searchValues.add(modifierGroupContainer.modifierGroup.name.toLowerCase());
    });

    productSearchValue[product.uuid] = searchValues;
  });

  const productsList = [];
  products?.forEach((product) => {
    const finalSearchValue = sorting?.filterByText.toLowerCase();
    const productSearchTermSet = productSearchValue[product.uuid];

    if (!finalSearchValue) {
      productsList.push(product);
      return;
    }
    const searchTerms = Array.from(productSearchTermSet);
    for (let i = 0; i < productSearchTermSet.size; i += 1) {
      const term = searchTerms[i];
      if (term.includes(finalSearchValue)) {
        productsList.push(product);
        return;
      }
    }
  });
  return productsList;
}

export function getFilteredProductsByCategories(products, categoriesByUuid) {
  const productList = [];
  products?.forEach((product) => {
    product?.categories?.forEach((categoryContainer) => {
      if (categoriesByUuid?.includes(categoryContainer?.category?.uuid)) {
        productList.push(product);
      }
    });
  });
  return uniqBy(productList, 'uuid');
}

export const pieceUnits = 'PIECES';
export const gramUnits = 'GRAMS';
export const kilogramUnits = 'KILOGRAM';
export const litreUnits = 'LITRE';
export const millilitreUnits = 'MILLILITRE';
export const units = [pieceUnits, gramUnits, kilogramUnits, litreUnits, millilitreUnits];

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME];

export function getFilteredMenuProductsByText(products, sorting) {
  return products.filter(
    (product) =>
      FILTER_PROPERTIES.filter((property) =>
        product[property].toLowerCase().includes(sorting.filterByText.toLowerCase()),
      ).length,
  );
}

export const filtersTableProductsTypes = {
  all: 'all',
  articles: 'articles',
  soldAlone: 'soldAlone',
};

export const optionsFilter = [
  { label: 'Ver todo', value: filtersTableProductsTypes.all },
  { label: 'Artículos', value: filtersTableProductsTypes.articles },
  { label: 'Personalizables', value: filtersTableProductsTypes.soldAlone },
];
