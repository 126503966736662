import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import InfoToolTip from '../InfoToolTip';

import useStyles from './styles';

function LabelWithTooltip({ className, label, tooltipText, description }) {
  const classes = useStyles();

  function renderInfoTooltip() {
    if (tooltipText) {
      return (
        <InfoToolTip message={<>{tooltipText}</>}>
          <HelpOutlineOutlinedIcon />
        </InfoToolTip>
      );
    }

    return null;
  }

  function renderDescription() {
    if (description) {
      return <p>{description}</p>;
    }
  }

  return (
    <div className={className}>
      <Typography className={classes.labelContainer} gutterBottom variant="h6">
        {label}
        {renderInfoTooltip()}
      </Typography>
      {renderDescription()}
    </div>
  );
}

LabelWithTooltip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  description: PropTypes.string,
};

export default LabelWithTooltip;
