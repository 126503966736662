/* eslint-disable no-plusplus */
import cookies from 'js-cookie';
import camelCase from 'lodash/camelCase';
import { eventChannel } from 'redux-saga';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

export const LOGIN_URI = `${window.location.origin}/login`;
export const LOGOUT_URI = `${window.location.origin}/logout`;
export const USER_URI = `${window.location.origin}/user`;

const COOKIE_PARROT_AUTH = 'parr-tok';
const COOKIE_PARROT_USER_STORE = 'parr-store';
const USER_STORES_STORAGE_KEY = 'parr-stores';
const USER_CURRENT_STORE_STORAGE_KEY = 'parr-current-store';
const COOKIE_PARROT_USER = 'parr-user';
const EXPIRE_COOKIE = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

export function setAuthToken(authInfo) {
  cookies.set(COOKIE_PARROT_AUTH, authInfo, {
    expires: EXPIRE_COOKIE,
  });
}

export const SECONDS_TO_CHANEL_CALL = 60000;

/**
 * return { accessToken, refreshToken }
 */
export function getAuthToken() {
  const cookie = cookies.get(COOKIE_PARROT_AUTH);

  if (!cookie) {
    return;
  }

  return JSON.parse(cookie);
}

export function removeAuthToken() {
  cookies.remove(COOKIE_PARROT_AUTH);
}

/**
 *
 * @param {*} store
 *
 * return store selected
 */
export function setUserCurrentStore(store) {
  localStorage.setItem(USER_CURRENT_STORE_STORAGE_KEY, JSON.stringify(store));
}

export function getUserCurrentStore() {
  const store = localStorage.getItem(USER_CURRENT_STORE_STORAGE_KEY);

  if (!store) {
    return;
  }

  return JSON.parse(store);
}

export function removeUserCurrentStore() {
  localStorage.removeItem(USER_CURRENT_STORE_STORAGE_KEY);
}
/**
 * end
 */

export function setUserStoresStoreKey(stores) {
  localStorage.setItem(USER_STORES_STORAGE_KEY, JSON.stringify(stores));
}

export function getUserStoresStoreKey() {
  const stores = localStorage.getItem(USER_STORES_STORAGE_KEY);

  if (!stores) {
    return;
  }

  return JSON.parse(stores);
}

export function removeUserStoresStoreKey() {
  localStorage.removeItem(USER_STORES_STORAGE_KEY);
}

// deprecate function use setUserStoresStoreKey
export function setCookieUserStore(stores) {
  cookies.set(COOKIE_PARROT_USER_STORE, stores);
}

// deprecate function use getUserStoresStoreKey
export function getCookieUserStore() {
  const cookie = cookies.get(COOKIE_PARROT_USER_STORE);

  if (!cookie) {
    return;
  }

  return JSON.parse(cookie);
}

export function removeCookieUserStore() {
  cookies.remove(COOKIE_PARROT_USER_STORE);
}

export function setCookieUser(user) {
  cookies.set(COOKIE_PARROT_USER, user, {
    expires: EXPIRE_COOKIE,
  });
}

export function getCookieUser() {
  const cookie = cookies.get(COOKIE_PARROT_USER);

  if (!cookie) {
    return;
  }

  return JSON.parse(cookie);
}

export function removeCookieUser() {
  cookies.remove(COOKIE_PARROT_USER);
}

const getValuesCamelCase = (values) =>
  values.map((element) => {
    return camelCase(element);
  });

export const getListPermissions = (permissions) => {
  return getValuesCamelCase(permissions);
};

export function channelStateLicenseInterval() {
  let seconds = 0;

  return eventChannel((emiter) => {
    const interval = setInterval(() => {
      seconds++;

      emiter(seconds);
    }, SECONDS_TO_CHANEL_CALL);
    return () => {
      clearInterval(interval);
    };
  });
}

export function isRedirectToSubscription(status, pathname) {
  return (
    (status === subscriptionStatusTypes.UNAVAILABLE || status === subscriptionStatusTypes.PAYMENT_REQUIRED) &&
    pathname !== '/subscription' &&
    pathname !== '/subscription/payment-history' &&
    pathname !== '/subscription/invoice-data'
  );
}

export function getActivesModules(modules) {
  const modulesList = [];

  Object.keys(modules).forEach((key) => {
    if (modules[key] === true) {
      modulesList.push(`@module.${key}`);
    }
  });

  return modulesList;
}
