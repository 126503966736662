/* eslint-disable array-callback-return */
/* eslint-disable radix */
// import { regexTypes } from 'utils/formatField';
import sortBy from 'lodash/sortBy';

export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const MONDAY = 'MONDAY';
const TUESDAY = 'TUESDAY';
const WEDNESDAY = 'WEDNESDAY';
const THURSDAY = 'THURSDAY';
const FRIDAY = 'FRIDAY';
const SATURDAY = 'SATURDAY';
const SUNDAY = 'SUNDAY';

export const daysTypes = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
};

const WEEKDAYS = 'WEEKDAYS';
const WEEKEND = 'WEEKEND';

export const weekdaysSelect = {
  [WEEKDAYS]: [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY],
  [WEEKEND]: [SATURDAY, SUNDAY],
};

const daysOrderQuery = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export function daysOrdered(daysList) {
  return daysOrderQuery.reduce((result, dayType) => [...result, ...daysList.filter((day) => day === dayType)], []);
}

const PARROT = 'PARROT';
const UBER_EATS = 'UBER_EATS';
const RAPPI = 'RAPPI';
const DIDI_FOOD = 'DIDI_FOOD';
const ONLINE = 'ONLINE';

export const providersTypes = {
  PARROT,
  DIDI_FOOD,
  UBER_EATS,
  RAPPI,
  ONLINE,
};

export const mappingValidateWeekdaysFields = [
  'mondayField',
  'wednesdayField',
  'fridayFiled',
  'tuesdayField',
  'thursdayFiled',
  'saturdayField',
  'sundayField',
];

const mappingValuesDayKey = {
  mondayField: MONDAY,
  wednesdayField: WEDNESDAY,
  fridayFiled: FRIDAY,
  tuesdayField: TUESDAY,
  thursdayFiled: THURSDAY,
  saturdayField: SATURDAY,
  sundayField: SUNDAY,
};

export function validateWeekdaysDirtyFields(dirtyFields, position) {
  let currentFields = {};

  if (dirtyFields?.timeFields?.length) {
    currentFields = dirtyFields?.timeFields[position] || {};
  }

  return mappingValidateWeekdaysFields.some((field) => currentFields[field] === mappingValuesDayKey[field]);
}

export function validateWeekdaysSelectTime(dirtyFields, position) {
  let currentFields = {};

  if (dirtyFields?.timeFields?.length) {
    currentFields = dirtyFields?.timeFields[position] || {};
  }

  return ['endDateField', 'startDateField'].every((field) => Boolean(currentFields[field]));
}

export function validateChangesWeekdays(currentMapping = [], position) {
  let isValid = false;
  let currentFields = [];

  if (currentMapping.length) {
    currentFields = currentMapping[position];
  }

  if (
    currentFields?.mondayField &&
    currentFields?.wednesdayField &&
    currentFields?.fridayFiled &&
    currentFields?.tuesdayField &&
    currentFields?.thursdayFiled
  ) {
    isValid = true;
  }

  return isValid;
}

export const mappingValidateWeekendFields = ['saturdayField', 'sundayField'];

export function validateChangesWeekend(currentMapping) {
  let isValid = false;

  if (currentMapping.saturdayField && currentMapping.sundayField) {
    isValid = true;
  }

  return isValid;
}

const POS_FILED = 'posFiled';
const UBER_EATS_FILED = 'uberEatsFiled';
const DIDI_FOOD_FILED = 'didiFoodFiled';
const RAPPI_FILED = 'rappiFiled';
const ONLINE_FIELD = 'onlineField';

export const providersFields = {
  [POS_FILED]: PARROT,
  [DIDI_FOOD_FILED]: DIDI_FOOD,
  [UBER_EATS_FILED]: UBER_EATS,
  [RAPPI_FILED]: RAPPI,
  [ONLINE_FIELD]: ONLINE,
};

const MONDAY_FIELD = 'mondayField';
const WEDNESDAY_FIELD = 'wednesdayField';
const FRIDAY_FILED = 'fridayFiled';
const TUESDAY_FIELD = 'tuesdayField';
const THURSDAY_FILED = 'thursdayFiled';
const SATURDAY_FIELD = 'saturdayField';
const SUNDAY_FIELD = 'sundayField';

const timeFields = {
  [daysTypes.MONDAY]: MONDAY_FIELD,
  [daysTypes.WEDNESDAY]: WEDNESDAY_FIELD,
  [daysTypes.FRIDAY]: FRIDAY_FILED,
  [daysTypes.TUESDAY]: TUESDAY_FIELD,
  [daysTypes.THURSDAY]: THURSDAY_FILED,
  [daysTypes.SATURDAY]: SATURDAY_FIELD,
  [daysTypes.SUNDAY]: SUNDAY_FIELD,
};

const nameMenuField = {
  name: 'nameMenuField',
  id: 'nameMenuField',
  label: 'menuMaker:createMenu.fields.nameFiled',
  required: true,
  maxLength: 50,
  minLength: 1,
  type: 'text',
  register: {
    required: 'menuMaker:createMenu.fieldErrors.nameField',
    maxLength: {
      value: 50,
      message: 'common:errors.maxLengthField',
    },
    minLength: {
      value: 1,
      message: 'common:errors.minLengthField',
    },
    // pattern: {
    //   value: regexTypes.REGEX_TEXT_NO_NUMBERS,
    //   message: 'common:messages.textFieldInvalid',
    // },
  },
};

const startDateField = {
  name: 'startDateField',
  id: 'startDateField',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};

const endDateField = {
  name: 'endDateField',
  id: 'endDateField',
  label: 'menuMaker:createMenu.fields.endDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};

const weekdaysFiled = {
  name: 'weekdaysFiled',
  id: 'weekdaysFiled',
  label: 'menuMaker:createMenu.fields.weekdaysFiled',
  required: false,
  register: {},
};

const mondayField = {
  name: 'mondayField',
  id: 'mondayField',
  label: 'menuMaker:createMenu.fields.mondayField',
  required: false,
  value: MONDAY,
  register: {},
};

const tuesdayField = {
  name: 'tuesdayField',
  id: 'tuesdayField',
  label: 'menuMaker:createMenu.fields.tuesdayField',
  value: TUESDAY,
  register: {},
};

const wednesdayField = {
  name: 'wednesdayField',
  id: 'wednesdayField',
  label: 'menuMaker:createMenu.fields.wednesdayField',
  value: WEDNESDAY,
  register: {},
};

const thursdayFiled = {
  name: 'thursdayFiled',
  id: 'thursdayFiled',
  label: 'menuMaker:createMenu.fields.thursdayFiled',
  value: THURSDAY,
  register: {},
};

const fridayFiled = {
  name: 'fridayFiled',
  id: 'fridayFiled',
  label: 'menuMaker:createMenu.fields.fridayFiled',
  value: FRIDAY,
  register: {},
};

const weekendFiled = {
  name: 'weekendFiled',
  id: 'weekendFiled',
  label: 'menuMaker:createMenu.fields.weekendFiled',
  register: {},
};

const saturdayField = {
  name: 'saturdayField',
  id: 'saturdayField',
  label: 'menuMaker:createMenu.fields.saturdayField',
  value: SATURDAY,
  register: {},
};

const sundayField = {
  name: 'sundayField',
  id: 'sundayField',
  label: 'menuMaker:createMenu.fields.sundayField',
  value: SUNDAY,
  register: {},
};

const posField = {
  name: POS_FILED,
  id: POS_FILED,
  label: 'menuMaker:createMenu.fields.posField',
  value: PARROT,
  register: {},
};

const onlineField = {
  name: ONLINE_FIELD,
  id: ONLINE_FIELD,
  label: 'menuMaker:createMenu.fields.onlineOrderingField',
  value: ONLINE,
  register: {},
};

const uberEatsFiled = {
  name: UBER_EATS_FILED,
  id: UBER_EATS_FILED,
  label: 'menuMaker:createMenu.fields.uberEatsFiled',
  value: UBER_EATS,
  register: {},
};

const didiFoodFiled = {
  name: DIDI_FOOD_FILED,
  id: DIDI_FOOD_FILED,
  label: 'menuMaker:createMenu.fields.didiFoodFiled',
  value: DIDI_FOOD,
  register: {},
};

const rappiFiled = {
  name: RAPPI_FILED,
  id: RAPPI_FILED,
  label: 'menuMaker:createMenu.fields.rappiFiled',
  value: RAPPI,
  register: {},
};

const changePricesField = {
  name: 'changePricesField',
  id: 'changePricesField',
  label: 'menuMaker:createMenu.fields.changePrice',
  register: {},
};

export const formDefinition = {
  nameMenuField,
  startDateField,
  endDateField,
  weekdaysFiled,
  weekendFiled,
  weekdaysListCheckBox: [mondayField, tuesdayField, wednesdayField, thursdayFiled, fridayFiled],
  weekendListCheckBox: [saturdayField, sundayField],
  providersOptions: [posField, didiFoodFiled, uberEatsFiled, rappiFiled, onlineField],
  changePricesField,
};

export function getValuesTimeFieldsFields(operationTimes) {
  const operationTimesFields = [];
  let daysObject = {};
  let operationTime = {};

  if (operationTimes?.length) {
    operationTimes?.forEach((time) => {
      daysObject = time?.days?.reduce((obj, item) => {
        return {
          ...obj,
          [timeFields[item]]: true,
        };
      }, {});

      operationTime = {
        ...daysObject,
        startDateField: time?.startHour,
        endDateField: time?.endHour,
      };

      operationTimesFields.push({ ...operationTime });
    });
  }

  return operationTimesFields;
}

export function getValuesProvidersFields(providers, getField) {
  let isValue = false;

  if (Object.entries(providers).length > 0) {
    Object.entries(providers).forEach(([, value]) => {
      if (value?.providerName === providersFields[getField]) {
        isValue = true;
      }
    });
  }

  return isValue;
}

function getDaysValues(weekdaysValues) {
  const weekdays = [];

  if (Object.entries(weekdaysValues).length > 0) {
    Object.entries(weekdaysValues).forEach(([, value]) => {
      if (
        value === daysTypes.FRIDAY ||
        value === daysTypes.MONDAY ||
        value === daysTypes.SATURDAY ||
        value === daysTypes.SUNDAY ||
        value === daysTypes.THURSDAY ||
        value === daysTypes.TUESDAY ||
        value === daysTypes.WEDNESDAY
      ) {
        weekdays.push(value);
      }
    });
  }

  return weekdays;
}

export function getProvidersValues(providersValues) {
  const providers = [];

  if (Object.entries(providersValues).length > 0) {
    Object.entries(providersValues).forEach(([, value]) => {
      if (
        value === providersTypes.DIDI_FOOD ||
        value === providersTypes.PARROT ||
        value === providersTypes.RAPPI ||
        value === providersTypes.SIN_DELANTAL ||
        value === providersTypes.UBER_EATS ||
        value === providersTypes.ONLINE
      ) {
        providers.push({
          providerName: value,
        });
      }
    });
  }

  return providers;
}

export function getOperationTimes(times) {
  const operationTimes = [];

  times?.forEach((time) => {
    const timeObject = {
      days: getDaysValues(time),
      start_hour: time?.startDateField,
      end_hour: time?.endDateField,
    };

    operationTimes.push(timeObject);
  });

  return operationTimes;
}

const PROPERTY_NAME = 'name';
const PROPERTY_AVAILABILITY = 'availability';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};

const FILTER_PROPERTIES = [PROPERTY_NAME, PROPERTY_AVAILABILITY];

export function getFilteredMenusByText(menus, sorting) {
  return menus?.filter(
    (menu) =>
      FILTER_PROPERTIES.filter((property) => menu[property].toLowerCase().includes(sorting.filterByText.toLowerCase()))
        .length,
  );
}

const MENU = 'MENU';
const CATEGORY = 'CATEGORY';
const PRODUCT = 'PRODUCT';
const GENERAL = 'GENERAL';
const MODIFIER_GROUP = 'MODIFIER_GROUP';

export const typeErrorsPublishMenus = {
  MENU,
  CATEGORY,
  PRODUCT,
  GENERAL,
  MODIFIER_GROUP,
};

export function orderPublisMenusErrors(validations) {
  const providersErrors = [PARROT, UBER_EATS, RAPPI, DIDI_FOOD];
  const typesErrors = {
    [MENU]: [],
    [CATEGORY]: [],
    [PRODUCT]: [],
    [MODIFIER_GROUP]: [],
  };

  providersErrors?.forEach((provider) => {
    validations[provider]?.forEach((error) => {
      switch (error?.type) {
        case MENU:
          typesErrors[MENU].push({ ...error, providerName: provider });
          break;
        case CATEGORY:
          typesErrors[CATEGORY].push({ ...error, providerName: provider });
          break;
        case PRODUCT:
          typesErrors[PRODUCT].push({ ...error, providerName: provider });
          break;
        case MODIFIER_GROUP:
          typesErrors[MODIFIER_GROUP].push({ ...error, providerName: provider });
          break;
        default:
          break;
      }
    });
  });

  return typesErrors;
}

export function getWeekDaysSelected(fields) {
  const objectReturn = [[]];
  if (fields.length === 0) return objectReturn;

  fields?.forEach((object, index) => {
    const includesItems = [];
    formDefinition?.weekdaysListCheckBox?.forEach((weekDay) => {
      if (object[weekDay?.name]) includesItems.push(weekDay?.value);
    });
    objectReturn[index] = includesItems;
  });

  return objectReturn;
}

export function getWeekendDaysSelected(fields) {
  const objectReturn = [[]];
  if (fields.length === 0) return objectReturn;

  fields?.forEach((object, index) => {
    const includesItems = [];
    formDefinition?.weekendListCheckBox?.forEach((weekendDay) => {
      if (object[weekendDay?.name]) includesItems.push(weekendDay?.value);
    });
    objectReturn[index] = includesItems;
  });

  return objectReturn;
}

export function getInitialCategories(categories, categoriesSelected) {
  const initialCategories = categories?.map((category) => ({ ...category, sortingPosition: 0 }));

  categoriesSelected?.forEach((categorySelected) => {
    categories?.forEach((category, index) => {
      if (category?.uuid === categorySelected?.uuid) {
        initialCategories[index] = categorySelected;
      }
    });
  });

  return initialCategories?.sort((a, b) => a.name.localeCompare(b.name));
}

export function getInitialCategoriesSelected(categories, categoriesSelected) {
  const initialCategoriesSelected = [];
  categoriesSelected?.forEach((group) => {
    categories?.forEach((category) => {
      if (category?.uuid === group?.category?.uuid) {
        initialCategoriesSelected.push({ ...category, sortingPosition: group?.sortingPosition });
      }
    });
  });

  return sortBy(initialCategoriesSelected, ['sortingPosition', 'name']);
}

export function getCategoriesValues(categoriesSelected) {
  return categoriesSelected?.map((category, index) => ({
    uuid: category?.uuid,
    sortingPosition: index,
  }));
}

export const actions = {
  ADD: 'add',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

const BRAND_SELECTED = 'brand-selected';

export function setBrandSelectedLocalStorage({ brandUuid }) {
  localStorage.setItem(BRAND_SELECTED, brandUuid);
}

export function getBrandSelectedLocalStorage() {
  const brandSelected = localStorage.getItem(BRAND_SELECTED);

  if (!brandSelected) {
    return;
  }

  return brandSelected;
}

export function removeBrandSelectedLocalStorage() {
  localStorage.removeItem(BRAND_SELECTED);
}
