export const SUBMIT_CHANGE_PRICE_EVENT_KEY = 'Enter';

export const PERCENTAGE_PRICE_VARIATION = 30;

export function formatPrice(string) {
  if (string) {
    return string.replace(/[$]/g, '');
  }

  return null;
}

const STATUS_AVAILABLE = 'AVAILABLE';

export const statusType = {
  STATUS_AVAILABLE,
};
