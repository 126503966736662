import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';

import useIconParrot from 'hooks/useIconParrot';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,

    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,
    '&$iconSizeSmall': {
      marginLeft: -2,
    },
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },
}));

function ButtonAdd({ children, disabled, ...others }) {
  const classes = useStyles();

  const { IconAddCircle } = useIconParrot({ icon: 'addCircle' });

  return (
    <ButtonBase disabled={disabled} disableRipple disableTouchRipple {...others}>
      <span
        className={clsx(classes.label, {
          [classes.disabledLabel]: disabled,
        })}
      >
        <span className={classes.startIcon}>
          <IconAddCircle />
        </span>

        {children}
      </span>
    </ButtonBase>
  );
}

ButtonAdd.propTypes = {
  ...ButtonBase.propTypes,
};

export default ButtonAdd;
