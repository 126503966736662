import React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';

import useIconParrot from 'hooks/useIconParrot';

const useStyles = makeStyles((theme) => ({
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: theme.palette.common.black,
    padding: '1rem',
    paddingInlineStart: 0,

    '&:hover': {
      color: '#262626',
      textDecoration: 'underline',
    },
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },
}));

function ButtonNavigationBack({ children, disabled, ...others }) {
  const classes = useStyles();

  const { IconBack } = useIconParrot({ icon: 'back' });

  return (
    <ButtonBase data-testid="goBackButton" disabled={disabled} disableRipple disableTouchRipple {...others}>
      <span
        className={clsx(classes.label, {
          [classes.disabledLabel]: disabled,
        })}
      >
        <span className={classes.startIcon}>
          <IconBack />
        </span>
        <span className="label">{children}</span>
      </span>
    </ButtonBase>
  );
}

ButtonNavigationBack.propTypes = {
  ...ButtonBase.propTypes,
};

export default ButtonNavigationBack;
