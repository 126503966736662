import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectProductsDomain = (state) => state.products || initialState;

const selectProducts = createSelector(selectProductsDomain, (subState) => subState.getProducts.products);

const selectProductsCategories = createSelector(selectProductsDomain, (subState) => subState.getProducts.categories);

const selectProductsPagination = createSelector(selectProductsDomain, (subState) => subState.getProducts.pagination);

const selectProductsSorting = createSelector(selectProductsDomain, (subState) => subState.sorting);

const selectProductsFetching = createSelector(selectProductsDomain, (subState) => subState.getProducts.fetching);

const selectProductsFetched = createSelector(selectProductsDomain, (subState) => subState.getProducts.fetched);

const selectProductsError = createSelector(selectProductsDomain, (subState) => subState.getProducts.error);

const selectCreateProduct = createSelector(selectProductsDomain, (subState) => subState.createProduct.product);

const selectCreateProductError = createSelector(selectProductsDomain, (subState) => subState.createProduct.error);

const selectCreateProductFetching = createSelector(selectProductsDomain, (subState) => subState.createProduct.fetching);

const selectCreateProductFetched = createSelector(selectProductsDomain, (subState) => subState.createProduct.fetched);

const selectDeleteProductFetching = createSelector(
  selectProductsDomain,
  (productState) => productState.deleteProduct.fetching,
);
const selectDeleteProductFetched = createSelector(
  selectProductsDomain,
  (productState) => productState.deleteProduct.fetched,
);
const selectDeleteProductError = createSelector(
  selectProductsDomain,
  (productState) => productState.deleteProduct.error,
);

const selectToggleProductAvailabilityFetching = createSelector(
  selectProductsDomain,
  (subState) => subState.toggleProductAvailability.fetching,
);
const selectToggleProductAvailabilityFetched = createSelector(
  selectProductsDomain,
  (subState) => subState.toggleProductAvailability.fetched,
);
const selectToggleProductAvailabilityError = createSelector(
  selectProductsDomain,
  (subState) => subState.toggleProductAvailability.error,
);

const selectUpdateProductImageFetching = createSelector(
  selectProductsDomain,
  (subState) => subState.updateProductImage.fetching,
);
const selectUpdateProductImageFetched = createSelector(
  selectProductsDomain,
  (subState) => subState.updateProductImage.fetched,
);
const selectUpdateProductImageError = createSelector(
  selectProductsDomain,
  (subState) => subState.updateProductImage.error,
);

const selectProductsPaginationInfo = createSelector(
  selectProductsDomain,
  (subState) => subState.getProducts.pagination,
);

const selectGetProductsPaginationScrollState = createSelector(
  selectProductsDomain,
  (subState) => subState.paginationGetProducts,
);

export {
  selectProductsDomain,
  selectProducts,
  selectProductsPagination,
  selectProductsSorting,
  selectProductsCategories,
  selectProductsFetching,
  selectProductsFetched,
  selectProductsError,
  selectCreateProduct,
  selectCreateProductFetching,
  selectCreateProductFetched,
  selectCreateProductError,
  selectDeleteProductFetching,
  selectDeleteProductFetched,
  selectDeleteProductError,
  selectToggleProductAvailabilityFetching,
  selectToggleProductAvailabilityFetched,
  selectToggleProductAvailabilityError,
  selectUpdateProductImageFetching,
  selectUpdateProductImageFetched,
  selectUpdateProductImageError,
  selectProductsPaginationInfo,
  selectGetProductsPaginationScrollState,
};
