import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'end',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },

  wrapperFilters: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(3),
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
      gridRow: '2/3',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },

  wrapperSearchBox: (props) => ({
    gridColumn: `1 / ${props.filtersNumber < 3 ? 3 : 2}`,
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1',
    },
  }),

  searchBox: {
    width: '100%',
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  wrapperMultiSelect: {
    overflow: 'hidden',
  },

  wrapperActions: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'end',
  },

  middlePrimaryButton: {
    alignSelf: 'baseline',
  },
}));

export default useStyles;
