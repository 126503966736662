import { stringify } from 'query-string';
import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getCategoriesStore({ storeUuid, brand, name, page = 1, allCategories, withoutBrand }, options = {}) {
  const query = stringify({
    store_uuid: storeUuid,
    brand: brand ?? undefined,
    without_brand: withoutBrand ?? undefined,
    name: name ?? undefined,
    page,
    page_size: allCategories ? undefined : 20,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/categories/?${query}`, { method: 'GET', ...options });
}

export function getCategoriesMenusStore(storeUuid, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/menus/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function saveCategoryStore(category, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/categories/`, {
    method: 'POST',
    body: JSON.stringify(category),
    ...options,
  });
}

export function deleteCategoryStore({ storeUuid, categoryUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getCategoryStore(categoryUuid, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/`, {
    method: 'GET',
    ...options,
  });
}

export function editCategoryStore({ storeUuid, categoryUuid, category }, options = {}) {
  return request(
    `${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/?store_uuid=${storeUuid}`,

    {
      method: 'PUT',

      body: JSON.stringify(category),

      ...options,
    },
  );
}

export function duplicateCategoryStore({ storeUuid, categoryUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: categoryUuid,
      objectType: 'CATEGORY',
    }),
    ...options,
  });
}

export function getProductsSortCategoryRequest({ storeUuid, categoryUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/sorting-products/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function updateProductsSortCategoryRequest({ storeUuid, categoryUuid, productsSort }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/categories/${categoryUuid}/sorting-products/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify(productsSort),
    ...options,
  });
}
