import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

function PopupAlert({ severity, message, onClose, shouldDisplay }) {
  const [isOpen, setIsOpen] = React.useState(shouldDisplay);

  useEffect(() => {
    if (shouldDisplay) {
      setIsOpen(true);
    }
  }, [shouldDisplay]);

  function handleClosed(event) {
    if (onClose) {
      onClose(event);
    }

    setIsOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={handleClosed}
      open={isOpen}
    >
      <Alert onClose={handleClosed} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

PopupAlert.propTypes = {
  severity: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  shouldDisplay: PropTypes.bool,
};

export default withTranslation()(PopupAlert);
