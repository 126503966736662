import { getGlobalInvoicesRequest, getCustomerInvoicesRequest } from 'utils/api/baas/invoicing/invoicesGenerated';

const GLOBAL = 'global';
const CUSTOMER = 'customer';

export const invoicingGeneratedCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export const invoicingGeneratedPaginationCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export const invoicingGeneratedDownloadCalls = {
  [GLOBAL]: getGlobalInvoicesRequest,
  [CUSTOMER]: getCustomerInvoicesRequest,
};

export function invoicingGeneratedRequest(type, ...args) {
  return invoicingGeneratedCalls[type](...args);
}

export function invoicingGeneratedPaginationRequest(type, ...args) {
  return invoicingGeneratedPaginationCalls[type](...args);
}

export function invoicingGenerateDownload(type, ...args) {
  return invoicingGeneratedDownloadCalls[type](...args);
}
