import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getMenuDetails, editMenu } from './actions';
import {
  selectMenu,
  selectMenuFetching,
  selectMenuFetched,
  selectMenuError,
  selectEditMenuFetching,
  selectEditMenuFetched,
  selectEditMenuError,
} from './selectors';

export function withMenuDetailsHoc(Component) {
  function withMenuDetails(props) {
    return <Component {...props} />;
  }

  withMenuDetails.displayName = `withMenuDetails(${getDisplayName(Component)})`; // Display Name for Easy Debugging

  return withMenuDetails;
}

const mapStateToProps = createStructuredSelector({
  menu: selectMenu,
  menuFetching: selectMenuFetching,
  menuFetched: selectMenuFetched,
  menuError: selectMenuError,
  editMenuFetching: selectEditMenuFetching,
  editMenuFetched: selectEditMenuFetched,
  editMenuError: selectEditMenuError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadMenu: ({ menuUuid, storeUuid }) => dispatch(getMenuDetails({ menuUuid, storeUuid })),
    editMenu: ({ menuUuid, availability, name, sortingPosition, store, operationTimes, providers, categories }) =>
      dispatch(
        editMenu({
          menuUuid,
          availability,
          name,
          sortingPosition,
          store,
          operationTimes,
          providers,
          categories,
        }),
      ),
    resetEditMenu: () => dispatch(editMenu.reset()),
  };
}

export const withMenuDetailsState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withMenuDetailsState, withMenuDetailsHoc);
