import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getInventoriesRequest,
  updateInventoryRequest,
  createInventoryRequest,
  deleteInventoriesRequest,
  getInventoryDetailsRequest,
} from 'utils/api/baas/inventories/suppliers';

import {
  createInventory,
  getInventories,
  deleteInventory,
  getInventoryDetails,
  updateInventory,
  getInventoriesScrollPagination,
} from './actions';

export function* handleGetInventoriesRequest(action) {
  const filters = action.payload;

  try {
    yield put(getInventories.request());

    const { data, pagination } = yield call(baasRequest, getInventoriesRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getInventories.success({ inventories: data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getInventories.failure(err));
  }
}

export function* getInventoriesScrollPaginationSaga(action) {
  const filters = action.payload;

  try {
    yield put(getInventoriesScrollPagination.request());

    const { data, pagination } = yield call(baasRequest, getInventoriesRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getInventoriesScrollPagination.success({ inventories: data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getInventoriesScrollPagination.failure(err));
  }
}

export function* handleGetInventoryDetailsRequest(action) {
  const inventoryUuid = action.payload;

  try {
    yield put(getInventoryDetails.request());

    const { data } = yield call(baasRequest, getInventoryDetailsRequest, inventoryUuid);

    yield put(getInventoryDetails.success(data));
  } catch (err) {
    yield put(getInventoryDetails.failure(err));
  }
}

export function* handleCreateInventoryRequest(action) {
  const inventory = action.payload;

  try {
    yield put(createInventory.request());

    const { data } = yield call(baasRequest, createInventoryRequest, inventory);

    yield put(createInventory.success(data));
  } catch (err) {
    yield put(createInventory.failure(err));
  }
}

export function* handleUpdateInventoryRequest(action) {
  const { inventoryUuid, inventory } = action.payload;

  try {
    yield put(updateInventory.request());

    const { data } = yield call(baasRequest, updateInventoryRequest, inventoryUuid, inventory);

    yield put(updateInventory.success({ inventory: data, inventoryUuid }));
  } catch (err) {
    yield put(updateInventory.failure(err));
  }
}

export function* handleDeleteInventoryRequest(action) {
  const inventoryUuid = action.payload;

  try {
    yield put(deleteInventory.request());

    yield call(baasRequest, deleteInventoriesRequest, inventoryUuid);

    yield put(deleteInventory.success());
  } catch (err) {
    yield put(deleteInventory.failure(err));
  } finally {
    yield put(deleteInventory.fulfill(inventoryUuid));
  }
}

export default function* reportsOrdersProviderRootSga() {
  yield takeLatest(getInventories.TRIGGER, handleGetInventoriesRequest);
  yield takeLatest(getInventoriesScrollPagination.TRIGGER, getInventoriesScrollPaginationSaga);
  yield takeLatest(createInventory.TRIGGER, handleCreateInventoryRequest);
  yield takeLatest(deleteInventory.TRIGGER, handleDeleteInventoryRequest);
  yield takeLatest(getInventoryDetails.TRIGGER, handleGetInventoryDetailsRequest);
  yield takeLatest(updateInventory.TRIGGER, handleUpdateInventoryRequest);
}
