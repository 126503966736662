import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  linkCell: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    width: theme.spacing(20),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  actionsColumn: {
    textAlign: 'center',
    width: theme.typography.pxToRem(120),
  },

  headerCellWithIcon: {
    alignContent: 'baseline',
    justifyContent: 'center',
    display: 'flex',
    '& > :last-child': {
      marginLeft: theme.spacing(1),
      marginTop: 'auto',
      marginBottom: 'auto',
      height: theme.typography.pxToRem(24),
    },
  },
  helperPopOver: {
    marginTop: theme.spacing(2),
  },
  helperBox: {
    color: theme.palette.darkGrey.main,
    fontWeight: theme.typography.fontWeightNormal,
    maxWidth: theme.typography.pxToRem(300),
    padding: theme.spacing(2),
    lineHeight: theme.typography.pxToRem(24),
    fontSize: theme.typography.pxToRem(16),
  },
  rowContentCenter: {
    textAlign: 'center',
  },
}));

export default useStyles;
