import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import camelCase from 'lodash/camelCase';

import Typography from '@material-ui/core/Typography';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import IconButton from '@design-system/IconButton';

import PopupAlert from 'components/PopupAlert';

import { createFileFromUrl, inWidthSizeImage, inHeightSizeImage } from '../../utils/files';

import useStyles from './styles';

function ImageDropzone({
  titleLabel,
  initialImageUrl,
  onImageChange,
  dropzoneDescription,
  buttonLabel,
  shouldNotifyOnInitialImageLoad,
  t,
}) {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [errorFileDimens, setErrorFileDimens] = useState(false);

  function updateFile(newFile) {
    try {
      setFile({ ...newFile, preview: URL.createObjectURL(newFile) });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles: 1,
    maxSize: 10000000, // 10 MB
    onDrop: (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      const image = new Image();

      image.addEventListener('load', () => {
        setErrorFileDimens(false);

        if (inWidthSizeImage(image.width) && inHeightSizeImage(image.height)) {
          updateFile(acceptedFile);

          if (onImageChange) {
            onImageChange(acceptedFile);
          }
        } else {
          setErrorFileDimens(true);
        }
      });

      if (acceptedFile) image.src = URL.createObjectURL(acceptedFile);
    },
  });

  useEffect(() => {
    async function loadInitialImage() {
      if (initialImageUrl) {
        try {
          const initialImage = await createFileFromUrl(initialImageUrl);
          updateFile(initialImage);
          if (shouldNotifyOnInitialImageLoad) {
            onImageChange(initialImage);
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    }
    loadInitialImage();
  }, [initialImageUrl, onImageChange, shouldNotifyOnInitialImageLoad]);

  function getPreview() {
    if (file) {
      return <img alt="Preview" src={file.preview} />;
    }
    return <InsertPhotoIcon className={classes.placeholderIcon} color="disabled" />;
  }

  function getDescription() {
    if (dropzoneDescription) {
      return <p>{dropzoneDescription}</p>;
    }
    return null;
  }

  function renderErrors() {
    if (fileRejections?.length > 0) {
      const latestReason = fileRejections[fileRejections.length - 1].errors[0].code;
      const message = t(`common:errors.image.${camelCase(latestReason)}`);
      return <PopupAlert message={message} severity="error" shouldDisplay={Boolean(latestReason)} />;
    }
    return null;
  }

  function renderErrorDimens() {
    if (errorFileDimens) {
      const message = t(`common:errors.image.fileInvalidDimens`);
      return <PopupAlert message={message} severity="error" shouldDisplay={errorFileDimens} />;
    }

    return null;
  }

  const handleRemoveImage = () => {
    setFile(null);
    onImageChange('');
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.titleLabel} gutterBottom variant="h6">
        {titleLabel}
      </Typography>
      <div className={classes.wrapperPhoto}>
        <div {...getRootProps({ className: classes.photoContainer })}>
          <input {...getInputProps()} />
          {getPreview()}
        </div>

        {file && (
          <IconButton
            className={classes.removeImage}
            onClick={handleRemoveImage}
            size="small"
            startIcon={<CloseIcon />}
          />
        )}
      </div>
      {renderErrors()}
      {getDescription()}
      <Button className={classes.changeButton} color="primary" onClick={open} variant="contained">
        {buttonLabel}
      </Button>
      {renderErrorDimens()}
    </div>
  );
}

ImageDropzone.defaultProps = {
  shouldNotifyOnInitialImageLoad: false,
};

ImageDropzone.propTypes = {
  titleLabel: PropTypes.string,
  initialImageUrl: PropTypes.string,
  onImageChange: PropTypes.func,
  dropzoneDescription: PropTypes.string,
  buttonLabel: PropTypes.string,
  shouldNotifyOnInitialImageLoad: PropTypes.bool,
  t: PropTypes.func,
};

export default ImageDropzone;
