import { createSelector } from 'reselect';

import { filterInventoriesByText } from 'utils/inventories/supplier/sorting';

import { initialState } from './reducer';

const selectInventoriesAdminProviderDomain = (state) => state.inventoriesAdminProvider || initialState;

const selectGetInventories = createSelector(selectInventoriesAdminProviderDomain, ({ getInventories, sorting }) => {
  const inventoriesCurrent = [...getInventories.inventories];
  let inventoriesList = [...inventoriesCurrent];

  const { filterByText } = sorting;

  if (filterByText && filterByText.length >= 3) {
    inventoriesList = filterInventoriesByText(inventoriesCurrent, sorting);
  }

  return inventoriesList;
});

const selectGetInventoriesState = createSelector(
  selectInventoriesAdminProviderDomain,
  ({ getInventories: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateInventoryState = createSelector(
  selectInventoriesAdminProviderDomain,
  (subState) => subState.createInventory,
);

const selectDeleteInventoryState = createSelector(
  selectInventoriesAdminProviderDomain,
  ({ deleteInventory: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectGetInventoryDetails = createSelector(
  selectInventoriesAdminProviderDomain,
  (subState) => subState.getInventoryDetails.inventory,
);

const selectGetInventoryDetailsState = createSelector(
  selectInventoriesAdminProviderDomain,
  ({ getInventoryDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateInventoryState = createSelector(
  selectInventoriesAdminProviderDomain,
  ({ updateInventory: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectSortingState = createSelector(selectInventoriesAdminProviderDomain, (subState) => subState.sorting);

const selectInventoriesPaginationInfo = createSelector(
  selectInventoriesAdminProviderDomain,
  (subState) => subState.getInventories.pagination,
);

const selectGetInventoriesPaginationScrollState = createSelector(
  selectInventoriesAdminProviderDomain,
  (subState) => subState.paginationGetInventories,
);

export {
  selectInventoriesAdminProviderDomain,
  selectGetInventories,
  selectGetInventoryDetails,
  selectGetInventoryDetailsState,
  selectGetInventoriesState,
  selectCreateInventoryState,
  selectDeleteInventoryState,
  selectUpdateInventoryState,
  selectSortingState,
  selectInventoriesPaginationInfo,
  selectGetInventoriesPaginationScrollState,
};
