import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getMenuProductsRequest({ menuUuid, storeUuid }, options = {}) {
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/menus/${menuUuid}/products/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function changeMenuProductPriceRequest({ menuUuid, productUuid, price, availability }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/${productUuid}/override/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      menu: menuUuid,
      price,
      availability,
    }),
    ...options,
  });
}
