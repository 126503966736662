import clsx from 'clsx';

import {
  withStyles,
  makeStyles,
  InputLabel,
  FormControl,
  FormHelperText,
  Select as SelectMui,
  SelectProps,
  MenuProps,
} from '@material-ui/core';

import { TextField } from '@design-system';
import IconChevronDown from '@experimental-components/IconsComponents/ChevronDown';

const SelectRoot = withStyles((theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,
    padding: '1.1rem 1rem',
    paddingRight: `${theme.typography.pxToRem(32)} !important`,
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(SelectMui);

const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: 16,
  },
}))(FormHelperText);

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}));

interface Props extends SelectProps {
  helperText?: string;
  errorMsg?: string;
  hideLabel?: boolean;
  hideLabelHelperText?: boolean;
}
function Select({
  id,
  error,
  fullWidth,
  label,
  disabled,
  required,
  errorMsg,
  helperText,
  hideLabel,
  hideLabelHelperText,
  ...props
}: Props) {
  const classes = useStyles();

  const menuProps: Partial<MenuProps> = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      <InputLabelRoot htmlFor={id} shrink>
        {label}
      </InputLabelRoot>

      <SelectRoot
        disabled={disabled}
        IconComponent={(iconProps) => (
          <IconChevronDown className={clsx(iconProps.className, classes.iconRoot)} color="#000" />
        )}
        input={<TextField hideLabel={hideLabel} hideLabelHelperText={hideLabelHelperText} />}
        MenuProps={menuProps}
        {...props}
      />
      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
}

export default Select;
