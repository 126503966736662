import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionMui, { AccordionProps } from '@material-ui/core/Accordion';

import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
  },
}));

function Accordion(props: AccordionProps) {
  const classes = useStyles();

  return <AccordionMui classes={{ root: classes.root }} elevation={0} {...props} />;
}

Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

export default Accordion;
