import produce from 'immer';
import baseState from 'utils/baseState';

import { sortingOrderTypes, sortingPropertyTypes, statusType } from 'utils/products';

import {
  getProducts,
  createProduct,
  deleteProduct,
  duplicateProduct,
  toggleProductAvailability,
  updateProductImage,
  getProductsScrollPagination,
} from './actions';

export const initialState = {
  getProducts: {
    ...baseState,
    products: [],
    categories: {},
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationGetProducts: {
    ...baseState,
    lastUpdatedRows: [],
  },

  sorting: {
    property: sortingPropertyTypes.NAME,
    filterByText: '',
    filterByCategories: [],
    filterByBrands: [],
    direction: sortingOrderTypes.ASC,
  },

  createProduct: {
    ...baseState,
    product: {},
  },

  toggleProductAvailability: {
    ...baseState,
  },

  deleteProduct: {
    ...baseState,
  },

  updateProductImage: {
    ...baseState,
  },
};

const productsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getProducts.REQUEST:
        draft.getProducts.fetching = true;
        draft.getProducts.error = null;
        draft.getProducts.pagination = { ...initialState.getProducts.pagination };
        draft.sorting = {
          ...initialState.sorting,
        };

        break;

      case getProducts.SUCCESS: {
        draft.getProducts.fetching = false;
        draft.getProducts.fetched = true;
        draft.getProducts.products = payload.products;
        if (!payload?.isSorting) {
          draft.getProducts.categories = {};
          payload.categories.forEach(function createCategoryMap(category) {
            draft.getProducts.categories[category.uuid] = category;
          });
        }
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getProducts.FAILURE:
        draft.getProducts.error = payload;
        draft.getProducts.fetching = false;
        draft.getProducts.fetched = false;
        break;

      case getProductsScrollPagination.REQUEST:
        draft.paginationGetProducts = {
          ...initialState.paginationGetProducts,
          fetching: true,
        };
        break;

      case getProductsScrollPagination.SUCCESS: {
        const currentState = state.getProducts.products;

        let newsRows = [...currentState];
        newsRows = [...newsRows, ...payload.products];

        draft.paginationGetProducts.fetching = false;
        draft.paginationGetProducts.fetched = true;
        draft.paginationGetProducts.lastUpdatedRows = payload.products;

        draft.getProducts.products = [...newsRows];
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getProductsScrollPagination.FAILURE:
        draft.paginationGetProducts.error = payload;
        draft.paginationGetProducts.fetching = false;
        draft.paginationGetProducts.fetched = false;
        break;

      case getProductsScrollPagination.RESET:
        draft.paginationGetProducts = {
          ...initialState.paginationGetProducts,
        };
        break;

      case getProducts.SORTING:
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...initialState.getProducts.pagination,
        };

        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getProducts.PAGINATION:
        draft.getProducts.pagination = {
          ...draft.getProducts.pagination,
          ...payload,
        };
        break;

      case getProducts.RESET_PAGINATION:
        draft.getProducts.pagination = { ...initialState.getProducts.pagination };
        break;

      case getProducts.RESET_SORTING:
        draft.sorting = { ...initialState.sorting };
        break;

      case getProducts.RESET:
        draft.getProducts = { ...initialState.getProducts };
        break;

      // create product
      case createProduct.REQUEST:
        draft.createProduct = { ...initialState.createProduct, fetching: true };
        break;

      case createProduct.SUCCESS: {
        const productsList = [...draft.getProducts.products];
        productsList.unshift(payload);

        draft.createProduct.fetching = false;
        draft.createProduct.fetched = true;
        draft.createProduct.product = payload;
        draft.getProducts.products = productsList;
        break;
      }

      case createProduct.FAILURE:
        draft.createProduct.error = payload;
        draft.createProduct.fetching = false;
        draft.createProduct.fetched = false;
        break;

      case createProduct.RESET:
        draft.createProduct = { ...initialState.createProduct };
        break;

      // Toggle
      case toggleProductAvailability.TRIGGER: {
        const productsList = draft.getProducts.products.map((product) => {
          return { ...product };
        });

        productsList.find((product) => product?.uuid === payload?.productUuid).availability = payload.isAvailable
          ? statusType.STATUS_AVAILABLE
          : statusType.STATUS_UNAVAILABLE;
        draft.getProducts.products = productsList;
        break;
      }

      case toggleProductAvailability.REQUEST:
        draft.toggleProductAvailability = { ...initialState.toggleProductAvailability, fetching: true };
        break;

      case toggleProductAvailability.SUCCESS: {
        const productsList = draft.getProducts.products.map((product) => {
          return { ...product };
        });
        productsList.find((product) => product?.uuid === payload?.product?.uuid).availability =
          payload.product.availability;

        draft.toggleProductAvailability.fetching = false;
        draft.toggleProductAvailability.fetched = true;
        draft.getProducts.products = productsList;
        break;
      }
      case toggleProductAvailability.FAILURE: {
        const productsList = draft.getProducts.products.map((product) => {
          return { ...product };
        });

        productsList.find((product) => product?.uuid === payload?.productUuid).availability =
          productsList.find((product) => product?.uuid === payload?.productUuid).availability ===
          statusType.STATUS_AVAILABLE
            ? statusType.STATUS_AVAILABLE
            : statusType.STATUS_UNAVAILABLE;

        draft.toggleProductAvailability.error = payload.error;
        draft.toggleProductAvailability.fetching = false;
        draft.toggleProductAvailability.fetched = false;
        draft.getProducts.products = productsList;
        break;
      }

      case toggleProductAvailability.RESET:
        draft.toggleProductAvailability = { ...initialState.toggleProductAvailability };
        break;

      // Delete
      case deleteProduct.REQUEST:
        draft.deleteProduct = { ...initialState.deleteProduct, fetching: true };
        break;

      case deleteProduct.SUCCESS: {
        draft.deleteProduct.fetching = false;
        draft.deleteProduct.fetched = true;
        break;
      }

      case deleteProduct.FULFILL: {
        if (!draft.deleteProduct.error) {
          const productList = draft.getProducts.products.filter((product) => product.uuid !== payload.productUuid);
          draft.getProducts.products = productList;
        }
        break;
      }

      case deleteProduct.FAILURE:
        draft.deleteProduct.error = payload.error;
        draft.deleteProduct.fetching = false;
        draft.deleteProduct.fetched = false;
        break;

      case deleteProduct.RESET:
        draft.deleteProduct = { ...initialState.deleteProduct };
        break;

      // Duplicate
      case duplicateProduct.TRIGGER:
        break;

      case duplicateProduct.SUCCESS:
        draft.products[payload.product.uuid] = payload.product;
        break;

      case duplicateProduct.FAILURE:
        break;

      // update image
      case updateProductImage.REQUEST:
        draft.updateProductImage = { ...initialState.updateProductImage, fetching: true };
        break;

      case updateProductImage.SUCCESS: {
        const productsList = draft.getProducts.products.map((product) => {
          return { ...product };
        });
        productsList.find((product) => product?.uuid === payload?.uuid).image = payload.image;

        draft.updateProductImage.fetching = false;
        draft.updateProductImage.fetched = true;
        draft.getProducts.products = productsList;
        break;
      }

      case updateProductImage.FAILURE: {
        draft.updateProductImage.error = payload;
        draft.updateProductImage.fetching = false;
        draft.updateProductImage.fetched = false;
        break;
      }

      case updateProductImage.RESET:
        draft.updateProductImage = { ...initialState.updateProductImage };
        break;

      default:
        return draft;
    }
  });

export default productsReducer;
