import { createRoutine } from 'redux-saga-routines';

import extendRoutine from 'utils/extendRoutines';

import {
  GET_STORAGE_AREAS,
  GET_STORAGE_AREAS_PAGINATION,
  GET_STORAGE_AREA_DETAILS,
  CREATE_STORAGE_AREA,
  UPDATE_STORAGE_AREA,
  DELETE_STORAGE_AREA,
} from './constants';

export const getStorageAreas = extendRoutine(createRoutine(GET_STORAGE_AREAS), ['RESET', 'RESET_SORTING', 'SORTING']);
export const getStorageAreasPagination = extendRoutine(createRoutine(GET_STORAGE_AREAS_PAGINATION), 'RESET');
export const getStorageAreaDetails = extendRoutine(createRoutine(GET_STORAGE_AREA_DETAILS), 'RESET');
export const createStorageArea = extendRoutine(createRoutine(CREATE_STORAGE_AREA), 'RESET');
export const updateStorageArea = extendRoutine(createRoutine(UPDATE_STORAGE_AREA), 'RESET');
export const deleteStorageArea = extendRoutine(createRoutine(DELETE_STORAGE_AREA), ['RESET', 'FILTER']);
