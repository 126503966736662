import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useHistory, Route } from 'react-router-dom';

import { withRoles } from 'providers/workforce/Roles';
import { withRole } from 'providers/workforce/RoleDetails';
import { withPermissions } from 'providers/workforce/Permissions';
import { withRequiredLicense } from 'providers/root/AuthProvider';

import Layout from 'Layout';

import useNotification from '@design-system/Notification/useNotification';

import { getUserCurrentStore } from 'utils/auth';

import { routesPaths } from './constants';
import RolesList from './RolesList';
import RoleCreateForm from './RoleCreateForm';

export function Roles({
  // Functions
  loadRoles,
  duplicateRole,
  deleteRole,
  resetLoadRoles,
  resetDuplicateRole,
  resetDeleteRole,
  // Get Roles
  rolesList,
  rolesIsFetching,
  rolesError,
  // Duplicate role
  duplicateRoleError,
  duplicateRoleDidFetch,
  // Deleted role
  deleteRoleError,
  deleteRoleIsFetching,
  deleteRoleDidFetch,
  // Saved Role
  saveRole,
  selectSaveRoleIsFetching,
  selectSaveRoleDidFetch,
  selectSaveRoleError,
  resetSaveRole,
  // permisions
  loadPermissions,
  permissionsList,
  // Misc
  t,
}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const history = useHistory();
  const { closeAllSnackbar, setNotification } = useNotification();

  const [filteredRoleList, setFilteredRoleList] = useState(rolesList);

  useEffect(() => {
    loadPermissions();
    loadRoles(storeUuid);
  }, [loadPermissions, loadRoles, storeUuid]);

  useEffect(() => {
    if (selectSaveRoleDidFetch) {
      loadRoles(storeUuid);
      history.push(routesPaths.ROUTE_ROLES);
      resetSaveRole();
    }
  }, [selectSaveRoleDidFetch, resetSaveRole, history, loadRoles, storeUuid]);

  useEffect(() => {
    setFilteredRoleList(rolesList);
  }, [rolesList]);

  function onSearchBoxChange(searchValue) {
    if (searchValue?.filterByText) {
      const filteredList = rolesList.filter((role) =>
        role.name.toLowerCase().includes(searchValue?.filterByText?.toLowerCase()),
      );

      setFilteredRoleList(filteredList);
    } else {
      setFilteredRoleList(rolesList);
    }
  }

  function onAddButtonClick() {
    history.push(routesPaths.ROUTE_ROLE_CREATE);
  }

  function hanleSaveRol(role) {
    saveRole({ ...role, storeUuid });
  }

  useEffect(() => {
    if (selectSaveRoleDidFetch) {
      setNotification(t('workforce:snacks.savedRole.success'), { variant: 'success' });
    }

    if (selectSaveRoleError) {
      setNotification(t('workforce:snacks.savedRole.error'), { variant: 'error' });
    }
  }, [selectSaveRoleDidFetch, selectSaveRoleError, setNotification, t]);

  useEffect(() => {
    if (deleteRoleDidFetch) {
      setNotification(t('workforce:snacks.deletedRole.success'), { variant: 'success' });
    }

    if (deleteRoleError) {
      setNotification(t('workforce:snacks.deletedRole.error'), { variant: 'error' });
    }
  }, [deleteRoleDidFetch, deleteRoleError, setNotification, t]);

  useEffect(() => {
    if (duplicateRoleDidFetch) {
      setNotification(t('workforce:snacks.duplicatedRole.success'), { variant: 'success' });
    }

    if (duplicateRoleError) {
      setNotification(t('workforce:snacks.duplicatedRole.error'), { variant: 'error' });
    }
  }, [duplicateRoleDidFetch, duplicateRoleError, setNotification, t]);

  useEffect(() => {
    return () => {
      resetLoadRoles();
      resetDuplicateRole();
      resetDeleteRole();
      closeAllSnackbar();
    };
  }, [resetLoadRoles, resetDuplicateRole, resetDeleteRole, closeAllSnackbar]);

  const assignablePermissions = permissionsList?.filter(
    (permission) => permission.slug !== 'admin.store' && permission.slug !== 'backoffice.access',
  );

  return (
    <Layout>
      <Route exact path={routesPaths.ROUTE_ROLES}>
        <RolesList
          deleteRole={deleteRole}
          deleteRoleDidFetch={deleteRoleDidFetch}
          deleteRoleIsFetching={deleteRoleIsFetching}
          duplicateRole={duplicateRole}
          filteredRoleList={filteredRoleList}
          loadRoles={loadRoles}
          onAddButtonClick={onAddButtonClick}
          onSearchBoxChange={onSearchBoxChange}
          rolesError={rolesError}
          rolesIsFetching={rolesIsFetching}
        />
      </Route>

      <Route exact path={routesPaths.ROUTE_ROLE_CREATE}>
        <RoleCreateForm
          assignablePermissions={assignablePermissions}
          error={Boolean(selectSaveRoleError)}
          loading={selectSaveRoleIsFetching}
          onSubmit={hanleSaveRol}
        />
      </Route>
    </Layout>
  );
}

Roles.propTypes = {
  // Functions
  loadRoles: PropTypes.func,
  duplicateRole: PropTypes.func,
  deleteRole: PropTypes.func,
  resetLoadRoles: PropTypes.func,
  resetDuplicateRole: PropTypes.func,
  resetDeleteRole: PropTypes.func,
  // Get Roles
  rolesList: PropTypes.array,
  rolesIsFetching: PropTypes.bool,
  rolesError: PropTypes.object,
  // Duplicate role
  duplicateRoleError: PropTypes.object,
  duplicateRoleDidFetch: PropTypes.bool,
  // Deleted role
  deleteRoleError: PropTypes.object,
  deleteRoleIsFetching: PropTypes.bool,
  deleteRoleDidFetch: PropTypes.bool,
  // Saved Role
  saveRole: PropTypes.func,
  selectSaveRoleIsFetching: PropTypes.bool,
  selectSaveRoleDidFetch: PropTypes.bool,
  selectSaveRoleError: PropTypes.object,
  resetSaveRole: PropTypes.func,
  // Misc
  t: PropTypes.func,
  // permisions
  loadPermissions: PropTypes.func,
  permissionsList: PropTypes.array,
};

export default compose(
  memo,
  withRequiredLicense(),
  withRoles,
  withRole,
  withPermissions,
  withTranslation('workforce'),
)(Roles);
