import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

import useViewPanel from 'hooks/useViewPanel';

function Container({ children, ...others }) {
  const theme = useTheme();
  const { open } = useViewPanel();

  if (open) {
    return null;
  }

  return (
    <Box
      component="main"
      flexGrow={1}
      pb={theme.typography.pxToRem(32)}
      pl={theme.typography.pxToRem(32)}
      pr={theme.typography.pxToRem(32)}
      role="main"
      {...others}
    >
      {children}
    </Box>
  );
}

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
