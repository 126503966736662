import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Feature, useFeature } from 'FeatureProvider';

import useTheme from '@material-ui/core/styles/useTheme';

import ChevronRight from '@experimental-components/IconsComponents/ChevronRight';
import QuestionMark from '@experimental-components/IconsComponents/QuestionMark';
import MenuOrdering from '@experimental-components/IconsComponents/MenuOrdering';

import SidebarLayout from 'components/SidebarLayout';
import MenuStores from 'components/MenuStores';

import useUserStore from 'hooks/useUserStore';
import useViewPanel from 'hooks/useViewPanel';
import useContentLayout from 'hooks/useContentLayout';
import useIconParrot from 'hooks/useIconParrot';
import useEnvironment from 'hooks/useEnvironment';

export function AppFrame({ children, t }) {
  const theme = useTheme();
  const { environmentsState } = useEnvironment();

  const { user } = useUserStore();
  const { setOpen } = useViewPanel();
  const { setValue } = useContentLayout();
  const { IconExit, IconUser } = useIconParrot({
    icons: ['exit', 'user'],
  });

  const superUserAccess = useFeature('adminStore');
  const flagEnableInventory = environmentsState.REACT_APP_FEATURE_INVENTORY_ENABLED === 'true';
  const flagEnableOnlineOrdering = environmentsState.REACT_APP_FEATURE_ONLINE_ORDERING_ENABLED === 'true';

  function handleResetViewPanel() {
    setOpen(false);
    setValue(0);
  }

  function renderMenuSuperUser() {
    return (
      <>
        <SidebarLayout.Item
          exact={false}
          isActive={(match, location) => {
            return location.pathname.startsWith('/reports');
          }}
          label={t('common:nav.reports')}
          onClick={handleResetViewPanel}
          to="/reports/resume"
        />
        <SidebarLayout.Item exact={false} label={t('common:nav.menu')} onClick={handleResetViewPanel} to="/menus" />
        <SidebarLayout.Item
          exact={false}
          label={t('common:nav.personal')}
          onClick={handleResetViewPanel}
          to="/workforce"
        />
        <SidebarLayout.Item
          exact={false}
          label={t('common:nav.invoicing')}
          onClick={handleResetViewPanel}
          to="/invoicing"
        />
        <SidebarLayout.Item
          exact={false}
          label={t('common:nav.setting')}
          onClick={handleResetViewPanel}
          to="/settings"
        />
        {/* @flag inventories */}
        {flagEnableInventory && (
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.inventories')}
            onClick={handleResetViewPanel}
            to="/inventories"
          />
        )}

        <SidebarLayout.Item
          exact={false}
          label={t('common:nav.subscription')}
          onClick={handleResetViewPanel}
          to="/subscription"
        />
        {flagEnableOnlineOrdering && (
          <SidebarLayout.ButtonCard
            exact={false}
            label={t('common:nav.digitalMenu')}
            onClick={handleResetViewPanel}
            startIcon={<MenuOrdering />}
            to="/online-ordering"
          />
        )}
      </>
    );
  }

  function renderMenuWithPermissions() {
    return (
      <>
        <Feature name="backofficeModuleReports">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.reports')}
            onClick={handleResetViewPanel}
            to="/reports/resume"
          />
        </Feature>
        <Feature name="backofficeModuleMenu">
          <SidebarLayout.Item exact={false} label={t('common:nav.menu')} onClick={handleResetViewPanel} to="/menus" />
        </Feature>
        <Feature name="backofficeModuleWorkforce">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.personal')}
            onClick={handleResetViewPanel}
            to="/workforce"
          />
        </Feature>
        <Feature name="backofficeModuleInvoicing">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.invoicing')}
            onClick={handleResetViewPanel}
            to="/invoicing"
          />
        </Feature>
        <Feature name="backofficeModuleSettings">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.setting')}
            onClick={handleResetViewPanel}
            to="/settings"
          />
        </Feature>
        <Feature name="backofficeModuleSubscriptions">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.subscription')}
            onClick={handleResetViewPanel}
            to="/subscription"
          />
        </Feature>
      </>
    );
  }

  return (
    <SidebarLayout>
      <SidebarLayout.Sidebar>
        <SidebarLayout.Section mb={6}>
          <MenuStores />
        </SidebarLayout.Section>

        <SidebarLayout.Section mb={3}>
          {superUserAccess ? renderMenuSuperUser() : renderMenuWithPermissions()}
        </SidebarLayout.Section>

        <SidebarLayout.Spacer />

        <SidebarLayout.Divider variant="middle" />

        <SidebarLayout.Section>
          <SidebarLayout.ItemMenu
            endIcon={<ChevronRight color={theme.palette.common.white} />}
            label={t('common:help.title')}
            startIcon={<QuestionMark color={theme.palette.common.white} size={24} />}
            t={t}
          />

          <SidebarLayout.Item
            endIcon={<IconExit />}
            label={
              !user?.firstName || (!user?.firstName && !user.lastName)
                ? t('common:auth.signOut')
                : `${user.firstName} ${user.lastName}`
            }
            onClick={handleResetViewPanel}
            startIcon={<IconUser />}
            to="/signout"
          />
        </SidebarLayout.Section>
      </SidebarLayout.Sidebar>

      <SidebarLayout.Content>{children}</SidebarLayout.Content>
    </SidebarLayout>
  );
}

AppFrame.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func,
};

export default withTranslation()(AppFrame);
