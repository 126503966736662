import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12,1fr)',
    gridGap: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  sidebar: {
    gridColumn: '1/span 4',
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: (props) => (props.isTestEnv ? theme.palette.testEnv.main : 'black'),
    padding: theme.typography.pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    '& > :not(:first-child)': {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  iconParrot: {
    width: theme.typography.pxToRem(200),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },

  titleParrot: {
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(54),
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(32),
      textAlign: 'center',
    },
  },

  messageParrot: {
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    width: '90%',
  },

  urlParrot: {
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },

  copyright: {
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.darkGrey[50],
      fontWeight: theme.typography.fontWeightRegular,
    },
  },

  form: {
    gridColumn: '5/span 8',
    overflow: 'hidden',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      flex: '1 1 auto',
      padding: '2rem 1.5rem',
    },
  },

  headerWrapper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(600),
    position: 'absolute',
    margin: '0 auto',
    marginBottom: theme.typography.pxToRem(500),
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginBottom: theme.spacing(4),
    },
  },

  formWrapper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(400),
    position: 'relative',
    margin: '0 auto',
  },

  headerTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 'bold',
  },

  spacer: {
    flex: '1 1 auto',
  },

  rootLinkTermsConditions: {
    color: theme.palette.info.main,
  },
  buttonWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
