import { put, call, take, cancel, fork } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { getReportDetailsRequest, exportReportsRequest, getReportsSessionsRequest } from 'utils/reports';

import { logEventRequest } from 'providers/root/AmplitudeProvider/helpers';

import {
  getReportsSessionsDetails,
  exportReportsToExcelDetails,
  getReportsSessions,
  getReportsSessionsDetailsPagination,
  getReportsSessionsPagination,
  getReportsSessionsDetailsAll,
} from '../actions';

export function* handleGetReportsSessionsRequest({ payload: { key, type, ...filters } }) {
  yield put(getReportsSessions.request({ key }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportsSessionsRequest, type, key, {
      ...filters,
    });

    logEventRequest('@event.$reports.viewDrawersReport');

    yield put(getReportsSessions.success({ key, sessions: data, pagination }));
  } catch (err) {
    yield put(getReportsSessions.failure({ error: err, key }));
  } finally {
    yield put(getReportsSessions.fulfill());
  }
}

export function* handleGetReportsSessionsPaginationRequest({ payload: { key, type, page } }) {
  yield put(getReportsSessionsPagination.request({ key, page }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportsSessionsRequest, type, key, {
      page,
    });

    yield put(getReportsSessionsPagination.success({ key, sessions: data, pagination }));
  } catch (err) {
    yield put(getReportsSessionsPagination.failure({ error: err, key }));
  } finally {
    yield put(getReportsSessionsPagination.fulfill());
  }
}

export function* handleGetReportsSessionsDetailsRequest({ payload: { key, storeUuid, type, ...filters } }) {
  const { startDate, endDate } = filters;

  yield put(getReportsSessionsDetails.request({ key }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportDetailsRequest, type, key, {
      storeUuid,
      startDate,
      endDate,
    });

    logEventRequest('@event.$reports.viewDrawersReportDetail');
    yield put(getReportsSessionsDetails.success({ key, report: data, pagination }));
  } catch (err) {
    yield put(getReportsSessionsDetails.failure({ error: err, key }));
  } finally {
    yield put(getReportsSessionsDetails.fulfill());
  }
}

export function* handleExportReportsToExcelDetailsRequest({ payload: { key, storeUuid, type, ...filters } }) {
  const { startDate, endDate } = filters;

  yield put(exportReportsToExcelDetails.request({ key }));

  try {
    const responseFile = yield call(baasRequest, exportReportsRequest, type, key, {
      storeUuid,
      startDate,
      endDate,
    });

    logEventRequest('@event.$reports.exportReport', '@@type.drawers');
    yield put(exportReportsToExcelDetails.success({ key, document: responseFile }));
  } catch (err) {
    yield put(exportReportsToExcelDetails.failure({ error: err, key }));
  } finally {
    yield put(exportReportsToExcelDetails.fulfill());
  }
}

export function* handleReportsSessionsDetailsPaginationRequest({ payload: { key, storeUuid, type, page } }) {
  yield put(getReportsSessionsDetailsPagination.request({ key, page }));

  try {
    const { data, pagination } = yield call(baasRequest, getReportDetailsRequest, type, key, {
      storeUuid,
      page,
    });

    yield put(getReportsSessionsDetailsPagination.success({ key, report: data, pagination }));
  } catch (err) {
    yield put(getReportsSessionsDetailsPagination.failure({ error: err, key }));
  } finally {
    yield put(getReportsSessionsDetailsPagination.fulfill());
  }
}

export function* handleGetReportsSessionsDetailsAllRequest({ payload: { key, storeUuid, type } }) {
  yield put(getReportsSessionsDetailsAll.request({ key }));

  try {
    const { data } = yield call(baasRequest, getReportDetailsRequest, type, key, {
      storeUuid,
    });

    yield put(getReportsSessionsDetailsAll.success({ key, data }));
  } catch (err) {
    yield put(getReportsSessionsDetailsAll.failure({ error: err, key }));
  } finally {
    yield put(getReportsSessionsDetailsAll.fulfill());
  }
}

export function* takeReportsSessions() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsSessions.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsSessionsRequest, action);
  }
}

export function* takeReportsSessionsPagination() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsSessionsPagination.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsSessionsPaginationRequest, action);
  }
}

export function* takeReportsSessionsDetails() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsSessionsDetails.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsSessionsDetailsRequest, action);
  }
}

export function* takeExportReportsToExcelDetails() {
  const tasks = {};

  while (true) {
    const action = yield take(exportReportsToExcelDetails.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleExportReportsToExcelDetailsRequest, action);
  }
}

export function* takeReportsSessionsDetailsPagination() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsSessionsDetailsPagination.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleReportsSessionsDetailsPaginationRequest, action);
  }
}

export function* takeReportsSessionsDetailsAll() {
  const tasks = {};

  while (true) {
    const action = yield take(getReportsSessionsDetailsAll.TRIGGER);
    const { key } = action.payload;

    if (tasks[key]) {
      yield cancel(tasks[key]);
    }

    tasks[key] = yield fork(handleGetReportsSessionsDetailsAllRequest, action);
  }
}
