import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getStockCategories,
  getStockCategoriesPagination,
  getStockCategoryDetails,
  createStockCategory,
  updateStockCategory,
  deleteStockCategory,
} from './actions';

export const initialState = {
  stockCategories: {
    ...baseState,
    stockCategories: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  sorting: {
    filterByText: '',
  },

  stockCategoryDetails: {
    ...baseState,
    stockCategory: {},
  },

  createStockCategory: {
    ...baseState,
    stockCategory: {},
  },

  updateStockCategory: {
    ...baseState,
  },

  deleteStockCategory: {
    ...baseState,
  },

  stockCategoriesPagination: {
    ...baseState,
    lastUpdatedRows: [],
  },
};

const stockCategoriesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getStockCategories.REQUEST:
        draft.stockCategories = {
          ...initialState.stockCategories,
          fetching: true,
        };
        break;

      case getStockCategories.SUCCESS:
        draft.stockCategories.fetching = false;
        draft.stockCategories.fetched = true;
        draft.stockCategories.stockCategories = payload.data;
        draft.stockCategories.pagination = {
          ...initialState.stockCategories.pagination,
          ...payload.pagination,
        };
        break;

      case getStockCategories.SORTING:
        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };

        break;

      case getStockCategories.RESET_SORTING:
        draft.sorting = { ...initialState.sorting };
        break;

      case getStockCategories.FAILURE:
        draft.stockCategories.error = payload;
        draft.stockCategories.fetching = false;
        draft.stockCategories.fetched = false;
        break;

      case getStockCategories.RESET:
        draft.stockCategories = {
          ...initialState.stockCategories,
        };
        break;

      case getStockCategoryDetails.REQUEST:
        draft.stockCategoryDetails = {
          ...initialState.stockCategoryDetails,
          fetching: true,
        };
        break;

      case getStockCategoryDetails.SUCCESS:
        draft.stockCategoryDetails.fetching = false;
        draft.stockCategoryDetails.fetched = true;
        draft.stockCategoryDetails.stockCategory = payload.data;
        break;

      case getStockCategoryDetails.FAILURE:
        draft.stockCategoryDetails.error = payload;
        draft.stockCategoryDetails.fetching = false;
        draft.stockCategoryDetails.fetched = false;
        break;

      case getStockCategoryDetails.RESET:
        draft.stockCategoryDetails = {
          ...initialState.stockCategoryDetails,
        };
        break;

      case createStockCategory.REQUEST:
        draft.createStockCategory = {
          ...initialState.createStockCategory,
          fetching: true,
        };
        break;

      case createStockCategory.SUCCESS: {
        const list = [...draft.stockCategories.stockCategories];
        list.push(payload.data);
        draft.createStockCategory.fetching = false;
        draft.createStockCategory.fetched = true;
        draft.createStockCategory.stockCategory = payload.data;
        draft.stockCategories.stockCategories = list;
        break;
      }

      case createStockCategory.FAILURE:
        draft.createStockCategory.error = payload;
        draft.createStockCategory.fetching = false;
        draft.createStockCategory.fetched = false;
        break;

      case createStockCategory.RESET:
        draft.createStockCategory = {
          ...initialState.createStockCategory,
        };
        break;

      case updateStockCategory.REQUEST:
        draft.updateStockCategory.fetching = true;
        draft.updateStockCategory.fetched = false;
        draft.updateStockCategory.error = false;
        break;

      case updateStockCategory.SUCCESS: {
        const index = draft.stockCategories.stockCategories.findIndex((stockCategory) => {
          return stockCategory.uuid === payload.uuid;
        });

        draft.updateStockCategory.fetching = false;
        draft.updateStockCategory.fetched = true;
        draft.updateStockCategory.reason = payload;
        draft.stockCategories.stockCategories[index] = payload;
        break;
      }

      case updateStockCategory.FAILURE:
        draft.updateStockCategory.error = payload;
        draft.updateStockCategory.fetched = false;
        draft.updateStockCategory.fetching = false;
        break;

      case updateStockCategory.RESET:
        draft.updateStockCategory = { ...initialState.updateStockCategory };
        break;

      case deleteStockCategory.REQUEST:
        draft.deleteStockCategory.fetching = true;
        draft.deleteStockCategory.error = false;
        draft.deleteStockCategory.fetched = false;
        break;

      case deleteStockCategory.SUCCESS: {
        draft.deleteStockCategory.fetching = false;
        draft.deleteStockCategory.fetched = true;
        break;
      }

      case deleteStockCategory.FULFILL: {
        const list = draft.stockCategories.stockCategories.filter((stockCategory) => {
          return stockCategory.uuid !== payload;
        });
        draft.stockCategories.stockCategories = list;
        break;
      }

      case deleteStockCategory.FAILURE:
        draft.deleteStockCategory.error = payload;
        draft.deleteStockCategory.fetching = false;
        draft.deleteStockCategory.fetched = false;
        break;

      case deleteStockCategory.RESET:
        draft.deleteStockCategory = { ...initialState.deleteStockCategory };
        break;

      case getStockCategoriesPagination.REQUEST:
        draft.stockCategoriesPagination = {
          ...initialState.stockCategoriesPagination,
          fetching: true,
        };
        break;

      case getStockCategoriesPagination.SUCCESS: {
        const currentStockCategoriesState = state.stockCategories.stockCategories;

        let newsRows = [...currentStockCategoriesState];
        newsRows = [...newsRows, ...payload.data];

        draft.stockCategoriesPagination.fetching = false;
        draft.stockCategoriesPagination.fetched = true;
        draft.stockCategoriesPagination.lastUpdatedRows = payload.data;

        draft.stockCategories.stockCategories = [...newsRows];
        draft.stockCategories.pagination = {
          ...initialState.stockCategoriesPagination.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getStockCategoriesPagination.FAILURE:
        draft.stockCategoriesPagination.error = payload;
        draft.stockCategoriesPagination.fetching = false;
        draft.stockCategoriesPagination.fetched = false;
        break;

      case getStockCategoriesPagination.RESET:
        draft.stockCategoriesPagination = {
          ...initialState.stockCategoriesPagination,
        };
        break;

      default:
        return draft;
    }
  });

export default stockCategoriesProviderReducer;
