export const EXACT_REQUIRED_TYPE = 'EXACT_VALUE';
export const RANGE_REQUIRED_TYPE = 'VALUE_IN_RANGE';
export const REQUIRED_TYPES = [EXACT_REQUIRED_TYPE, RANGE_REQUIRED_TYPE];
export const SELECTION_RULES = {
  FORCE_PRODUCT_SELECTION: 'FORCE_PRODUCT_SELECTION',
  NOT_RULE_DEFINED: 'NOT_RULE_DEFINED',
  DEFINE_MAX_PRODUCT_SELECTION: 'DEFINE_MAX_PRODUCT_SELECTION',
};

const selectionTypeField = {
  id: 'selectionType',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};
const requiredTypeField = {
  id: 'requiredType',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};
const minField = {
  id: 'min',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};
const maxField = {
  id: 'max',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};
const exactField = {
  id: 'exact',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};
const repeatedField = {
  id: 'repeated',
  label: 'menuMaker:createMenu.fields.startDateField',
  required: true,
  rules: {
    required: 'common:messages.requireField',
  },
};

export const ruleDefinition = {
  selectionType: selectionTypeField,
  requiredType: requiredTypeField,
  min: minField,
  max: maxField,
  exact: exactField,
  repeated: repeatedField,
};
