import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getStockCategories,
  getStockCategoriesPagination,
  getStockCategoryDetails,
  createStockCategory,
  updateStockCategory,
  deleteStockCategory,
} from './actions';
import {
  selectStockCategories,
  selectStockCategoriesPaginationState,
  selectStockCategoriesPaginationInfo,
  selectStockCategoriesState,
  selectStockCategory,
  selectStockCategoryState,
  selectCreateStockCategoryState,
  selectUpdateStockCategoryState,
  selectDeleteStockCategory,
  selectStockCategorySortingState,
} from './selectors';

export function withStockCategoriesHoc(Component) {
  function withStockCategories(props) {
    return <Component {...props} />;
  }

  withStockCategories.displayName = `withStockCategories(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withStockCategories;
}

const mapStateToProps = createStructuredSelector({
  stockCategories: selectStockCategories,
  stockCategoriesState: selectStockCategoriesState,
  stockCategoriesPaginationState: selectStockCategoriesPaginationState,
  stockCategoriesPaginationInfo: selectStockCategoriesPaginationInfo,
  stockCategory: selectStockCategory,
  stockCategoryState: selectStockCategoryState,
  createStockCategoryState: selectCreateStockCategoryState,
  updateStockCategoryState: selectUpdateStockCategoryState,
  deleteStockCategoryState: selectDeleteStockCategory,
  sortingState: selectStockCategorySortingState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadStockCategories: (filters = {}) => dispatch(getStockCategories(filters)),
    loadStockCategoriesPagination: (filters = {}) => dispatch(getStockCategoriesPagination(filters)),
    resetStockCategories: () => dispatch(getStockCategories.reset()),
    loadStockCategoryDetails: (stockCategoryUuid) => dispatch(getStockCategoryDetails(stockCategoryUuid)),
    resetGetStockCategoryDetails: () => dispatch(getStockCategoryDetails.reset()),
    createStockCategory: (fields = {}) => dispatch(createStockCategory(fields)),
    resetCreateStockCategory: () => dispatch(createStockCategory.reset()),
    updateStockCategory: (fields = {}) => dispatch(updateStockCategory(fields)),
    resetUpdateStockCategory: () => dispatch(updateStockCategory.reset()),
    deleteStockCategory: (stockCategoryUuid) => dispatch(deleteStockCategory(stockCategoryUuid)),
    resetDeleteStockCategory: () => dispatch(deleteStockCategory.reset()),
    sortingStockCategories: (sorting = {}) => dispatch(getStockCategories.sorting(sorting)),
    resetSorting: () => dispatch(getStockCategories.resetSorting()),
  };
}

export const withStockCategoriesState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withStockCategoriesState, withStockCategoriesHoc);
