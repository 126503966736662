import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import InputAdornment from '@material-ui/core/InputAdornment';

function InputBase(props) {
  const { isDisabled = false, isFullWidth, suffixAdornment, ...other } = props;

  return (
    <InputAdornmentFiled
      disabled={isDisabled}
      endAdornment={suffixAdornment ? <InputAdornment position="end">{suffixAdornment}</InputAdornment> : null}
      fullWidth={isFullWidth}
      {...other}
    />
  );
}

InputBase.propTypes = {
  ...InputAdornmentFiled.propTypes,
};

export default InputBase;
