import produce from 'immer';

import { getReportsFilters } from './actions';

export const initialState = {
  getFilters: {
    error: null,
    fetching: false,
    fetched: false,
    brands: [],
    providers: [],
    orderTypes: [],
    categories: [],
    orderStates: {},
    paymentTypes: [],
    users: [],
  },
};

const reportsFiltersProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getReportsFilters.REQUEST:
        draft.getFilters = { ...initialState.getFilters, fetching: true };
        break;

      case getReportsFilters.SUCCESS: {
        draft.getFilters.fetching = false;
        draft.getFilters.fetched = true;
        draft.getFilters.dates = payload?.dates;
        draft.getFilters.brands = payload?.brands;
        draft.getFilters.providers = payload?.providers;
        draft.getFilters.orderTypes = payload?.orderTypes;
        draft.getFilters.categories = payload?.categories;

        draft.getFilters.paymentTypes = payload?.paymentTypes;
        draft.getFilters.users = payload?.users;

        const orderStatesTmp = payload?.orderStates;
        delete orderStatesTmp.voided;
        draft.getFilters.orderStates = orderStatesTmp;
        break;
      }

      case getReportsFilters.FAILED:
        draft.getFilters.error = payload;
        draft.getFilters.fetching = false;
        draft.getFilters.fetched = false;
        break;

      case getReportsFilters.RESET:
        draft.getFilters = { ...initialState.getFilters };
        break;

      default:
        return draft;
    }
  });

export default reportsFiltersProviderReducer;
