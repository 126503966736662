import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectProductDomain = (state) => state.product || initialState;

const selectProduct = createSelector(selectProductDomain, (subState) => subState.getProduct.product);
const selectProductFetching = createSelector(selectProductDomain, (subState) => subState.getProduct.fetching);
const selectProductFetched = createSelector(selectProductDomain, (subState) => subState.getProduct.fetched);
const selectProductError = createSelector(selectProductDomain, (subState) => subState.getProduct.error);

const selectProductEdit = createSelector(selectProductDomain, (subState) => subState.editProduct.product);
const selectProductEditFetching = createSelector(selectProductDomain, (subState) => subState.editProduct.fetching);
const selectProductEditFetched = createSelector(selectProductDomain, (subState) => subState.editProduct.fetched);
const selectProductEditError = createSelector(selectProductDomain, (subState) => subState.editProduct.error);

const selectUpdateProductPriceFetching = createSelector(
  selectProductDomain,
  (subState) => subState.changeProductPrice.fetching,
);

const selectUpdateProductPriceFetched = createSelector(
  selectProductDomain,
  (subState) => subState.changeProductPrice.fetched,
);

const selectUpdateProductPriceError = createSelector(
  selectProductDomain,
  (subState) => subState.changeProductPrice.error,
);

export {
  selectProductDomain,
  selectProduct,
  selectProductFetching,
  selectProductFetched,
  selectProductError,
  selectProductEdit,
  selectProductEditFetching,
  selectProductEditFetched,
  selectProductEditError,
  selectUpdateProductPriceFetching,
  selectUpdateProductPriceFetched,
  selectUpdateProductPriceError,
};
