import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ChevronRight = forwardRef(function ChevronRight(props, ref) {
  const { color = '#fff', size = 13, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M4.293 1.293a1 1 0 000 1.414L9.586 8l-5.293 5.293a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

ChevronRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ChevronRight;
