import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import useViewPanel from 'hooks/useViewPanel';

function Header({ children, ...others }) {
  const theme = useTheme();
  const { open } = useViewPanel();

  if (open) {
    return null;
  }

  return (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        <Toolbar />
      </Hidden>

      <Box
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.darkGrey[5]}`}
        component="header"
        display="flex"
        height="100%"
        marginBottom={theme.typography.pxToRem(48)}
        maxHeight={theme.typography.pxToRem(120)}
        minHeight={theme.typography.pxToRem(120)}
        {...others}
      >
        {children}
      </Box>
    </>
  );
}

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
