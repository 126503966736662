import { Children } from 'react';
import PropTypes from 'prop-types';

import PayIn from 'providers/settings/PayIn';
import AdminTables from 'providers/settings/AdminTables';
import ServiceAreas from 'providers/settings/ServiceAreas';
import TablesConfig from 'providers/settings/TablesConfig';
import KitchenTicket from 'providers/settings/KitchenTicket';
import ServiceCharges from 'providers/settings/ServiceCharges';
import SettingsStore from 'providers/settings/SettingsStore';
import Discounts from 'providers/settings/Discounts';
import Payments from 'providers/settings/Payments';
import DiningOptions from 'providers/settings/DiningOptions';
import VoidReasons from 'providers/settings/VoidReasons';
import ReceiptCustomization from 'providers/settings/ReceiptCustomization';
import KitchenAreas from 'providers/settings/KitchenAreas';
import SettingsMultiTaxesProvider from 'providers/SettingsMultiTaxesProvider';

import ComposeProviders from 'components/ComposeProviders';

function SettingsRootProvider({ children }) {
  return (
    <ComposeProviders
      providers={[
        <PayIn />,
        <AdminTables />,
        <ServiceAreas />,
        <TablesConfig />,
        <KitchenTicket />,
        <ServiceCharges />,
        <SettingsStore />,
        <Discounts />,
        <Payments />,
        <DiningOptions />,
        <VoidReasons />,
        <ReceiptCustomization />,
        <KitchenAreas />,
        <SettingsMultiTaxesProvider />,
      ]}
    >
      {Children.only(children)}
    </ComposeProviders>
  );
}

SettingsRootProvider.propTypes = {
  children: PropTypes.element,
};

export default SettingsRootProvider;
