import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Route, useHistory, useParams } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';

import useNotification from '@design-system/Notification/useNotification';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';
import { withModifierGroupDetails } from 'providers/menuMaker/ModifierGroupDetails';
import { useListsOptions } from 'providers/root/ListsOptionsProvider';

import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import ModifierGroupsTable from 'components/ModifierGroupsTable';
import Layout, { ContentLayout } from 'Layout';

import PageError from 'components/PageError';
import HeaderForm from 'components/HeaderForm';
import ModifierGroupForm from 'components/ModifierGroupForm';

import useUserStore from 'hooks/useUserStore';
import useViewPanel from 'hooks/useViewPanel';
import { actions } from 'utils/menuMaker';
import useSortingListsOptions from 'hooks/useSortingListsOptions';

export function ModifierGroups({
  // Get Modifier Groups
  loadModifierGroups,
  modifierGroupsList,
  modifierGroupsFetching,
  modifierGroupsFetched,
  modifierGroupsError,
  modifierGroupPagination,
  paginationModifierGroupState,
  paginateModifierGroups,
  // Delete Modifier Group
  deleteModifierGroup,
  resetDeleteModifierGroup,
  deleteModifierGroupFetching,
  deleteModifierGroupFetched,
  // Duplicate Modifier Group
  duplicateModifierGroup,
  resetDuplicateModifierGroup,
  duplicateModifierGroupFetching,
  duplicateModifierGroupFetched,
  // Modifier Group create
  createModifierGroup,
  createModifierGroupError,
  createModifierGroupFetching,
  createModifierGroupFetched,
  resetCreateModifierGroup,
  // edit
  resetEditModifierGroup,
  editModifierGroupFetched,
  t,
}) {
  const { storeId } = useUserStore();
  const history = useHistory();
  const { actionType } = useParams();
  const { setOpen: setOpenViewPanel } = useViewPanel();
  const { closeAllSnackbar, setNotification } = useNotification();

  const { getListProductsAutocomplete, productsAutocomplete, productsAutocompleteState } = useListsOptions();
  const {
    getProductsAutocompleteFilter: { productsAutocompleteFilterValues },
  } = useSortingListsOptions();
  const [modifierGroupToDuplicate, setModifierGroupToDuplicate] = useState(null);
  const [deleteModifierGroupName, setDeleteModifierGroupName] = useState(null);
  const [modifierGroupSearched, setModifierGroupSearched] = useState(null);

  const isFinishedLoading = productsAutocompleteState?.fetched;

  useEffect(() => {
    loadModifierGroups({ storeUuid: storeId });
  }, [loadModifierGroups, storeId]);

  useEffect(() => {
    if (actionType === 'create') {
      getListProductsAutocomplete({ ...productsAutocompleteFilterValues });
      setOpenViewPanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, getListProductsAutocomplete, setOpenViewPanel]);

  useEffect(() => {
    if (deleteModifierGroupFetched) {
      loadModifierGroups({ storeUuid: storeId, name: modifierGroupSearched });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteModifierGroupFetched]);

  useEffect(() => {
    if (duplicateModifierGroupFetched) {
      loadModifierGroups({ storeUuid: storeId });
    }
  }, [loadModifierGroups, duplicateModifierGroupFetched, storeId]);

  useEffect(() => {
    if (createModifierGroupFetched) {
      setOpenViewPanel(false);
      history.push({
        pathname: '/menus/modifier-groups',
      });
      resetCreateModifierGroup();
    }
  }, [createModifierGroupFetched, history, setOpenViewPanel, resetCreateModifierGroup]);

  useEffect(() => {
    if (createModifierGroupFetched) {
      setNotification(t('menuMaker:modifierGroupForm.messages.saveSuccess'), { variant: 'success' });
    }

    if (createModifierGroupError) {
      setNotification(t('menuMaker:modifierGroupForm.messages.saveError'), { variant: 'error' });
    }
  }, [createModifierGroupFetched, createModifierGroupError, setNotification, t]);

  useEffect(() => {
    if (deleteModifierGroupFetched) {
      setNotification(
        t('menuMaker:modifierGroupsTable.snacks.deletedModifierGroup.title', {
          modifierGroup: deleteModifierGroupName,
        }),
        { variant: 'success' },
      );
    }
  }, [deleteModifierGroupFetched, deleteModifierGroupName, setNotification, t]);

  useEffect(() => {
    if (duplicateModifierGroupFetched) {
      setNotification(
        t('menuMaker:modifierGroupsTable.snacks.duplicatedModifierGroup.title', {
          modifierGroup: modifierGroupToDuplicate?.name,
        }),
        { variant: 'success' },
      );
    }
  }, [duplicateModifierGroupFetched, modifierGroupToDuplicate?.name, setNotification, t]);

  useEffect(() => {
    if (editModifierGroupFetched) {
      setNotification(t('menuMaker:modifierGroupForm.messages.saveSuccess'), { variant: 'success' });
    }
  }, [editModifierGroupFetched, setNotification, t]);

  useEffect(() => {
    return () => {
      resetDeleteModifierGroup();
      resetDuplicateModifierGroup();
      resetCreateModifierGroup();
      resetEditModifierGroup();
      closeAllSnackbar();
    };
  }, [
    resetDeleteModifierGroup,
    resetDuplicateModifierGroup,
    resetCreateModifierGroup,
    resetEditModifierGroup,
    closeAllSnackbar,
  ]);

  const handleSearchByTextModifierGroups = () => (value) => {
    const { filterByText } = value;

    if (filterByText?.length >= 3) {
      setModifierGroupSearched(filterByText);
      loadModifierGroups({ storeUuid: storeId, name: filterByText });
    }

    if (!filterByText) {
      setModifierGroupSearched(null);
      loadModifierGroups({ storeUuid: storeId, name: null });
    }
  };

  function handleDeleteModifierGroup(storeUuid, modifierGroup) {
    setDeleteModifierGroupName(modifierGroup.name);
    deleteModifierGroup({ storeUuid, modifierGroupUuid: modifierGroup.uuid });
  }

  function handleDuplicateModifierGroup(storeUuid, modifierGroup) {
    setModifierGroupToDuplicate(modifierGroup);
    duplicateModifierGroup({ storeUuid, modifierGroupUuid: modifierGroup.uuid });
  }

  function handleCreateModifierGroup(modifierGroup) {
    createModifierGroup(storeId, modifierGroup);
  }

  function onAddButtonClick() {
    history.push(`/menus/modifier-groups/create`);
  }

  const handlePaginateModifierGroups = () => {
    paginateModifierGroups({
      storeUuid: storeId,
      page: modifierGroupPagination?.page + 1,
    });
  };

  return (
    <Layout>
      <Route exact path="/menus/modifier-groups">
        <ContentLayout>
          <ContentLayout.Header>
            <MenuMakerTabsNavigation />
          </ContentLayout.Header>

          <ContentLayout.Container px="0">
            <HeaderForm
              addButtonLabel={t('menuMaker:modifierGroupsTable.buttons.newModifierGroup')}
              onAddButtonClick={onAddButtonClick}
              onSearchBoxChange={handleSearchByTextModifierGroups()}
              px={4}
              searchLabel={t('menuMaker:modifierGroupsTable.formHeader.searchModifierGroup')}
            />

            {modifierGroupsError && (
              <PageError
                id="modifierGroupsError"
                labelAction={t('common:buttons.retry')}
                message={t('common:errors.loaded.modifierGroups')}
                onAction={() => loadModifierGroups({ storeUuid: storeId, name: modifierGroupSearched })}
              />
            )}

            {!modifierGroupsError && (
              <ModifierGroupsTable
                isSearchFilter={modifierGroupSearched?.length > 0}
                loading={modifierGroupsFetching || deleteModifierGroupFetching || duplicateModifierGroupFetching}
                modifierGroupPagination={modifierGroupPagination}
                modifierGroups={modifierGroupsList}
                modifierGroupsFetched={modifierGroupsFetched}
                onDeleteModifierGroup={handleDeleteModifierGroup}
                onDuplicateModifierGroup={handleDuplicateModifierGroup}
                onPaginate={handlePaginateModifierGroups}
                paginationModifierGroupState={paginationModifierGroupState}
              />
            )}
          </ContentLayout.Container>
        </ContentLayout>
      </Route>

      <Route exact path="/menus/modifier-groups/create">
        {!isFinishedLoading && <LinearProgress />}
        {isFinishedLoading && (
          <ModifierGroupForm
            action={actions.ADD}
            error={Boolean(createModifierGroupError)}
            loading={createModifierGroupFetching}
            modifierGroup={{}}
            products={productsAutocomplete}
            saveModifierGroup={handleCreateModifierGroup}
          />
        )}
      </Route>
    </Layout>
  );
}

ModifierGroups.propTypes = {
  // Get Modifier Groups
  loadModifierGroups: PropTypes.func,
  modifierGroupsList: PropTypes.array,
  modifierGroupsFetching: PropTypes.bool,
  modifierGroupsFetched: PropTypes.bool,
  modifierGroupsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  modifierGroupPagination: PropTypes.object,
  paginationModifierGroupState: PropTypes.object,
  paginateModifierGroups: PropTypes.func,
  // Delete Modifier Group
  deleteModifierGroup: PropTypes.func,
  resetDeleteModifierGroup: PropTypes.func,
  deleteModifierGroupFetching: PropTypes.bool,
  deleteModifierGroupFetched: PropTypes.bool,
  // Duplicate Modifier Group
  duplicateModifierGroup: PropTypes.func,
  resetDuplicateModifierGroup: PropTypes.func,
  duplicateModifierGroupFetching: PropTypes.bool,
  duplicateModifierGroupFetched: PropTypes.bool,
  // Modifier Group Save
  createModifierGroup: PropTypes.func,
  createModifierGroupError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createModifierGroupFetching: PropTypes.bool,
  createModifierGroupFetched: PropTypes.bool,
  resetCreateModifierGroup: PropTypes.func,
  // edit
  resetEditModifierGroup: PropTypes.func,
  editModifierGroupFetched: PropTypes.bool,
  t: PropTypes.func,
};

export default compose(
  memo,
  withRequiredLicense(),
  withModifierGroups,
  withModifierGroupDetails,
  withTranslation('menuMaker'),
)(ModifierGroups);
