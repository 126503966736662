import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { cleanObject } from 'utils/objects';
// import { pageSizeTypes } from 'utils/pagination'; // it's temp

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getStorageAreasRequest(filters, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    ...filters,
    store_uuid: storeUuid,
    // page_size: pageSizeTypes.PAGE_SIZE_DEFAULT, // it's temp
  };

  const query = queryString.stringify(cleanObject(params));

  return request(`${API_BASE_URL}/v1/storage-areas/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getStorageAreaDetailsRequest(storageAreaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/storage-areas/${storageAreaUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createStorageAreaRequest({ name }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/storage-areas/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
    ...options,
  });
}

export function deleteStorageAreaRequest(storageAreaUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/storage-areas/${storageAreaUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function updateStorageAreaRequest({ uuid, name, isDefault, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    name,
    isDefault,
    isEnabled,
  };
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/storage-areas/${uuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(cleanObject(params)),
    ...options,
  });
}
