import { useContext } from 'react';

import SidebarLayoutContext from './SidebarLayoutContext';

function useSidebarLayout() {
  const { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer } = useContext(SidebarLayoutContext);

  return { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer };
}

export default useSidebarLayout;
