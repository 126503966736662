import produce from 'immer';

import { DEFAULT_PAGE } from 'utils/menuMaker';

import {
  getMenus,
  getPaginationMenu,
  createMenu,
  publishMenu,
  duplicateMenu,
  deleteMenu,
  copyMenuConfig,
  copyMenuBrands,
  publishMenus,
} from './actions';

export const initialState = {
  getMenus: {
    error: null,
    fetching: false,
    fetched: false,
    list: [],
    pagination: { page: DEFAULT_PAGE },
  },

  paginationMenu: {
    fetching: false,
    fetched: false,
    error: null,
  },

  createMenu: {
    error: null,
    fetching: false,
    fetched: false,
    menu: {},
  },

  publishMenu: {
    error: null,
    fetching: false,
    fetched: false,
  },

  duplicateMenu: {
    error: null,
    fetching: false,
    fetched: false,
  },

  deleteMenu: {
    error: null,
    fetching: false,
    fetched: false,
  },

  copyMenuConfig: {
    error: null,
    fetching: false,
    fetched: false,
    menu: {},
    stores: [],
  },

  copyMenuBrands: {
    error: null,
    fetching: false,
    fetched: false,
  },

  publishMenus: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const menusReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getMenus.REQUEST:
        draft.getMenus.fetching = true;
        draft.getMenus.error = false;
        draft.getMenus.fetched = false;
        draft.getMenus.pagination = { ...initialState.getMenus.pagination };
        break;

      case getMenus.SUCCESS:
        draft.getMenus.fetching = false;
        draft.getMenus.fetched = true;
        draft.getMenus.list = payload.data;
        draft.getMenus.pagination = { ...draft.getMenus.pagination, ...payload.pagination };
        break;

      case getMenus.FAILURE:
        draft.getMenus.error = payload;
        draft.getMenus.fetching = false;
        draft.getMenus.fetched = false;
        break;

      case getMenus.RESET:
        draft.getMenus = { ...initialState.getMenus };
        break;

      case getPaginationMenu.REQUEST:
        draft.paginationMenu.fetching = true;
        draft.paginationMenu.fetched = false;
        draft.paginationMenu.error = null;
        break;

      case getPaginationMenu.SUCCESS: {
        const newMenus = [...draft.getMenus.list, ...payload.data];

        draft.paginationMenu.fetching = false;
        draft.paginationMenu.fetched = true;
        draft.paginationMenu.error = null;
        draft.getMenus.list = newMenus;
        draft.getMenus.pagination = { ...draft.getMenus.pagination, ...payload.pagination, page: payload.page };
        break;
      }

      case getPaginationMenu.FAILURE:
        draft.paginationMenu.error = payload;
        draft.paginationMenu.fetching = false;
        draft.paginationMenu.fetched = false;
        break;

      case getPaginationMenu.RESET:
        draft.paginationMenu = { ...initialState.paginationMenu };
        break;

      case createMenu.REQUEST:
        draft.createMenu.fetching = true;
        draft.createMenu.error = false;
        draft.createMenu.fetched = false;
        break;

      case createMenu.SUCCESS: {
        const menusList = [...draft.getMenus.list];
        menusList.unshift(payload);

        draft.createMenu.fetching = false;
        draft.createMenu.fetched = true;
        draft.createMenu.menu = payload;
        draft.getMenus.list = menusList;

        break;
      }

      case createMenu.FAILURE:
        draft.createMenu.error = payload;
        draft.createMenu.fetching = false;
        draft.createMenu.fetched = false;
        break;

      case createMenu.RESET:
        draft.createMenu = { ...initialState.createMenu };
        break;

      case publishMenu.REQUEST:
        draft.publishMenu.fetching = true;
        draft.publishMenu.error = false;
        draft.publishMenu.fetched = false;
        break;

      case publishMenu.SUCCESS: {
        draft.publishMenu.fetching = false;
        draft.publishMenu.fetched = true;
        break;
      }

      case publishMenu.FAILURE:
        draft.publishMenu.error = payload;
        draft.publishMenu.fetching = false;
        draft.publishMenu.fetched = false;
        break;

      case publishMenu.RESET:
        draft.publishMenu = { ...initialState.publishMenu };
        break;

      case duplicateMenu.REQUEST:
        draft.duplicateMenu.fetching = true;
        draft.duplicateMenu.error = false;
        draft.duplicateMenu.fetched = false;
        break;

      case duplicateMenu.SUCCESS: {
        draft.duplicateMenu.fetching = false;
        draft.duplicateMenu.fetched = true;
        break;
      }

      case duplicateMenu.FAILURE:
        draft.duplicateMenu.error = payload;
        draft.duplicateMenu.fetching = false;
        draft.duplicateMenu.fetched = false;
        break;

      case duplicateMenu.RESET:
        draft.duplicateMenu = { ...initialState.duplicateMenu };
        break;

      case deleteMenu.REQUEST:
        draft.deleteMenu.fetching = true;
        draft.deleteMenu.error = false;
        draft.deleteMenu.fetched = false;
        break;

      case deleteMenu.SUCCESS: {
        draft.deleteMenu.fetching = false;
        draft.deleteMenu.fetched = true;
        break;
      }

      case deleteMenu.FULFILL: {
        if (!draft.deleteMenu.error) {
          const menusList = draft.getMenus.list.filter((menu) => menu.uuid !== payload);
          draft.getMenus.list = menusList;
        }
        break;
      }

      case deleteMenu.FAILURE:
        draft.deleteMenu.error = payload;
        draft.deleteMenu.fetching = false;
        draft.deleteMenu.fetched = false;
        break;

      case deleteMenu.RESET:
        draft.deleteMenu = { ...initialState.deleteMenu };
        break;

      case copyMenuConfig.REQUEST:
        draft.copyMenuConfig.fetching = true;
        break;

      case copyMenuConfig.SUCCESS:
        draft.copyMenuConfig.fetching = false;
        draft.copyMenuConfig.fetched = true;
        draft.copyMenuConfig.menu = payload.menu;
        draft.copyMenuConfig.stores = payload.stores;
        break;

      case copyMenuConfig.FAILURE:
        draft.copyMenuConfig.error = payload;
        draft.copyMenuConfig.fetching = false;
        draft.copyMenuConfig.fetched = false;
        break;

      case copyMenuConfig.RESET:
        draft.copyMenuConfig = { ...initialState.copyMenuConfig };
        break;

      case copyMenuBrands.REQUEST:
        draft.copyMenuBrands.fetching = true;
        break;

      case copyMenuBrands.SUCCESS:
        draft.copyMenuBrands.fetching = false;
        draft.copyMenuBrands.fetched = true;
        break;

      case copyMenuBrands.FAILURE:
        draft.copyMenuBrands.error = payload;
        draft.copyMenuBrands.fetching = false;
        draft.copyMenuBrands.fetched = false;
        break;

      case copyMenuBrands.RESET:
        draft.copyMenuBrands = { ...initialState.copyMenuBrands };
        break;

      case publishMenus.REQUEST:
        draft.publishMenus.fetching = true;
        break;

      case publishMenus.SUCCESS: {
        draft.publishMenus.fetching = false;
        draft.publishMenus.fetched = true;
        break;
      }

      case publishMenus.FAILURE:
        draft.publishMenus.error = payload;
        draft.publishMenus.fetching = false;
        draft.publishMenus.fetched = false;
        break;

      case publishMenus.RESET:
        draft.publishMenus = { ...initialState.publishMenus };
        break;

      default:
        return draft;
    }
  });

export default menusReducer;
