import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ButtonAlert from '@design-system/ButtonAlert';
import ButtonOutlined from '@design-system/ButtonOutlined';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.typography.pxToRem(30),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.typography.pxToRem(352),
    width: '100%',
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
  },
}));

function ConfirmDialog({
  isDisabled,
  anchorEl,
  id,
  labelAccept,
  labelCancel,
  loading,
  message,
  onAccept,
  onCancel,
  open,
  title,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      classes={{ paper: classes.paper }}
      id={id}
      open={open}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Box>
        <Box align="center" component="h6" fontSize={18} fontWeight="fontWeightMedium" m={0}>
          {title}
        </Box>
      </Box>

      <Box mt={2}>
        <Box align="center" color="text.secondary" component="p" fontSize={14} fontWeight="fontWeightRegular" m={0}>
          {message}
        </Box>

        <Box
          alignItems="center"
          display="flex"
          mt={4}
          sx={{
            '& > :not(:first-child)': {
              marginLeft: theme.spacing(3),
            },
          }}
        >
          <ButtonOutlined disabled={isDisabled} onClick={onCancel}>
            {labelCancel}
          </ButtonOutlined>

          <ButtonAlert disabled={isDisabled} loading={loading} onClick={onAccept}>
            {labelAccept}
          </ButtonAlert>
        </Box>
      </Box>
    </Popover>
  );
}

ConfirmDialog.propTypes = {
  anchorEl: PropTypes.object,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  labelCancel: PropTypes.string,
  onAccept: PropTypes.func,
  labelAccept: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmDialog;
