/**
 *
 * ParrotSoftware
 * OnlineOrderingProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import {
  getOnlineOrdering,
  getOnlineOrderingPagination,
  getBrandSettings,
  updateCanOrdering,
  updateBrandSettings,
  getBrandContactInformation,
  updateContactInformation,
  getOperationsSettings,
  updateOperationsSettings,
  getDeliveryZones,
  updateDeliveryZones,
  validateDirectionUrl,
  getResumesMenus,
  saveOnboarding,
  createDeliveryZones,
  deleteDeliveryZones,
  publishBrand,
} from './actions';

export const initialState = {
  onlineOrdering: {
    ...baseState,
    list: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },
  onlineOrderingPagination: {
    ...baseState,
    lastUpdatedRows: [],
  },
  canOrdering: {
    ...baseState,
  },
  brandSettings: {
    ...baseState,
    data: {},
  },
  updateBrandSettings: {
    ...baseState,
  },

  brandContactInformation: {
    ...baseState,
    contact: null,
  },
  updateContactInformation: {
    ...baseState,
  },
  operationsSettings: {
    ...baseState,
    data: {},
  },
  updateOperationsSettings: {
    ...baseState,
  },
  deliveryZones: {
    ...baseState,
    list: [],
    uuidBrand: '',
  },
  updateDeliveryZones: {
    ...baseState,
    data: {},
  },
  createDeliveryZones: {
    ...baseState,
  },
  deleteDeliveryZones: {
    ...baseState,
  },

  validateDirectionUrl: {
    ...baseState,
    slugRegistered: false,
  },

  getResumesMenus: {
    ...baseState,
    menusResume: [],
  },

  saveOnboarding: {
    ...baseState,
    brand: {},
  },

  publishBrand: {
    ...baseState,
  },
};

const onlineOrderingReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getOnlineOrdering.REQUEST:
        draft.onlineOrdering = {
          ...initialState.onlineOrdering,
          fetching: true,
        };
        break;

      case getOnlineOrdering.SUCCESS:
        draft.onlineOrdering.fetching = false;
        draft.onlineOrdering.fetched = true;
        draft.onlineOrdering.list = payload.data;
        draft.onlineOrdering.pagination = {
          ...initialState.onlineOrdering.pagination,
          ...payload.pagination,
        };
        break;

      case getOnlineOrdering.FAILURE:
        draft.onlineOrdering.error = payload;
        draft.onlineOrdering.fetching = false;
        draft.onlineOrdering.fetched = false;
        break;

      case getOnlineOrdering.RESET:
        draft.onlineOrdering = {
          ...initialState.onlineOrdering,
        };
        break;

      case getOnlineOrderingPagination.REQUEST:
        draft.onlineOrderingPagination = {
          ...initialState.onlineOrderingPagination,
          fetching: true,
        };
        break;

      case getOnlineOrderingPagination.SUCCESS: {
        const currentListAreaState = state.onlineOrdering.list;

        let newsRows = [...currentListAreaState];
        newsRows = [...newsRows, ...payload.data];

        draft.onlineOrderingPagination.fetching = false;
        draft.onlineOrderingPagination.fetched = true;
        draft.onlineOrderingPagination.lastUpdatedRows = payload.data;

        draft.onlineOrdering.list = [...newsRows];
        draft.onlineOrdering.pagination = {
          ...initialState.onlineOrderingPagination.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getOnlineOrderingPagination.FAILURE:
        draft.onlineOrderingPagination.error = payload;
        draft.onlineOrderingPagination.fetching = false;
        draft.onlineOrderingPagination.fetched = false;
        break;

      case getOnlineOrderingPagination.RESET:
        draft.onlineOrderingPagination = {
          ...initialState.onlineOrderingPagination,
        };
        break;

      case updateCanOrdering.REQUEST:
        draft.canOrdering.fetching = true;
        draft.canOrdering.fetched = false;
        draft.canOrdering.error = false;
        break;

      case updateCanOrdering.SUCCESS: {
        const index = draft.onlineOrdering.list.findIndex((item) => {
          return item.uuid === payload?.brand.uuid;
        });

        const item = draft.onlineOrdering.list[index];
        item.onlineConfig.canOrdering = payload.canOrdering;
        draft.canOrdering.fetching = false;
        draft.canOrdering.fetched = true;
        draft.onlineOrdering.list[index] = item;
        break;
      }

      case updateCanOrdering.FAILURE:
        draft.canOrdering.error = payload;
        draft.canOrdering.fetched = false;
        draft.canOrdering.fetching = false;
        break;

      case updateCanOrdering.RESET:
        draft.canOrdering = { ...initialState.canOrdering };
        break;

      case getBrandSettings.REQUEST:
        draft.brandSettings.fetching = true;
        draft.brandSettings.fetched = false;
        draft.brandSettings.error = false;
        break;

      case getBrandSettings.SUCCESS: {
        draft.brandSettings.fetching = false;
        draft.brandSettings.fetched = true;
        draft.brandSettings.data = payload;
        break;
      }

      case getBrandSettings.FAILURE:
        draft.brandSettings.error = payload;
        draft.brandSettings.fetched = false;
        draft.brandSettings.fetching = false;
        break;

      case getBrandSettings.RESET:
        draft.brandSettings = { ...initialState.brandSettings };
        break;

      case updateBrandSettings.REQUEST:
        draft.updateBrandSettings.fetching = true;
        draft.updateBrandSettings.fetched = false;
        draft.updateBrandSettings.error = false;
        break;

      case updateBrandSettings.SUCCESS: {
        draft.updateBrandSettings.fetching = false;
        draft.updateBrandSettings.fetched = true;
        draft.brandSettings.data = payload;
        break;
      }

      case updateBrandSettings.FAILURE:
        draft.updateBrandSettings.error = payload;
        draft.updateBrandSettings.fetched = false;
        draft.updateBrandSettings.fetching = false;
        break;

      case updateBrandSettings.RESET:
        draft.updateBrandSettings = { ...initialState.updateBrandSettings };
        break;

      case getBrandContactInformation.REQUEST:
        draft.brandContactInformation.fetching = true;
        draft.brandContactInformation.fetched = false;
        draft.brandContactInformation.error = false;
        break;

      case getBrandContactInformation.SUCCESS: {
        draft.brandContactInformation.fetching = false;
        draft.brandContactInformation.fetched = true;
        draft.brandContactInformation.contact = payload;
        break;
      }

      case getBrandContactInformation.FAILURE:
        draft.brandContactInformation.error = payload;
        draft.brandContactInformation.fetched = false;
        draft.brandContactInformation.fetching = false;
        break;

      case getBrandContactInformation.RESET:
        draft.brandContactInformation = { ...initialState.brandContactInformation };
        break;

      case updateContactInformation.REQUEST:
        draft.updateContactInformation.fetching = true;
        draft.updateContactInformation.fetched = false;
        draft.updateContactInformation.error = false;
        break;

      case updateContactInformation.SUCCESS: {
        draft.updateContactInformation.fetching = false;
        draft.updateContactInformation.fetched = true;
        draft.brandContactInformation.data = payload;
        break;
      }

      case updateContactInformation.FAILURE:
        draft.updateContactInformation.error = payload;
        draft.updateContactInformation.fetched = false;
        draft.updateContactInformation.fetching = false;
        break;

      case updateContactInformation.RESET:
        draft.updateContactInformation = { ...initialState.updateContactInformation };
        break;

      case getOperationsSettings.REQUEST:
        draft.operationsSettings.fetching = true;
        draft.operationsSettings.fetched = false;
        draft.operationsSettings.error = false;
        break;

      case getOperationsSettings.SUCCESS: {
        draft.operationsSettings.fetching = false;
        draft.operationsSettings.fetched = true;
        draft.operationsSettings.data = payload;
        break;
      }

      case getOperationsSettings.FAILURE:
        draft.operationsSettings.error = payload;
        draft.operationsSettings.fetched = false;
        draft.operationsSettings.fetching = false;
        break;

      case getOperationsSettings.RESET:
        draft.operationsSettings = { ...initialState.operationsSettings };
        break;

      case updateOperationsSettings.REQUEST:
        draft.updateOperationsSettings.fetching = true;
        draft.updateOperationsSettings.fetched = false;
        draft.updateOperationsSettings.error = false;
        break;

      case updateOperationsSettings.SUCCESS: {
        draft.updateOperationsSettings.fetching = false;
        draft.updateOperationsSettings.fetched = true;
        draft.operationsSettings.data = payload;
        break;
      }

      case updateOperationsSettings.FAILURE:
        draft.updateOperationsSettings.error = payload;
        draft.updateOperationsSettings.fetched = false;
        draft.updateOperationsSettings.fetching = false;
        break;

      case updateOperationsSettings.RESET:
        draft.updateOperationsSettings = { ...initialState.updateOperationsSettings };
        break;

      case getDeliveryZones.REQUEST:
        draft.deliveryZones.fetching = true;
        draft.deliveryZones.fetched = false;
        draft.deliveryZones.error = false;
        break;

      case getDeliveryZones.SUCCESS: {
        draft.deliveryZones.fetching = false;
        draft.deliveryZones.fetched = true;
        draft.deliveryZones.list = payload.list;
        draft.deliveryZones.uuidBrand = payload.uuidBrand;
        break;
      }

      case getDeliveryZones.FAILURE:
        draft.deliveryZones.error = payload;
        draft.deliveryZones.fetched = false;
        draft.deliveryZones.fetching = false;
        break;

      case getDeliveryZones.RESET:
        draft.deliveryZones = { ...initialState.deliveryZones };
        break;

      case updateDeliveryZones.REQUEST:
        draft.updateDeliveryZones.fetching = true;
        draft.updateDeliveryZones.fetched = false;
        draft.updateDeliveryZones.error = false;
        break;

      case updateDeliveryZones.SUCCESS: {
        const index = draft.deliveryZones.list.findIndex((deliveryZone) => {
          return deliveryZone.uuid === payload.uuid;
        });
        draft.updateDeliveryZones.fetching = false;
        draft.updateDeliveryZones.fetched = true;
        draft.deliveryZones.list[index] = payload;
        break;
      }

      case updateDeliveryZones.FAILURE:
        draft.updateDeliveryZones.error = payload;
        draft.updateDeliveryZones.fetched = false;
        draft.updateDeliveryZones.fetching = false;
        break;

      case updateDeliveryZones.RESET:
        draft.updateDeliveryZones = { ...initialState.updateDeliveryZones };
        break;

      case createDeliveryZones.SUCCESS: {
        const list = [...draft.deliveryZones.list];
        list.push(payload.data);
        draft.createDeliveryZones.fetching = false;
        draft.createDeliveryZones.fetched = true;
        draft.deliveryZones.list = list;
        break;
      }

      case createDeliveryZones.FAILURE:
        draft.createDeliveryZones.error = payload;
        draft.createDeliveryZones.fetched = false;
        draft.createDeliveryZones.fetching = false;
        break;

      case createDeliveryZones.RESET:
        draft.createDeliveryZones = { ...initialState.createDeliveryZones };
        break;

      case deleteDeliveryZones.REQUEST:
        draft.deleteDeliveryZones.fetching = true;
        draft.deleteDeliveryZones.error = false;
        draft.deleteDeliveryZones.fetched = false;
        break;

      case deleteDeliveryZones.SUCCESS: {
        draft.deleteDeliveryZones.fetching = false;
        draft.deleteDeliveryZones.fetched = true;
        break;
      }

      case deleteDeliveryZones.FULFILL: {
        const list = draft.deliveryZones.list.filter((deliveryZone) => {
          return deliveryZone.uuid !== payload;
        });
        draft.deliveryZones.list = list;
        break;
      }

      case deleteDeliveryZones.FAILURE:
        draft.deleteDeliveryZones.error = payload;
        draft.deleteDeliveryZones.fetching = false;
        draft.deleteDeliveryZones.fetched = false;
        break;

      case deleteDeliveryZones.RESET:
        draft.deleteDeliveryZones = { ...initialState.deleteDeliveryZones };
        break;

      case validateDirectionUrl.REQUEST:
        draft.validateDirectionUrl = {
          ...initialState.validateDirectionUrl,
          fetching: true,
        };
        break;

      case validateDirectionUrl.SUCCESS: {
        draft.validateDirectionUrl.fetching = false;
        draft.validateDirectionUrl.fetched = true;
        draft.validateDirectionUrl.slugRegistered = payload.slugRegistered;
        break;
      }

      case validateDirectionUrl.FAILURE:
        draft.validateDirectionUrl.error = payload;
        draft.validateDirectionUrl.fetched = false;
        draft.validateDirectionUrl.fetching = false;
        break;

      case validateDirectionUrl.RESET:
        draft.validateDirectionUrl = { ...initialState.validateDirectionUrl };
        break;

      case getResumesMenus.REQUEST:
        draft.getResumesMenus = {
          ...initialState.getResumesMenus,
          fetching: true,
        };
        break;

      case getResumesMenus.SUCCESS: {
        draft.getResumesMenus.fetching = false;
        draft.getResumesMenus.fetched = true;
        draft.getResumesMenus.menusResume = payload;
        break;
      }

      case getResumesMenus.FAILURE:
        draft.getResumesMenus.error = payload;
        draft.getResumesMenus.fetched = false;
        draft.getResumesMenus.fetching = false;
        break;

      case getResumesMenus.RESET:
        draft.getResumesMenus = { ...initialState.getResumesMenus };
        break;

      case saveOnboarding.REQUEST:
        draft.saveOnboarding = {
          ...initialState.saveOnboarding,
          fetching: true,
        };
        break;

      case saveOnboarding.SUCCESS: {
        const brandIndex = draft.onlineOrdering.list.findIndex((brand) => brand.uuid === payload.uuid);

        draft.saveOnboarding.fetching = false;
        draft.saveOnboarding.fetched = true;
        draft.saveOnboarding.brand = payload;
        draft.onlineOrdering.list[brandIndex] = payload;
        break;
      }

      case saveOnboarding.FAILURE:
        draft.saveOnboarding.error = payload;
        draft.saveOnboarding.fetched = false;
        draft.saveOnboarding.fetching = false;
        break;

      case saveOnboarding.RESET:
        draft.saveOnboarding = { ...initialState.saveOnboarding };
        break;

      case publishBrand.REQUEST:
        draft.publishBrand = {
          ...initialState.publishBrand,
          fetching: true,
        };
        break;

      case publishBrand.SUCCESS: {
        const indexItem = draft.onlineOrdering.list.findIndex((brand) => brand.uuid === payload.brand.uuid);

        draft.publishBrand.fetching = false;
        draft.publishBrand.fetched = true;
        draft.onlineOrdering.list[indexItem].onlineConfig.isPublished = payload.isPublished;

        break;
      }

      case publishBrand.FAILURE:
        draft.publishBrand.error = payload;
        draft.publishBrand.fetched = false;
        draft.publishBrand.fetching = false;
        break;

      case publishBrand.RESET:
        draft.publishBrand = { ...initialState.publishBrand };
        break;

      default:
        return draft;
    }
  });

export default onlineOrderingReducer;
