import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getSuscriptionHomeRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/home/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function deletePaymentMethodRequest(paymentMethodUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/${paymentMethodUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function savePaymentMethodRequest(paymentMethod, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/?${query}`, {
    method: 'POST',
    body: JSON.stringify(paymentMethod),
    ...options,
  });
}

export function getSubscriptionPaymentsRequest({ page }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payments/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getSubscriptionInvoiceDataRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/invoice-data/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateSubscriptionInvoiceDataRequest(invoiceData, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/invoice-data/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(invoiceData),
    ...options,
  });
}
