import { put, call, takeLatest } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { createProductRequest, getProductRequest } from 'utils/api/baas/menuMaker';

import { createProduct, getProduct } from './actions';

export function* handleGetProductRequest(action) {
  const { storeUuid, productUuid } = action.payload;
  try {
    yield put(getProduct.request());
    const { data } = yield call(baasRequest, getProductRequest, {
      storeUuid,
      productUuid,
    });
    yield put(getProduct.success(data));
  } catch (err) {
    yield put(getProduct.failure({ error: err }));
  }
}

export function* handleCreateProductRequest(action) {
  const { storeUuid, product } = action.payload;
  try {
    yield put(createProduct.request());
    const { data } = yield call(baasRequest, createProductRequest, {
      storeUuid,
      product,
    });
    yield put(createProduct.success(data));
  } catch (err) {
    yield put(createProduct.failure({ error: err }));
  }
}

export default function* productDetailsSaga() {
  yield takeLatest(getProduct.TRIGGER, handleGetProductRequest);
  yield takeLatest(createProduct.TRIGGER, handleCreateProductRequest);
}
