import { useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import IconButton from '@design-system/IconButton';

import Help from '@experimental-components/IconsComponents/Help';

import ToolTip from './ToolTip';

import useStyles from './styles';

function RadioWithLabels({ currentValue, titleLabel, radioLabels, onSelectionChanged, disabled, informationText }) {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleModifierChange = (event, newValue) => {
    if (onSelectionChanged && newValue !== null) {
      onSelectionChanged(newValue);
    }
  };

  const handleSetAnchorEl = () => (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleSetAnchorElClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <Box
        alignItems="center"
        display="flex"
        mb={2}
        sx={{
          '& > :not(:first-child)': {
            marginLeft: theme.spacing(2),
          },
        }}
      >
        <Box fontSize="1.25rem" fontWeight="fontWeightMedium">
          {titleLabel}
        </Box>
        {informationText && (
          <IconButton onClick={handleSetAnchorEl()}>
            <Help />
          </IconButton>
        )}
      </Box>
      <ToggleButtonGroup exclusive onChange={handleModifierChange} orientation="horizontal" value={currentValue}>
        {radioLabels.map((label) => (
          <ToggleButton key={label} data-testid="radioButton" disabled={disabled} value={label}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <ToolTip anchorEl={anchorEl} message={informationText} onClose={handleSetAnchorElClose} />
    </div>
  );
}

RadioWithLabels.defaultProps = {
  currentValue: '0',
  disabled: false,
};

RadioWithLabels.propTypes = {
  disabled: PropTypes.bool,
  titleLabel: PropTypes.string,
  radioLabels: PropTypes.array,
  currentValue: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  informationText: PropTypes.string,
};

export default RadioWithLabels;
