import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getListReportsOrdersRequest,
  getDetailsReportsOrdersRequest,
  exportReportsOrdersToExcelRequest,
} from 'utils/api/baas/reports/orders';

import {
  getListReportsOrders,
  exportReportsOrders,
  getListReportsOrdersScrollPagination,
  getDetailsReportsOrders,
} from './actions';

export function* handleGetListReportsOrdersRequest(action) {
  const filters = action.payload;

  try {
    yield put(getListReportsOrders.request());

    const { data, pagination } = yield call(baasRequest, getListReportsOrdersRequest, filters);

    yield put(getListReportsOrders.success({ ...data, pagination })); // { summaries, headers, rows } = data
  } catch (err) {
    yield put(getListReportsOrders.failure(err));
  }
}

export function* handleGetListReportsOrdersScrollPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getListReportsOrdersScrollPagination.request());

    const { data, pagination } = yield call(baasRequest, getListReportsOrdersRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };

    yield put(getListReportsOrdersScrollPagination.success({ ...data, pagination: paginationCurrent })); // { summaries, headers, rows } = data
  } catch (err) {
    yield put(getListReportsOrdersScrollPagination.failure(err));
  }
}

export function* handleGetDetailsReportsOrdersRequest(action) {
  const orderUuid = action.payload;

  try {
    yield put(getDetailsReportsOrders.request());

    const { data } = yield call(baasRequest, getDetailsReportsOrdersRequest, orderUuid);

    yield put(getDetailsReportsOrders.success(data));
  } catch (err) {
    yield put(getDetailsReportsOrders.failure(err));
  }
}

export function* handleExportReportsOrdersToExcelRequest(action) {
  const filters = action.payload;

  try {
    yield put(exportReportsOrders.request());

    const documentData = yield call(baasRequest, exportReportsOrdersToExcelRequest, filters);

    yield put(exportReportsOrders.success(documentData));
  } catch (err) {
    yield put(exportReportsOrders.failure(err));
  }
}

export default function* reportsOrdersProviderRootSga() {
  yield takeLatest(getListReportsOrders.TRIGGER, handleGetListReportsOrdersRequest);
  yield takeLatest(getDetailsReportsOrders.TRIGGER, handleGetDetailsReportsOrdersRequest);
  yield takeLatest(getListReportsOrdersScrollPagination.TRIGGER, handleGetListReportsOrdersScrollPaginationRequest);
  yield takeLatest(exportReportsOrders.TRIGGER, handleExportReportsOrdersToExcelRequest);
}
