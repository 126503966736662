export function setProductsOptionsList(options) {
  if (options)
    return options?.map((option) => ({
      uuid: option?.uuid,
      name: option?.name,
      value: option?.uuid,
    }));

  return [];
}
