import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

function Header({ children, ...others }) {
  const theme = useTheme();

  return (
    <Box
      alignItems="center"
      borderBottom={`1px solid ${theme.palette.darkGrey[5]}`}
      component="header"
      display="flex"
      height={theme.typography.pxToRem(64)}
      marginBottom={4}
      padding={`${theme.typography.pxToRem(64)} ${theme.typography.pxToRem(24)}`}
      {...others}
    >
      {children}
    </Box>
  );
}

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
