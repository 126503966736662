import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import ContentLayout from 'components/ContentLayout';

function WorkforceTabsNavigation({ t }) {
  return (
    <ContentLayout.TabsNavigation>
      <ContentLayout.TabLink label={t('workforce:tabs.roles')} to="/workforce/roles" />
      <ContentLayout.TabLink label={t('workforce:tabs.people')} to="/workforce/people" />
    </ContentLayout.TabsNavigation>
  );
}

WorkforceTabsNavigation.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('workforce')(WorkforceTabsNavigation);
