import { memo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';

import Box from '@material-ui/core/Box';

import { withProducts } from 'providers/menuMaker/Products';

import ProductsTable from 'components/ProductsTable';

function ProductsTableWrapper({
  categoriesByUuid,
  deleteProduct,
  deleteProductError,
  deleteProductFetched,
  deleteProductLoading,
  isLoading,
  isSearchFilter,
  loadingEnabledProduct,
  loadingUpdateProductImage,
  onClearActions,
  onClearNotification,
  onUpdateProductImage,
  products,
  toggleProductStock,
  t,
  loadProductsScrollPagination,
  productsPaginationInfo,
  loadProductsPaginationScrollState,
  filters,
}) {
  const scrollPaginationRef = useRef(null);

  const handlePaginationInvoices = useCallback(() => {
    const currentPage = productsPaginationInfo.page + 1;

    if (!loadProductsPaginationScrollState.fetching && currentPage !== productsPaginationInfo?.page) {
      loadProductsScrollPagination({ ...filters, page: currentPage });
    }
  }, [loadProductsPaginationScrollState.fetching, loadProductsScrollPagination, productsPaginationInfo.page, filters]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && productsPaginationInfo?.next) {
          handlePaginationInvoices();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return function cleanup() {
      observer.disconnect();
    };
  }, [handlePaginationInvoices, productsPaginationInfo?.next, scrollPaginationRef]);

  return (
    <Box>
      <ProductsTable
        categoriesByUuid={categoriesByUuid}
        deleteProduct={deleteProduct}
        deleteProductError={deleteProductError}
        deleteProductFetched={deleteProductFetched}
        deleteProductLoading={deleteProductLoading}
        isLoading={isLoading}
        isLoadingScrollPagination={loadProductsPaginationScrollState.fetching}
        isSearchFilter={isSearchFilter}
        loadingEnabledProduct={loadingEnabledProduct}
        loadingUpdateProductImage={loadingUpdateProductImage}
        onClearActions={onClearActions}
        onClearNotification={onClearNotification}
        onUpdateProductImage={onUpdateProductImage}
        products={products}
        t={t}
        toggleProductStock={toggleProductStock}
      />

      <Box ref={scrollPaginationRef} mb={5} name="actionTableScroll" />
    </Box>
  );
}

ProductsTableWrapper.propTypes = {
  categoriesByUuid: PropTypes.object,
  deleteProduct: PropTypes.func,
  deleteProductError: PropTypes.bool,
  deleteProductFetched: PropTypes.bool,
  deleteProductLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  loadingEnabledProduct: PropTypes.bool,
  loadingUpdateProductImage: PropTypes.bool,
  onClearActions: PropTypes.func,
  onClearNotification: PropTypes.func,
  onUpdateProductImage: PropTypes.func,
  products: PropTypes.array,
  toggleProductStock: PropTypes.func,
  t: PropTypes.func,
  loadProductsScrollPagination: PropTypes.func,
  productsPaginationInfo: PropTypes.object,
  loadProductsPaginationScrollState: PropTypes.object,
  filters: PropTypes.object,
};

export default compose(memo, withProducts)(ProductsTableWrapper);
