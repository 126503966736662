import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import RadioMui from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as RadioCheckedIcon } from './icons/RadioCheckedIcon.svg';
import { ReactComponent as RadioIcon } from './icons/RadioIcon.svg';

const RadioBase = withStyles(() => ({
  root: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(RadioMui);

const FormControlLabelBase = withStyles((theme) => ({
  root: {
    margin: 0,
  },

  label: {
    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },

    marginLeft: theme.spacing(2),
  },

  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabel);

function Radio({ ...others }) {
  return (
    <FormControlLabelBase
      control={<RadioBase checkedIcon={<RadioCheckedIcon />} disableRipple icon={<RadioIcon />} />}
      {...others}
    />
  );
}

Radio.propTypes = {
  ...RadioMui.propTypes,
};

export default Radio;
