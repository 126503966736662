/* eslint-disable no-unused-vars */
import produce from 'immer';

import { createProduct, getProduct } from './actions';

export const initialState = {
  getProduct: {
    error: null,
    fetching: false,
    fetched: false,
    product: null,
  },
  createProduct: {
    error: null,
    creating: false,
    created: false,
    product: null,
  },
};

const productDetailsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      /*
        Get
      */
      case getProduct.REQUEST:
        draft.getProduct.error = false;
        draft.getProduct.fetching = true;
        draft.getProduct.fetched = false;
        break;

      case getProduct.SUCCESS:
        draft.getProduct.fetching = false;
        draft.getProduct.fetched = true;
        draft.getProduct.product = payload;
        break;

      case getProduct.FAILURE:
        draft.getProduct.error = payload.error;
        draft.getProduct.fetching = false;
        draft.getProduct.fetched = false;
        break;

      case getProduct.RESET:
        draft.getProduct = { ...initialState.getProduct };
        break;

      /*
        Create
      */
      case createProduct.REQUEST:
        draft.createProduct.error = null;
        draft.createProduct.creating = true;
        draft.createProduct.created = false;
        break;

      case createProduct.SUCCESS:
        draft.createProduct.creating = false;
        draft.createProduct.created = true;
        draft.createProduct.product = payload;
        break;

      case createProduct.FAILURE:
        draft.createProduct.error = payload;
        draft.createProduct.creating = false;
        draft.createProduct.created = false;
        break;

      case createProduct.RESET:
        draft.createProduct = { ...initialState.createProduct };
        break;

      default:
        return draft;
    }
  });

export default productDetailsReducer;
