import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.typography.pxToRem(0),
  },

  listContainer: {
    width: 'fit-content',
    border: '1px solid #0000003b',
    borderRadius: theme.typography.pxToRem(5),
    marginTop: theme.typography.pxToRem(14),

    '& li': {
      paddingLeft: theme.typography.pxToRem(0),

      '& button': {
        padding: `0 ${theme.typography.pxToRem(14)}`,
      },

      '& div': {
        paddingRight: `${theme.typography.pxToRem(36)}`,
      },
    },
  },

  disableHover: {
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export default useStyles;
