import { createSelector } from 'reselect';

import { filterInventoriesByText } from 'utils/inventories/supplier/sorting';

import { initialState } from './reducer';

const selectStockCategoriesProviderDomain = (state) => state.stockCategoriesProvider || initialState;

const selectStockCategories = createSelector(selectStockCategoriesProviderDomain, ({ stockCategories, sorting }) => {
  const currentList = [...stockCategories.stockCategories];
  let list = [...currentList];
  const { filterByText } = sorting;
  if (filterByText && filterByText.length >= 3) {
    list = filterInventoriesByText(currentList, sorting);
  }
  return list;
});

const selectStockCategoriesState = createSelector(
  selectStockCategoriesProviderDomain,
  ({ stockCategories: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectStockCategory = createSelector(
  selectStockCategoriesProviderDomain,
  (subState) => subState.stockCategoryDetails.stockCategory,
);

const selectStockCategoryState = createSelector(
  selectStockCategoriesProviderDomain,
  ({ stockCategoryDetails: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectCreateStockCategoryState = createSelector(
  selectStockCategoriesProviderDomain,
  ({ createStockCategory: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectUpdateStockCategoryState = createSelector(
  selectStockCategoriesProviderDomain,
  ({ updateStockCategory: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectDeleteStockCategory = createSelector(
  selectStockCategoriesProviderDomain,
  ({ deleteStockCategory: { error, fetching, fetched } }) => ({ error, fetching, fetched }),
);

const selectStockCategoriesPaginationState = createSelector(
  selectStockCategoriesProviderDomain,
  ({ stockCategoriesPagination }) => stockCategoriesPagination,
);

const selectStockCategoriesPaginationInfo = createSelector(
  selectStockCategoriesProviderDomain,
  ({ stockCategories }) => stockCategories.pagination,
);

const selectStockCategorySortingState = createSelector(selectStockCategoriesProviderDomain, ({ sorting }) => sorting);

export {
  selectStockCategoriesProviderDomain,
  selectStockCategories,
  selectStockCategoriesState,
  selectStockCategoriesPaginationState,
  selectStockCategory,
  selectStockCategoryState,
  selectCreateStockCategoryState,
  selectUpdateStockCategoryState,
  selectDeleteStockCategory,
  selectStockCategoriesPaginationInfo,
  selectStockCategorySortingState,
};
