/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { map } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import Table from '@experimental-components/Table';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVertical';
import MenuActions from 'components/MenuActions';
import ConfirmDialog from 'components/ConfirmDialog';

import { useHistory } from 'react-router-dom';

import PlaceholdersTable from '../PlaceholdersTable';

function RolesTable({
  t,
  rolesList,
  rolesIsFetching,
  deleteRoleIsFetching,
  deleteRoleDidFetch,
  deleteRole,
  duplicateRole,
}) {
  const history = useHistory();

  const [selectedDeleteRoleUuid, setSelectedDeleteRoleUuid] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorActions, setAnchorActions] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if (deleteRoleDidFetch) {
      setSelectedDeleteRoleUuid(null);
      handleCloseActions();
    }
  }, [deleteRoleDidFetch]);

  const handleDuplicate = (roleUuid) => {
    duplicateRole(roleUuid);
    handleCloseActions();
  };

  const handleDelete = () => {
    deleteRole(selectedDeleteRoleUuid);
  };

  function handleCancelDeleteRow() {
    setSelectedDeleteRoleUuid(null);
    setAnchorEl(null);
    handleCloseActions();
  }

  const deleteRoleWithConfirm = (roleUuid) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedDeleteRoleUuid(roleUuid);
    handleCloseActions();
  };

  const handleClickRoleRow = (role) => {
    history.push(`/workforce/roles/details/${role.uuid}`);
  };

  const handleOpenActions = (roleUuid) => ({ currentTarget }) => {
    setAnchorActions(currentTarget);
    setSelectedRole(roleUuid);
  };

  const handleCloseActions = () => {
    setAnchorActions(null);
    setSelectedRole(null);
  };

  function renderBody() {
    if (rolesIsFetching) {
      return <PlaceholdersTable placeholderCols={3} />;
    }

    return map(rolesList, (role, index) => {
      return (
        <Table.Row key={role.uuid}>
          <Table.Cell isBorder={index > 0} onClick={() => handleClickRoleRow(role)}>
            <Link
              color="textPrimary"
              component="button"
              onClick={() => handleClickRoleRow(role)}
              underline="none"
              variant="body2"
            >
              {role.name}
            </Link>
          </Table.Cell>
          <Table.Cell align="center" isBorder={index > 0}>
            {role.numUsers}
          </Table.Cell>
          <Table.Cell align="right" isBorder={index > 0}>
            <IconButton aria-label="actions" disableRipple onClick={handleOpenActions(role.uuid)}>
              <IconMoreVertical />
            </IconButton>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('workforce:rolesTable.header.name')}</Table.HeadCell>
            <Table.HeadCell align="center">{t('workforce:rolesTable.header.numPeople')}</Table.HeadCell>
            <Table.HeadCell align="right">{t('workforce:rolesTable.header.actions')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>{renderBody()}</Table.Body>
      </Table>

      <MenuActions
        anchorEl={anchorActions}
        onClose={handleCloseActions}
        onDelete={deleteRoleWithConfirm(selectedRole)}
        onDuplicate={() => handleDuplicate(selectedRole)}
        open={!!selectedRole}
      />

      <ConfirmDialog
        anchorEl={anchorEl}
        data-testid="popperMenuDelete"
        id="popperMenuDelete"
        isDisabled={deleteRoleIsFetching}
        labelAccept={t(`common:buttons.delete`)}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteRoleIsFetching}
        message={t('workforce:deleteMenu.popperDialog.message')}
        onAccept={handleDelete}
        onCancel={handleCancelDeleteRow}
        open={Boolean(selectedDeleteRoleUuid)}
        title={t('workforce:deleteMenu.popperDialog.title')}
      />
    </>
  );
}

RolesTable.propTypes = {
  t: PropTypes.func,
  rolesList: PropTypes.array,
  rolesIsFetching: PropTypes.bool,
  deleteRoleIsFetching: PropTypes.bool,
  deleteRoleDidFetch: PropTypes.bool,
  deleteRole: PropTypes.func,
  duplicateRole: PropTypes.func,
};

export default withTranslation('workforce')(RolesTable);
