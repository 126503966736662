import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

import InputBase from './InputBase';

function NumberField(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat {...other} allowNegative={false} customInput={InputBase} fixedDecimalScale getInputRef={inputRef} />
  );
}

NumberField.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]),
};

export default NumberField;
