/* eslint-disable no-unused-vars */
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainerMui from '@material-ui/core/TableContainer';
import TableHeadMui from '@material-ui/core/TableHead';
import TableRowMui from '@material-ui/core/TableRow';

import withStyles from '@material-ui/core/styles/withStyles';

import TableHeadCell from './TableHeadCell';
import TableCell from './TableCell';
import TableBigCell from './TableBigCell';

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableHeadMui);

const SortableBody = SortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

const TableContainer = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableContainerMui);

const TableRow = withStyles(() => ({
  hover: {
    cursor: 'pointer',
  },
}))(TableRowMui);

const SortableRow = SortableElement((props) => <TableRow {...props} />);
function Table({ children, ...others }) {
  return (
    <TableContainer>
      <TableMui {...others}>{children}</TableMui>
    </TableContainer>
  );
}

Table.HeadCell = TableHeadCell;
Table.Head = TableHead;
Table.SortableRow = SortableRow;
Table.Row = TableRow;
Table.Body = TableBody;
Table.BodyDraggable = SortableBody;
Table.Cell = TableCell;
Table.BigCell = TableBigCell;

Table.propTypes = {
  ...Table.propTypes,
};

export default Table;
