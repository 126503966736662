export function mapNewProductToOption(product) {
  return {
    product: {
      uuid: product?.uuid,
      name: product?.name,
      description: product?.description,
      image: product?.image,
      price: product?.price,
      availability: product?.availability,
      servingUnit: product?.servingUnit,
    },
    conditionals: [],
    finalGroup: null,
    name: null,
    description: null,
    servingUnit: null,
    additionalPrice: product.price || 0,
    availability: 'AVAILABLE',
    menuOverrides: [],
  };
}

export function mapProductsToOptions(products) {
  const options = Object.keys(products).map((key) => {
    const product = products[key];
    return mapNewProductToOption(product);
  });
  return options;
}

export function mapOptionsToSave(options) {
  return options.map((option) => {
    const { product, finalGroup, ...otherProps } = option;
    return { product: product?.uuid, finalGroup: finalGroup?.uuid ?? null, ...otherProps };
  });
}
