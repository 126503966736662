import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { pageSizeTypes } from 'utils/pagination';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getInventoriesSuppliesRequest(
  { page, searchTerm, category, supplier, storageArea, sortItem },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    page_size: pageSizeTypes.PAGE_SIZE_DEFAULT,
    page,
    search_term: searchTerm,
    category,
    supplier,
    storage_area: storageArea,
    order_by: sortItem,
  });
  const headers = { ...options.headers, 'Accept-Language': 'es' };

  return request(`${API_BASE_URL}/v1/inventory-items/listing/?${query}`, {
    method: 'GET',
    headers,
    ...options,
  });
}

export function getInventorySupplyDetailsRequest(inventoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/${inventoryUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createInventorySupplyRequest(inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}

export function updateInventorySupplyRequest(inventoryUuid, inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/${inventoryUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}

export function deleteInventoriesSupplyRequest(inventoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/${inventoryUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function deleteInventoriesSuppliesRequest(items, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/bulk-delete/?${query}`, {
    method: 'DELETE',
    ...options,
    body: JSON.stringify({
      items,
    }),
  });
}

export function storageAreasAssignationRequest({ items, storageAreas }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-items/assign-storage-areas/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      items,
      storageAreas,
    }),
    ...options,
  });
}

export function createInventoryRequest(inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}

export function getInventoryDetailsRequest(inventoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/${inventoryUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateInventoryRequest(inventoryUuid, inventory, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/suppliers/${inventoryUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...inventory,
    }),
    ...options,
  });
}
