import { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { useDebounce } from '@open-react-hooks/use-debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';

import MenuBrands from 'components/MenuBrands';
import useIconParrot from 'hooks/useIconParrot';

import SelectCategories from './SelectCategories';
import TypeSoldProducts from './TypeSoldProducts';
import SelectBrands from './SelectBrands';
import useStyles from './styles';

function HeaderForm({
  addButtonLabel,
  categoriesList,
  isShowCategories,
  isShowFilterBrands,
  isShowFilterProducts,
  isShowFilterBrandsInFilters,
  onAddButtonClick,
  onSearchBoxChange,
  onSelectedCategoriesChange,
  onSelectTypeSoldProductChange,
  onSelectBrandsChange,
  searchLabel,
  ...others
}) {
  const filtersNumber = !!isShowCategories + !!isShowFilterProducts + !!isShowFilterBrandsInFilters;
  const classes = useStyles({ filtersNumber });
  const { IconSearch } = useIconParrot({ icon: 'search' });
  const { debounce } = useDebounce({ delay: 300 });

  const [, setFiltersLocals] = useState({ filterByCategories: [], filterByBrands: [], page: 1, filterByText: null });

  function handleChange(event) {
    const { value } = event.target;

    if (onSearchBoxChange) {
      if (value) {
        setFiltersLocals((currentFilters) => {
          onSearchBoxChange({ ...currentFilters, filterByText: value });
          return { ...currentFilters, filterByText: value };
        });
      } else {
        setFiltersLocals((currentFilters) => {
          onSearchBoxChange({ ...currentFilters, filterByText: null });
          return { ...currentFilters, filterByText: null };
        });
      }
    }
  }

  function handleSelectedCategoriesChange(value, withoutCategories) {
    setFiltersLocals((currentFilters) => {
      onSelectedCategoriesChange({ ...currentFilters, filterByCategories: value, withoutCategories });
      return { ...currentFilters, filterByCategories: value, withoutCategories };
    });
  }

  function handleFilterByTypeSoldProductChange(value) {
    if (value === 1) {
      setFiltersLocals((currentFilters) => {
        onSelectTypeSoldProductChange({ ...currentFilters, filterByTypeSoldProduct: null });
        return { ...currentFilters, filterByTypeSoldProduct: null };
      });
    } else {
      setFiltersLocals((currentFilters) => {
        onSelectTypeSoldProductChange({ ...currentFilters, filterByTypeSoldProduct: value === 2 });
        return { ...currentFilters, filterByTypeSoldProduct: value === 2 };
      });
    }
  }

  function handleFilterByBrands(value, withoutBrands) {
    setFiltersLocals((currentFilters) => {
      onSelectBrandsChange({ ...currentFilters, filterByBrands: value, withoutBrands });
      return { ...currentFilters, filterByBrands: value, withoutBrands };
    });
  }

  return (
    <Box className={classes.root} {...others}>
      <Box className={classes.wrapperFilters}>
        <Box className={classes.wrapperSearchBox}>
          <InputAdornmentFiled
            fullWidth
            hideLabelHelperTex
            id="header-form-search-box"
            onChange={debounce(handleChange)}
            placeholder={searchLabel}
            startAdornment={
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            }
          />
        </Box>
        {isShowFilterBrandsInFilters && (
          <Box className={classes.wrapperMultiSelect}>
            <SelectBrands onSelectedBrands={handleFilterByBrands} />
          </Box>
        )}
        {isShowCategories && (
          <Box className={classes.wrapperMultiSelect}>
            <SelectCategories categories={categoriesList} onSelectedCategories={handleSelectedCategoriesChange} />
          </Box>
        )}

        {isShowFilterProducts && (
          <Box className={classes.wrapperMultiSelect}>
            <TypeSoldProducts onSelectTypeSoldProduct={handleFilterByTypeSoldProductChange} />
          </Box>
        )}
      </Box>

      <Box className={classes.wrapperActions}>
        {isShowFilterBrands && <MenuBrands />}

        {onAddButtonClick && (
          <ButtonPrimary className={classes.middlePrimaryButton} onClick={onAddButtonClick}>
            {addButtonLabel}
          </ButtonPrimary>
        )}
      </Box>
    </Box>
  );
}

HeaderForm.propTypes = {
  addButtonLabel: PropTypes.string,
  categoriesList: PropTypes.object,
  isShowCategories: PropTypes.bool,
  isShowFilterBrands: PropTypes.bool,
  isShowFilterProducts: PropTypes.bool,
  isShowFilterBrandsInFilters: PropTypes.bool,
  onAddButtonClick: PropTypes.func,
  onSearchBoxChange: PropTypes.func,
  onSelectedCategoriesChange: PropTypes.func,
  onSelectTypeSoldProductChange: PropTypes.func,
  onSelectBrandsChange: PropTypes.func,
  searchLabel: PropTypes.string,
};

export default withTranslation('headerForm')(HeaderForm);
