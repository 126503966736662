import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { BoxProps as BoxPropsBase } from '@material-ui/core/Box';

declare module '@material-ui/core' {
  interface PropertyProps {
    error?: boolean;
    disabled?: boolean;
    active?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }
  interface BoxProps extends BoxPropsBase {
    ref?: React.LegacyRef<HTMLElement>;
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface CustomColor1 {
    main: string;
    2: string;
    5: string;
    15: string;
    50: string;
  }
  interface CustomColor2 {
    main?: string;
  }
  interface CustomColor3 {
    main?: string;
    light?: string;
  }
  interface Palette {
    darkGrey: CustomColor1;
    orange: CustomColor3;
    lightBlue: CustomColor3;
    glacier: CustomColor2;
    malibu: CustomColor2;
    hitGray: CustomColor2;
    orchid: CustomColor2;
    celery: CustomColor2;
    lavender: CustomColor2;
    blush: CustomColor2;
    portage: CustomColor2;
    hitPink: CustomColor2;
    aquamarine: CustomColor2;
    raisinBlack: CustomColor2;
    eclipse: CustomColor2;
    testEnv: CustomColor2;
    itemList: CustomColor2;
  }
  interface PaletteOptions {
    darkGrey: CustomColor1;
    orange?: CustomColor3;
    lightBlue?: CustomColor3;
    glacier: CustomColor2;
    malibu: CustomColor2;
    hitGray: CustomColor2;
    orchid: CustomColor2;
    celery: CustomColor2;
    lavender: CustomColor2;
    blush: CustomColor2;
    portage: CustomColor2;
    hitPink: CustomColor2;
    aquamarine: CustomColor2;
    raisinBlack: CustomColor2;
    eclipse: CustomColor2;
    testEnv: CustomColor2;
    itemList: CustomColor2;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },

    secondary: {
      main: '#F04E4A',
    },

    action: {
      disabledBackground: '#D9D9D9',
    },

    text: {
      primary: '#000000',
      secondary: '#47465F',
    },

    error: {
      main: '#DF130E',
      light: '#FADCDB',
      dark: '#C91C18',
    },

    info: {
      main: '#44AEC9',
    },

    success: {
      main: '#27AE60',
      light: '#DFF3E7',
    },

    warning: {
      main: '#F7BE2C',
      light: '#FCEBC0',
    },

    darkGrey: {
      2: '#F7F7F9',
      5: '#F2F2F4',
      15: '#CECDD1',
      50: '#A3A3AF',
      main: '#47465F',
    },

    background: {
      default: '#FFFFFF',
    },
    orange: {
      main: '#F57D39',
      light: '#FCD6C0',
    },
    lightBlue: {
      main: '#44AEC9',
      light: '#D2EBF6',
    },
    glacier: {
      main: '#5DB4CA',
    },
    malibu: {
      main: '#96BEF2',
    },
    hitGray: {
      main: '#94A6B5',
    },
    orchid: {
      main: '#DF8B81',
    },
    celery: {
      main: '#C1DE82',
    },
    lavender: {
      main: '#C8BBE3',
    },
    blush: {
      main: '#DF81A3',
    },
    portage: {
      main: '#819BDF',
    },
    hitPink: {
      main: '#F2AF7E',
    },
    aquamarine: {
      main: '#82DEB2',
    },
    raisinBlack: {
      main: '#262626',
    },
    eclipse: {
      main: '#383838',
    },
    testEnv: {
      main: '#325077',
    },
    itemList: {
      main: '#1E2025',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      textTransform: 'initial',
    },
  },

  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
      disableTouchRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

export default responsiveFontSizes(theme);
