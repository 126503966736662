const respond = {
  pagination: {
    next: null,
    previous: null,
    count: 43,
  },
  data: {
    summaries: [],
    headers: [
      {
        name: 'Nombre',
        sorting: 1,
        tooltip: null,
      },
      {
        name: 'Órdenes cerradas',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Orden promedio',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Órdenes canceladas',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Descuentos de artículo',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Descuentos de ordenes',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Venta total',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Cargos por servicio',
        sorting: 0,
        tooltip: null,
      },
      {
        name: 'Propina de tarjeta',
        sorting: 0,
        tooltip: null,
      },
    ],
    rows: [
      {
        uuid: 'da7e8a58-7813-49f6-aa34-c50a8821fc22',
        content: [
          {
            value: 'Paulina Hernández',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '16',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '1,450.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '3 ($850.00)',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '2 ($89.00)',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '8,495.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '2 ($89.00)',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
          },
          {
            value: '404.90',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
          },
        ],
      },
    ],
  },
  datetime: '2022-06-20T17:47:04.070100Z',
};

export const ReportUser = (req, res, ctx) => {
  return res(
    // Respond with a 200 status code
    ctx.status(200),
    ctx.json(respond),
  );
};
