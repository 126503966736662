import { useState, Children } from 'react';
import PropTypes from 'prop-types';

import SidebarLayoutContext from './SidebarLayoutContext';

function SidebarLayoutProvider({ children }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMdDrawer, setOpenMdDrawer] = useState(true);

  const contextDefault = {
    openDrawer,
    setOpenDrawer,
    openMdDrawer,
    setOpenMdDrawer,
  };

  return (
    <SidebarLayoutContext.Provider value={contextDefault}>{Children.only(children)}</SidebarLayoutContext.Provider>
  );
}

SidebarLayoutProvider.propTypes = {
  children: PropTypes.element,
};

export { default as useSidebarLayout } from './useSidebarLayout';
export default SidebarLayoutProvider;
