import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/extendRoutines';

import {
  GET_INVENTORIES,
  CREATE_INVENTORY,
  DELETE_INVENTORY,
  UPDATE_INVENTORY,
  GET_INVENTORY_DETAILS,
  GET_INVENTORIES_SCROLL_PAGINATION,
} from './constants';

export const getInventories = extendRoutine(createRoutine(GET_INVENTORIES), ['RESET', 'SORTING']);
export const createInventory = extendRoutine(createRoutine(CREATE_INVENTORY), 'RESET');
export const deleteInventory = extendRoutine(createRoutine(DELETE_INVENTORY), 'RESET');
export const updateInventory = extendRoutine(createRoutine(UPDATE_INVENTORY), 'RESET');
export const getInventoryDetails = extendRoutine(createRoutine(GET_INVENTORY_DETAILS), 'RESET');
export const getInventoriesScrollPagination = extendRoutine(createRoutine(GET_INVENTORIES_SCROLL_PAGINATION), 'RESET');
