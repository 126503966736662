import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useForm, useWatch } from 'react-hook-form';
import { withTranslation, Trans } from 'react-i18next';

import dayjs from 'dayjs';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ButtonPrimary from '@design-system/ButtonPrimary';
import InputTextFiled from '@design-system/TextField';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import Checkbox from '@design-system/Checkbox';

import useEnvironment from 'hooks/useEnvironment';

import { ReactComponent as IconParrot } from 'images/svg/brand/logo-connect.svg';

import pkg from '../../../package.json';

import useStyles from './styles';

export function SignInForm({ onSubmit, onResetSignIn, error, loading, t }) {
  const theme = useTheme();
  const isLoginResponsive = useMediaQuery(theme.breakpoints.down('sm'));

  const [showPassword, setShowPassword] = useState(false);
  const { isTestEnv } = useEnvironment();

  const classes = useStyles({ isTestEnv });
  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const { usernameField, passwordField, termsAndConditionsField } = useWatch({ control });

  const validateForm = (() => {
    if (formState.isSubmitted && formState.isValid) {
      return true;
    }
    if (formState.isSubmitted && !formState.isValid) {
      return false;
    }

    if (usernameField && passwordField && termsAndConditionsField) {
      return true;
    }

    return false;
  })();

  function handleSubmitValues(values) {
    onSubmit({ user: values.usernameField?.trim().toLowerCase(), password: values.passwordField });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <aside className={classes.sidebar}>
        <div className={classes.spacer} />

        <div className={classes.header}>
          <IconParrot className={classes.iconParrot} />
          {!isLoginResponsive && (
            <div>
              <Typography className={classes.titleParrot} color="initial" component="h1" gutterBottom variant="h2">
                {t('signIn:sidebar.title')}
              </Typography>

              <Typography className={classes.messageParrot} color="initial" variant="h6">
                <Trans i18nKey="signIn:sidebar.message" />
              </Typography>
            </div>
          )}
          {isLoginResponsive && (
            <div>
              <Typography className={classes.titleParrot} color="initial" component="h1" gutterBottom variant="h2">
                {t('signIn:sidebar.title')}
              </Typography>
            </div>
          )}
        </div>

        <div className={classes.spacer} />

        {!isLoginResponsive && (
          <>
            <Typography className={classes.urlParrot} color="initial" variant="h6">
              <Link color="inherit" href="https://pos.parrotsoftware.io/" rel="noopener" target="_blank">
                {t('common:parrotUrl')}
              </Link>
            </Typography>

            <Typography className={classes.copyright} variant="body2">
              {t('signIn:messages.copyright', { version: `V${pkg.version}`, date: dayjs().year() })}
            </Typography>
          </>
        )}
      </aside>

      <form className={classes.form} onSubmit={handleSubmit(handleSubmitValues)}>
        <div className={classes.headerWrapper}>
          <Collapse in={error}>
            <Alert data-cy="notificationAlert" onClose={onResetSignIn} severity="error">
              {t('signIn:errors.emailAndPassword')}
            </Alert>
          </Collapse>
        </div>

        <div className={classes.formWrapper}>
          {!isLoginResponsive && (
            <Box mb={6}>
              <Typography className={classes.headerTitle} variant="h2">
                {t('signIn:form.title')}
              </Typography>
            </Box>
          )}

          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <InputTextFiled
                autoComplete="off"
                data-cy="usernameField"
                disabled={loading}
                error={Boolean(errors?.usernameField)}
                errorMsg={errors?.usernameField?.message}
                fullWidth
                id="usernameField"
                inputRef={register({
                  required: t('signIn:form.fieldErrors.emailTextFieldError'),
                })}
                label={t('signIn:form.email')}
                name="usernameField"
                placeholder={t('signIn:form.placeHolderEmail')}
                type="text"
              />
            </Grid>

            <Grid item xs={12}>
              <InputAdornmentFiled
                data-cy="passwordField"
                disabled={loading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="passwordField"
                      disabled={loading}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                error={Boolean(errors?.passwordField)}
                errorMsg={errors?.passwordField?.message}
                fullWidth
                id="passwordField"
                inputRef={register({
                  required: t('signIn:form.fieldErrors.passwordTextFieldError'),
                })}
                label={t('signIn:form.password')}
                name="passwordField"
                placeholder={t('signIn:form.placeHolderPassword')}
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                inputRef={register({
                  required: true,
                })}
                label={
                  <Box fontSize="0.875rem">
                    {t('signIn:form.acceptTermsConditions')}
                    <Link
                      className={classes.rootLinkTermsConditions}
                      href="https://docs.google.com/document/d/e/2PACX-1vS0_C-Wv8LejXzwH-nolsw9fEggVOTZcoaPUP3l8VKqrKh80sUBgKvTS9mJB7qL9w/pub"
                      target="_blank"
                      underline="none"
                    >
                      {t('signIn:form.linkTermsConditions')}
                    </Link>
                  </Box>
                }
                name="termsAndConditionsField"
              />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.buttonWrapper}>
                <ButtonPrimary
                  data-cy="submitButton"
                  disabled={loading || !validateForm}
                  loading={loading}
                  type="submit"
                >
                  {t('signIn:form.initSession')}
                </ButtonPrimary>
              </div>
            </Grid>
          </Grid>
        </div>
        {isLoginResponsive && (
          <Typography className={classes.copyright} variant="body2">
            {t('signIn:messages.copyright', { version: `V${pkg.version}`, date: dayjs().year() })}
          </Typography>
        )}
      </form>
    </div>
  );
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
  onResetSignIn: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation('signIn')(SignInForm);
