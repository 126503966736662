import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const InputRoot = withStyles((theme) => {
  const placeholder = {
    color: (props) => (props.disabled ? theme.palette.darkGrey[15] : theme.palette.darkGrey.main),
    opacity: 1,
  };

  const getBorder = (props) => {
    if (props?.disabled) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
  };

  const borderColor = (props) => getBorder(props);

  const getBorderColor = (props) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[15];
    }

    if (props?.error) {
      return theme.palette.error.main;
    }

    return theme.palette.darkGrey[50];
  };

  const getBackgroundColor = (props) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[2];
    }

    if (props?.error) {
      return theme.palette.error.light;
    }

    return theme.palette.common.white;
  };

  return {
    root: {
      backgroundColor: (props) => getBackgroundColor(props),
      borderRadius: theme.shape.borderRadius,

      '&:hover $notchedOutline': {
        borderColor: (props) => getBorder(props),
        borderWidth: 1,
      },

      '@media (hover: none)': {
        '&:hover $notchedOutline': {
          borderColor: (props) => getBorderColor(props),
          borderWidth: 1,
        },
      },
      '&$focused $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$disabled $notchedOutline': {
        borderColor: (props) => getBorderColor(props),
        borderWidth: 1,
      },

      'label + &': {
        marginTop: theme.spacing(4),
      },
    },

    notchedOutline: {
      borderColor: (props) => getBorderColor(props),
      borderWidth: 1,
    },

    focused: {
      borderWidth: 1,
    },

    disabled: {
      color: theme.palette.darkGrey[15],
    },

    error: {
      color: theme.palette.error.main,
    },

    input: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,
    },

    inputAdornedStart: {
      paddingLeft: '1rem',
    },
  };
})(OutlinedInput);

const InputLabelRoot = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme) => ({
  root: {
    color: theme.palette.main,
    fontSize: '0.875rem',
  },
}))(FormHelperText);

function InputAdornmentFiled({
  id,
  error,
  fullWidth,
  label = null,
  helperText,
  errorMsg,
  disabled,
  required,
  hideLabel,
  hideLabelHelperTex,
  ...others
}) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required}>
      {!hideLabel && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}

      <InputRoot disabled={disabled} error={error} id={id} required={required} {...others} />
      {!hideLabelHelperTex && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
}

InputAdornmentFiled.propTypes = {
  ...OutlinedInput.propTypes,
};

export default InputAdornmentFiled;
