import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { updateProductRequest } from 'utils/api/baas/menuMaker';

import { editProduct } from '../actions';

export function* handleEditProductRequest(action) {
  const { storeUuid, product } = action.payload;
  try {
    yield put(editProduct.request());

    const { data } = yield call(baasRequest, updateProductRequest, {
      storeUuid,
      product,
    });

    yield put(editProduct.success(data));
  } catch (err) {
    yield put(editProduct.failure({ error: err }));
  }
}
