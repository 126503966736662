import { put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import { deleteProductRequest } from 'utils/api/baas/menuMaker';

import { deleteProduct } from '../actions';

export function* handleDeleteProductRequest(action) {
  const { storeUuid, productUuid } = action.payload;
  try {
    yield put(deleteProduct.request());

    yield call(baasRequest, deleteProductRequest, {
      storeUuid,
      productUuid,
    });

    yield put(deleteProduct.success());
  } catch (err) {
    yield put(deleteProduct.failure({ error: err, productUuid }));
  } finally {
    yield put(deleteProduct.fulfill({ productUuid }));
  }
}
