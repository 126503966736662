/* eslint-disable no-unused-vars */
import produce from 'immer';

import { DEFAULT_PAGE } from 'utils/categories';

import { getCategories, getPaginationCategory, createCategory, deleteCategory, duplicateCategory } from './actions';

export const initialState = {
  getCategories: {
    error: null,
    fetching: false,
    fetched: false,
    categories: [],
    menus: [],
    pagination: { page: DEFAULT_PAGE },
  },

  paginationCategory: {
    fetching: false,
    fetched: false,
    error: null,
  },

  createCategory: {
    error: null,
    fetching: false,
    fetched: false,
    category: {},
  },
  deleteCategory: {
    error: null,
    fetching: false,
    fetched: false,
  },
  duplicateCategory: {
    error: null,
    fetching: false,
    fetched: false,
    category: {},
  },
};

const categoriesReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getCategories.REQUEST:
        draft.getCategories.fetching = true;
        draft.getCategories.fetched = false;
        draft.getCategories.error = null;
        draft.getCategories.pagination = { ...initialState.getCategories.pagination };
        break;

      case getCategories.SUCCESS:
        draft.getCategories.fetching = false;
        draft.getCategories.fetched = true;
        draft.getCategories.error = null;
        draft.getCategories.categories = payload.categories;
        draft.getCategories.menus = payload.menus;
        draft.getCategories.pagination = {
          ...draft.getCategories.pagination,
          ...payload.pagination,
        };
        break;

      case getCategories.FAILURE:
        draft.getCategories.error = payload;
        draft.getCategories.fetching = false;
        draft.getCategories.fetched = false;
        break;

      case getCategories.RESET:
        draft.getCategories = { ...initialState.getCategories };
        break;

      case getPaginationCategory.REQUEST:
        draft.paginationCategory.fetching = true;
        draft.paginationCategory.fetched = false;
        draft.paginationCategory.error = null;
        break;

      case getPaginationCategory.SUCCESS: {
        const newCategories = [...draft.getCategories.categories, ...payload.data];

        draft.paginationCategory.fetching = false;
        draft.paginationCategory.fetched = true;
        draft.paginationCategory.error = null;
        draft.getCategories.categories = newCategories;
        draft.getCategories.pagination = {
          ...draft.getCategories.pagination,
          ...payload.pagination,
          page: payload.page,
        };
        break;
      }

      case getPaginationCategory.FAILURE:
        draft.paginationCategory.error = payload;
        draft.paginationCategory.fetching = false;
        draft.paginationCategory.fetched = false;
        break;

      case createCategory.REQUEST:
        draft.createCategory.fetching = true;
        draft.createCategory.fetched = false;
        draft.createCategory.error = false;
        break;

      case createCategory.SUCCESS: {
        draft.createCategory.fetching = false;
        draft.createCategory.fetched = true;
        draft.createCategory.category = payload;
        break;
      }

      case createCategory.FAILURE:
        draft.createCategory.error = payload;
        draft.createCategory.fetched = false;
        draft.createCategory.fetching = false;
        break;

      case createCategory.RESET:
        draft.createCategory = { ...initialState.createCategory };
        break;

      case deleteCategory.REQUEST:
        draft.deleteCategory.fetching = true;
        draft.deleteCategory.fetched = false;
        draft.deleteCategory.error = false;
        break;

      case deleteCategory.SUCCESS: {
        draft.deleteCategory.fetched = true;
        draft.deleteCategory.fetching = false;
        break;
      }

      case deleteCategory.FULFILL: {
        if (!draft.deleteCategory.error) {
          const categoriesList = draft.getCategories.categories.filter((category) => category.uuid !== payload);
          draft.getCategories.categories = categoriesList;
        }
        break;
      }

      case deleteCategory.FAILURE:
        draft.deleteCategory.error = payload;
        draft.deleteCategory.fetched = false;
        draft.deleteCategory.fetching = false;
        break;

      case deleteCategory.RESET:
        draft.deleteCategory = { ...initialState.deleteCategory };
        break;

      case duplicateCategory.REQUEST:
        draft.duplicateCategory.fetching = true;
        draft.duplicateCategory.fetched = false;
        draft.duplicateCategory.error = false;
        break;

      case duplicateCategory.SUCCESS: {
        draft.duplicateCategory.fetching = false;
        draft.duplicateCategory.fetched = true;
        draft.duplicateCategory.category = payload;
        draft.getCategories.categories.push(payload);
        break;
      }

      case duplicateCategory.FAILURE:
        draft.duplicateCategory.error = true;
        draft.duplicateCategory.fetched = false;
        draft.duplicateCategory.fetching = false;
        break;

      case duplicateCategory.RESET:
        draft.duplicateCategory = { ...initialState.duplicateCategory };
        break;

      default:
        return draft;
    }
  });

export default categoriesReducer;
