import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

function MenuItem({ children, selected, onClick }) {
  const theme = useTheme();

  return (
    <Box
      bgcolor={selected ? '#383838' : ''}
      component="li"
      fontSize={theme.typography.pxToRem(14)}
      fontStyle={theme.typography.fontWeightRegular}
      onClick={onClick}
      p="0.625rem 1.5rem"
      sx={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#383838',
        },
      }}
    >
      {children}
    </Box>
  );
}

MenuItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MenuItem;
