import { Children, memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import FeatureProvider from 'FeatureProvider';

import { useInjectSaga } from 'utils/injectSaga';

import { refreshToken, getUser, getLicenseState, getLicenseStateChannel } from './actions';
import {
  selectIsAuthenticated,
  selectAuthFetching,
  selectAuthUser,
  selectAuthUserPermissions,
  selectLicense,
  selectLicenseState,
  selectStoreModules,
} from './selectors';
import saga from './saga';

export function AuthProvider({
  children,
  loadGetUser,
  isFetching,
  authUserPermissions,
  loadLicenseState,
  authenticated,
  storeModules,
}) {
  useInjectSaga({ key: 'auth', saga });

  useEffect(() => {
    loadGetUser();
  }, [loadGetUser]);

  useEffect(() => {
    if (authenticated) {
      loadLicenseState();
    }
  }, [authenticated, loadLicenseState]);

  if (isFetching) {
    return (
      <Backdrop open>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  return (
    <FeatureProvider features={[...authUserPermissions, ...storeModules]}>{Children.only(children)}</FeatureProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  authenticated: selectIsAuthenticated,
  authUser: selectAuthUser,
  isFetching: selectAuthFetching,
  authUserPermissions: selectAuthUserPermissions,
  licenseStatus: selectLicense,
  licenseState: selectLicenseState,
  storeModules: selectStoreModules,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadRefreshToken: () => dispatch(refreshToken()),
    loadGetUser: () => dispatch(getUser()),
    loadLicenseState: () => dispatch(getLicenseState()),
    loadLicenseStateChannel: () => dispatch(getLicenseStateChannel()),
  };
}

export const withAuthState = connect(mapStateToProps, mapDispatchToProps);
const withConnect = connect(mapStateToProps, mapDispatchToProps);

AuthProvider.propTypes = {
  children: PropTypes.element,
  isFetching: PropTypes.bool,
  authUserPermissions: PropTypes.array,
  storeModules: PropTypes.array,
  loadGetUser: PropTypes.func,
  loadLicenseState: PropTypes.func,
  authenticated: PropTypes.bool,
};

export { default as useAuth } from './useAuth';
export { default as withRequiredLicense } from './withRequiredLicense';
export default compose(withConnect, memo)(AuthProvider);
