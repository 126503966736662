import React from 'react';

import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { List, ListItem, ListItemText } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CloseIcon from '@material-ui/icons/Close';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';

import arrayMove from '../../utils/array';

import useStyles from './styles';

function MultiSelect({
  getOptionLabel,
  getOptionUniqueKey,
  options,
  selectedOptions,
  inputPlaceholderText,
  titleLabel,
  onChange,
  headerClassName,
}) {
  const classes = useStyles();

  function handleRemoveItem(value) {
    onChange(selectedOptions.filter((option) => option !== value));
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(selectedOptions, oldIndex, newIndex));
  };

  // This ensures we don't show options which are already selected.
  const finalOptions = options.filter((option) => !selectedOptions.includes(option));

  const DraggableHandle = SortableHandle(({ children }) => children);

  const SortableItem = SortableElement(({ value }) => {
    return (
      <ListItem>
        <DraggableHandle>
          <IconButton aria-label="drag" className={classes.disableHover}>
            <DragHandleIcon />
          </IconButton>
        </DraggableHandle>
        <ListItemText primary={getOptionLabel(value)} />
        <IconButton
          aria-label="delete"
          className={classes.disableHover}
          edge="end"
          onClick={() => handleRemoveItem(value)}
        >
          <CloseIcon />
        </IconButton>
      </ListItem>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    if (!items.length) {
      return <Box />;
    }
    return (
      <Box>
        <List className={classes.listContainer}>
          {items.map((value, index) => (
            <SortableItem
              key={getOptionUniqueKey ? getOptionUniqueKey(value) : value}
              distance={1}
              index={index}
              value={value}
            />
          ))}
        </List>
      </Box>
    );
  });

  return (
    <div className={classes.root}>
      <Typography className={headerClassName} gutterBottom variant="h6">
        {titleLabel}
      </Typography>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        id="tags-standard"
        multiple
        onChange={(event, newValue) => {
          onChange(selectedOptions.concat(newValue));
        }}
        options={finalOptions}
        renderInput={(params) => (
          <TextField
            ref={params.InputProps.ref}
            fullWidth
            inputProps={params.inputProps}
            placeholder={inputPlaceholderText}
            variant="outlined"
          />
        )}
        value={[]}
      />
      <SortableList items={selectedOptions} lockAxis="y" onSortEnd={onSortEnd} useDragHandle />
    </div>
  );
}

MultiSelect.propTypes = {
  titleLabel: PropTypes.string,
  inputPlaceholderText: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionUniqueKey: PropTypes.func,
  onChange: PropTypes.func,
  headerClassName: PropTypes.string,
};

export default MultiSelect;
