const ADMIN = 'ADMIN';
const BACK_OFFICE = 'BACK_OFFICE';
const POS = 'POS';
const NOTIFICATION = 'NOTIFICATION';
const AREA_TABLES = 'AREA_TABLES';
const PAY_TERMINAL = 'PAY_TERMINAL';

export const namespacesTypes = [ADMIN, AREA_TABLES, BACK_OFFICE, NOTIFICATION, PAY_TERMINAL, POS];

export const namespacesCheckIncludes = {
  ADMIN: [],
  BACK_OFFICE: [],
  POS: [],
  NOTIFICATION: [],
  AREA_TABLES: [],
  PAY_TERMINAL: [],
};

export const namespacesToLower = {
  admin: ADMIN,
  backoffice: BACK_OFFICE,
  pos: POS,
  notification: NOTIFICATION,
  areaTable: AREA_TABLES,
  payTerminal: PAY_TERMINAL,
};

export function setInitialNamespacesCheckedAll(permissions) {
  const objectSet = {
    ADMIN: [],
    BACK_OFFICE: [],
    POS: [],
    NOTIFICATION: [],
    AREA_TABLES: [],
    PAY_TERMINAL: [],
  };

  permissions?.forEach((permission) => {
    if (permission.slug !== 'backoffice.access') {
      objectSet[permission?.namespace].push(permission.slug);
    }
  });

  return objectSet;
}
