import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { cleanObject } from 'utils/objects';
// import { pageSizeTypes } from 'utils/pagination'; // it's temp

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getStockCategoriesRequest(filters, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    ...filters,
    store_uuid: storeUuid,
    // page_size: pageSizeTypes.PAGE_SIZE_DEFAULT, // it's temp
  };

  const query = queryString.stringify(cleanObject(params));

  return request(`${API_BASE_URL}/v1/inventory-categories/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getStockCategoryDetailsRequest(stockCategoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-categories/${stockCategoryUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function createStockCategoryRequest({ name }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-categories/?${query}`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
    ...options,
  });
}

export function deleteStockCategoryRequest(stockCategoryUuid, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/inventory-categories/${stockCategoryUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

export function updateStockCategoryRequest({ uuid, name, isDefault, isEnabled }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const params = {
    name,
    isDefault,
    isEnabled,
  };
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/inventory-categories/${uuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(cleanObject(params)),
    ...options,
  });
}
