import { call, put } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { getAuthToken, removeAuthToken, removeCookieUserStore } from 'utils/auth';

export const createHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'es-MX',
  Authorization: `Bearer ${getAuthToken()?.accessToken}`,
});

export default function* baasRequest(request, ...params) {
  const headers = createHeaders();

  try {
    const response = yield call(request, ...params, { headers });

    return response;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      removeAuthToken();
      removeCookieUserStore();
      yield put(push('/logged-out'));
    }

    throw err;
  }
}
