import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';

import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Radio from '@design-system/Radio';
import Switch from '@design-system/Switch';

import { checkIfIeps, getTaxValue } from 'utils/settingsTaxes/utils';
import { groupLabel, taxesTypes } from 'utils/settingsTaxes/taxesTypes';

import useStyles from './styles';

function SettingsAdvanceTaxes({ control, register, taxesGroup = [], setValue }) {
  const classes = useStyles();
  const [blockTaxSection, setBlockTaxSection] = useState();
  const { t } = useTranslation('settingsTaxes');

  const isExcempt = useWatch({
    control,
    name: 'exemptField',
  });

  const ieps = useWatch({
    control,
    name: 'taxGroupFieldIEPS',
  });

  const isIeps = useMemo(() => {
    const bIeps = checkIfIeps(ieps, taxesGroup);
    if (bIeps) setBlockTaxSection(taxesTypes.IVA);
    return bIeps;
  }, [ieps, taxesGroup]);

  useEffect(() => {
    if (isIeps) {
      const taxFound = getTaxValue(0, taxesGroup.find((tax) => tax.name === taxesTypes.IVA)?.items);
      if (taxFound) setValue('taxGroupFieldIVA', taxFound.uuid);
    }
  }, [isIeps, setValue, taxesGroup]);

  useEffect(() => {
    if (!isIeps && blockTaxSection) {
      const ivaTaxes = taxesGroup.find((tax) => tax.name === taxesTypes.IVA);
      if (ivaTaxes?.items) {
        const ivaDefault = ivaTaxes.items.find((tax) => tax.isDefault);
        setValue('taxGroupFieldIVA', ivaDefault.uuid);
      }
      setBlockTaxSection();
    }
  }, [isIeps, blockTaxSection, taxesGroup, setValue]);

  function renderRadioGroups(taxesClassGroup = [], group) {
    return (
      <Controller
        control={control}
        name={`taxGroupField${group}`}
        render={({ onChange, value, ...props }) => (
          <FormControl component="fieldset">
            <RadioGroup className={classes.radioGroups} onChange={onChange} value={value} {...props}>
              {taxesClassGroup.map((tax) => (
                <Radio
                  key={tax.uuid}
                  checked={value === tax?.uuid}
                  disabled={blockTaxSection === group}
                  label={tax.name}
                  value={tax.uuid}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    );
  }

  return (
    <Box>
      <Box fontSize={18} fontWeight="fontWeightMedium">
        {t('settingsTaxes:messages.taxes')}
      </Box>
      <Box mt={4}>
        <Switch
          color="secondary"
          defaultChecked={isExcempt}
          inputRef={register()}
          label={t('settingsTaxes:labels.exempt')}
          name="exemptField"
        />
        <FormHelperText className={classes.helperText}>{t('settingsTaxes:messages.exemptHelper')}</FormHelperText>
      </Box>
      {!isExcempt &&
        taxesGroup.map((group) => (
          <Box key={group.name}>
            <Box fontSize={18} fontWeight="fontWeightMedium" mt={4}>
              {t(groupLabel[group.name])}
            </Box>
            <Box mb={4} mt={4}>
              {renderRadioGroups(group.items, group.name)}
            </Box>
          </Box>
        ))}
    </Box>
  );
}

SettingsAdvanceTaxes.propTypes = {
  control: PropTypes.object,
  taxesGroup: PropTypes.array,
  register: PropTypes.func,
  setValue: PropTypes.func,
};

export default SettingsAdvanceTaxes;
