export const DEFAULT_SIZE_PAGE = 10;
export const DEFAULT_PAGE = 1;

const PROPERTY_NAME = 'name';

const DESC = 'desc';
const ASC = 'asc';

export const sortingOrderTypes = {
  DESC,
  ASC,
};

export const sortingPropertyTypes = {
  NAME: PROPERTY_NAME,
};
