/**
 * i18n.js
 *
 * This will setup the i18next library
 *
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/es'; // load on demand

dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// when Sunday is the first day of the week
dayjs.locale('es'); // use Spanish locale globally

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng: 'es-MX',
    fallbackLng: ['es-MX'],
    load: 'currentOnly',
    backend: {
      loadPath: `${process.env.PUBLIC_PATH || '/'}locales/{{lng}}/{{ns}}.json`,
    },
    ns: ['common', 'parrotErrorCode', 'commonValidation'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
