import produce from 'immer';

import { DEFAULT_SIZE_PAGE, DEFAULT_PAGE, sortingOrderTypes, sortingPropertyTypes } from 'utils/discounts';

import { getDiscounts, getDiscountDetail, createDiscount, deleteDiscount, setIsEnabledDiscount } from './actions';

export const initialState = {
  getDiscounts: {
    error: null,
    fetching: false,
    fetched: false,
    discounts: [],
    pagination: {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE_PAGE,
      count: 0,
    },
  },

  sorting: {
    property: sortingPropertyTypes.NAME,
    filterByText: '',
    direction: sortingOrderTypes.ASC,
  },

  getDiscountDetail: {
    error: null,
    fetching: false,
    fetched: false,
    discount: {},
  },

  createDiscount: {
    error: null,
    fetching: false,
    fetched: false,
    discount: {},
  },

  deleteDiscount: {
    error: null,
    fetching: false,
    fetched: false,
  },

  setIsEnabledDiscount: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const restaurantDiscountsProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getDiscounts.REQUEST:
        draft.getDiscounts.fetching = true;
        draft.getDiscounts.error = false;
        draft.getDiscounts.fetched = false;
        draft.getDiscounts.pagination = { ...initialState.getDiscounts.pagination };
        draft.sorting = {
          ...initialState.sorting,
        };
        break;

      case getDiscounts.SUCCESS:
        draft.getDiscounts.fetching = false;
        draft.getDiscounts.fetched = true;
        draft.getDiscounts.discounts = payload.discounts;
        draft.getDiscounts.pagination = {
          ...draft.getDiscounts.pagination,
          ...payload.pagination,
        };
        break;

      case getDiscounts.FAILURE:
        draft.getDiscounts.error = payload;
        draft.getDiscounts.fetching = false;
        draft.getDiscounts.fetched = false;
        break;

      case getDiscounts.SORTING:
        draft.getDiscounts.pagination = {
          ...draft.getDiscounts.pagination,
          ...initialState.getDiscounts.pagination,
        };

        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };
        break;

      case getDiscounts.PAGINATION:
        draft.getDiscounts.pagination = {
          ...draft.getDiscounts.pagination,
          ...payload,
        };
        break;

      case getDiscounts.RESET_PAGINATION:
        draft.getDiscounts.pagination = { ...initialState.getDiscounts.pagination };
        break;

      case getDiscounts.RESET_SORTING:
        draft.sorting = { ...initialState.sorting };
        break;

      case getDiscounts.RESET:
        draft.getDiscounts = { ...initialState.getDiscounts };
        break;

      // discount detail
      case getDiscountDetail.REQUEST:
        draft.getDiscountDetail = { ...initialState.getDiscountDetail, fetching: true };
        break;

      case getDiscountDetail.SUCCESS:
        draft.getDiscountDetail.fetching = false;
        draft.getDiscountDetail.fetched = true;
        draft.getDiscountDetail.discount = payload;
        break;

      case getDiscountDetail.FAILURE:
        draft.getDiscountDetail.error = payload;
        draft.getDiscountDetail.fetching = false;
        draft.getDiscountDetail.fetched = false;
        break;

      case getDiscountDetail.RESET:
        draft.getDiscountDetail = { ...initialState.getDiscountDetail };
        break;

      // create discount
      case createDiscount.REQUEST:
        draft.createDiscount = { ...initialState.createDiscount, fetching: true };
        break;

      case createDiscount.SUCCESS: {
        const discountsList = [...draft.getDiscounts.discounts];
        discountsList.unshift(payload);

        draft.createDiscount.fetching = false;
        draft.createDiscount.fetched = true;
        draft.createDiscount.discount = payload;
        draft.getDiscounts.discounts = discountsList;

        break;
      }

      case createDiscount.FAILURE:
        draft.createDiscount.error = payload;
        draft.createDiscount.fetching = false;
        draft.createDiscount.fetched = false;
        break;

      case createDiscount.RESET:
        draft.createDiscount = { ...initialState.createDiscount };
        break;

      // delete discount
      case deleteDiscount.REQUEST:
        draft.deleteDiscount = { ...initialState.deleteDiscount, fetching: true };
        break;

      case deleteDiscount.SUCCESS: {
        const discountsList = draft.getDiscounts.discounts.filter((discount) => discount.uuid !== payload);

        draft.deleteDiscount.fetching = false;
        draft.deleteDiscount.fetched = true;
        draft.getDiscounts.discounts = discountsList;

        break;
      }

      case deleteDiscount.FAILURE:
        draft.deleteDiscount.error = payload;
        draft.deleteDiscount.fetching = false;
        draft.deleteDiscount.fetched = false;
        break;

      case deleteDiscount.RESET:
        draft.deleteDiscount = { ...initialState.deleteDiscount };
        break;

      // setIsEnabled
      case setIsEnabledDiscount.REQUEST:
        draft.setIsEnabledDiscount = { ...initialState.setIsEnabledDiscount, fetching: true };
        break;

      case setIsEnabledDiscount.SUCCESS: {
        const discountLis = draft.getDiscounts.discounts.map((discount) => {
          return { ...discount };
        });
        discountLis.find((discount) => discount?.uuid === payload?.uuid).isEnabled = payload?.isEnabled;

        draft.setIsEnabledDiscount.fetching = false;
        draft.setIsEnabledDiscount.fetched = true;
        draft.getDiscounts.discounts = discountLis;
        break;
      }

      case setIsEnabledDiscount.FAILURE:
        draft.setIsEnabledDiscount.error = payload;
        draft.setIsEnabledDiscount.fetching = false;
        draft.setIsEnabledDiscount.fetched = false;
        break;

      case setIsEnabledDiscount.RESET:
        draft.setIsEnabledDiscount = { ...initialState.setIsEnabledDiscount };
        break;

      default:
        return draft;
    }
  });

export default restaurantDiscountsProviderReducer;
