import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import { useTranslation } from 'react-i18next';

import Checkbox from '@design-system/Checkbox';
import Select from '@design-system/Select';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import useStyles from './styles';
import { menuProps } from './utils';

function SelectCategories({ categories, onSelectedCategories }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [withOutCategoriesSelected, setWithOutCategoriesSelected] = useState(true);

  const stateSelectedAllCategories = (() => {
    return categoriesSelected.length === Object.keys(categories).length;
  })();

  useEffect(() => {
    const categoriesSelectedAll = [];
    map(categories, (category) => {
      categoriesSelectedAll.push(category?.uuid);
    });
    setCategoriesSelected(categoriesSelectedAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  function handleRenderNamesCategories(categoriesByUuid) {
    let renderSelected = t('menuMaker:headerForm.placeholderCategories');
    if (categoriesByUuid?.length > 0)
      renderSelected = categoriesByUuid?.map((uuid) => categories[uuid]?.name).join(', ');

    if (stateSelectedAllCategories) renderSelected = t('menuMaker:headerForm.filterProducts.allCategories');
    if (withOutCategoriesSelected) {
      renderSelected =
        categoriesByUuid?.length > 0
          ? `${renderSelected}, ${t('menuMaker:headerForm.filterProducts.withoutCategories')}`
          : t('menuMaker:headerForm.filterProducts.withoutCategories');
    }
    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  const handleSelectedCategories = (value) => {
    let newState = [...categoriesSelected];
    if (newState.includes(value)) {
      newState = [...newState].filter((item) => item !== value);
    } else {
      newState.push(value);
    }
    setCategoriesSelected(newState);
    onSelectedCategories(newState, withOutCategoriesSelected);
  };

  const handleSelectAllCategories = (isAll) => {
    setCategoriesSelected([]);
    const categoriesSelectedAll = [];
    if (!isAll) {
      map(categories, (category) => {
        categoriesSelectedAll.push(category?.uuid);
      });
      setCategoriesSelected(categoriesSelectedAll);
    }

    onSelectedCategories(categoriesSelectedAll, withOutCategoriesSelected);
  };

  const handleSelectWithoutCategories = () => {
    const tmpState = !withOutCategoriesSelected;
    setWithOutCategoriesSelected(tmpState);
    onSelectedCategories([...categoriesSelected], tmpState);
  };

  return (
    <Select
      displayEmpty
      fullWidth
      hideLabelHelperText
      label={t('menuMaker:headerForm.categories')}
      MenuProps={{ ...menuProps }}
      multiple
      name="categoriesFiltersProductField"
      renderValue={(selected) => handleRenderNamesCategories(selected)}
      value={categoriesSelected}
    >
      <MenuItem key="withoutCategories" onClick={handleSelectWithoutCategories}>
        <Checkbox checked={withOutCategoriesSelected} />
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.withoutCategories')} />
      </MenuItem>
      <MenuItem key="all" onClick={() => handleSelectAllCategories(stateSelectedAllCategories)}>
        <Checkbox checked={stateSelectedAllCategories} />
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.allCategories')} />
      </MenuItem>
      <Divider />
      {map(categories, (category) => (
        <MenuItem key={category.uuid} onClick={() => handleSelectedCategories(category.uuid)} value={category.uuid}>
          <Checkbox checked={categoriesSelected.includes(category.uuid)} />
          <ListItemText primary={category.name} />
        </MenuItem>
      ))}
    </Select>
  );
}

SelectCategories.propTypes = {
  categories: PropTypes.object,
  onSelectedCategories: PropTypes.func,
};

export default SelectCategories;
