import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Divider, Tabs } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';

import { get as getAttribute } from 'lodash';

import CheckboxWithLabel from '../CheckboxWithLabel';

import useStyles from './styles';
import OptionRule from './OptionRule';
import { SELECTION_RULES, RANGE_REQUIRED_TYPE } from './constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      style={{ padding: '25px 0' }}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `rule-tab-${index}`,
    'aria-controls': `rule-tabpanel-${index}`,
  };
}

const ModifierGroupRules = ({
  modifierGroup,
  conditionalGroup,
  conditionalRules,
  register,
  errors,
  controller,
  control,
  watch,
  getValues,
  setValue,
  setError,
  clearErrors,
  t,
}) => {
  const classes = useStyles();
  const [selectedRuleTab, setSelectedRuleTab] = React.useState(0);
  // change force selection
  const [shouldForceSelection, setShouldForceSelection] = React.useState(modifierGroup?.selectionType);

  const handleSelectRuleTab = (event, newValue) => {
    setSelectedRuleTab(newValue);
  };

  function handleSetForceSelection(value) {
    setShouldForceSelection(value);
  }

  function handleChangeForceSelection(checked) {
    const nameFields = buildRuleNames();
    const conditionalRulesValues = getAttribute(getValues(), 'conditionalRules');
    if (checked) {
      setShouldForceSelection(SELECTION_RULES.FORCE_PRODUCT_SELECTION);
      if (!conditionalRulesValues && getAttribute(getValues(), nameFields.requiredType) === RANGE_REQUIRED_TYPE) {
        setValue(nameFields.min, '1', { shouldDirty: true });
      }
    } else {
      setShouldForceSelection(null);
      if (!conditionalRulesValues && getAttribute(getValues(), nameFields.requiredType) === RANGE_REQUIRED_TYPE) {
        setValue(nameFields.min, '0', { shouldDirty: true });
      }
    }
  }

  const buildRuleNames = (index) => {
    const selectionType = Boolean(index) || index === 0 ? `conditionalRules[${index}].selectionType` : 'selectionType';
    const requiredType = Boolean(index) || index === 0 ? `conditionalRules[${index}].requiredType` : 'requiredType';
    const min = Boolean(index) || index === 0 ? `conditionalRules[${index}].min` : 'min';
    const max = Boolean(index) || index === 0 ? `conditionalRules[${index}].max` : 'max';
    const exact = Boolean(index) || index === 0 ? `conditionalRules[${index}].exact` : 'max';
    const repeated = Boolean(index) || index === 0 ? `conditionalRules[${index}].repeated` : 'repeated';
    return { selectionType, requiredType, min, max, exact, repeated };
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subtitle}>{t('menuMaker:modifierGroupForm.rulesSection.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography className={classes.accordionHeader} gutterBottom variant="h6">
          {t('menuMaker:modifierGroupForm.rulesSection.description')}
        </Typography>

        <CheckboxWithLabel
          defaultValue={shouldForceSelection === SELECTION_RULES.FORCE_PRODUCT_SELECTION}
          inputProps={{
            name: 'shouldForceSelection',
          }}
          inputRef={register()}
          label={t('menuMaker:modifierGroupForm.rulesSection.obligatorySelection')}
          onChange={handleChangeForceSelection}
        />

        <Divider className={classes.rulesDivider} variant="middle" />

        {/* JUST ONE RULE */}
        {!conditionalGroup && modifierGroup && (
          <OptionRule
            clearErrors={clearErrors}
            control={control}
            controller={controller}
            errors={errors}
            getValues={getValues}
            handleSetForceSelection={handleSetForceSelection}
            // minValue={minValueInRange}
            names={buildRuleNames()}
            register={register}
            rule={modifierGroup}
            setError={setError}
            watch={watch}
          />
        )}

        {/* CONDITIONAL RULES */}

        {conditionalRules && conditionalRules.length > 0 && (
          <div>
            <Grid container>
              <Tabs aria-label="tabs options" onChange={handleSelectRuleTab} value={selectedRuleTab}>
                {conditionalRules.map((option, ruleIndex) => (
                  <Tab key={option.uuid} label={option.name || option.product?.name} {...a11yProps(ruleIndex)} />
                ))}
              </Tabs>
            </Grid>
            {conditionalRules.map((conditionalRule, tabIndex) => (
              <TabPanel key={conditionalRule.uuid} index={tabIndex} value={selectedRuleTab}>
                <OptionRule
                  clearErrors={clearErrors}
                  control={control}
                  controller={controller}
                  errors={errors}
                  getValues={getValues}
                  names={buildRuleNames(tabIndex)}
                  register={register}
                  rule={conditionalRule}
                  setError={setError}
                  watch={watch}
                />
              </TabPanel>
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ModifierGroupRules.propTypes = {
  conditionalGroup: PropTypes.object,
  conditionalRules: PropTypes.array,
  modifierGroup: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  controller: PropTypes.func,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'), withTranslation('modifierGroups'))(ModifierGroupRules);
