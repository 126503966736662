import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    background: theme.palette.darkGrey[2],
    alignItems: 'center',
    display: 'flex',
    padding: `0 ${theme.typography.pxToRem(32)}`,
    width: '100%',
  },
  accordionAreaAction: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(24)} 0`,
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  labelSection: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
  endIconRotate: {
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
