/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider constants
 *
 */
export const GET_SUPPLIES = 'providers/inventories/InventoriesSuppliesProvider/GET_SUPPLIES';
export const GET_SUPPLIES_PAGINATION = 'providers/inventories/InventoriesSuppliesProvider/GET_SUPPLIES_PAGINATION';
export const GET_SUPPLY_DETAILS = 'providers/inventories/InventoriesSuppliesProvider/GET_SUPPLY_DETAILS';
export const CREATE_SUPPLY = 'providers/inventories/InventoriesSuppliesProvider/CREATE_SUPPLY';
export const UPDATE_SUPPLY = 'providers/inventories/InventoriesSuppliesProvider/UPDATE_SUPPLY';
export const DELETE_SUPPLY = 'providers/inventories/InventoriesSuppliesProvider/DELETE_SUPPLY';
export const ASSIGN_STORAGE_AREAS = 'providers/inventories/InventoriesSuppliesProvider/ASSIGN_STORAGE_AREAS';
