import produce from 'immer';

import baseState from 'utils/baseState';

import {
  createInventory,
  getInventories,
  deleteInventory,
  getInventoryDetails,
  updateInventory,
  getInventoriesScrollPagination,
} from './actions';

export const initialState = {
  getInventories: {
    ...baseState,
    inventories: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  paginationGetInventories: {
    ...baseState,
    lastUpdatedRows: [],
  },

  sorting: {
    filterByText: '',
  },

  getInventoryDetails: {
    ...baseState,
    inventory: {},
  },

  createInventory: {
    ...baseState,
    inventory: {},
  },

  updateInventory: {
    ...baseState,
    inventory: {},
  },

  deleteInventory: {
    ...baseState,
    inventory: {},
  },
};

const inventoriesProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getInventories.REQUEST:
        draft.getInventories = {
          ...initialState.getInventories,
          fetching: true,
        };
        break;

      case getInventories.SUCCESS:
        draft.getInventories.fetching = false;
        draft.getInventories.fetched = true;
        draft.getInventories.inventories = payload.inventories;

        draft.getInventories.pagination = {
          ...initialState.getInventories.pagination,
          ...payload.pagination,
        };
        break;

      case getInventories.SORTING:
        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };

        break;

      case getInventories.FAILURE:
        draft.getInventories = {
          ...initialState.getInventories,
          error: payload,
        };
        break;

      case getInventories.RESET:
        draft.getInventories = {
          ...initialState.getInventories,
        };
        break;

      case getInventoriesScrollPagination.REQUEST:
        draft.paginationGetInventories = {
          ...initialState.paginationGetInventories,
          fetching: true,
        };
        break;

      case getInventoriesScrollPagination.SUCCESS: {
        const currentListState = state.getInventories.inventories;

        let newsRows = [...currentListState];
        newsRows = [...newsRows, ...payload.inventories];

        draft.paginationGetInventories.fetching = false;
        draft.paginationGetInventories.fetched = true;
        draft.paginationGetInventories.lastUpdatedRows = payload.inventories;

        draft.getInventories.inventories = [...newsRows];
        draft.getInventories.pagination = {
          ...initialState.getInventories.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getInventoriesScrollPagination.FAILURE:
        draft.paginationGetInventories.error = payload;
        draft.paginationGetInventories.fetching = false;
        draft.paginationGetInventories.fetched = false;
        break;

      case getInventoriesScrollPagination.RESET:
        draft.paginationGetInventories = {
          ...initialState.paginationGetInventories,
        };
        break;

      case getInventoryDetails.REQUEST:
        draft.getInventoryDetails = {
          ...initialState.getInventoryDetails,
          fetching: true,
        };
        break;

      case getInventoryDetails.SUCCESS:
        draft.getInventoryDetails.fetching = false;
        draft.getInventoryDetails.fetched = true;
        draft.getInventoryDetails.inventory = payload;

        break;

      case getInventoryDetails.FAILURE:
        draft.getInventoryDetails = {
          ...initialState.getInventoryDetails,
          error: payload,
        };
        break;

      case getInventoryDetails.RESET:
        draft.getInventoryDetails = {
          ...initialState.getInventoryDetails,
        };
        break;

      case createInventory.REQUEST:
        draft.createInventory = {
          ...initialState.createInventory,
          fetching: true,
        };
        break;

      case createInventory.SUCCESS: {
        const inventoriesList = [...draft.getInventories.inventories];
        inventoriesList.unshift(payload);

        draft.createInventory.fetching = false;
        draft.createInventory.fetched = true;
        draft.createInventory.inventory = payload;

        draft.getInventories.inventories = inventoriesList;

        break;
      }

      case createInventory.FAILURE:
        draft.createInventory = {
          ...initialState.createInventory,
          error: payload,
        };
        break;

      case createInventory.RESET:
        draft.createInventory = {
          ...initialState.createInventory,
        };
        break;

      case updateInventory.REQUEST:
        draft.updateInventory = {
          ...initialState.updateInventory,
          fetching: true,
        };
        break;

      case updateInventory.SUCCESS: {
        const indexInventory = draft.getInventories.inventories.findIndex((inventory) => {
          return inventory.uuid === payload.inventoryUuid;
        });

        draft.updateInventory.fetching = false;
        draft.updateInventory.fetched = true;
        draft.updateInventory.inventory = payload.inventory;
        draft.getInventories.inventories[indexInventory] = payload.inventory;

        break;
      }

      case updateInventory.FAILURE:
        draft.updateInventory = {
          ...initialState.updateInventory,
          error: payload,
        };
        break;

      case updateInventory.RESET:
        draft.updateInventory = {
          ...initialState.updateInventory,
        };
        break;

      case deleteInventory.REQUEST:
        draft.deleteInventory = {
          ...initialState.deleteInventory,
          fetching: true,
        };
        break;

      case deleteInventory.SUCCESS:
        draft.deleteInventory.fetching = false;
        draft.deleteInventory.fetched = true;

        break;

      case deleteInventory.FULFILL: {
        const currentInventories = draft.getInventories.inventories.filter((inventory) => {
          return inventory.uuid !== payload;
        });

        draft.getInventories.inventories = currentInventories;
        break;
      }

      case deleteInventory.FAILURE:
        draft.deleteInventory = {
          ...initialState.deleteInventory,
          error: payload,
        };
        break;

      case deleteInventory.RESET:
        draft.deleteInventory = {
          ...initialState.deleteInventory,
        };
        break;

      default:
        return draft;
    }
  });

export default inventoriesProviderReducer;
