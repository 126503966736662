import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import ButtonPrimary from '@design-system/ButtonPrimary';

import { ViewPanelLayout } from 'Layout';

function ProductFormSkeleton({ t, actionLabel, pathGoBack }) {
  const history = useHistory();
  const theme = useTheme();

  function handleGoBack() {
    history.push({
      pathname: pathGoBack,
    });
  }

  return (
    <ViewPanelLayout
      actionComponent={<ButtonPrimary disabled>{actionLabel}</ButtonPrimary>}
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={handleGoBack}
    >
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <Grid container direction="column" spacing={3}>
            <Grid item md={9} xs={12}>
              <Skeleton variant="text" width="15%" />
              <Skeleton animation="wave" height={85} />
            </Grid>

            <Grid item md={9} xs={12}>
              <Skeleton variant="text" width="15%" />
              <Skeleton animation="wave" height={85} />
            </Grid>

            <Grid item>
              <Skeleton height={25} width="30%" />
            </Grid>
            <Grid item>
              <Skeleton height={25} width="30%" />
            </Grid>
            <Grid item>
              <Skeleton height={25} width="30%" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12}>
          <Skeleton variant="text" width="15%" />
          <Skeleton height="100%" width="40%" />
        </Grid>
      </Grid>

      <Box mt={3}>
        <Skeleton height={100} width="100%" />
        <Skeleton height={100} width="100%" />
      </Box>

      <Box mt={3}>
        <Box
          sx={{
            '& > :not(:first-child)': {
              marginTop: theme.spacing(4),
            },
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            sx={{
              '& > :not(:first-child)': {
                marginLeft: theme.spacing(3),
              },
            }}
          >
            <Skeleton height={56} variant="rect" width={326} />

            <Skeleton height={30} variant="rect" width={100} />
          </Box>

          <Box
            alignItems="center"
            display="flex"
            sx={{
              '& > :not(:first-child)': {
                marginLeft: theme.spacing(3),
              },
            }}
          >
            <Skeleton height={56} variant="rect" width={326} />

            <Skeleton height={30} variant="rect" width={100} />
          </Box>

          <Box
            alignItems="center"
            display="flex"
            sx={{
              '& > :not(:first-child)': {
                marginLeft: theme.spacing(3),
              },
            }}
          >
            <Skeleton height={56} variant="rect" width={326} />

            <Skeleton height={30} variant="rect" width={100} />
          </Box>
        </Box>
      </Box>
    </ViewPanelLayout>
  );
}

ProductFormSkeleton.propTypes = {
  t: PropTypes.func,
  pathGoBack: PropTypes.string,
  actionLabel: PropTypes.string,
};

export default withTranslation()(ProductFormSkeleton);
