import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getReportsResumeSummariesRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/general/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getReportsResumeSalesChannelsRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/provider-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getReportsResumeTopProductsRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/top-products/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getReportsResumeSalesBrandsRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/brand-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getReportsResumeSalesCategoriesRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/category-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function getReportsResumeSalesPeriodRequest({ startDate, endDate }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
    start_date: startDate,
    end_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/reports/period-sales/?${query}`, {
    method: 'GET',
    ...options,
  });
}
