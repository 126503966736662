/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider saga
 *
 */
import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';
import {
  createInventorySupplyRequest,
  getInventoriesSuppliesRequest,
  getInventorySupplyDetailsRequest,
  updateInventorySupplyRequest,
  storageAreasAssignationRequest,
} from 'utils/api/baas/inventories/supplies';

import { deleteSupplies } from 'utils/inventories/supplies/request';

import {
  getSupplies,
  getSuppliesPagination,
  getSupplyDetails,
  createSupply,
  updateSupply,
  deleteSupply,
  assignStorageAreas,
} from './actions';

export function* handleGetSupplies(action) {
  const filters = action.payload;
  try {
    yield put(getSupplies.request());

    const { data, pagination } = yield call(baasRequest, getInventoriesSuppliesRequest, filters);

    yield put(getSupplies.success({ data, pagination }));
  } catch (err) {
    yield put(getSupplies.failure(err));
  }
}

export function* handleGetSuppliesPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getSuppliesPagination.request());

    const { data, pagination } = yield call(baasRequest, getInventoriesSuppliesRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getSuppliesPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getSuppliesPagination.failure(err));
  }
}

export function* handleGetSupplyDetailsRequest(action) {
  const supplyUuid = action.payload;

  try {
    yield put(getSupplyDetails.request());

    const { data } = yield call(baasRequest, getInventorySupplyDetailsRequest, supplyUuid);

    yield put(getSupplyDetails.success(data));
  } catch (err) {
    yield put(getSupplyDetails.failure(err));
  }
}

export function* handleCreateSupplyRequest(action) {
  const fields = action.payload;

  try {
    yield put(createSupply.request());

    const { data } = yield call(baasRequest, createInventorySupplyRequest, fields);

    yield put(createSupply.success({ supply: data, supplyRawData: data?.rawData }));
  } catch (err) {
    yield put(createSupply.failure(err));
  }
}

export function* handleUpdateSupplyRequest(action) {
  const { fields, inventoryUuid } = action.payload;

  try {
    yield put(updateSupply.request());
    const { data } = yield call(baasRequest, updateInventorySupplyRequest, inventoryUuid, fields);

    yield put(updateSupply.success({ supply: data, supplyRawData: data?.rawData, inventoryUuid }));
  } catch (err) {
    yield put(updateSupply.failure(err));
  }
}

export function* handleDeleteSupplyRequest(action) {
  const supplyUuid = action.payload;
  const isArray = Array.isArray(supplyUuid);
  let wasSuccess = true;

  try {
    yield put(deleteSupply.request());

    yield call(baasRequest, deleteSupplies, isArray, supplyUuid);

    yield put(deleteSupply.success());
  } catch (err) {
    wasSuccess = false;
    yield put(deleteSupply.failure(err));
  } finally {
    if (wasSuccess) yield put(deleteSupply.fulfill(supplyUuid));
  }
}

export function* handleStorageAreasRequest(action) {
  const { items, storageAreas } = action.payload;

  try {
    yield put(assignStorageAreas.request());
    yield call(baasRequest, storageAreasAssignationRequest, { items, storageAreas });

    yield put(assignStorageAreas.success());
  } catch (err) {
    yield put(assignStorageAreas.failure(err));
  }
}

export default function* inventoriesSuppliesRootSga() {
  yield takeLatest(getSupplies.TRIGGER, handleGetSupplies);
  yield takeLatest(getSuppliesPagination.TRIGGER, handleGetSuppliesPaginationRequest);
  yield takeLatest(getSupplyDetails.TRIGGER, handleGetSupplyDetailsRequest);
  yield takeLatest(createSupply.TRIGGER, handleCreateSupplyRequest);
  yield takeLatest(updateSupply.TRIGGER, handleUpdateSupplyRequest);
  yield takeLatest(deleteSupply.TRIGGER, handleDeleteSupplyRequest);
  yield takeLatest(assignStorageAreas.TRIGGER, handleStorageAreasRequest);
}
