/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { uniq, flatten } from 'lodash';

import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import Table from '@experimental-components/Table';
import IconHelp from '@experimental-components/IconsComponents/Help';
import Menu from '@experimental-components/Menu';
import EyeOnOutline from '@experimental-components/IconsComponents/EyeOnOutline';
import EyeOffOutline from '@experimental-components/IconsComponents/EyeOffOutline';
import IconMoreVertical from '@experimental-components/IconsComponents/MoreVertical';
import IconDeleteOutline from '@experimental-components/IconsComponents/DeleteOutline';
import IconDuplicateOutline from '@experimental-components/IconsComponents/DuplicateOutline';

import IconButton from '@design-system/IconButton';
import IconButtonMui from '@material-ui/core/IconButton';

import { useHistory } from 'react-router-dom';

import PlaceholdersTable from 'components/PlaceholdersTable';
import DisplayMessage from 'components/DisplayMessageV2';
import ImageDropzoneProductList from 'components/ImageDropzoneProductList';
import ConfirmDialog from 'components/ConfirmDialog';

import useUserStore from 'hooks/useUserStore';
import { statusType } from 'utils/products';

import useStyles from './styles';

function ProductsTable({
  t,
  products,
  categoriesByUuid,
  toggleProductStock,
  deleteProduct,
  isLoading,
  deleteProductError,
  deleteProductLoading,
  deleteProductFetched,
  loadingEnabledProduct,
  onClearActions,
  onClearNotification,
  loadingUpdateProductImage,
  onUpdateProductImage,
  isSearchFilter,
  isLoadingScrollPagination,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const columns = [
    t('menuMaker:productsTable.header.image'),
    t('menuMaker:productsTable.header.name'),
    t('menuMaker:productsTable.header.price'),
    t('menuMaker:productsTable.header.menus'),
    t('menuMaker:productsTable.header.categories'),
    t('menuMaker:productsTable.header.modifiers'),
    t('menuMaker:productsTable.header.isInStock'),
    t('menuMaker:productsTable.header.actions'),
  ];

  const { storeId: storeUuid } = useUserStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPop, setAnchorElPop] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleChange = (productUuid, availability) => {
    onClearNotification();
    setSelectedProduct(productUuid);
    let availableType = statusType.STATUS_AVAILABLE;
    if (availability === statusType.STATUS_AVAILABLE) {
      availableType = statusType.STATUS_UNAVAILABLE;
    }

    return toggleProductStock({
      storeUuid,
      productUuid,
      availability: availableType,
    });
  };

  function handleDuplicate() {
    history.push(`/menus/products/details/${selectedProduct}/duplicate`);
  }

  const handleDelete = () => {
    deleteProduct({
      storeUuid,
      productUuid: selectedProduct,
    });
  };

  function handleCancelDeleteMenu() {
    setSelectedProduct(null);
    setAnchorEl(null);
    setAnchorMenu(null);
  }

  useEffect(() => {
    if (deleteProductFetched) {
      setAnchorEl(null);
      setAnchorMenu(null);
      setSelectedProduct(null);
    }
  }, [deleteProductFetched]);

  function deleteProductWithConfirm() {
    setAnchorEl(anchorMenu);
    setAnchorMenu(null);
  }

  const handleClickProductRow = (product) => {
    onClearActions();
    history.push({
      pathname: `/menus/products/details/${product.uuid}/edit`,
    });
  };

  const handleOpenActions = (product) => (event) => {
    setAnchorMenu(event.currentTarget);
    setSelectedProduct(product.uuid);
  };

  function handleCloseActions() {
    setAnchorMenu(null);
    setSelectedProduct(null);
  }

  function handleUpdateProductImage(productUuid, image) {
    setSelectedProduct(productUuid);
    onUpdateProductImage(productUuid, image);
  }

  function handlePopOverClose() {
    setAnchorElPop(null);
  }

  function handlePopOverOpen(event) {
    setAnchorElPop(event.currentTarget);
  }

  useEffect(() => {
    return () => {
      onClearNotification();
    };
  }, [onClearNotification]);

  function renderBody() {
    if (isLoading) {
      return <PlaceholdersTable placeholderCols={8} />;
    }
    return products?.map((product, index) => {
      const uniqueMenuNames = uniq(
        flatten(
          product?.categories?.map((category) =>
            categoriesByUuid[category?.category?.uuid]?.menus.map((menu) => menu.name),
          ),
        ),
      );

      return (
        <Table.Row key={product.uuid}>
          <Table.Cell align="center" isBorder={index !== 0}>
            {loadingUpdateProductImage && selectedProduct === product.uuid ? (
              <CircularProgress />
            ) : (
              <ImageDropzoneProductList
                initialImageUrl={product?.image}
                onImageChange={handleUpdateProductImage}
                productUuid={product?.uuid}
              />
            )}
          </Table.Cell>

          <Table.Cell align="left" component="th" isBorder={index !== 0} scope="row" width={198}>
            <Link
              aria-label="edit-product-location"
              className={classes.linkCell}
              color="textPrimary"
              component="button"
              onClick={() => handleClickProductRow(product)}
              underline="none"
              variant="body2"
            >
              {product.name}
            </Link>
          </Table.Cell>

          <Table.Cell isBorder={index !== 0}>${product.price}</Table.Cell>

          <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
            {uniqueMenuNames?.length > 0 ? (
              <Table.BigCell.ExpandableList
                list={uniqueMenuNames?.map((menuName) => (
                  <Table.BigCell.Option key={menuName}>{menuName}</Table.BigCell.Option>
                ))}
              />
            ) : (
              t('common:notAvailableDefault')
            )}
          </Table.BigCell>

          <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
            {product?.categories?.length > 0 ? (
              <Table.BigCell.ExpandableList
                list={product?.categories?.map(({ category }) => (
                  <Table.BigCell.Option key={category?.uuid}>{category?.name}</Table.BigCell.Option>
                ))}
              />
            ) : (
              t('common:notAvailableDefault')
            )}
          </Table.BigCell>

          <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
            {product?.modifierGroups?.length > 0 ? (
              <Table.BigCell.ExpandableList
                list={product?.modifierGroups?.map(({ modifierGroup }) => (
                  <Table.BigCell.Option key={modifierGroup?.uuid}>{modifierGroup?.name}</Table.BigCell.Option>
                ))}
              />
            ) : (
              t('common:notAvailableDefault')
            )}
          </Table.BigCell>

          <Table.Cell align="center" isBorder={index !== 0}>
            {loadingEnabledProduct && selectedProduct === product.uuid ? (
              <CircularProgress />
            ) : (
              <IconButton
                aria-label="availability-product"
                onClick={() => handleChange(product.uuid, product.availability)}
              >
                {product.availability === statusType.STATUS_AVAILABLE ? <EyeOnOutline /> : <EyeOffOutline />}
              </IconButton>
            )}
          </Table.Cell>

          <Table.Cell className={classes.actionsColumn} isBorder={index !== 0}>
            <IconButtonMui aria-label="actions" onClick={handleOpenActions(product)}>
              <IconMoreVertical />
            </IconButtonMui>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  if (!products?.length && isSearchFilter) {
    return (
      <DisplayMessage
        id="productsSearchNoData"
        maxWidth={450}
        message={t('common:messages.searchMessageNoResults')}
        sizeIcon={120}
        title={t('common:messages.searchTitleNoResults')}
      />
    );
  }

  if (!products?.length && !isLoading && !isSearchFilter) {
    return <DisplayMessage id="productsSearchNoData" message={t('menuMaker:messages.noProducts')} sizeIcon={120} />;
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row key="menu-items-table-columns">
            {columns.map((value) => {
              return value === t('menuMaker:productsTable.header.isInStock') ? (
                <Table.HeadCell key={`${value}-header`}>
                  <Box className={classes.headerCellWithIcon}>
                    {t('menuMaker:productsTable.header.isInStock')}
                    <IconButton onClick={handlePopOverOpen}>
                      <IconHelp />
                    </IconButton>
                  </Box>
                </Table.HeadCell>
              ) : (
                <Table.HeadCell
                  key={`${value}-header`}
                  align={value === t('menuMaker:productsTable.header.actions') ? 'center' : 'inherit'}
                >
                  {value}
                </Table.HeadCell>
              );
            })}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {renderBody()}
          {isLoadingScrollPagination && <PlaceholdersTable placeholderCols={8} />}
        </Table.Body>
      </Table>

      <ConfirmDialog
        anchorEl={anchorEl}
        data-testid="popperMenuDelete"
        id="popperMenuDelete"
        isDisabled={deleteProductLoading}
        labelAccept={t(`common:buttons.${deleteProductError ? 'retry' : 'delete'}`)}
        labelCancel={t('common:buttons.cancel')}
        loading={deleteProductLoading}
        onAccept={handleDelete}
        onCancel={handleCancelDeleteMenu}
        open={Boolean(anchorEl) && selectedProduct}
        title={t('menuMaker:deleteProduct.popperDialog.title')}
      />

      <Popover
        anchorEl={anchorElPop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.helperPopOver}
        onClose={handlePopOverClose}
        open={Boolean(anchorElPop)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.helperBox}>
          {t('menuMaker:productsTable.messages.inStockHelp')}
          <Box component="p" fontWeight={theme.typography.fontWeightMedium}>
            {t('menuMaker:productsTable.messages.inStockHelpEmphasis')}
          </Box>
        </Box>
      </Popover>

      <Menu anchorEl={anchorMenu} onClose={handleCloseActions} open={Boolean(anchorMenu)}>
        <Menu.Item key="duplicate-button" data-testid="duplicateButton" onClick={handleDuplicate}>
          <IconButton startIcon={<IconDuplicateOutline />}>
            <Box>{t('menuMaker:buttons.duplicate')}</Box>
          </IconButton>
        </Menu.Item>
        <Menu.Item key="trash-button" data-testid="deleteButton" onClick={deleteProductWithConfirm}>
          <IconButton startIcon={<IconDeleteOutline />}>
            <Box color="error.main">{t('common:buttons.delete')}</Box>
          </IconButton>
        </Menu.Item>
      </Menu>
    </>
  );
}

ProductsTable.propTypes = {
  t: PropTypes.func,
  products: PropTypes.array,
  categoriesByUuid: PropTypes.object,
  toggleProductStock: PropTypes.func,
  deleteProduct: PropTypes.func,
  onClearActions: PropTypes.func,
  onClearNotification: PropTypes.func,
  isLoading: PropTypes.bool,
  deleteProductError: PropTypes.bool,
  deleteProductLoading: PropTypes.bool,
  deleteProductFetched: PropTypes.bool,
  loadingEnabledProduct: PropTypes.bool,
  loadingUpdateProductImage: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  isLoadingScrollPagination: PropTypes.bool,
  onUpdateProductImage: PropTypes.func,
};

export default withTranslation('menuMaker')(ProductsTable);
