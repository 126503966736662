import React from 'react';
import PropTypes from 'prop-types';

import { FeatureContext } from './FeatureContext';

function FeatureProvider({ features, children }) {
  if (!features || features === null || typeof features !== 'object') {
    throw new TypeError('The features prop must be an object or an array.');
  }

  return <FeatureContext.Provider value={features}>{React.Children.only(children)}</FeatureContext.Provider>;
}

FeatureProvider.propTypes = {
  features: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.element,
};

export default FeatureProvider;
