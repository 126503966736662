/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@experimental-components/Table';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import IconMoreVertical from '@experimental-components/IconsComponents/MoreVertical';
import MenuActions from 'components/MenuActions';
import PlaceholdersTable from 'components/PlaceholdersTable';
import DisplayMessage from 'components/DisplayMessageV2';
import ConfirmDialog from 'components/ConfirmDialog';

import useUserStore from 'hooks/useUserStore';

import useContentLayout from 'hooks/useContentLayout';

import useStyles from './styles';

export function truncateToTwoItems(items) {
  if (items.length <= 2) return items.join(', ');
  return `${items[0]}, ${items[1]}, +${items.length - 2}`;
}

export function modifierOptionsNames(modifierOptions) {
  const namesList = [];
  modifierOptions?.forEach((option) => {
    const productName = option?.name || option?.product?.name;
    if (productName) namesList.push(productName);
  });

  return truncateToTwoItems(namesList);
}

export function modifierOptionsProductNames(appliedProducts) {
  const namesList = [];
  appliedProducts?.forEach((product) => namesList.push(product.name));
  if (appliedProducts.length <= 2) return namesList.join(', ');
  return truncateToTwoItems(namesList);
}

export function ModifierGroupsTable({
  isSearchFilter,
  loading,
  modifierGroupPagination,
  modifierGroups,
  onDeleteModifierGroup,
  onPaginate,
  paginationModifierGroupState,
  t,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { storeId: storeUuid } = useUserStore();
  const { setValue } = useContentLayout();
  const [selectedModifierGroup, setSelectedModifierGroup] = useState(null);
  const [selectedModifierGroupDelete, setSelectedModifierGroupDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorActions, setAnchorActions] = useState(null);
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    setValue(3);
  }, [setValue]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && modifierGroupPagination?.next) {
          if (!paginationModifierGroupState.fetching) {
            onPaginate();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPaginationRef, modifierGroupPagination?.next]);

  function handleDeleteModifierGroup() {
    onDeleteModifierGroup(storeUuid, selectedModifierGroupDelete);
    setSelectedModifierGroupDelete(null);
  }

  const handleConfirmDeleteModifierGroup = (modifierGroup) => (event) => {
    setSelectedModifierGroupDelete(modifierGroup);
    setAnchorEl(event.currentTarget);
    handleCloseActions();
  };

  const handleDuplicateModifierGroup = (modifierGroup) => () => {
    history.push(`/menus/modifier-groups/details/${modifierGroup.uuid}/duplicate`);
  };

  function handleCancelDeleteModifierGroup() {
    setSelectedModifierGroupDelete(null);
    setAnchorEl(null);
  }

  const handleClickModifierGroupName = (modifierGroup) => {
    history.push(`/menus/modifier-groups/details/${modifierGroup.uuid}/edit`);
  };

  const handleOpenActions = (modifierGroup) => ({ currentTarget }) => {
    setAnchorActions(currentTarget);
    setSelectedModifierGroup(modifierGroup);
  };

  const handleCloseActions = () => {
    setAnchorActions(null);
    setSelectedModifierGroup(null);
  };

  function renderTableRows() {
    if (loading) {
      return <PlaceholdersTable placeholderCols={4} />;
    }

    return modifierGroups.map((modifierGroup, index) => (
      <Table.Row key={modifierGroup.uuid}>
        <Table.Cell align="left" component="td" isBorder={index !== 0} scope="row">
          <Link
            className={classes.linkCell}
            color="textPrimary"
            component="button"
            onClick={() => handleClickModifierGroupName(modifierGroup)}
            underline="none"
            variant="body2"
          >
            {modifierGroup.name}
          </Link>
        </Table.Cell>

        <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
          {modifierGroup.options?.length ? (
            <Table.BigCell.ExpandableList
              list={modifierGroup?.options?.map((option) => (
                <Table.BigCell.Option key={option?.uuid}>{option?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.BigCell align="left" component="td" isBorder={index !== 0} scope="row">
          {modifierGroup.appliedProducts?.length ? (
            <Table.BigCell.ExpandableList
              list={modifierGroup?.appliedProducts?.map((product) => (
                <Table.BigCell.Option key={product?.uuid}>{product?.name}</Table.BigCell.Option>
              ))}
            />
          ) : (
            t('common:notAvailableDefault')
          )}
        </Table.BigCell>

        <Table.Cell align="right" isBorder={index !== 0} width={150}>
          <IconButton aria-label="actions" onClick={handleOpenActions(modifierGroup)}>
            <IconMoreVertical />
          </IconButton>
        </Table.Cell>
      </Table.Row>
    ));
  }

  if (!modifierGroups?.length && isSearchFilter) {
    return (
      <DisplayMessage
        id="productsSearchNoData"
        maxWidth={450}
        message={t('common:messages.searchMessageNoResults')}
        sizeIcon={120}
        title={t('common:messages.searchTitleNoResults')}
      />
    );
  }

  if (!modifierGroups?.length && !loading && !isSearchFilter) {
    return (
      <DisplayMessage id="productsSearchNoData" message={t('menuMaker:messages.noModifierGroups')} sizeIcon={120} />
    );
  }

  return (
    <>
      <Table aria-label="simple table">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.name')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.includes')}</Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupsTable.headers.articles')}</Table.HeadCell>
            <Table.HeadCell align="right">{t('common:messages.actions')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {renderTableRows()}
          {paginationModifierGroupState.fetching && <PlaceholdersTable placeholderCols={4} />}
        </Table.Body>
      </Table>

      <Box ref={scrollPaginationRef} mb={6} name="actionTableScroll" />

      <MenuActions
        anchorEl={anchorActions}
        onClose={handleCloseActions}
        onDelete={handleConfirmDeleteModifierGroup(selectedModifierGroup)}
        onDuplicate={handleDuplicateModifierGroup(selectedModifierGroup)}
        open={!!selectedModifierGroup}
      />

      <ConfirmDialog
        anchorEl={anchorEl}
        data-testid="popperModifierGroupDelete"
        id="popperModifierGroupDelete"
        labelAccept={t('common:buttons.delete')}
        labelCancel={t('common:buttons.cancel')}
        message={t('menuMaker:modifierGroupsTable.popper.onDelete.message')}
        onAccept={handleDeleteModifierGroup}
        onCancel={handleCancelDeleteModifierGroup}
        open={Boolean(selectedModifierGroupDelete)}
        title={t('menuMaker:modifierGroupsTable.popper.onDelete.title')}
      />
    </>
  );
}

ModifierGroupsTable.propTypes = {
  isSearchFilter: PropTypes.bool,
  loading: PropTypes.bool,
  modifierGroupPagination: PropTypes.object,
  modifierGroups: PropTypes.array,
  onDeleteModifierGroup: PropTypes.func,
  onPaginate: PropTypes.func,
  paginationModifierGroupState: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation('menuMaker')(ModifierGroupsTable);
