/* eslint-disable no-useless-return */
import { takeLatest, put, call, all } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  getReportsResumeSummariesRequest,
  getReportsResumeSalesChannelsRequest,
  getReportsResumeTopProductsRequest,
  getReportsResumeSalesBrandsRequest,
  getReportsResumeSalesCategoriesRequest,
  getReportsResumeSalesPeriodRequest,
} from 'utils/api/baas/reports/resume';

import * as actions from './actions';

export function* handleGetReportsResumeSummariesRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeSummaries.request());

    const { data: summaries } = yield call(baasRequest, getReportsResumeSummariesRequest, filters);

    yield put(actions.getReportsResumeSummaries.success(summaries));
  } catch (err) {
    yield put(actions.getReportsResumeSummaries.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeSummaries.fulfill());
  }
}

export function* handleGetReportsResumeSalesChannelsRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeSalesChannels.request());

    const { data: salesChannels } = yield call(baasRequest, getReportsResumeSalesChannelsRequest, filters);

    yield put(actions.getReportsResumeSalesChannels.success(salesChannels));
  } catch (err) {
    yield put(actions.getReportsResumeSalesChannels.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeSalesChannels.fulfill());
  }
}

export function* handleGetReportsResumeTopProductsRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeTopProducts.request());

    const { data: topProducts } = yield call(baasRequest, getReportsResumeTopProductsRequest, filters);

    yield put(actions.getReportsResumeTopProducts.success(topProducts));
  } catch (err) {
    yield put(actions.getReportsResumeTopProducts.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeTopProducts.fulfill());
  }
}

export function* handleGetReportsResumeSalesBrandsRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeSalesBrands.request());

    const { data: salesBrands } = yield call(baasRequest, getReportsResumeSalesBrandsRequest, filters);

    yield put(actions.getReportsResumeSalesBrands.success(salesBrands));
  } catch (err) {
    yield put(actions.getReportsResumeSalesBrands.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeSalesBrands.fulfill());
  }
}

export function* handleGetReportsResumeSalesCategoriesRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeSalesCategories.request());

    const { data: salesCategories } = yield call(baasRequest, getReportsResumeSalesCategoriesRequest, filters);

    yield put(actions.getReportsResumeSalesCategories.success(salesCategories));
  } catch (err) {
    yield put(actions.getReportsResumeSalesCategories.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeSalesCategories.fulfill());
  }
}

export function* handleGetReportsResumeSalesPeriodRequest(action) {
  const filters = action.payload;

  try {
    yield put(actions.getReportsResumeSalesPeriod.request());

    const { data: salesPeriod } = yield call(baasRequest, getReportsResumeSalesPeriodRequest, filters);

    yield put(actions.getReportsResumeSalesPeriod.success(salesPeriod));
  } catch (err) {
    yield put(actions.getReportsResumeSalesPeriod.failure(err));

    return { error: err };
  } finally {
    yield put(actions.getReportsResumeSalesPeriod.fulfill());
  }
}

export function* initialGetReportsResumeAll(action) {
  const result = yield all([
    call(handleGetReportsResumeSummariesRequest, action),
    call(handleGetReportsResumeSalesChannelsRequest, action),
    call(handleGetReportsResumeTopProductsRequest, action),
    call(handleGetReportsResumeSalesBrandsRequest, action),
    call(handleGetReportsResumeSalesCategoriesRequest, action),
    call(handleGetReportsResumeSalesPeriodRequest, action),
  ]);

  const [summaries, salesChannels, topProducts, salesBrands, salesCategories, salesPeriod] = result;

  if (
    summaries?.error &&
    salesChannels?.error &&
    topProducts?.error &&
    salesBrands?.error &&
    salesCategories?.error &&
    salesPeriod?.error
  ) {
    yield put(new Error());
  }

  return;
}

export function* initFlowReportsResumeAll(action) {
  try {
    yield put(actions.getReportsResumeALL.request());

    yield call(initialGetReportsResumeAll, action);

    yield put(actions.getReportsResumeALL.success());
  } catch (err) {
    yield put(actions.getReportsResumeALL.failure(err));
  } finally {
    yield put(actions.getReportsResumeALL.fulfill());
  }
}

export default function* reportsDiscountsSaga() {
  yield takeLatest(actions.getReportsResumeALL.TRIGGER, initFlowReportsResumeAll);
}
