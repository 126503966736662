import { takeLatest, put, call } from 'redux-saga/effects';

import baasRequest from 'utils/baasRequest';

import {
  createStockCategoryRequest,
  getStockCategoriesRequest,
  getStockCategoryDetailsRequest,
  updateStockCategoryRequest,
  deleteStockCategoryRequest,
} from 'utils/api/baas/inventories/stockCategories/stockCategories';

import {
  getStockCategories,
  getStockCategoriesPagination,
  getStockCategoryDetails,
  createStockCategory,
  updateStockCategory,
  deleteStockCategory,
} from './actions';

export function* handleGetStockCategoriesRequest(action) {
  const filters = action.payload;

  try {
    yield put(getStockCategories.request());

    const { data, pagination } = yield call(baasRequest, getStockCategoriesRequest, filters);

    yield put(getStockCategories.success({ data, pagination }));
  } catch (err) {
    yield put(getStockCategories.failure(err));
  }
}

export function* handleGetStockCategoriesPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getStockCategoriesPagination.request());

    const { data, pagination } = yield call(baasRequest, getStockCategoriesRequest, filters);
    const paginationCurrent = { ...pagination, page: filters?.page };
    yield put(getStockCategoriesPagination.success({ data, pagination: paginationCurrent }));
  } catch (err) {
    yield put(getStockCategoriesPagination.failure(err));
  }
}

export function* handleGetStockCategoryDetailsRequest(action) {
  const stockCategoryUuid = action.payload;

  try {
    yield put(getStockCategoryDetails.request());

    const { data } = yield call(baasRequest, getStockCategoryDetailsRequest, stockCategoryUuid);

    yield put(getStockCategoryDetails.success({ data }));
  } catch (err) {
    yield put(getStockCategoryDetails.failure(err));
  }
}

export function* handleCreateKitchenAreaRequest(action) {
  const fields = action.payload;

  try {
    yield put(createStockCategory.request());

    const { data } = yield call(baasRequest, createStockCategoryRequest, fields);

    yield put(createStockCategory.success({ data }));
  } catch (err) {
    yield put(createStockCategory.failure(err));
  }
}

export function* handleUpdateStockCategoryRequest(action) {
  const fields = action.payload;

  try {
    yield put(updateStockCategory.request());
    const { data } = yield call(baasRequest, updateStockCategoryRequest, fields);

    yield put(updateStockCategory.success(data));
  } catch (err) {
    yield put(updateStockCategory.failure(err));
  }
}

export function* handleDeleteStockCategoryRequest(action) {
  const stockCategoryUuid = action.payload;

  try {
    yield put(deleteStockCategory.request());

    yield call(baasRequest, deleteStockCategoryRequest, stockCategoryUuid);

    yield put(deleteStockCategory.success());
  } catch (err) {
    yield put(deleteStockCategory.failure(err));
  } finally {
    yield put(deleteStockCategory.fulfill(stockCategoryUuid));
  }
}

export default function* reportsOrdersProviderRootSga() {
  yield takeLatest(getStockCategories.TRIGGER, handleGetStockCategoriesRequest);
  yield takeLatest(getStockCategoryDetails.TRIGGER, handleGetStockCategoryDetailsRequest);
  yield takeLatest(getStockCategoriesPagination.TRIGGER, handleGetStockCategoriesPaginationRequest);
  yield takeLatest(createStockCategory.TRIGGER, handleCreateKitchenAreaRequest);
  yield takeLatest(updateStockCategory.TRIGGER, handleUpdateStockCategoryRequest);
  yield takeLatest(deleteStockCategory.TRIGGER, handleDeleteStockCategoryRequest);
}
