import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useFeature } from 'FeatureProvider';

import Products from 'containers/Products';
import SignIn from 'containers/SignIn';
import MenuMaker from 'containers/MenuMaker/Loadable';
import Categories from 'containers/Categories/Loadable';
import ModifierGroups from 'containers/ModifierGroups';
import ModifierGroup from 'containers/ModifierGroup';
import Product from 'containers/Product';
import MenuMakerDetails from 'containers/MenuMakerDetails/Loadable';
import SignOut from 'containers/SignOut';
import CategoryDetail from 'containers/CategoryDetail/Loadable';
import Roles from 'containers/Roles';
import Role from 'containers/Role/Loadable';
import Employees from 'containers/Employees/Loadable';
import EmployeesDetails from 'containers/EmployeesDetails/Loadable';

import Reports from 'containers/Reports/Loadable';
import ReportsPay from 'containers/ReportsPay/Loadable';
import TransferDetails from 'containers/ReportsPay/TransferDetailsLoadable';
import ReportsIndex from 'containers/ReportsIndex/Loadable';
import Invoicing from 'containers/Invoicing/Loadable';
import InvoicingConfig from 'containers/InvoicingConfig/Loadable';
import InvoicingPortalConfig from 'containers/InvoicingPortalConfig/Loadable';
import PublishMenuMaker from 'containers/MenuMakerPublish/Loadable';
import MenuMakerPreview from 'containers/MenuMakerPreview/Loadable';
import SettingsLink from 'containers/Settings/Loadable';
import InvoicesGenerated from 'containers/InvoicesGenerated/Loadable';
import InvoicingGlobal from 'containers/InvoicingGlobal/Loadable';
import Subscription from 'containers/Subscription/Loadable';
import SettingsPayIn from 'containers/SettingsPayInConfig/Loadable';
import SettingsKitchenTicket from 'containers/SettingsKitchenTicket/Loadable';
import ReportsOrders from 'containers/ReportsOrders/Loadable';
import ReportsPayments from 'containers/ReportsPayments/Loadable';
import ReportsCancellations from 'containers/ReportsCancellations/Loadable';
import ReportsDiscounts from 'containers/ReportsDiscounts/Loadable';
import ReportsResume from 'containers/ReportsResume/Loadable';
import ReportsClientData from 'containers/ReportsClientData/Loadable';

import SettingsPayments from 'containers/SettingsPayments/Loadable';
import SettingsDiscounts from 'containers/SettingsDiscounts/Loadable';
import SettingsCancellationReasons from 'containers/SettingsCancellationReasons/Loadable';
import SettingsOrderTypes from 'containers/SettingsOrderTypes/Loadable';
import SettingsReceiptCustom from 'containers/SettingsReceiptCustom/Loadable';
import SettingsServiceCharges from 'containers/SettingsServiceCharges/Loadable';
import SettingsCommissionCapture from 'containers/SettingsCommissionCapture/Loadable';
import SettingsParrotPay from 'containers/SettingsParrotPay/Loadable';
import SettingsKitchenAreas from 'containers/SettingsKitchenAreas/Loadable';
import SettingsServiceAreas from 'containers/SettingsServiceAreas/Loadable';
import SettingsAdminTables from 'containers/SettingsAdminTables/Loadable';
import SettingsTablesConfig from 'containers/SettingsTablesConfig/Loadable';
import SettingsCashRegister from 'containers/SettingsCashRegister/Loadable';
import SettingsMultiTaxes from 'containers/SettingsMultiTaxes/Loadable';

import InvoicingTicketsEditor from 'containers/InvoicingTicketsEditor/Loadable';

import InventoriesManagement from 'containers/InventoriesManagement/Loadable';
import InventoriesAdmin from 'containers/InventoriesAdmin/Loadable';
import InventoriesStockCategories from 'containers/InventoriesStockCategories/Loadable';
import InventoriesStorageAreas from 'containers/InventoriesStorageAreas/Loadable';
import InventoriesSupplies from 'containers/InventoriesSupplies/Loadable';

import AccessDeniedAdmin from 'containers/AccessDeniedAdmin/Loadable';

import OnlineOrdering from 'containers/OnlineOrdering/Loadable';

import LoggedOutPage from 'components/LoggedOutPage';
import NotFound from 'components/NotFound';
import WithoutStore from 'components/WithoutStore';

import useEnvironment from 'hooks/useEnvironment';

import AppRoute from './AppRoute';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const { environmentsState } = useEnvironment();
  const { i18n } = useTranslation();
  const accessTicketsEditorPermission = useFeature('invoicingTicketEditor');
  const superUserAccess = useFeature('adminStore');
  const menuMakerModuleAccess = useFeature('backofficeModuleMenu');
  const workforceModuleAccess = useFeature('backofficeModuleWorkforce');
  const invoicingModuleAccess = useFeature('backofficeModuleInvoicing');
  const settingsModuleAccess = useFeature('backofficeModuleSettings');
  const reportModuleAccess = useFeature('backofficeModuleReports');
  const inventoryModuleAccess = useFeature('backofficeModuleInventory');
  const subscriptionModuleAccess = useFeature('backofficeModuleSubscriptions');
  const areaTableCanViewAreaTablesAccess = useFeature('areaTableCanViewAreaTables');
  const areaTableCanViewSettingsAccess = useFeature('areaTableCanViewSettings');

  const permissionMenuMakerModule = superUserAccess || menuMakerModuleAccess;
  const permissionWorkforceModule = superUserAccess || workforceModuleAccess;
  const permissionInvoicingModule = superUserAccess || invoicingModuleAccess;
  const permissionSettingsModule = superUserAccess || settingsModuleAccess;
  const permissionReportModule = superUserAccess || reportModuleAccess;
  const permissionAreaTableCanViewAreaTablesModule = superUserAccess || areaTableCanViewAreaTablesAccess;
  const permissionAreaTableCanViewSettingsAccessModule = superUserAccess || areaTableCanViewSettingsAccess;

  const pathDefaultRedirect = (() => {
    if (superUserAccess || reportModuleAccess) return '/reports/resume';
    if (menuMakerModuleAccess) return '/menus';
    if (workforceModuleAccess) return '/workforce';
    if (invoicingModuleAccess) return '/invoicing';
    if (settingsModuleAccess) return '/settings';
    if (inventoryModuleAccess) return '/inventory';
    if (subscriptionModuleAccess) return '/subscription';
    return '/permissions-denied';
  })();

  const isActiveModuleOnlineOrdering = useFeature('@module.onlineOrdering');
  const flagEnableInventory = environmentsState.REACT_APP_FEATURE_INVENTORY_ENABLED === 'true';
  const flagEnableOnlineOrdering = environmentsState.REACT_APP_FEATURE_ONLINE_ORDERING_ENABLED === 'true';

  return (
    <>
      <Helmet defaultTitle="ParrotConnect" htmlAttributes={{ lang: i18n.language }} titleTemplate="%s - ParrotConnect">
        <meta content="ParrotConnect" name="ParrotConnect" />
        {isAuthenticated && (
          <>
            <script>
              {`
                var beamer_config = {
                  product_id: 'bZFFZpks24122',
                  filter: '${environmentsState.NODE_ENV === 'production' ? 'production' : 'staging'}',
                };
              `}
            </script>
            <script defer="defer" src="https://app.getbeamer.com/js/beamer-embed.js" type="text/javascript"></script>
          </>
        )}
      </Helmet>

      <Switch>
        <Redirect exact from="/" to={pathDefaultRedirect} />
        <Redirect exact from="/workforce" to="/workforce/roles" />
        <Route component={WithoutStore} exact path="/without-store" />
        <Route component={SignIn} exact path="/signin" />
        <Route component={LoggedOutPage} exact path="/logged-out" />
        <Route component={SignOut} exact path="/signout" />

        <AppRoute component={AccessDeniedAdmin} exact path="/permissions-denied" />

        {/* Menu Maker Module */}
        {permissionMenuMakerModule && <AppRoute component={MenuMaker} exact path="/menus/:actionType(create)?" />}
        {permissionMenuMakerModule && (
          <AppRoute
            component={MenuMakerDetails}
            exact
            path="/menus/details/:menuUuid/:actionType(duplicate)?/(edit)?/:priceType(adjust-prices)?"
          />
        )}
        {permissionMenuMakerModule && (
          <AppRoute component={Categories} exact path="/menus/categories/:actionType(create)?" />
        )}
        {permissionMenuMakerModule && (
          <AppRoute component={CategoryDetail} exact path="/menus/categories/details/:categoryUuid/(edit)?" />
        )}
        {permissionMenuMakerModule && (
          <AppRoute component={Products} exact path="/menus/products/:actionType(create)?" />
        )}
        {permissionMenuMakerModule && (
          <AppRoute
            component={Product}
            exact
            path="/menus/products/details/:productUuid/:actionType(duplicate|edit)?"
          />
        )}
        {permissionMenuMakerModule && (
          <AppRoute component={ModifierGroups} exact path="/menus/modifier-groups/:actionType(create)?" />
        )}
        {permissionMenuMakerModule && (
          <AppRoute
            component={ModifierGroup}
            exact
            path="/menus/modifier-groups/details/:modifierGroupUuid/:actionType(duplicate|edit)?"
          />
        )}
        {permissionMenuMakerModule && <AppRoute component={PublishMenuMaker} exact path="/menus/publish" />}
        {permissionMenuMakerModule && <AppRoute component={MenuMakerPreview} exact path="/menus/preview/:menuUuid" />}

        {/* Workforce Module */}
        {permissionWorkforceModule && <AppRoute component={Roles} exact path="/workforce/roles/(create)?" />}
        {permissionWorkforceModule && (
          <AppRoute component={Role} exact path="/workforce/roles/details/:roleUuid/:actionType(duplicate)?/(edit)?" />
        )}
        {permissionWorkforceModule && (
          <AppRoute component={Employees} exact path="/workforce/people/:actionType(create)?" />
        )}
        {permissionWorkforceModule && (
          <AppRoute
            component={EmployeesDetails}
            exact
            path="/workforce/people/details/:employeeUuid/:actionType(duplicate|change-password)?/(edit)?"
          />
        )}

        {/* Invoicing Module */}
        {permissionInvoicingModule && <AppRoute component={Invoicing} exact path="/invoicing" />}
        {permissionInvoicingModule && (
          <AppRoute component={InvoicesGenerated} exact path="/invoicing/generated/:type(global|customer)" />
        )}
        {permissionInvoicingModule && (
          <AppRoute
            component={InvoicingPortalConfig}
            exact
            path="/invoicing/portal-config/:actionType(create|edit)?/:uuid?"
          />
        )}
        {permissionInvoicingModule && (
          <AppRoute component={InvoicingConfig} exact path="/invoicing/config/:actionType(create|edit)?/:uuid?" />
        )}
        {permissionInvoicingModule && <AppRoute component={InvoicingGlobal} exact path="/invoicing/global" />}
        {accessTicketsEditorPermission && (
          <AppRoute component={InvoicingTicketsEditor} exact path="/invoicing/tickets-editor" />
        )}

        {/* Settings Module */}
        {permissionSettingsModule && <AppRoute component={SettingsLink} exact path="/settings" />}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsPayments}
            exact
            path="/settings/payment-methods/:actionType(duplicate|create|edit)?/:paymentUuid?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsDiscounts}
            exact
            path="/settings/payments-discounts/:actionType(duplicate|create|edit)?/:discountUuid?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsCancellationReasons}
            exact
            path="/settings/payments-cancellation-reasons/:actionType(create)?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsOrderTypes}
            exact
            path="/settings/order-types/:actionType(duplicate|create|edit)?/:diningOptionUuid?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsReceiptCustom}
            exact
            path="/settings/receipt-custom/:actionType(duplicate|create|edit)?/:brandUuid?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute component={SettingsKitchenTicket} exact path="/settings/kitchen-ticket/" />
        )}
        {permissionSettingsModule && (
          <AppRoute component={SettingsPayIn} exact path="/settings/payin/:actionType(create|edit)?/:reasonUuid?" />
        )}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsServiceCharges}
            exact
            path="/settings/payments-service-charges/:actionType(duplicate|create|edit)?/:serviceChargeUuid?"
          />
        )}
        {permissionSettingsModule && (
          <AppRoute component={SettingsCommissionCapture} exact path="/settings/commission-capture" />
        )}
        {permissionSettingsModule && <AppRoute component={SettingsParrotPay} exact path="/settings/pay" />}
        {permissionSettingsModule && (
          <AppRoute
            component={SettingsKitchenAreas}
            exact
            path="/settings/kitchen-areas/:actionType(duplicate|create|edit)?/:kitchenAreaUuidParam?"
          />
        )}

        {/* Reports Module */}
        {permissionReportModule && <AppRoute component={ReportsIndex} exact path="/reports" />}
        {permissionReportModule && <AppRoute component={ReportsResume} exact path="/reports/resume" />}

        {permissionReportModule && (
          <AppRoute
            component={Reports}
            exact
            path="/reports/:type(products-sales|cash-register)/:reportFormat(table|lines|list|details)?/:key?/:typeView(all|part)?/:itemType?"
          />
        )}
        {permissionReportModule && <AppRoute component={ReportsPay} exact path="/reports/transactions-pay" />}
        {permissionReportModule && <AppRoute component={ReportsPay} exact path="/reports/transfers-pay" />}
        {permissionReportModule && <AppRoute component={TransferDetails} exact path="/reports/transfers-pay/details" />}
        {permissionReportModule && <AppRoute component={ReportsPay} exact path="/reports/movements-pay" />}

        {permissionReportModule && <AppRoute component={ReportsOrders} exact path="/reports/orders" />}

        {permissionReportModule && <AppRoute component={ReportsPayments} exact path="/reports/payments/summary" />}
        {permissionReportModule && <AppRoute component={ReportsPayments} exact path="/reports/payments/payments" />}

        {permissionReportModule && (
          <AppRoute
            component={ReportsCancellations}
            exact
            path="/reports/cancellations/:reportFormat(summary|detailed)?"
          />
        )}
        {permissionReportModule && (
          <AppRoute component={ReportsDiscounts} exact path="/reports/discounts/:reportFormat(summary|detailed)?" />
        )}

        {permissionReportModule && (
          <AppRoute
            component={ReportsClientData}
            exact
            path="/reports/client-data/:type(summary|details)/:clientUuid?"
          />
        )}

        {permissionAreaTableCanViewAreaTablesModule && (
          <AppRoute component={SettingsServiceAreas} exact path="/settings/tables-service-areas" />
        )}

        {permissionAreaTableCanViewAreaTablesModule && (
          <AppRoute
            component={SettingsServiceAreas}
            exact
            path="/settings/tables-service-areas/:actionType(create|edit)?/:serviceAreaUuid?"
          />
        )}

        {permissionAreaTableCanViewAreaTablesModule && (
          <AppRoute
            component={SettingsAdminTables}
            exact
            path="/settings/tables-admin/:actionType(create|edit)?/:tableUuid?"
          />
        )}

        {permissionAreaTableCanViewSettingsAccessModule && (
          <AppRoute component={SettingsTablesConfig} exact path="/settings/tables-config" />
        )}

        {permissionAreaTableCanViewSettingsAccessModule && (
          <AppRoute component={SettingsCashRegister} exact path="/settings/cash-register" />
        )}

        <AppRoute component={SettingsMultiTaxes} exact path="/settings/taxes" />

        {/* start @flag inventories */}
        {flagEnableInventory && <AppRoute component={InventoriesManagement} exact path="/inventories" />}
        {flagEnableInventory && (
          <AppRoute
            component={InventoriesAdmin}
            exact
            path="/inventories/inventories-admin/:actionType(create|edit)?/:inventoryUuid?"
          />
        )}

        {flagEnableInventory && (
          <AppRoute component={InventoriesAdmin} exact path="/inventories/inventories-admin/:actionType(create)?" />
        )}

        {flagEnableInventory && (
          <AppRoute
            component={InventoriesStockCategories}
            exact
            path="/inventories/stock-categories/:actionType(create|edit)?/:stockCategoryUuidParam?"
          />
        )}

        {flagEnableInventory && (
          <AppRoute
            component={InventoriesStorageAreas}
            exact
            path="/inventories/storage-areas/:actionType(create|edit)?/:storageAreaUuidParam?"
          />
        )}

        {flagEnableInventory && (
          <AppRoute
            component={InventoriesSupplies}
            exact
            path="/inventories/supplies/:actionType(create|edit|duplicate)?/:supplyUuidParam?"
          />
        )}

        {/* end @flag inventories */}

        {isActiveModuleOnlineOrdering && flagEnableOnlineOrdering && (
          <AppRoute component={OnlineOrdering} exact path="/online-ordering" />
        )}

        {/* {isActiveModuleOnlineOrdering && flagEnableOnlineOrdering && ( */}
        <AppRoute
          component={OnlineOrdering}
          exact
          path="/online-ordering/:type(brand-settings|data-settings|operation-settings|zone-settings|payments-gateway)?/:key?"
        />
        {/* )} */}

        <AppRoute component={Subscription} exact path="/subscription/(payment-history|invoice-data)?/(edit)?" />

        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
