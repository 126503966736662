import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MenuItem, Select, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { regexTypes } from 'utils/formatField';
import { get as getAttribute } from 'lodash';

import InfoToolTip from '../InfoToolTip';

import { EXACT_REQUIRED_TYPE, RANGE_REQUIRED_TYPE, REQUIRED_TYPES } from './constants';
import useStyles from './styles';

const OptionRule = ({
  controller: Controller,
  control,
  rule,
  errors,
  watch,
  names,
  getValues,
  setError,
  clearErrors,
  handleSetForceSelection,
  t,
}) => {
  const classes = useStyles();
  const isRange = watch([`${names.requiredType}`]);
  const errorMin = getAttribute(errors, names.min);
  const errorMax = getAttribute(errors, names.max);
  const errorExact = getAttribute(errors, names.exact);
  const errorRepeated = getAttribute(errors, names.repeated);

  const shouldForceSelection = (() => {
    return getAttribute(getValues(), 'shouldForceSelection');
  })();

  const defaultMinValeField = (() => {
    return shouldForceSelection ? '1' : '0';
  })();

  const handleChangeMinValueInRange = (event) => {
    clearErrors(names.max);
    const conditionalRulesValues = getAttribute(getValues(), 'conditionalRules');
    const maxValue = parseInt(getAttribute(getValues(), names.max), 10);
    const minValue = parseInt(event.target.value, 10);

    if (minValue >= 1 && minValue > maxValue) {
      setError(names.max, {
        type: 'matchesValidRange',
        message: t('menuMaker:modifierGroupForm.errors.maxShouldBeGreatherThanMin'),
      });
    }

    if (isRange[`${names.requiredType}`] === RANGE_REQUIRED_TYPE && !conditionalRulesValues) {
      if (minValue >= 1 && maxValue > minValue) {
        handleSetForceSelection('FORCE_PRODUCT_SELECTION');
      } else {
        handleSetForceSelection(null);
      }
    }
  };

  return (
    <div>
      <Grid alignItems="center" className={classes.selectionContainer} container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.subtitle} gutterBottom variant="h6">
            {t('menuMaker:modifierGroupForm.rulesSection.optionsLabel')}
          </Typography>
          <Typography className={classes.subtitleDescription} gutterBottom variant="h6">
            {t('menuMaker:modifierGroupForm.rulesSection.quantityPickerLabel')}
          </Typography>
        </Grid>

        <Grid item style={{ marginBottom: 15 }} xs={12}>
          <FormControl variant="outlined">
            <Controller
              control={control}
              defaultValue={rule.requiredType}
              name={names.requiredType}
              render={({ onChange, value, name, ref }) => (
                <Select inputRef={ref} name={name} onChange={onChange} value={value}>
                  {REQUIRED_TYPES.map((valueOPtion) => (
                    <MenuItem key={valueOPtion} value={valueOPtion}>
                      {t(`menuMaker:modifierGroupForm.selectionType.${valueOPtion}`)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {isRange[`${names.requiredType}`] === RANGE_REQUIRED_TYPE && (
            <>
              <Controller
                control={control}
                defaultValue={rule.min ?? defaultMinValeField}
                name={names.min}
                render={({ onChange, ...others }) => (
                  <TextField
                    error={Boolean(errorMin)}
                    helperText={errorMin?.message}
                    inputProps={{ type: 'number' }}
                    onChange={(e) => {
                      onChange(e);
                      handleChangeMinValueInRange(e);
                    }}
                    {...others}
                    variant="outlined"
                  />
                )}
                rules={{
                  required: t('common:errors.requiredField'),
                  pattern: {
                    value: regexTypes.REGEX_ONLY_NUMBERS_NO_NEGATIVES,
                    message: t('common:errors.noNegatives'),
                  },
                }}
              />
              <Controller
                as={
                  <TextField
                    error={Boolean(errorMax)}
                    helperText={errorMax?.message}
                    inputProps={{ type: 'number', max: 20 }}
                    variant="outlined"
                  />
                }
                control={control}
                defaultValue={rule.max ?? parseInt(defaultMinValeField, 10) + 1}
                name={names.max}
                rules={{
                  required: t('common:errors.requiredField'),
                  pattern: {
                    value: regexTypes.REGEX_ONLY_NUMBERS_NO_NEGATIVES,
                    message: t('common:errors.noNegatives'),
                  },
                  validate: {
                    matchesValidRange: (value) => {
                      const min = getAttribute(getValues(), names.min)
                        ? parseInt(getAttribute(getValues(), names.min), 10)
                        : 0;
                      const max = parseInt(value, 10);
                      return min < max || t('menuMaker:modifierGroupForm.errors.maxShouldBeGreatherThanMin');
                    },
                    maxValueAllowed: (value) => value <= 20 || t('menuMaker:modifierGroupForm.errors.maxValueAllowed'),
                  },
                }}
              />
            </>
          )}
          {isRange[`${names.requiredType}`] === EXACT_REQUIRED_TYPE && (
            <Controller
              as={
                <TextField
                  error={Boolean(errorExact)}
                  helperText={errorExact?.message}
                  inputProps={{ type: 'number', max: '20' }}
                  variant="outlined"
                />
              }
              control={control}
              defaultValue={(rule.exact || rule.max) ?? '1'}
              name={names.exact}
              rules={{
                required: t('common:errors.requiredField'),
                pattern: {
                  value: regexTypes.REGEX_ONLY_NUMBERS_1_9,
                  message: t('common:errors.numbersOneToNine'),
                },
                validate: (value) => value <= 20 || t('menuMaker:modifierGroupForm.errors.maxValueAllowed'),
              }}
            />
          )}
        </Grid>
        <Grid className={classes.maxSelectionAmountContainer} item xs={12}>
          <Typography className={classes.maxSelectionAmountContainer} gutterBottom variant="h6">
            {t('menuMaker:modifierGroupForm.maxSelectionAmount.label')}
            <InfoToolTip message={<>{t('menuMaker:modifierGroupForm.maxSelectionAmount.tooltip')}</>}>
              <HelpOutlineOutlinedIcon />
            </InfoToolTip>
          </Typography>
          <Controller
            as={
              <TextField
                error={Boolean(errorRepeated)}
                helperText={errorRepeated?.message}
                inputProps={{ type: 'number', max: 20 }}
                variant="outlined"
              />
            }
            control={control}
            defaultValue={rule.repeated || ''}
            name={names.repeated}
            rules={{
              required: t('common:errors.requiredField'),
              pattern: {
                value: regexTypes.REGEX_ONLY_NUMBERS_NO_NEGATIVES,
                message: t('common:errors.noNegatives'),
              },
              validate: (value) => value <= 20 || t('menuMaker:modifierGroupForm.errors.maxValueAllowed'),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

OptionRule.propTypes = {
  t: PropTypes.func,
  rule: PropTypes.object,
  errors: PropTypes.object,
  names: PropTypes.object,
  control: PropTypes.object,
  controller: PropTypes.func,
  getValues: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  handleSetForceSelection: PropTypes.func,
  watch: PropTypes.func,
};
export default compose(withTranslation('menuMaker'), withTranslation('modifierGroups'))(OptionRule);
