import withStyles from '@material-ui/core/styles/withStyles';
import DialogMui from '@material-ui/core/Dialog';
import DialogTitleMui from '@material-ui/core/DialogTitle';
import DialogActionsMui from '@material-ui/core/DialogActions';
import DialogContentMui from '@material-ui/core/DialogContent';

const DialogBase = withStyles((theme) => ({
  paperWidthMd: {
    borderRadius: theme.shape.borderRadiusLarge,
    maxWidth: theme.typography.pxToRem(916),
    padding: theme.spacing(5),
  },
}))(DialogMui);

const DialogTitle = withStyles((theme) => ({
  root: {
    padding: 0,
    marginBottom: theme.spacing(5),
  },
}))(DialogTitleMui);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    marginBottom: theme.spacing(5),
  },
}))(DialogContentMui);

const DialogActions = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(5),
    },
  },
}))(DialogActionsMui);

function Dialog({ open, children, ...others }) {
  return (
    <DialogBase open={open} {...others}>
      {children}
    </DialogBase>
  );
}

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.Actions = DialogActions;

Dialog.propTypes = {
  ...Dialog.propTypes,
};

export default Dialog;
