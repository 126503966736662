import { takeLatest, put, call, take, fork, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import history from 'utils/history';
import baasRequest from 'utils/baasRequest';
import { authRefreshTokenRequest, getUserRequest, getLicenseStateRequest } from 'utils/api/baas/auth';
import {
  getAuthToken,
  setAuthToken,
  getCookieUser,
  getUserStoresStoreKey,
  getUserCurrentStore,
  channelStateLicenseInterval,
  isRedirectToSubscription,
  SECONDS_TO_CHANEL_CALL,
} from 'utils/auth';

import { refreshToken, getUser, getLicenseState, clearLicenseStateChannel, getLicenseStateChannel } from './actions';

export function* handleRefreshTokenRequest() {
  try {
    yield put(refreshToken.request());

    const { refresh, access } = yield call(authRefreshTokenRequest, getAuthToken()?.refreshToken);

    setAuthToken({ accessToken: access, refreshToken: refresh });

    yield put(refreshToken.success());
  } catch (err) {
    yield put(refreshToken.failure(err));
  }
}

export function* handleGetUserRequest() {
  const user = getCookieUser();
  const allStores = getUserStoresStoreKey();
  const store = getUserCurrentStore();
  const hasAccessMoreStores = allStores?.length > 1;
  try {
    yield put(getUser.request());

    const { data } = yield call(baasRequest, getUserRequest, { userUuid: user?.uuid, storeUuid: store?.uuid });

    data.stores = allStores;
    data.hasAccessMoreStores = hasAccessMoreStores;

    yield put(getUser.success(data));
  } catch (err) {
    yield put(getUser.failure(err));
  }
}

export function* handleGetLicenseState() {
  try {
    yield put(getLicenseState.request());
    const { data } = yield call(baasRequest, getLicenseStateRequest);

    yield put(getLicenseState.success(data));

    if (isRedirectToSubscription(data.status, history?.location?.pathname)) {
      yield put(push('/subscription'));
    }

    yield delay(SECONDS_TO_CHANEL_CALL);
    yield put(getLicenseStateChannel.trigger());
  } catch (err) {
    yield put(getLicenseState.failure(err));
  }
}

export function* handleGetLicenseStateChannel() {
  const channelLicense = yield call(channelStateLicenseInterval);

  // eslint-disable-next-line func-names
  yield fork(function* () {
    yield take(clearLicenseStateChannel.TRIGGER);
    channelLicense.close();
  });

  try {
    while (true) {
      yield take(channelLicense);

      try {
        yield put(getLicenseStateChannel.request());
        const { data } = yield call(baasRequest, getLicenseStateRequest);
        yield put(getLicenseStateChannel.success(data));

        if (isRedirectToSubscription(data.status, history?.location?.pathname)) {
          yield put(push('/subscription'));
        }
      } catch (error) {
        yield put(getLicenseStateChannel.failure(error));
      } finally {
        yield put(getLicenseStateChannel.fulfill());
      }
    }
  } finally {
    channelLicense.close();
  }
}

export default function* auth() {
  yield takeLatest(refreshToken.TRIGGER, handleRefreshTokenRequest);
  yield takeLatest(getUser.TRIGGER, handleGetUserRequest);
  yield takeLatest(getLicenseState.TRIGGER, handleGetLicenseState);
  yield takeLatest(getLicenseStateChannel.TRIGGER, handleGetLicenseStateChannel);
}
