import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 2rem 2rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
  },

  subtitle: {
    display: 'block',
    width: '100%',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
  },

  subtitleDescription: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(20),
  },

  accordionDetails: {
    display: 'block',
  },

  accordionHeader: {
    marginTop: '0 !important',
  },

  rulesDivider: {
    margin: `${theme.typography.pxToRem(25)} 0`,
  },

  selectionContainer: {
    '& div': {
      margin: `0  ${theme.typography.pxToRem(6)}`,
    },
  },

  maxSelectionAmountContainer: {
    display: 'flex',
    marginTop: theme.typography.pxToRem(6),
    '& div': {
      margin: `0  ${theme.typography.pxToRem(6)}`,
    },
  },
  containerProducts: {
    border: `1px solid ${theme.palette.darkGrey[5]}`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    width: 'fit-content',
  },
  dividerOption: {
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.darkGrey[5]}`,
    },
  },
  itemProduct: {
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 0 ',
    fontSize: '1rem',
  },
}));

export default useStyles;
