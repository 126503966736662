import { useMemo } from 'react';

import { Redirect } from 'react-router-dom';
import getDisplayName from 'utils/getDisplayName';

import useLicenseState from 'hooks/useLicenseState';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

export default function withRequiredLicense() {
  return (Component) => (props) => {
    const { licenseStatus } = useLicenseState();

    const isRedirectToSubscriptionPath = useMemo(() => {
      return (
        licenseStatus === subscriptionStatusTypes.UNAVAILABLE ||
        licenseStatus === subscriptionStatusTypes.PAYMENT_REQUIRED
      );
    }, [licenseStatus]);

    if (isRedirectToSubscriptionPath) {
      return <Redirect to="/subscription" />;
    }

    Component.displayName = `withRequiredLicense(${getDisplayName(Component)})`;
    return <Component {...props} />;
  };
}
