import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

function Layout({ children, ...other }) {
  return (
    <Box display="flex" flexDirection="column" width="100%" {...other}>
      {children}
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export { default as ContentLayout } from './ContentLayout';
export { default as ViewPanelLayout } from './ViewPanelLayout';
export default Layout;
