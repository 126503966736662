import React, { memo } from 'react';

import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { modifierOptionsNames } from '../ModifierGroupsTable';

import useStyles from './styles';

function ModifierGroupSelect({ currentModifierGroup, modifierGroups, conditionalGroup, updateOptionsContext, t }) {
  const classes = useStyles();
  const getModifierGroupLabel = (modifierGroup) => modifierGroup.name;
  return (
    <Box className={classes.modifierGroupContainer}>
      <Autocomplete
        getOptionLabel={getModifierGroupLabel}
        getOptionSelected={(option, value) => option.uuid === value?.uuid}
        noOptionsText={t('menuMaker:modifierGroupForm.labels.emptyModifierGroupsConditionalGroup')}
        onChange={(event, newValue) => {
          updateOptionsContext(newValue);
        }}
        options={modifierGroups.filter(
          (item) => (currentModifierGroup && item.uuid !== currentModifierGroup.uuid) || !currentModifierGroup,
        )}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              label={t('menuMaker:modifierGroupForm.labels.selectModifieGroup')}
              variant="outlined"
            />
          </div>
        )}
        renderOption={(modifierGroup) => (
          <Box className={classes.modifierGroupBox}>
            <Typography className={classes.modifierGroupName} gutterBottom variant="body1">
              {modifierGroup.name}
            </Typography>
            <Typography className={classes.modifierGroupOptions} gutterBottom variant="body1">
              {modifierOptionsNames(modifierGroup.options)}
            </Typography>
          </Box>
        )}
        value={conditionalGroup}
      />
    </Box>
  );
}

ModifierGroupSelect.propTypes = {
  currentModifierGroup: PropTypes.object,
  modifierGroups: PropTypes.array,
  conditionalGroup: PropTypes.object,
  updateOptionsContext: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'), memo)(ModifierGroupSelect);
