import { takeLatest } from 'redux-saga/effects';

import { getProduct, editProduct, updateProductPrice } from '../actions';

import { handleGetProductRequest } from './getProduct';
import { handleEditProductRequest } from './editProduct';
import { handleSetNewProductPrice } from './updateProductPrice';

export default function* productsSaga() {
  yield takeLatest(getProduct.TRIGGER, handleGetProductRequest);
  yield takeLatest(editProduct.TRIGGER, handleEditProductRequest);
  yield takeLatest(updateProductPrice.TRIGGER, handleSetNewProductPrice);
}
