/* eslint-disable no-unused-vars */
import produce from 'immer';

import { getProduct, editProduct, updateProductPrice } from './actions';

export const initialState = {
  getProduct: {
    fetching: false,
    fetched: false,
    error: null,
    product: {},
  },
  editProduct: {
    fetching: false,
    fetched: false,
    error: null,
    product: {},
  },
  changeProductPrice: {
    error: null,
    fetching: false,
    fetched: false,
  },
};

const productReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getProduct.REQUEST:
        draft.getProduct = { ...initialState.getProduct, fetching: true };
        break;

      case getProduct.SUCCESS:
        draft.getProduct.fetched = true;
        draft.getProduct.fetching = false;
        draft.getProduct.product = payload;
        break;

      case getProduct.FAILURE:
        draft.getProduct.fetching = false;
        draft.getProduct.fetched = false;
        draft.getProduct.error = payload.error;
        break;

      case getProduct.RESET:
        draft.getProduct = { ...initialState.getProduct };
        break;

      // edit product
      case editProduct.REQUEST:
        draft.editProduct = { ...initialState.editProduct, fetching: true };
        break;

      case editProduct.SUCCESS:
        draft.editProduct.fetched = true;
        draft.editProduct.fetching = false;
        draft.editProduct.product = payload;
        break;

      case editProduct.FAILURE:
        draft.editProduct.fetched = false;
        draft.editProduct.fetching = false;
        draft.editProduct.error = payload.error;
        break;

      case editProduct.RESET:
        draft.editProduct = { ...initialState.editProduct };
        break;

      // update product price
      case updateProductPrice.REQUEST:
        draft.changeProductPrice.fetching = true;
        draft.changeProductPrice.error = false;
        draft.changeProductPrice.fetched = false;
        break;

      case updateProductPrice.SUCCESS:
        draft.changeProductPrice.fetching = false;
        draft.changeProductPrice.fetched = true;
        break;

      case updateProductPrice.FAILURE:
        draft.changeProductPrice.error = payload;
        draft.changeProductPrice.fetching = false;
        draft.changeProductPrice.fetched = false;
        break;

      case updateProductPrice.RESET:
        draft.changeProductPrice = { ...initialState.changeProductPrice };
        break;

      default:
        return draft;
    }
  });

export default productReducer;
