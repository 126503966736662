import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getProductsOptionsRequest({ filterByText, page = 1, orderBy, soldAlone }, options = {}) {
  const query = queryString.stringify({
    store_uuid: getUserCurrentStore()?.uuid,
    page_size: 50,
    page,
    name: filterByText,
    sold_alone: soldAlone,
    order_by: orderBy,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/products/search/?${query}`, {
    method: 'GET',
    ...options,
  });
}
