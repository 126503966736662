/* eslint-disable no-unused-vars */
/**
 *
 * ParrotSoftware
 * InventoriesSuppliesProvider reducer
 *
 */
import produce from 'immer';
import baseState from 'utils/baseState';

import {
  getSupplies,
  getSuppliesPagination,
  getSupplyDetails,
  createSupply,
  updateSupply,
  deleteSupply,
  assignStorageAreas,
} from './actions';

export const initialState = {
  supplies: {
    ...baseState,
    suplies: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  supplyDetails: {
    ...baseState,
    supply: {},
  },

  createSupply: {
    ...baseState,
    supply: {},
  },

  updateSupply: {
    ...baseState,
  },

  deleteSupply: {
    ...baseState,
  },

  suppliesPagination: {
    ...baseState,
    lastUpdatedRows: [],
  },

  assignStorageAreas: {
    ...baseState,
  },
};

const inventoriesSuppliesReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getSupplies.REQUEST:
        draft.supplies = {
          ...initialState.supplies,
          fetching: true,
        };
        break;

      case getSupplies.SUCCESS:
        draft.supplies.fetching = false;
        draft.supplies.fetched = true;
        draft.supplies.suplies = payload.data;
        draft.supplies.pagination = {
          ...initialState.supplies.pagination,
          ...payload.pagination,
        };
        break;

      case getSupplies.FAILURE:
        draft.supplies.error = payload;
        draft.supplies.fetching = false;
        draft.supplies.fetched = false;
        break;

      case getSupplies.RESET:
        draft.supplies = {
          ...initialState.supplies,
        };
        break;

      case getSuppliesPagination.REQUEST:
        draft.suppliesPagination = {
          ...initialState.suppliesPagination,
          fetching: true,
        };
        break;

      case getSuppliesPagination.SUCCESS: {
        const { suplies: currentSuppliesState } = draft.supplies;

        let newsRows = [...currentSuppliesState.rows];
        newsRows = [...newsRows, ...payload.data.rows];

        draft.suppliesPagination.fetching = false;
        draft.suppliesPagination.fetched = true;
        draft.suppliesPagination.lastUpdatedRows = payload.data.rows;
        draft.supplies.suplies.rows = newsRows;
        draft.supplies.pagination = {
          ...initialState.suppliesPagination.pagination,
          ...payload.pagination,
        };
        break;
      }

      case getSuppliesPagination.FAILURE:
        draft.suppliesPagination.error = payload;
        draft.suppliesPagination.fetching = false;
        draft.suppliesPagination.fetched = false;
        break;

      case getSuppliesPagination.RESET:
        draft.suppliesPagination = {
          ...initialState.suppliesPagination,
        };
        break;

      case getSupplyDetails.REQUEST:
        draft.supplyDetails = {
          ...initialState.supplyDetails,
          fetching: true,
        };
        break;

      case getSupplyDetails.SUCCESS:
        draft.supplyDetails.fetching = false;
        draft.supplyDetails.fetched = true;
        draft.supplyDetails.supply = payload;
        break;

      case getSupplyDetails.FAILURE:
        draft.supplyDetails.error = payload;
        draft.supplyDetails.fetching = false;
        draft.supplyDetails.fetched = false;
        break;

      case getSupplyDetails.RESET:
        draft.supplyDetails = {
          ...initialState.supplyDetails,
        };
        break;

      case createSupply.REQUEST:
        draft.createSupply = {
          ...initialState.createSupply,
          fetching: true,
        };
        break;

      case createSupply.SUCCESS: {
        const suppliesList = [...draft.supplies.suplies.rows];
        suppliesList.unshift(payload.supplyRawData);

        draft.createSupply.fetching = false;
        draft.createSupply.fetched = true;
        draft.createSupply.supply = payload.supply;

        draft.supplies.suplies.rows = suppliesList;
        break;
      }

      case createSupply.FAILURE:
        draft.createSupply.error = payload;
        draft.createSupply.fetching = false;
        draft.createSupply.fetched = false;
        break;

      case createSupply.RESET:
        draft.createSupply = {
          ...initialState.createSupply,
        };
        break;

      case updateSupply.REQUEST:
        draft.updateSupply = {
          ...initialState.updateSupply,
          fetching: true,
        };
        break;

      case updateSupply.SUCCESS: {
        const indexSupply = draft.supplies.suplies.rows.findIndex((supply) => supply.uuid === payload.inventoryUuid);

        draft.updateSupply.fetching = false;
        draft.updateSupply.fetched = true;
        draft.supplies.suplies.rows[indexSupply] = payload.supplyRawData;
        break;
      }

      case updateSupply.FAILURE:
        draft.updateSupply.error = payload;
        draft.updateSupply.fetching = false;
        draft.updateSupply.fetched = false;
        break;

      case updateSupply.RESET:
        draft.updateSupply = {
          ...initialState.updateSupply,
        };
        break;

      case deleteSupply.REQUEST:
        draft.deleteSupply = {
          ...initialState.deleteSupply,
          fetching: true,
        };
        break;

      case deleteSupply.SUCCESS:
        draft.deleteSupply.fetching = false;
        draft.deleteSupply.fetched = true;
        draft.deleteSupply.suplies = payload;
        break;

      case deleteSupply.FULFILL: {
        const isArray = Array.isArray(payload);
        const list = draft.supplies.suplies?.rows || [];
        const rows = list.filter((supply) => {
          if (isArray) return payload.indexOf(supply.uuid) < 0;
          return supply.uuid !== payload;
        });
        draft.supplies.suplies.rows = rows;
        break;
      }

      case deleteSupply.FAILURE:
        draft.deleteSupply.error = payload;
        draft.deleteSupply.fetching = false;
        draft.deleteSupply.fetched = false;
        break;

      case deleteSupply.RESET:
        draft.deleteSupply = {
          ...initialState.deleteSupply,
        };
        break;

      case assignStorageAreas.REQUEST:
        draft.assignStorageAreas = {
          ...initialState.assignStorageAreas,
          fetching: true,
        };
        break;

      case assignStorageAreas.SUCCESS:
        draft.assignStorageAreas.fetching = false;
        draft.assignStorageAreas.fetched = true;
        break;

      case assignStorageAreas.FAILURE:
        draft.assignStorageAreas.error = payload;
        draft.assignStorageAreas.fetching = false;
        draft.assignStorageAreas.fetched = false;
        break;

      case assignStorageAreas.RESET:
        draft.assignStorageAreas = {
          ...initialState.assignStorageAreas,
        };
        break;

      default:
        return draft;
    }
  });

export default inventoriesSuppliesReducer;
