import produce from 'immer';

import baseState from 'utils/baseState';

import {
  getStorageAreas,
  getStorageAreasPagination,
  getStorageAreaDetails,
  createStorageArea,
  updateStorageArea,
  deleteStorageArea,
} from './actions';

export const initialState = {
  storageAreas: {
    ...baseState,
    storageAreas: [],
    pagination: {
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
  },

  sorting: {
    filterByText: '',
  },

  storageAreaDetails: {
    ...baseState,
    storageArea: {},
  },

  createStorageArea: {
    ...baseState,
    storageArea: {},
  },

  updateStorageArea: {
    ...baseState,
  },

  deleteStorageArea: {
    ...baseState,
  },

  storageAreasPagination: {
    ...baseState,
    lastUpdatedRows: [],
  },
};

const storageAreasProviderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getStorageAreas.REQUEST:
        draft.storageAreas = {
          ...initialState.storageAreas,
          fetching: true,
        };
        break;

      case getStorageAreas.SUCCESS:
        draft.storageAreas.fetching = false;
        draft.storageAreas.fetched = true;
        draft.storageAreas.storageAreas = payload.data;
        draft.storageAreas.pagination = {
          ...initialState.storageAreas.pagination,
          ...payload.pagination,
        };
        break;

      case getStorageAreas.SORTING:
        draft.sorting = {
          ...draft.sorting,
          ...payload,
        };

        break;

      case getStorageAreas.RESET_SORTING:
        draft.sorting = { ...initialState.sorting };
        break;

      case getStorageAreas.FAILURE:
        draft.storageAreas.error = payload;
        draft.storageAreas.fetching = false;
        draft.storageAreas.fetched = false;
        break;

      case getStorageAreas.RESET:
        draft.storageAreas = {
          ...initialState.storageAreas,
        };
        break;

      case getStorageAreaDetails.REQUEST:
        draft.storageAreaDetails = {
          ...initialState.storageAreaDetails,
          fetching: true,
        };
        break;

      case getStorageAreaDetails.SUCCESS:
        draft.storageAreaDetails.fetching = false;
        draft.storageAreaDetails.fetched = true;
        draft.storageAreaDetails.storageArea = payload.data;
        break;

      case getStorageAreaDetails.FAILURE:
        draft.storageAreaDetails.error = payload;
        draft.storageAreaDetails.fetching = false;
        draft.storageAreaDetails.fetched = false;
        break;

      case getStorageAreaDetails.RESET:
        draft.storageAreaDetails = {
          ...initialState.storageAreaDetails,
        };
        break;

      case createStorageArea.REQUEST:
        draft.createStorageArea = {
          ...initialState.createStorageArea,
          fetching: true,
        };
        break;

      case createStorageArea.SUCCESS: {
        const list = [...draft.storageAreas.storageAreas];
        list.push(payload.data);
        draft.createStorageArea.fetching = false;
        draft.createStorageArea.fetched = true;
        draft.createStorageArea.storageArea = payload.data;
        draft.storageAreas.storageAreas = list;
        break;
      }

      case createStorageArea.FAILURE:
        draft.createStorageArea.error = payload;
        draft.createStorageArea.fetching = false;
        draft.createStorageArea.fetched = false;
        break;

      case createStorageArea.RESET:
        draft.createStorageArea = {
          ...initialState.createStorageArea,
        };
        break;

      case updateStorageArea.REQUEST:
        draft.updateStorageArea.fetching = true;
        draft.updateStorageArea.fetched = false;
        draft.updateStorageArea.error = false;
        break;

      case updateStorageArea.SUCCESS: {
        const index = draft.storageAreas.storageAreas.findIndex((storageArea) => {
          return storageArea.uuid === payload.uuid;
        });

        draft.updateStorageArea.fetching = false;
        draft.updateStorageArea.fetched = true;
        draft.updateStorageArea.reason = payload;
        draft.storageAreas.storageAreas[index] = payload;
        break;
      }

      case updateStorageArea.FAILURE:
        draft.updateStorageArea.error = payload;
        draft.updateStorageArea.fetched = false;
        draft.updateStorageArea.fetching = false;
        break;

      case updateStorageArea.RESET:
        draft.updateStorageArea = { ...initialState.updateStorageArea };
        break;

      case deleteStorageArea.REQUEST:
        draft.deleteStorageArea.fetching = true;
        draft.deleteStorageArea.error = false;
        draft.deleteStorageArea.fetched = false;
        break;

      case deleteStorageArea.SUCCESS: {
        draft.deleteStorageArea.fetching = false;
        draft.deleteStorageArea.fetched = true;
        break;
      }

      case deleteStorageArea.FULFILL: {
        const list = draft.storageAreas.storageAreas.filter((storageArea) => {
          return storageArea.uuid !== payload;
        });
        draft.storageAreas.storageAreas = list;
        break;
      }

      case deleteStorageArea.FAILURE:
        draft.deleteStorageArea.error = payload;
        draft.deleteStorageArea.fetching = false;
        draft.deleteStorageArea.fetched = false;
        break;

      case deleteStorageArea.RESET:
        draft.deleteStorageArea = { ...initialState.deleteStorageArea };
        break;

      case getStorageAreasPagination.REQUEST:
        draft.storageAreasPagination = {
          ...initialState.storageAreasPagination,
          fetching: true,
        };
        break;

      case getStorageAreasPagination.SUCCESS: {
        const currentStorageAreasState = state.storageAreas.storageAreas;

        let newsRows = [...currentStorageAreasState];
        newsRows = [...newsRows, ...payload.data];

        draft.storageAreasPagination.fetching = false;
        draft.storageAreasPagination.fetched = true;
        draft.storageAreasPagination.lastUpdatedRows = payload.data;

        draft.storageAreas.storageAreas = [...newsRows];
        draft.storageAreas.pagination = {
          ...initialState.storageAreasPagination.pagination,
          ...payload.pagination,
        };

        break;
      }

      case getStorageAreasPagination.FAILURE:
        draft.storageAreasPagination.error = payload;
        draft.storageAreasPagination.fetching = false;
        draft.storageAreasPagination.fetched = false;
        break;

      case getStorageAreasPagination.RESET:
        draft.storageAreasPagination = {
          ...initialState.storageAreasPagination,
        };
        break;

      default:
        return draft;
    }
  });

export default storageAreasProviderReducer;
