import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useRouteMatch } from 'react-router-dom';

import Tabs from '@design-system/Tabs';

import useContentLayout from 'hooks/useContentLayout';

function TabLink({ to, value, ...props }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setValue } = useContentLayout();

  React.useEffect(() => {
    /*
     * This is needed in order to remove all the query string params from the url
     * Example:
     * '/dashboard?query=1&query=2'
     * is converted to:
     * '/dashboard'
     */
    const toUrl = to.split('?')[0];
    if (toUrl === url) {
      setValue(value);
    }
  }, [value, setValue, to, url]);

  const handleNavigation = useCallback(() => {
    history.push(to);
  }, [history, to]);

  return <Tabs.Tab onClick={handleNavigation} {...props} />;
}

TabLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
};

export default TabLink;
