import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& div[role=group]': {
      columnGap: theme.typography.pxToRem(20),
      justifyContent: 'space-between',

      '& button': {
        border: `${theme.typography.pxToRem(1)} solid #E3E5F6`,
        borderRadius: theme.typography.pxToRem(5),
        padding: theme.typography.pxToRem(20),

        '&:enabled': {
          color: 'black',
        },
      },
    },
  },

  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  labelIcon: {
    marginLeft: theme.typography.pxToRem(5),
  },
}));

export default useStyles;
