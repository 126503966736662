import { stringify } from 'query-string';
import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getModifierGroupsRequest({ storeUuid, name, page = 1, allModifiers }, options = {}) {
  const query = stringify({
    store_uuid: storeUuid,
    name: name ?? undefined,
    page,
    page_size: allModifiers ? undefined : 20,
  });

  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/?${query}`, { method: 'GET', ...options });
}

export function deleteModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/${modifierGroupUuid}/?store_uuid=${storeUuid}`, {
    method: 'DELETE',
    ...options,
  });
}

export function duplicateModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/duplicate/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      uuid: modifierGroupUuid,
      objectType: 'MODIFIER_GROUP',
    }),
    ...options,
  });
}

export function getModifierGroupRequest({ storeUuid, modifierGroupUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/${modifierGroupUuid}/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function createModifierGroupRequest({ storeUuid, modifierGroup }, options = {}) {
  const body = {
    ...modifierGroup,
    store: storeUuid,
  };
  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/?store_uuid=${storeUuid}`, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
}

export function updateModifierGroupRequest({ storeUuid, modifierGroup }, options = {}) {
  // eslint-disable-next-line no-unused-vars
  const { uuid, ...toUpdate } = modifierGroup;
  return request(`${API_BASE_URL}/v1/menu-maker/modifier-groups/${modifierGroup.uuid}/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: JSON.stringify(toUpdate),
    ...options,
  });
}
