import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';
import isString from 'lodash/isString';

import { useAuth } from 'providers/root/AuthProvider';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { withSignIn } from 'providers/root/SignInProvider';
import SignInForm from 'components/SignInForm';

import useLicenseState from 'hooks/useLicenseState';

import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';

export function SignIn({ initSignIn, authenticated, signInFetching, signInError, resetInitSignIn, location }) {
  const query = qs.parse(location.search.substr(1));

  const { loadLicenseState } = useAuth();
  const { licenseStatus, licenseState } = useLicenseState();

  useEffect(() => {
    if (!isString(query.continue)) {
      window.location.replace(`${window.location.origin}/`);
    }
  }, [query.continue]);

  useEffect(() => {
    if (authenticated) {
      loadLicenseState();
    }
  }, [authenticated, loadLicenseState]);

  useEffect(() => {
    if (authenticated) {
      if (licenseState.fetched && licenseStatus === subscriptionStatusTypes.AVAILABLE) {
        window.location.replace(`${window.location.origin}/`);
      }

      if (licenseState.fetched && licenseStatus !== subscriptionStatusTypes.AVAILABLE) {
        window.location.replace(`${window.location.origin}/subscription`);
      }
    }
  }, [authenticated, licenseState.fetched, licenseStatus]);

  function handleSubmit({ user, password }) {
    initSignIn({ user, password });
  }

  function handleResetSignIn() {
    resetInitSignIn();
  }

  return (
    <SignInForm
      error={Boolean(signInError)}
      loading={signInFetching}
      onResetSignIn={handleResetSignIn}
      onSubmit={handleSubmit}
    />
  );
}

SignIn.propTypes = {
  initSignIn: PropTypes.func,
  authenticated: PropTypes.bool,
  signInFetching: PropTypes.bool,
  signInError: PropTypes.bool,
  resetInitSignIn: PropTypes.func,
  location: PropTypes.object,
};

export default compose(withSignIn, withRouter, memo)(SignIn);
