import { rest } from 'msw';

import { ReportUser } from './ReportUser';
import { getTranctions, getTransfers, getMovements } from './controllers/reports/transactions-pay';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api/`;
const URL_BASE_PAY = `${process.env.REACT_APP_BASE_URL_API_PAY}/api/`;

export const handlers = [
  rest.get(`${URL_BASE}reports/users/`, ReportUser),
  rest.get(`${URL_BASE_PAY}transactions/store/:storeId`, getTranctions),
  rest.get(`${URL_BASE_PAY}transactions/grouped/store/:storeId`, getTransfers),
  rest.get(`${URL_BASE_PAY}store/:storeId/movements`, getMovements),
];
