import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuMui from '@material-ui/core/Menu';
import MenuItemMui from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    background: theme.palette.background.default,
    boxShadow: `0px 8px 40px  ${theme.palette.darkGrey[5]}`,
    borderRadius: 4,
    padding: '6px 0',
    width: '100%',
    maxWidth: 200,
    minWidth: 200,
  },
}));

const MenuItem = withStyles((theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))(MenuItemMui);

function Menu({ children, classes: classesProp, ...others }) {
  const classes = useStyles();

  return (
    <MenuMui
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      classes={{ paper: classes.menuPaper, ...classesProp }}
      elevation={0}
      getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      {...others}
    >
      {children}
    </MenuMui>
  );
}

Menu.Item = MenuItem;

Menu.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default Menu;
